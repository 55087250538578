<template>
  <div>

    <!-- user_permissoes:{{user_permissoes}} <br/><br/> -->

    <!-- qual_importar:{{ qual_importar }} <br/><br/> -->
    <!-- dados_importados:{{ dados_importados }} <br/><br/> -->
    <!-- {{zapzap_mostrar}} -->
    <!-- {{zapzap_conectado}} -->
    <!-- {{ item_edita.ENVIAR_PARAR }} -->
    <!-- item_edita:{{ item_edita }} <br/> -->
    <!-- {{ obj_dados_empresa }} -->
    <!-- {{ superA }} -->
    <!-- {{item_edita.URL_FILE}} -->
    <!-- {{ value_expansion }} -->
    <!-- {{ buscar_nomes_model }} -->
    <!-- {{ header_nomes }} -->
    <!-- {{selected}} -->

    <!-- obj_clicado:{{ obj_clicado }} -->
    <!-- <br/> -->
    <!-- <br/> -->


    <!-- {{ item_sub_edita }} -->

    <!-- {{ items_nomes }} -->
    <!-- campanha_selecionou: {{ campanha_selecionou }} -->
    <!-- items_campanha:{{ items_campanha }} <br/> -->
    <!-- {{ items_editaveis }} -->
    <!-- {{ zapzap_conectado }} -->



    <input
      type="file"
      ref="fileInput"
      accept="*"
      @input="handleFileUpload"
      style="display: none;"
    />


    <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>





      <template>
      <div class="text-center">
        <v-dialog
          v-model="dialog_ok"
          width="500"
          persistent
        >


          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{  (dialog_ok_tipo == 'conectar_zap') ? 'Concluído':''  }}
            </v-card-title>

            <v-card-text>
              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog_ok_clicou(dialog_ok_tipo), dialog_ok=false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>


    <v-container v-if="(user_permissoes.alt_convenio)">
        <v-row dense class="orange lighten-5">

          <!-- campanhas -->
          <v-col cols="12" md="3" class="grey">
              <v-card class="mx-auto"> 

                <v-card-title>
                    <v-icon color=""  class="mr-1">mdi-flag-variant </v-icon>
                    Campanhas ({{ items_campanha.length }})
                    <v-spacer/>

                    <v-btn x-small fab color="primary" @click="nova_tabela('item_edita','dialog_tabela')">
                      <v-icon>mdi-plus</v-icon>
                      <!-- <v-icon>mdi-flag-variant-plus </v-icon> -->
                    </v-btn>

                </v-card-title>

                <v-container class="elevation-4 mb-1" >
                    <v-row dense>
                        <v-col>

                        </v-col>
                    </v-row>
                </v-container>

                <v-data-table
                    v-model="selected"
                    item-key="CODIGO"
                    @click:row="seleciona_campanha"

                    :headers="header_campanha"
                    :items="items_campanha"
                    class="elevation-1 pl-1"
                    :items-per-page="-1"
                    
                    :mobile-breakpoint="55"
                    hide-default-footer
                    dense
                    :height="(isMobile)? '300' : retorna_altura(120)"
                    >

                    <template v-slot:item.actions="{ item }">
                        <v-icon
                            class="mr-2"
                            @click="editar_tabela(item, 'item_edita', 'dialog_tabela'), table_codigo = item.CODIGO"
                            color="blue"
                            size="18"
                        >
                        mdi-pencil
                        </v-icon>
                    </template>
                    

                    

                    <template v-slot:item.x_DATA="{ item }">
                        <v-chip small>
                           <span class="fs-9 mr-1">{{ item.CODIGO }}</span> 
                           <span class="fs-12">{{ item.x_DATA }}</span>
                        </v-chip>
                    </template>

                    <template v-slot:item.TITULO="{ item }">
                        <v-chip
                         color="info"
                         small
                         active-class="white--text"
                        >
                        <a class="white--text">{{ item.TITULO }}</a>
                        </v-chip>
                    </template>



                </v-data-table>
              </v-card>
          </v-col>  
          

          <!-- nomes -->
          <v-col cols="12" md="4" class="grey" v-if="obj_clicado.CODIGO">

              <v-card class="mx-auto"> 

                  <v-card-title>
                      <v-icon color=""  class="mr-1">mdi-account </v-icon>

                      Contatos ({{ items_nomes.length }}) 
                      
                      <v-spacer/>

                      <v-btn x-small fab color="primary" 
                      @click="abrir_janela_modal(null,'novo'), buscar_nomes_model='Todos'">
                      <!-- <v-icon>mdi-account-plus</v-icon> -->
                      <v-icon>mdi-account-search </v-icon>
                      </v-btn>

                  </v-card-title>

                  <v-card-text class="px-1 pb-1">
                  <v-container class="elevation-4 pt-5 pb-2" >
                      <v-row dense>
                          <v-col>
                              <v-text-field
                                  dense
                                  v-model="item_sub_edita.NOME"
                                  label="Nome"
                                  type="text"
                                  :rules="notnullRules"
                                  id="id_nome"


                                  @keydown="firstUpper($event, item_sub_edita.NOME)"
                                  @keypress.enter="focus_by_id('id_numero')"
                              ></v-text-field>
                              <!-- @keydown="firstUpper($event, item_sub_edita.NOME)" -->
                          </v-col>
                          
                          <v-col>
                              <v-text-field
                                  dense
                                  v-model="item_sub_edita.NUMERO"
                                  label="Numero"
                                  type="text"
                                  :rules="notnullRules"
                                  id="id_numero"

                                  @keypress.enter="add_contato()"

                                  append-outer-icon="mdi-send"
                                  @click:append-outer="add_contato()"
                              ></v-text-field>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>



              <v-container class="elevation-4 mb-1 " >
                      <v-row dense class="" >

                          <v-col class="pt-3 " md="6" cols="12">
   
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
      
                                  <v-icon
                                  class="pb-1"
                                  color="primary"
                                  size="28"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-menu
                                </v-icon>

                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(item, index) in menu_nomes"
                                    :key="index"
                                    dense
                                    @click="clicou_menu_nomes(item.acao)"
                                    v-if="(retorna_titulo(item.title, item.acao))"
                                  >
                                    <v-list-item-title>
                                      {{ retorna_titulo(item.title, item.acao) }}
                                      <!-- <hr> -->
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>


                              <v-chip small class="ml-2 mt-n1" color="info">
                                {{ obj_clicado.TITULO }}
                              </v-chip>

                          </v-col>


                          <v-col class="" md="3" cols="6">
                            <v-text-field
                              class="mt-3"
                              dense
                              v-model="search"
                              clearable
                              append-icon="mdi-magnify"
                              label="Procurar"
                              single-line                
                              hide-details
                              @keyup.esc="search=''"
                              @keyup="search = search.toLowerCase()"
                              >
                              
                              </v-text-field>
                            </v-col>
                          
                            <v-col class="" md="3" cols="6">
        
                                <v-select
                                class="mt-3"
                                v-model="buscar_nomes_model"
                                :items="items_buscar_nomes"
                                label="Filtrar"
                                dense
                                
                                hide-details=""
                              ></v-select>


                            </v-col>
                      </v-row>
                  </v-container>

                  <v-data-table
                      v-model="selected"
                      item-key="CODIGO"

                      :headers="header_nomes"
                      :items="items_nomes"
                      class="elevation-1 pl-1"
                      :items-per-page="-1"

                      :search="search"
                      
                      :mobile-breakpoint="55"
                      hide-default-footer
                      dense
                      :height="(isMobile)? '300' : retorna_altura(240)"

                      
                      >
                      <!-- @contextmenu:row="rightClick" -->

                      <template v-slot:item.NOME="{ item }">
                        <v-chip small 
                         :color="(item.ID_WHATS) ? 'success' : ''"
                         @dblclick="volta_pendente(item)"
                         class="hand"
                        >
                        
                        <span>
                          {{ item.NOME }}
                        </span>
                        </v-chip>

                      </template>

                      <template v-slot:item.NUMERO="{ item, index }">

                        <v-icon
                          v-if="(item.ID_WHATS)"
                          class="mx-1"
                          color="green"
                          size="20"
                        >
                          mdi-whatsapp
                        </v-icon>

                        <v-chip small 
                        :color="(item.ID_WHATS) ? 'success' : ''">
                        <span>
                          <span style=" text-align: right; font-size: 10px;" class="grey--text pa-0 mt-2 ml-0"> {{ index + 1 }}º &nbsp </span>

                          {{ item.NUMERO }}
                        </span>
                        </v-chip>
                      </template>

                      <template v-slot:item.actions_delete="{ item }">
                          <v-icon
                              class="mr-2"
                              @click="remover_esse(item)"
                              color="red"
                              size="18"
                          >
                          mdi-delete
                          </v-icon>
                      </template>


                      <template v-slot:item.actions="{ item }">
                          <v-icon
                              class="mr-2"
                              @click="editar_tabela(item, 'item_edita', 'dialog_tabela'), table_codigo = item.CODIGO"
                              color="blue"
                              size="18"
                          >
                          mdi-pencil
                          </v-icon>
                      </template>

                      

                      <template v-slot:item.x_DATA="{ item }">
                          <v-chip small>
                          <span class="fs-9 mr-1">{{ item.CODIGO }}</span> 
                          <span class="fs-12">{{ item.x_DATA }}</span>
                          </v-chip>
                      </template>

                      <template v-slot:item.TITULO="{ item }">
                          <v-chip
                          color="success"
                          small
                          active-class="white--text"
                          >
                          <a class="white--text">{{ item.TITULO }}</a>
                          </v-chip>
                      </template>



                  </v-data-table>
              </v-card>
            
          </v-col> 
          
          <!-- card dicas -->
          <v-col v-else cols="12" md="4" class="grey">

              <template>
                <v-card
                  class="mx-auto overflow-y-auto" 
                  :height="retorna_altura(30)"
                  color=""
                  max-width="700"
                >
                <!-- color="#26c6da" -->

                <v-img
                  
                  aspect-ratio="2.5"
                >
                <!-- :src="xx" -->
                <!-- gradient="to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)" -->


                  <v-card-title class="mb-3">
                    <v-icon
                      left
                      size="28"
                      color="orange"
                    >
                      mdi-whatsapp
                    </v-icon>
                    <h5 class="text-h5 font-weight-bold orange--text">Dicas</h5> 
                  </v-card-title>

                  

                  <!-- ITEM 1 -->
                  <v-card-subtitle>
                    <h5 class="text-h5 font-weight-bold black--text "> Frequência de Mensagens </h5>
                  </v-card-subtitle>

                  <v-card-text>
                    <span>
                      Enviar muitas mensagens pode sobrecarregar os clientes e levar ao cancelamento da assinatura. Além disso, os clientes podem ficar aborrecidos se receberem mensagens em horários inconvenientes, como tarde da noite ou de manhã cedo.
                    </span>
                  </v-card-text>

                  <v-card-text>
                    <span>
                      <b>Solução:</b> Esteja atento à frequência das mensagens que você envia aos seus clientes. Considere os fusos horários de seus clientes e envie mensagens durante o horário apropriado. Permita que os clientes escolham a frequência das mensagens que recebem.
                    </span>
                  </v-card-text>
                  
                  
                  
                  <!-- ITEM 1.2 -->
                  <v-card-subtitle>
                    <h5 class="text-h5 font-weight-bold black--text "> Dê opção para seu Cliente </h5>
                  </v-card-subtitle>

                  <v-card-text>
                    <span>
                      Vamos abordar primeiro por que você precisa de uma opção de <b>PARAR</b> ao enviar mensagens. 
                      Bem, se você não tiver, seus usuários poderão usar a opção <span class="red--text bold">'Denunciar spam'</span> quando receberem a mensagem. 
                      Isso pode fazer com que você seja bloqueado no WhatsApp. Portanto, é sempre seguro enviar a opção de <b>PARAR</b> recebimento desse tipo de mensagem.
                    </span>
                  </v-card-text>

                  <v-card-text>
                    <span>
                     <b> Solução:</b> Envie junto com sua mensagens a opção de poder <b>PARAR o recebimento desse tipo de mensagem.</b>
                    </span>
                  </v-card-text>

                  

                  <!-- ITEM 2 -->
                  <v-card-subtitle>
                    <h5 class="text-h5 font-weight-bold black--text "> Mensagens Irrelevantes</h5>
                  </v-card-subtitle>

                  <v-card-text>
                    <span>
                      Enviar mensagens que não são relevantes para os clientes pode fazer com que eles percam o interesse e cancelem a assinatura da sua lista. Por exemplo, se você é dono de um restaurante e envia mensagens sobre descontos em pratos de carne para vegetarianos, é improvável que eles se interessem.            </span>
                  </v-card-text>

                  <v-card-text>
                    <span>
                      <b> Solução:</b> Segmente sua lista de clientes e envie mensagens direcionadas para grupos específicos. Ao fazer isso, os clientes receberão apenas mensagens relevantes para eles, o que melhorará sua experiência.            </span>
                  </v-card-text>


                  <!-- ITEM 3 -->
                  <v-card-subtitle>
                    <h5 class="text-h5 font-weight-bold black--text ">
                      Momento Ruim
                    </h5>
                  </v-card-subtitle>

                  <v-card-text>
                    <span>
                      Os clientes podem cancelar a inscrição da sua lista do WhatsApp se receberem mensagens em horários inconvenientes. Por exemplo, os clientes podem não gostar de receber mensagens durante o horário de trabalho ou quando estão no meio de uma tarefa importante.            
                    </span>
                  </v-card-text>

                  <v-card-text>
                    <span>
                      <b> Solução:</b> Envie mensagens em horários adequados.
                    </span>
                  </v-card-text>


                  <!-- ITEM 4 -->
                  <v-card-subtitle>
                    <h5 class="text-h5 font-weight-bold black--text ">
                      Falta de Valor
                    </h5>
                  </v-card-subtitle>

                  <v-card-text>
                    <span>
                      Se os clientes não encontrarem valor nas mensagens que recebem, é provável que cancelem a assinatura. Mensagens percebidas como spam ou que não agregam valor aos clientes podem fazer com que eles percam o interesse            
                    </span>
                  </v-card-text>

                  <v-card-text>
                    <span>
                      <b> Solução:</b> Certifique-se de que as mensagens enviadas aos seus clientes sejam informativas e valiosas. Use mensagens para fornecer ofertas exclusivas ou informações valiosas que os clientes não podem receber em outro lugar.
                    </span>
                  </v-card-text>




                  </v-img>
                </v-card>
              </template>

          </v-col>

          <!-- texto -->
          <v-col cols="12" md="4" class="grey pr-2" v-if="obj_clicado.CODIGO">
            
            <!-- v-if="items_nomes.length>0" -->


          <!-- PAINEL ESQUERDA -->
            <v-card 
            flat
            elevation="5"
            class="mt-0 grey lighten-3 mb-4 overflow-y-auto"
            scrollable
            :height="retorna_altura(150)"
            >
              <v-expansion-panels 
                :multiple="true"
                :focusable='false'
                v-model="value_expansion"
                >


                <!-- PARAMETROS -->
                <v-expansion-panel>
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  
                  >
                    <div>
                      <v-row
                      >
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="20"
                            >
                            mdi-text-box-multiple-outline  
                            </v-icon> 
                            <span class="blue--text text-h6"> Parâmetros</span>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="py-0 ma-0">

                  <v-row class="">

                      <v-col cols="12" class="pa-0 mt-1">
                        <v-card  class="ma-2" >
                            <v-card-text>                        
                              <v-chip-group column >
                                  <v-chip
                                      v-for="(item, i) in tags_empresa"
                                      :key="i"
                                      
                                      @click="insere_tag(item)"
                                  >

                                  <span> {{replace_emojiHERE(item)}} </span>
                                  
                                  </v-chip>
                              </v-chip-group>
                            </v-card-text>
                        </v-card>
                  </v-col>
                  </v-row>


                  </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- MENSAGEM -->
                <v-expansion-panel>
                  <v-expansion-panel-header 
                  class="pa-2 px-4"
                  style="min-height: 28px"
                  
                  >
                    <div>
                      <v-row
                      >
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon
                             size="20"
                            >
                            mdi-text-box-multiple-outline  
                            </v-icon> 
                            <span class="blue--text text-h6"> Mensagem</span>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content class="py-0 ma-0">

                  <v-row class="">
                     <v-col cols="12" class="pa-0 mt-1">
                        <v-card class="ma-2 pa-4" elevation="4">
                          <v-textarea
                              v-model="item_edita.TEXTO"
                              label="Mensagem"
                              dense
                              filled
                              background-color="#FAFAFA"
                              hide-details
                              :height="(isMobile) ? '500' : retorna_altura(430)"
                          >
                          </v-textarea>


                          <v-checkbox
                            v-model="item_edita.ENVIAR_PARAR"
                            label="* Caso não queira mais receber esse tipo de mensagem, responda PARAR"
                            dense
                            class="ml-0 pt-3"
                            style="margin-left: 8px"  
                            hide-details 
                        >
                        <template v-slot:label>
                          <span class="red--text ml-n2 bold" style="font-size:12px !important">
                            Enviar: * Caso não queira mais receber esse tipo de mensagem, responda PARAR"
                          </span>
                        </template>
                      </v-checkbox>


                          <v-text-field
                              v-model="item_edita.URL_FILE"
                              v-if="(!superA)"
                              label="PATH IMAGEM"
                              dense
                          ></v-text-field>
                          
                          <v-img
                            v-if="item_edita.URL_FILE"
                            :src="item_edita.URL_FILE"
                            max-width="500"
                            max-height="300"
                            class="mt-2"
                          ></v-img>

                        </v-card>
                     </v-col>
                  </v-row>


                  <v-row class="">
                     <v-col cols="6" class="pa-0 mt-1">
                      
                          <v-file-input
                            v-model="selectedFile"
                            show-size
                            show-overflow
                            label="Imagem ou PDF"
                            @change="upload_logo(selectedFile, session_bd+'/whats_lab', 'anexo_'+campanha_selecionou.CODIGO, 'URL_FILE')"
                          >
                        </v-file-input>
                     </v-col>
                     
                     <v-col cols="6" class="pa-0 mt-6 pr-2 text-right" v-if="item_edita.URL_FILE">
                        
                        <v-btn x-small color="error"
                          @click="item_edita.URL_FILE='', selectedFile=null"
                        >
                          Remover Anexo
                        </v-btn>
                     </v-col>
                  </v-row>

                  </v-expansion-panel-content>
                </v-expansion-panel>


              </v-expansion-panels>
            </v-card>




              <v-card class="mx-auto ma-1"> 
                <v-row dense class="grey lighten-4">

                

                  <v-col cols="12" class="">
                    <v-card class="ma-2 pa-4" elevation="4">
                       <v-card-actions>
                        
                        <v-btn color="primary" @click="salvar_msg" :disabled="(!item_edita.TEXTO)" small>
                            Salvar mensagem
                            <!-- <v-icon small class="ml-2"> mdi-send  </v-icon> -->
                        </v-btn>

                        <v-spacer></v-spacer>


                        <!-- mostra caso esteja conectado no modulo whatsapp -->
                        <v-btn
                         :color="(zapzap_conectado) ? 'success' : 'orange'" 
                         @click="disparar_whats()" :disabled="(!item_edita.TEXTO)||(!items_nomes.length>0)" v-if="(zapzap_mostrar) && (zapzap_conectado)">
                            Disparar
                            <v-icon  class="ml-2" size="28" > mdi-whatsapp  </v-icon>
                        </v-btn>


                        <!-- mostra esse botao caso nao esteja conectado no modulo whatsapp -->
                        <v-btn
                            :color="(zapzap_conectado) ? 'success' : 'orange'"
                            dark

                            class="mx-2"
                            v-if="(zapzap_mostrar) && (!zapzap_conectado)"
                            @click="botao_conectar(false, true)"
                          >
                            Conectar
                              <v-icon
                                right
                                dark
                                size="30" 
                                class="ml-3"
                              >
                                mdi-whatsapp
                              </v-icon>
                          </v-btn>


                          <!-- mostra esse botao caso nao tenha o modulo whatsapp ainda -->
                        <v-btn color="grey" dark @click="alerta_zap"  v-if="(!zapzap_mostrar)">
                            Disparar
                            <v-icon small class="ml-2"> mdi-whatsapp  </v-icon>
                        </v-btn>
                        


                          

                            <!-- <v-container class="px-0" fluid> -->
    
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
      
                                  <v-icon
                                  class="pb-1 ml-5"
                                  color="primary"
                                  size="32"
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="(!item_edita.TEXTO)||(!items_nomes.length>0)"
                                >
                                  mdi-menu
                                </v-icon>

                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(item, index) in menu_45"
                                    :key="index"
                                    dense
                                    @click="clicou_menu_nomes(item.acao)"
                                    v-if="(retorna_titulo(item.title, item.acao))"
                                  >
                                    <v-list-item-title>
                                      {{ retorna_titulo(item.title, item.acao) }}
                                      <!-- <hr> -->
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>

                          

                       </v-card-actions> 
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
          </v-col>  

        </v-row>   


        <v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
      >
  
          <v-card class="" >
          <!-- height="80%" -->
          <v-card-title>
              <span class="text-h5" 
                v-text="(item_edita.CODIGO>0) ? 'Alterar '+ card_titulo +' '+item_edita.CODIGO : 'Novo '+ card_titulo "
              >
              Alterar
             </span>
          </v-card-title>
  
  

              <!-- passo 4 monto os campos editaveis -->
              <!-- :readonly = "readonly" -->

                  <v-card-text class="pa-1">
                    <v-form
                      ref="form"
                      v-model="valido"
                      >
                      <v-container>
                          <v-row>
                              <v-col
                                  class=""
                                  v-for="(item, i) in items_editaveis"
                                  :key="i"
                                  :cols="(isMobile) ? 12 : item.cols"
                                  v-if="parseInt(item.cols)>0"   
                              >
                              <!-- :cols="item.cols" -->




                                <v-card  class="" 
                                  v-if="(item.tag == 'v-card')&&(item.value == 'PARAM_WHATS')"
                                >
                                  <v-card-text>

                                    <h3 class="blue--text"> {{ item.text }} </h3>
                                    
                                    <v-chip-group
                                      column
                                    >
                                      <v-chip
                                          v-for="(item, i) in tags_empresa"
                                          :key="i"
                                          small
                                          @click="insere_tag(item)"
                                      >
                                        {{ item }}

                                        <span v-if="(item=='@;)')"> 😉 </span>
                                        <span v-if="(item=='@:)')"> 🙂 </span>
                                        <span v-if="(item=='@[]')"> 👩🏼‍💻 </span>
                                        
                                      </v-chip>
                                    </v-chip-group>
                                  </v-card-text>
                                  </v-card>



                                  <v-text-field
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      :type="item.type"
                                      v-if="item.tag == 'v-text'"
                                      @keyup="meu_keyup($event, item.value, item.upper)"
                                      
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                      :autofocus="i==2"
                                      v-mask = "getVmask(item.vmask)"
                                      class=""
                                  ></v-text-field>
                                  <!-- @keydown="firstUpper($event, item.value)" -->
                                  <!-- @input="(item.upper == 'S') ? item_edita[item.value] = item_edita[item.value].toUpperCase() : ''" -->
                                  
                                  <v-combobox
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-autocomplete'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"
                                  ></v-combobox>
                                  
                                  <v-select
                                      :class="[item.class]"
                                      dense
                                      v-model="item_edita[item.value]"
                                      :label="item.text"
                                      v-if="item.tag == 'v-select'"
                                      :items="item.tag_items"
                                      :rules="item.rule == 'S' ? notnullRules: noRules"

                                      item-value='CODIGO'
                                      item-text='NOME'
                                      :clearable = "item.tag_clear == 'S'"
                                      :persistent-hint="item.cols>2"
                                      :hint="item.hint"
                                  >
                                  
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    
                                  <!-- <template slot="selection" v-slot-scope="item"> -->
                                    <template v-slot:label>
                                      <v-tooltip bottom v-if="item.hint">
                                          <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            > 
                                              <span :class="item.class">
                                                {{ item.text }} 
                                                <!-- {{ item_edita[item.value] }} -->
                                              </span>
                                            </span>

                                          </template>
                                          <span> {{ item.hint }} </span>
                                        </v-tooltip>
                                    </template>
       
                                </v-select>


                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                              <!-- <component :is="(vai_imprimir) ? 'v-text' : 'v-textarea' "   -->
                              <v-textarea
                                v-model="item_edita[item.value]"
                                :label="item.text"
                                v-if="item.tag == 'v-textarea'"
                                :hint="item.hint"
                                :persistent-hint="item.cols>2"
                                dense
                                :rows="item.rows"
                                :filled="item.solo=='S'"
                                :autofocus="i==2"
                                :rules="item.rule == 'S' ? notnullRules: noRules"
                                @click="clicou_em=item.value"
                                id="textara_to_print"
                                ref="textara_to_print"
                              >
                              <!-- vai_imprimir -->
                              </v-textarea>                                  
                                  
                              </v-col>
                          </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
  
          <v-card-actions>
              <v-btn
                  x-small
                  color="red"
                  text
                  @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false"
              >
                Excluir
              </v-btn>
  
              <v-spacer></v-spacer>


              <v-btn
                  color="blue"
                  text
                  @click="imprimir_pagina()"
                  class="mr-5"
                  x-small
                  v-if="0>1"
              >
                Imprimir
              </v-btn>
  
              <v-btn
                  color="red"
                  text
                  @click="dialog_tabela = false"
              >
                Cancelar
              </v-btn>
              
              &nbsp
  
              <v-btn
                  :disabled="!valido"
                  color="primary"
                  @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela')"
              >
                Salvar
              </v-btn>
          </v-card-actions>
          </v-card>
      </v-dialog>

      <v-snackbar
          v-model="snackbar"
          :timeout="2000"
          top
          :color="snack_color"    
      >
        <center>
          {{ snack_text }}
        </center>
      </v-snackbar>

    </v-container>


    <!-- MOSTRA JANELA MODAL -->
    <div>
      <v-row>
        <v-col>
        <LembreteVue 
          :aberto = 'janela_modal'
          pro_tabela = 'CLIENTES'
          :pro_titulo = "'Localizar Pacientes'"
          :pro_obj_menus = null
          :show_menu = 'false'
          :pro_modo_edicao = 'false'
          @muda_aberto_lembretes="muda_aberto_modal"
        />
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>

import axios from "axios";

import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
import { downloadText, processCsvFile, processTxtFile } from '../assets/js/DSi_basico'
import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
import { crud_sql_45 } from '../assets/js/DSi_sql'
import { send_whats_api, status_whats, replace_emoji, conectar_whats } from '../assets/js/DSi_whats'
import { GetPermissoes } from '../assets/js/DSi_rotinas'

var url =  sessionStorage['url']


export default {


     components:{
      LembreteVue: () => import("./Lembretes.vue")
     },

    data: () => ({

        user_permissoes: {},

        dialog_ok: false,
        dialog_ok_tipo: 'conectar_zap',

        search:'',

        items_lista_importados: [], //uso no sql
        dados_importados: [],
        qual_importar :'',

        obj_dados_empresa: null,

        superA: false,
        backgroundImage:'',

        value_expansion: [ 1 ], //[ 0,1 ]
        session_bd:'',

        clicou_em_copiar: false,
        campanha_selecionou:{},

        selectedFile: null,
      

        buscar_nomes_model:'Todos',
        items_buscar_nomes: ['Todos', 'Pendentes', 'Enviados'],

        menu_nomes: [
          { title: 'Excluir'                            , acao: 'del_all' },
          { title: 'Resetar Enviados'                   , acao: 'reset_all' },
          { title: 'Copiar Contatos'                    , acao: 'copiar_contatos' },
          { title: 'Colar Contatos'                     , acao: 'colar_contatos' },
          { title: 'Importar Contatos (.txt)'           , acao: 'importar_contatos' },
          { title: 'Importar e Salvar Contatos (.csv)'  , acao: 'importar_contatos_save' },
        ],

        menu_45: [
          { title: 'Limpar fila de envios', acao: 'limpar_fila' },
        ],

        zapzap_mostrar: false,
        zapzap_conectado: false,

        janela_modal: false,
        modal_salvou: false,
        modal_qual:'',
        obj_janela_modal: {},

        selected: [],
        obj_clicado : {},

        overlay: false,
        today:'',


        dialog_tabela: false,

        isMobile : false,

        header_campanha:[],
        items_campanha:[],

        header_nomes:['NOME','NUMERO'],
        items_nomes:[],

        tags_empresa:[],

        tabela_selecionada:'',
        item_edita: {},
        item_sub_edita: {},
        items_editaveis: [],
        card_titulo:'',
        valido:false,

  
        notnullRules: [
          v => !!v || 'necessário'
         ],

         noRules:[v => true],

         snackbar: false,
         snack_text: 'My timeout is set to 2000.',
         snack_color: "success",

         w_data: '99px',
         w_fone: '125px',
         w_cpf: '120px',
         w_cnpj: '150px',
         w_nome: '220px',
         w_situacao: '60px',
         w_valor: '90px',


    }),

    created(){
        this.onCreated()
    },

    watch:{

      dados_importados(a){
        if (a?.length){

          if (this.qual_importar == 'clientes'){
              this.salvar_importacao(this.qual_importar)
          }
          
          else if (this.qual_importar == 'WP_CONTATOS'){
              this.salvar_importacao_txt(this.qual_importar)
          }
        }
      },

      buscar_nomes_model(){
         this.tabela_2('WP_CONTATOS', this.obj_clicado)//atualizo
      }
    },
  
    computed: {
      imageUrl() {
        return URL.createObjectURL(this.selectedFile);
      }
    },

    methods:{


      async botao_conectar(alertar, conectar){
        
        // verifica se conectado
        this.zapzap_conectado = await status_whats(alertar, conectar, 'connected')

        // caso nao conectado..abre dialog ok
        if (!this.zapzap_conectado){
          this.dialog_ok      = true
          this.dialog_ok_tipo = 'conectar_zap'
        }

    },

      async dialog_ok_clicou(tipo){

        if (tipo == 'conectar_zap'){
          //  somente verifico se conectado
           this.zapzap_conectado = await status_whats(false,false, 'connected')
       }

      },

      async volta_pendente(item){
        // if (!confirm(`Tornar Pendente?`)){
        //     return false
        // }

            let sql 
            if (item.ID_WHATS){
                //tornar pendente
                sql = `update WP_CONTATOS set DATA_VISTO = null, ID_WHATS = null WHERE CODIGO = ${item.CODIGO}`
            }
            else{
                //tornar enviado
                sql = `update WP_CONTATOS set DATA_VISTO = null, ID_WHATS = 'null' WHERE CODIGO = ${item.CODIGO}`
            }
            
            // console.log('sql:', sql);
            
            // return false
            await this.crud_sql(sql)

            // await this.tabela_2('WP_CONTATOS', this.obj_clicado)//atualizo

            // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
            let index = this.items_nomes.findIndex( x => x.CODIGO === item.CODIGO );
            this.$set(item, 'ID_WHATS', (item.ID_WHATS) ? '' : 'null')
            Object.assign(this.items_nomes[index], item)
      },

      async salvar_importacao_txt(tabela){
        
        let novo_array 
    
        novo_array = this.dados_importados
                                .filter(x => x.CELULAR) // Filtra apenas os objetos onde o campo CELULAR está preenchido
                                .map(x => ( x ));


        console.log('novo_array:', novo_array);


        if (!confirm(`Confirmar Importação?`)){
          return false
        }

        await this.add_contato(novo_array)

        alert('Dados importados com sucesso!')


        this.dados_importados = []
        this.qual_importar    = ''
        

      },

      async salvar_importacao(tabela){
        
        let novo_array 
        // let CODIGOCLIENTE = '(SELECT max(CLIENTES.CODIGOCLIENTE+1)FROM CLIENTES)'
        let CODIGOCLIENTE = '(SELECT COALESCE(MAX(CLIENTES.CODIGOCLIENTE) + 1, 1)FROM CLIENTES)'
        let CODIGOCONVENIO = 0
        let CODIGOCONSULTORIO = 0
        let ATIVO = 'N'
        // let TOKEN          = this.obj_cliente_selecionado.TOKEN
        
    
        novo_array = this.dados_importados
                                .filter(x => x.CELULAR) // Filtra apenas os objetos onde o campo FONE está preenchido
                                .map(x => ({ ...x, CODIGOCLIENTE, CODIGOCONVENIO, CODIGOCONSULTORIO, ATIVO }));


        // console.log('novo_array:', novo_array);
        let sql = this.gerarSQLInsertFIREBIRD(tabela, novo_array);

        // console.log(sql);

        if (!confirm(`Confirmar Importação?`)){
          return false
        }


        // Dividir a string em um array de linhas
        let sqlLines = sql.split('\n').filter(line => line.trim() !== '');

        // Converter cada linha em um objeto com o campo SQL
        let sqlObjects = sqlLines.map(line => {
          return { SQL: line };
        });

        // Exibir o array resultante de objetos
        console.log(sqlObjects);


        // Percorrer o array sqlObjects e dar um log dos valores
        for (let obj of sqlObjects) {
          // console.log(obj.SQL);
          await  this.crud_sql(obj.SQL)
        }

       alert('Dados importados com sucesso!')

       //atualiza janela
      //  await this.open_campanhas()


       this.dados_importados = []
       this.qual_importar    = ''
        

      },




      gerarSQLInsertFIREBIRD(tabela, objetos) {
        if (!objetos.length) {
          return "";
        }

        // Filtra os objetos removendo os campos vazios, aqueles que começam com "x_" e o campo "CODIGO"
        let objetosFiltrados = objetos.map(objeto => {
          let novoObjeto = {};
          for (let chave in objeto) {
            if (
              objeto[chave] !== null &&
              objeto[chave] !== undefined &&
              objeto[chave] !== '' &&
              !chave.startsWith('x_') &&
              chave !== 'CODIGO'
            ) {
              novoObjeto[chave] = objeto[chave];
            }
          }
          return novoObjeto;
        });

        // Coleta todos os campos únicos presentes em algum objeto, exceto os que começam com "x_" e "CODIGO"
        let todosCampos = new Set();
        objetosFiltrados.forEach(objeto => {
          Object.keys(objeto).forEach(campo => {
            if ((!campo.startsWith('x_')) && (!campo.startsWith('X_')) && (campo !== 'CODIGO')) {
              todosCampos.add(campo);
            }
          });
        });

        // Converte o set para array
        todosCampos = Array.from(todosCampos);

        // Monta os valores para o SQL, colocando NULL nos campos faltantes
        let valores = objetosFiltrados.map(objeto => {
          let camposPresentes = [];
          let valoresPresentes = [];
          todosCampos.forEach(campo => {
            if (objeto[campo] !== undefined) {
              camposPresentes.push(campo);
              let valor = objeto[campo];
              if (typeof valor === "string") {

                // caso o valor comece assim..nao manda como string
                if ((valor.startsWith("GEN_ID") || valor.startsWith("(SELECT max") || valor.startsWith("(SELECT MAX"))) {
                  valoresPresentes.push(valor);
                }
                else{
                  valoresPresentes.push(`'${valor.replace(/'/g, "''")}'`);
                }
                
              } else if (valor instanceof Date) {
                // Format date as 'YYYY-MM-DD' for Firebird
                let year = valor.getFullYear();
                let month = ('0' + (valor.getMonth() + 1)).slice(-2);
                let day = ('0' + valor.getDate()).slice(-2);
                valoresPresentes.push(`'${year}-${month}-${day}'`);
              } else {
                valoresPresentes.push(valor);
              }
            } else {
              camposPresentes.push(campo);
              valoresPresentes.push('NULL');
            }
          });

          return `INSERT INTO ${tabela} (${camposPresentes.join(", ")}) VALUES (${valoresPresentes.join(", ")});`;
        });

        return valores.join("\n");
      },




      async handleFileUpload(event) {

        let file = event.target.files[0];
        this.dados_importados = [];

        if (file) {
          const fileName = file.name;
          const fileExtension = fileName.split('.').pop().toLowerCase();

          if (fileExtension === 'csv') {

              this.dados_importados = await processCsvFile(file);

          } else if (fileExtension === 'txt') {
            
              this.dados_importados = await processTxtFile(file);

          } else {
            console.error('Tipo de arquivo não suportado');
          }

          event.target.value = null; // limpa valor do input
        }
      },


      async triggerFileInput(tabela) {

        this.qual_importar = tabela
        await this.$refs.fileInput.click();
      },

      retorna_titulo(titulo, acao){

         if (acao == 'del_all'){
           return titulo + ' '+this.buscar_nomes_model
         }
         else if (acao == 'copiar_contatos'){
            if (this.items_nomes.length){
              return titulo
            }else{
              return false
            }
         }
         else if (acao == 'colar_contatos'){
            if (this.clicou_em_copiar){
              return titulo
            }else{
              return false
            }
         }
         else{
          return titulo
         }
      },

      async verifica_limite(limite){
        
        let ja_tem = this.items_nomes.length 

        if (ja_tem >= limite){
          if (!confirm(`Esta lista já tem ${ja_tem} contatos!\nRecomendamos enviar no máximo ${limite} contatos por vez!\nDeseja continuar mesmo assim?`)){
            return false
          }
        }
        
        return true
            
      },

      encontrarEmojiNoTexto(texto) {

          // console.log('texto:', texto);
          // Divida o texto em palavras usando espaços em branco como delimitadores
          const palavras = texto.split(/\s+/);

          let achou = '';
          for (let i = 0; i < palavras.length; i++) {
            // Verificar se a palavra contém sequências percent-encoded
              try {
                // Decodificar a sequência percent-encoded dentro da palavra
                achou = encodeURIComponent(palavras[i]);
                // console.log('achou:', achou);

                // se achou ele substitui no texto
                texto = texto.replaceAll(palavras[i] , achou );


              } catch (error) {
                // console.error(`Erro ao decodificar sequência percent-encoded: ${palavras[i]}`);
                achou = palavras[i];
              }
          }

          // console.log('palavras:', palavras);

          return texto
      },

      encontrarCaractereNoTexto(texto) {

          // console.log('texto:', texto);
          // Divida o texto em palavras usando espaços em branco como delimitadores
          const palavras = texto.split(/\s+/);

          let achou = '';
          for (let i = 0; i < palavras.length; i++) {
            // Verificar se a palavra contém sequências percent-encoded
            if (/%[0-9A-Fa-f]{2}/.test(palavras[i])) {
              try {
                // Decodificar a sequência percent-encoded dentro da palavra
                achou = decodeURIComponent(palavras[i]);
                // console.log('achou:', achou);

                // se achou ele substitui no texto
                texto = texto.replaceAll(palavras[i] , achou );


              } catch (error) {
                // console.error(`Erro ao decodificar sequência percent-encoded: ${palavras[i]}`);
                achou = palavras[i];
              }
            } else {
              // Se a palavra não contiver sequências percent-encoded, mantenha a palavra original
              achou = palavras[i];
            }
          }

          // console.log('palavras:', palavras);

          return texto
      },



      alerta_zap(){
        alert("É necessário ATIVAR o módulo WhatsApp, entre em contato para maiores informações")
      },

      async situacao_whats(){

        this.zapzap_mostrar   = JSON.parse(sessionStorage['zapzap_mostrar']);
        this.zapzap_conectado = JSON.parse(sessionStorage['zapzap_conectado']);


        return false
        let zapzap =  sessionStorage['config_whats']
        
        // return false
        if ((zapzap)&&(zapzap.SITUACAO !='CANCELADO')){
          this.zapzap_mostrar = true
        }else{
          this.zapzap_mostrar = false
        }

        let r = null
        if (r = await status_whats()){
            console.log('r:', r);

            this.zapzap_conectado = r.connected
            
        }
      },

      replace_emojiHERE(msg){
          return replace_emoji(msg)
      },

      async replace_msg(msg, item){

        console.log('here item:', item);

        let nome = item.NOME
        nome = nome.toLowerCase().trim();  //passo tudo pra minuscula
        nome = nome.split(" ");//separo as palavras
        nome = nome[0];//pego o primeiro nome        
        nome = nome.charAt(0).toUpperCase() + nome.slice(1);//passo 1 letra maiuscula

        let ide = item.COD_CLIENTE
        if (ide){ '*'+ide+'*' }

        msg = msg.replaceAll( '@nome'             , '*'+ nome + '*' );
        msg = msg.replaceAll( '@ide'              , ide );

        return msg

      },

      async disparar_whats(){


        await this.botao_conectar(false, true)

        if (!this.zapzap_conectado){

            return false
        }

        if (!confirm(`Iniciar o disparo de mensagens para esta lista de Contatos?`)){
          return false
        }

        this.define_defaults('')


        // verifica se nao esta vazio a lista de nomes
        let items = this.items_nomes
        console.log('items:', items);
        if (!items){
          return false
        }

        // verifica a mensagem
        let msg = this.item_edita.TEXTO
        // msg = this.encontrarEmojiNoTexto(msg) //nao preciso mais enviar ja codificada pq quando salvo eu ja codifico

        



        if (msg.length<=10){
          alert('Defina uma mensagem válida!')
          return false
        }
        if (!msg){
          alert('Defina uma Mensagem para ser enviada!')
          return false
        }


        let file = this.item_edita.URL_FILE

        let retorno = ''
        let sql     = ''
        let item    = null
        let msg_tratada = ''

        for (let i = 0; i < items.length; i++) {
          item = items[i]
          
          // verifica o numero
          if (item.NUMERO.length>=10){
            

            // TRATO A MSG..REPLACE NO @nome, @ide
            msg_tratada = await this.replace_msg(msg, item)//pega o nome etc..


            // ENVIA OPCAO DE PARAR RECEBIMENTO DO ZAP
            if ((this.item_edita.ENVIAR_PARAR == 'true') || (this.item_edita.ENVIAR_PARAR == true)){
              msg_tratada = msg_tratada+`\n* Caso não queira mais receber esse tipo de mensagem, responda *PARAR*`
            }

            // console.log('msg:', msg_tratada);
            if (retorno = await send_whats_api('CAMPANHA', item.NUMERO, msg_tratada, file)){
              

              sql = `update WP_CONTATOS set ID_WHATS = '${retorno.id}', DATA_VISTO = '${this.today}'  where codigo = ${item.CODIGO}`
              // console.log('sql:', sql);
              await this.crud_sql(sql)
              
            }
          }  
        }

        await this.tabela_2('WP_CONTATOS', this.obj_clicado)//atualizo

      },
        
        async clicou_menu_nomes(acao){
          
          let item = Object.assign({}, this.obj_clicado)

          if (acao == 'del_all'){

            let where = ` WHERE COD_CAMPANHA = ${item.CODIGO}`
            
            if(this.buscar_nomes_model == 'Pendentes'){
                where += ` and DATA_VISTO <> '${this.today}' OR DATA_VISTO IS NULL `
            }
            else if(this.buscar_nomes_model == 'Enviados'){
                where += ` and DATA_VISTO = '${this.today}' `
            }

            if (confirm(`Deseja Excluir ${this.buscar_nomes_model} desta lista?`)){
              let sql = `delete from WP_CONTATOS ${where}`
              // console.log('sql:', sql);
              
              await this.crud_sql(sql)

              await this.tabela_2('WP_CONTATOS', this.obj_clicado)//atualizo

            }
          }

          else if (acao == 'limpar_fila'){
                        
            let ide = this.obj_dados_empresa.IDE_PROGRAMA
            if (!ide){ return false }

            if (!confirm(`Todas as mensagens que ainda não foram enviadas serão canceladas, deseja continuar?`)){
              return false
            }

            let sql = `update tabela_api_whats set visto = 'X' where visto != 'N' and codigo_cliente = ${ide}`
            let r = await crud_sql_45(sql)

            this.alerta(r,'green')
          }

          else if (acao == 'copiar_contatos'){
            this.clicou_em_copiar = true
            sessionStorage['pacientes_selecionados']  =  JSON.stringify(this.items_nomes)
            // alert(`Lista de Contatos Copiada com sucesso!\nClique em Colar Contatos na Campanha desejada` )
            this.alerta('Contatos copiados com sucesso','green')
          }

          else if (acao == 'importar_contatos_save'){
             
             await this.triggerFileInput('clientes')
          }

          else if (acao == 'importar_contatos'){

             alert('2')
             await this.triggerFileInput('WP_CONTATOS')

          }

          else if (acao == 'colar_contatos'){
            this.clicou_em_copiar = false
            this.modal_alteracoes()
          }

          else if (acao == 'reset_all'){

            if (!confirm(`Deseja Resetar os Enviados? eles se tornaram Pendentes`)){
              return false
            }

            let sql = `update WP_CONTATOS set DATA_VISTO = null, ID_WHATS = null WHERE COD_CAMPANHA = ${item.CODIGO}`
            // console.log('sql:', sql);
            
            await this.crud_sql(sql)

            await this.tabela_2('WP_CONTATOS', this.obj_clicado)//atualizo
          }
        },

        async abrir_janela_modal(item, acao){

          this.janela_modal      = true
          sessionStorage['pacientes_selecionados'] = ''

          if (acao == 'editar'){
            this.obj_janela_modal  = item
          }
          else if (acao == 'novo'){
            this.obj_janela_modal  = null
          }

        },

        muda_aberto_modal(valor){
          this.janela_modal = valor
          
          this.modal_alteracoes()
        },

        async modal_alteracoes(){
          let pacientes_selecionados = JSON.parse(sessionStorage['pacientes_selecionados'])
          // console.log('pacientes_selecionados:', pacientes_selecionados);

          if (pacientes_selecionados.length>0){

            if (confirm(`Deseja adicionar os Pacientes selecionados?`)){
              const resultado = this.items_nomes.concat(pacientes_selecionados);
              // console.log('resultado:', resultado);

              this.add_contato(pacientes_selecionados)
            }

          }
        },

        salvar_msg(item){

          item = this.obj_clicado

          let media = this.item_edita.URL_FILE
          let parar = this.item_edita.ENVIAR_PARAR


          //           const emoji = '👩🏻‍💻';
          // const encodedEmoji = encodeURIComponent(emoji);

          // console.log(`Emoji: ${emoji}`);
          // console.log(`Encoded: ${encodedEmoji}`);


          // // const percentEncodedEmoji = '%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BB';
          // const percentEncodedEmoji = '%E2%80%8D';
          // const decodedEmoji = decodeURIComponent(percentEncodedEmoji);

          // console.log(`Encoded: ${percentEncodedEmoji}`);
          // console.log(`Decoded: ${decodedEmoji}`);


          const textoOriginal = this.item_edita.TEXTO;
          const textoTratado = this.encontrarEmojiNoTexto(textoOriginal);

          let sql = `update  WP_CAMPANHA set TEXTO = '${textoTratado}' , URL_FILE = '${media}', ENVIAR_PARAR = '${parar}' where codigo = ${item.CODIGO}`
          // let sql = `update  WP_CAMPANHA set TEXTO = '${this.item_edita.TEXTO}' , URL_FILE = '${media}' where codigo = ${item.CODIGO}`
          console.log(sql);
          // return false
          this.crud_sql(sql)

          // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
          // let index = this.items_conta.indexOf(item)
          let index = this.items_campanha.findIndex( x => x.CODIGO === item.CODIGO );
          this.$set(item, 'TEXTO'       ,  this.item_edita.TEXTO)
          this.$set(item, 'URL_FILE'    ,  this.item_edita.URL_FILE)
          this.$set(item, 'ENVIAR_PARAR',  `${this.item_edita.ENVIAR_PARAR}` )
          
          Object.assign(this.items_campanha[index], item)
        },

        remover_esse(item){
            console.log('item:', item);
        
            if (confirm(`Deseja realmente Excluir o ítem ${item.CODIGO}?`)){
              let sql = `delete from WP_CONTATOS where codigo = ${item.CODIGO}`
              console.log(sql);
              this.crud_sql(sql)

              // removo com base no valor dele
              let index = this.items_nomes.findIndex( x => x.CODIGO === item.CODIGO );
              this.items_nomes.splice(index, 1);//removo a coluna senha


            }
        },

        async add_contato(items){
            
            // console.log(this.obj_clicado);
            this.define_defaults('')

            let nome  = ''
            let numero = ''
            let sql = ''

            if (! await this.verifica_limite(300)){
              return false
            }

            this.overlay = true


            if (items){
              // ADICIONO DE UM ARRAY DE OJBETO

              for (let i = 0; i < items.length; i++) {
              

                  // console.log('items[i]:', items[i]);

                  let cod_cliente = 0
                  
                  if (items[i].CODIGOCLIENTE > 0){
                    cod_cliente =  items[i].CODIGOCLIENTE
                  }
                  else if (items[i].COD_CLIENTE > 0){
                    cod_cliente = items[i].COD_CLIENTE
                  }

                  nome     = items[i].NOME
                  nome     = nome.trim();
                  numero   = items[i].CELULAR
                  if (!numero){
                    numero   = items[i].FONE1
                  }

                  
                  if(url == '/api/'){
                      // numero = '44999685172'//somente para teste
                  }


                  sql = `
                    insert into WP_CONTATOS
                    (
                    COD_CAMPANHA,
                    NOME        ,
                    NUMERO      ,
                    COD_CLIENTE 
                    )

                    VALUES
                    (
                      ${this.obj_clicado.CODIGO},
                      '${nome}',
                      '${numero}',
                      ${cod_cliente}
                    )
                  ;`


                  if (numero){
                    // console.log(sql);
                     await this.crud_sql(sql)//adiciono
                  }
              }

            }
            else{

              // ADICIONO MANUALMENTE
              if ((!this.item_sub_edita.NOME) || (!this.item_sub_edita.NUMERO)){
                alert('Digite os dados!')
                return false
              }

              if (!this.obj_clicado.CODIGO){
                alert('Selecione uma Campanha!')
                return false
              }

              nome   = this.item_sub_edita.NOME
              nome   = nome.trim();

              numero = this.item_sub_edita.NUMERO


              sql = `
                insert into WP_CONTATOS
                (
                COD_CAMPANHA,
                NOME        ,
                NUMERO        
                )

                VALUES
                (
                  ${this.obj_clicado.CODIGO},
                  '${nome}',
                  '${numero}'
                )
               ;`


                await this.crud_sql(sql)//adiciono

                // limpa os campos
                this.item_sub_edita.NOME   = ''
                this.item_sub_edita.NUMERO = ''

                //volta o focus no nome
                this.focus_by_id('id_nome')

            }

            // console.log('sql:', sql);

            this.overlay = false
            await this.tabela_2('WP_CONTATOS', this.obj_clicado)//atualizo

        },

        seleciona_campanha(row){

            this.campanha_selecionou = Object.assign({}, row)
            
            this.seleciona_linha(row)
            
            // console.log('hrerere:', row);

          //  console.log('row.TEXTO:', row.TEXTO);
           let vem = this.encontrarCaractereNoTexto(row.TEXTO) 
          //  console.log('vemm:', vem);
           
          // let vem = row.TEXTO
          //  vem = row.TEXTO
          

            this.$set(this.item_edita, 'TEXTO'        , vem)  
            // this.$set(this.item_edita, 'TEXTO'    , row.TEXTO)  
            this.$set(this.item_edita, 'URL_FILE'     , row.URL_FILE)  
            this.$set(this.item_edita, 'ENVIAR_PARAR' , (row.ENVIAR_PARAR == 'true') ? true : false) 
            //  alert(row.ENVIAR_PARAR)

            this.tabela_2('WP_CONTATOS',row)

            // let nomes = []
            // nomes =  (row.NOMES) ?  JSON.parse(row.NOMES) : []
            // this.items_nomes = nomes.map((x) => x); //clonando array copiar array
       
        },

        async onCreated(){
            this.isMobile = (window.innerWidth < 600)
            this.define_tags_key()
            this.session_bd =  'mc_'+ sessionStorage['LSid'];
            this.superA = (sessionStorage['superA']=='S')
            this.obj_dados_empresa = JSON.parse(sessionStorage['obj_dados_empresa'])


            await this.GetPermissoes()
            await this.open_campanhas()
            await this.situacao_whats()

        },


        async GetPermissoes(){
          let r = await GetPermissoes()
          this.user_permissoes = Object.assign({}, r) 
        },

        async open_campanhas(){


            this.tabela('WP_CAMPANHA','','')
            
            // let sql = `SELECT *  FROM WP_CAMPANHA` 

            // // console.log(tabela+': '+sql);
            // // await this.crud_abrir_tabela(sql, 'items_lista')
            // await DSisql.crud_abrir_tabela(this, sql, 'items_campanha')
        },

        async tabela(tabela, acao, item){//PASSO 3
            //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table

            // this.search = ''//limpa filtro

            this.tabela_selecionada = tabela
            // this.card_titulo = item.text

           //   console.log('item:');
           //   console.log(item);

            //limpa array
            this.items_lista       = [] //recebe os dados do sql
            this.items_header      = [] //campos que tem que mostrar no header
            this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header


            if (tabela=='WP_CAMPANHA'){
                
                // this.card_titulo = `Campanha`

                this.items_header = [

                 { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
                //  { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },                    
                 { text: 'Data', value: 'DATA',tag:'v-text', type:'date', cols:'2',rule:'S', width: this.w_data, upper:'N' },
                 { text: 'Data', value: 'x_DATA',tag:'v-text', type:'date', cols:'2',rule:'S', width: this.w_data, upper:'N' },
                 { text: 'Titulo', value: 'TITULO',tag:'v-text', type:'text', cols:'4',rule:'S', width: this.w_nome, upper:'S' },
                //  { text: 'URL_FILE', value: 'URL_FILE',tag:'v-text', type:'text', cols:'4',rule:'S', width: this.w_nome, upper:'S' },

                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array
                this.header_campanha = this.items_header.map((x) => x); //clonando array copiar array

                // REMOVO DO HEADER
                this.header_campanha.splice(this.items_header.findIndex(x => x.value === "DATA") , 1);//removo a coluna senha

                // REMOVO DO EDITAVEL
                this.items_editaveis.splice(this.items_header.findIndex(x => x.value === "x_DATA") , 1);//removo a coluna senha


                let sql = `SELECT *  FROM ${tabela}` 
                await DSisql.crud_abrir_tabela(this, sql, 'items_campanha')



                this.header_nomes = [

                 { text: '..', value: 'actions_delete', sortable: false, type:'hide', cols:'0',width:'5px'},

                 { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },                    
                 { text: 'Nome', value: 'NOME',tag:'v-text', type:'text', cols:'2',rule:'S', width: this.w_nome, upper:'N' },
                 { text: 'Numero', value: 'NUMERO',tag:'v-text', type:'text', cols:'2',rule:'S', width: this.w_nome, upper:'N' },
                ]
            }
        },



        async upload_logo(file,pasta,nome,campo_salvar){

          // alert(campo_salvar)
          // console.log(file);


          //update refresh image
          this.urlLogo = URL.createObjectURL(file);

          var bodyFormData = new FormData();
          bodyFormData.append('pasta'       , pasta);
          bodyFormData.append('nome_arquivo', nome);
          bodyFormData.append('userfile'    , file);

          //isso daqui server para ser lido com o $_POST no php 
          const header =   { headers: {'Content-Type': 'multipart/form-data'}}

          // console.log(data);

          const res = await axios.post(url+'upFile.php', bodyFormData, header, )

          // console.log(res.data);

          let r = ''

          // console.log(res.data);

          if (res.data.status == 'OK'){

              this.alerta('Atualizado','green')
            
              let tipo = file.type
              tipo = tipo.substr(tipo.indexOf('/')+1,5);
            
              r = ( pasta+'/'+nome+'.'+tipo)

              // sessionStorage['emp_url_logo'] =  sessionStorage['url'] + 'images/'+ r //salvo para ser lido

          }else{
              this.alerta('Ops: '+res.data,'red')
          }

          //salvo no campo editavel no caso dados da empresa
          // this.item_edita[campo_salvar] = r
          
          if (campo_salvar){

            if (url == '/api/'){
              r = 'http://localhost/vue_agenda_firebird/public/images/'+r
            }
            else{
              r = url +'/images/' + r
            }

            // console.log('r::::', r);

            this.$set(this.item_edita, campo_salvar,  r)

            this.salvar_msg()

          }


        },


        async tabela_2(tabela, item){

          if (tabela=='WP_CONTATOS'){
                
              // this.card_titulo = `Campanha`

              this.header_nomes = [

              //  { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
              { text: '..', value: 'actions_delete', sortable: false, type:'hide', cols:'0',width:'5px'},

              //  { text: 'Data', value: 'DATA',tag:'v-text', type:'date', cols:'2',rule:'S', width: this.w_data, upper:'N' },
                { text: 'Numero', value: 'NUMERO',tag:'v-text', type:'text', cols:'7',rule:'S', width: '190px', upper:'N' },
                { text: 'Nome', value: 'NOME',tag:'v-text', type:'text', cols:'7',rule:'S', width: '220px', upper:'N' },
                { text: 'Data Enviado', value: 'x_DATA_VISTO',tag:'v-text', type:'date', cols:'2',rule:'S', width: this.w_data, upper:'N' },
                { text: 'ID_WHATS', value: 'ID_WHATS',tag:'v-text', type:'text', cols:'2',rule:'N', upper:'N' },
                { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },


              ]

              // clono os items
              // this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array
              this.items_nomes = this.items_header.map((x) => x); //clonando array copiar array

              // REMOVO DO EDITAVEL
              // this.items_editaveis.splice(this.items_header.findIndex(x => x.value === "x_DATA") , 1);//removo a coluna senha


              this.define_defaults('')

              let where = ` WHERE COD_CAMPANHA = ${item.CODIGO}`

              if(this.buscar_nomes_model == 'Pendentes'){
                // where += ` and DATA_VISTO <> '${this.today}' OR DATA_VISTO IS NULL `
                where += ` and (ID_WHATS = '' or ID_WHATS is null)`
              }
              else if(this.buscar_nomes_model == 'Enviados'){
                // where += ` and DATA_VISTO = '${this.today}' `
                where += ` and ID_WHATS <> '' `
              }
              
              let sql = `SELECT *  FROM ${tabela} ${where}  ORDER BY DATA_VISTO,  NOME` 
              // console.log('sql:', sql);
              await DSisql.crud_abrir_tabela(this, sql, 'items_nomes')

          }
        },


        seleciona_linha(row) {
          // console.log('row:');
          // console.log(row);
           this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table

           //clonando objeto
           this.obj_clicado = Object.assign({}, row)
        },

        async focus_by_id(id){
           document.getElementById(id).focus();
        },

        define_defaults(tabela){

            // --------------------DEFINE DATA E HORA-----------------------
            let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
            // console.log('hoje:'+ hoje);

            let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
            this.today = today
            // console.log('today:'+ today);

            let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
            let hora_curta = hora.slice(0, 5)
            // console.log('hora = '+ hora);
            // console.log('hora = '+ hora_curta);
            // --------------------DEFINE DATA E HORA-----------------------

            if (tabela=='WP_CAMPANHA'){
              this.$set(this.item_edita, 'DATA', today)         
            }
        },

        async before_update(tabela){
          // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela

          // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS
          let VERIFICAR = [
            'USUARIOS|CODIGODOUTOR|0',
          ]
        
          let CAMPOS      = []
          let CAMPO_VER   = ''
          let CAMPO_VALOR = ''
          for (let i = 0; i < VERIFICAR.length; i++) {
             
             CAMPOS      = VERIFICAR[i].split("|");//divide
             CAMPO_VER   = CAMPOS[1]
             CAMPO_VALOR = this.item_edita[CAMPO_VER]

             if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                if (!CAMPO_VALOR){//verifica se vazio
                  this.$set(this.item_edita, CAMPOS[1], CAMPOS[2] ) 
                }
             }
          }
        },

        meu_keyup(event,campo,upper){
  
            //identifica se o campo é double e muda o display
            if ((campo.includes('double_') || campo.includes('VALOR')) ){
                this.item_edita[campo] =  this.moeda(event)
                // @keyup="editedItem.double_credito = moeda($event)"
            }
            else if (campo.includes('CEP')) {

                this.consulta_cep(this.item_edita[campo])    
            }
            else{
                if ((campo.includes('EMAIL')==false) &&
                (campo.includes('SENHA')==false) &&
                (campo.includes('SENHA_WEB')==false)){

                    if (upper !=='N'){
                      this.allUpper(event, campo)
                    }
                    
                }
            }
        },

        allUpper(event, field){
            console.log('alluupper');
            let a = event.target.value;//captura o valor do event
            if (a!==''){  
                a = a.toUpperCase();
                // this.item_edita[field] = a;

                // event.target.value = a //AQUI ELE DEIXA O FINAL MINUSCULO PQ NAO APLICOU AINDA A ALTERAÇÃO NO ITEM_EDITA
                this.item_edita[field] = a;//AQUI ELE ALTERA DIRETO NO ITEM_EDITA


            } 
        },

        firstUpper(event, field){
              // console.log(event);
              let a = event.target.value;//captura o valor do event

              if (a!=='' && a.length==1){  
                a = a.toUpperCase();
                // this.item_edita[field] = a;

                event.target.value = a
              } 
          },



        alerta(text,color){
           DSibasico.alerta(this, text, color)
        },

        retorna_altura(menos){
            let r = (window.innerHeight - 80)
            if (menos){
              r = (r - menos)
            }
            return r
        },

        define_tags_key(){

            this.tags_empresa = [
            '@ide',
            '@nome',
            '@empresa',
            // '@paciente',
            // '@::)',
            // '@;u',
            // '@:u',
            // '@h_dir',
            // '@av',
            // '@sirene',
            // '@local',
            // '@festa1',
            // '@festa2',
            // '@festa3',
            // '@e_fone',
            // '@rb',
            // '@r_set'
            ] 
                    
        },

        insere_tag(tag){
          
          let ja = this.item_edita.TEXTO
          if (tag){
            // this[clicou_em] = this[clicou_em] + tag
            this.$set(this.item_edita, 'TEXTO',(ja) ? ja+tag : tag)
          }
        },

        getVmask(qual){
            
            if (qual == 'cel'){
                return "(##) #####-####"
            }
            else if (qual == 'fone'){
                return "(##) ####-####"
            }
            else if (qual == 'cnpj'){
                return "##.###.###/####-##"
            }
            else if (qual == 'cpf'){
                return "###.###.###-##"
            }
            else if (qual == 'cep'){
                return "#####-###"
            }
            else{
                return ""
            }
        },



           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
              return await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios

              await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)
          },
  
          async remover_tabela(tabela, obj_edita, titulo){

            // if (confirm(`Deseja excluir este lançamento?`)){
            
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)

              let sql = `delete from WP_CONTATOS where cod_campanha = ${obj_edita.CODIGO}`              
              await this.crud_sql(sql)

              this.items_nomes    = []
              this.obj_clicado    = {}
            //  }
          },
  
          editar_tabela(item, obj_edita_name, dialog_name) {
              this.seleciona_linha(item)
              DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },
  
  
           //---------------importando funcoes sql ----------------------- FIM


    },

}
</script>

<style scoped>
    .container{
        max-width: 100%;
    }

  /* tamanho da fonte do data-table */
  .v-data-table >>> tr>td{
   font-size:12px !important;
   /* height: 25px !important; */
  }

 /* tamanho da fonte do data-table */

 /* cor da linha selecionada do data-table */
 .theme--light.v-data-table tbody tr.v-data-table__selected {
     /* seleciona_linha cor */
     background: #eee9e9 !important;
     /* background: blue !important; */
 }

 ::v-deep td.text-start{ /* padding das colunas data-table  */
   padding-left: 5px !important;
   padding-right: 2px !important;
   /* padding: 0px 2px !important; */
   /* background-color: red; */
 }

 ::v-deep th.text-start{ /* padding dos titulos das colunas data-table */
   padding: 0px 4px !important;
   /* background-color: red; */
 }
</style>