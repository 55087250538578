<template>
  <div>
    
    <!-- {{tipo_enviar}} <br/><br/> -->
    <!-- selectedFarmacia:{{selectedFarmacia}} <br/><br/> -->
    <!-- {{PrintPode}} -->
    <!-- {{inPDF}} -->
    <!-- {{msg_farmacia}} -->
    <!-- {{array_ids_whats}} -->
    <!-- {{ tem_farmacias }} -->
    <!-- {{items_farmacias}} -->
    <!-- {{superA}} -->

    <!-- config_whats:{{config_whats}} <br/><br/> -->
    <!-- zapzap_mostrar:{{zapzap_mostrar}} <br/><br/> -->
    <!-- zapzap_conectado:{{zapzap_conectado}} <br/><br/> -->

    <!-- {{nome_modelo}} -->
    <!-- {{ mostrar_logo }} -->
    <!-- {{nome_formato_modelo}} -->
    <!-- {{ items_modelos_imp_sub }} -->
    <!-- {{cod_prof_modelo}} -->
    <!-- {{obj_profissionais_sub}} -->
    <!-- obj_dados_profissional:{{obj_dados_profissional}} <br/><br/> -->
    <!-- obj_dados_paciente: {{ obj_dados_paciente }} <br/> <br/> -->
    <!-- {{get}} -->
    <!-- {{modelo_selecionado}} -->
    <!-- {{PATH_IMG}} -->
    <!-- {{url_img}} -->    
    <!-- {{ files_upload }} -->
    <!-- {{ retorno_path_url_img }} -->
    <!-- {{NomeEmpresa}} -->
    <!-- items_atestados:{{items_atestados}} <br/> -->
    <!-- tipo_atestado:{{ tipo_atestado }} <br/> -->
    <!-- {{items_exames}} -->
    <!-- {{selected_exames}} -->
    <!-- {{pagina_select}} -->
  
    <!-- {{ obj_modelo }} -->

    <!-- selected_receitas: {{selected_receitas}}<br/><br/> -->
    
    <!-- items_receitas:    {{ items_receitas }} <br/><br/> -->
    <!-- {{ obj_exames }} -->
    
    <!-- urlLogo:{{ urlLogo }} <br/> -->
    <!-- urlLogo_pro:{{ urlLogo_pro }} <br/> -->
    <!-- {{mostrar_logo_pro}} -->
    <!-- {{pegaTitulo}} -->
    <!-- {{titulo_modelo}} -->
    <!-- {{ item_before }} -->
    <!-- {{item_edita}} -->
    <!-- {{ items_modelos_imp }} -->
    <!-- {{ pegaDiretorTecnico }} -->
    <!-- {{ pegaDiretorArray }} -->
    <!-- {{ urlImages }} -->
    <!-- {{ pegaTituloArray }} -->
    <!-- {{ get[0] }} -->
    <!-- {{ get[0].PATH_LOGO }} -->
    <!-- {{ urlLogo }} -->
    <!-- DSI -->
    <!-- {{cor_fernanda}} -->

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      :color="snack_color"
      class="no-print"
    >
      <center>
        {{ snack_text }}
      </center>
    </v-snackbar>




    <template>
      <div class="text-center">
        <v-dialog
          v-model="dialog_ok"
          width="500"
          persistent
        >


          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{  (dialog_ok_tipo == 'conectar_zap') ? 'Concluído':''  }}
            </v-card-title>

            <v-card-text>
              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog_ok_clicou(dialog_ok_tipo), dialog_ok=false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>

    <template>
      <div class="text-center">
        <v-dialog
          v-model="dialog_pronto"
          width="500"
          persistent
        >


          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Registro Salvo com sucesso!
            </v-card-title>

            <v-card-text>
              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog_pronto=false, dialog_pre_assinar=false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>


    <v-dialog
      v-model="dialog_assinar"
      max-width="600px"
      persistent
      scrollable 
    >
      <v-card 
        class="grey lighten-4"
        ref="cardWithCanvas">
        <v-card-title>
          Assinatura
        </v-card-title>

        <v-card-text class="px-0">
          <v-container>
            <v-row justify="center" dense>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-row justify="center" >
                      <canvas ref="canvas" width="350" height="200" style="border: 1px solid #000;"></canvas>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        
        <v-card-actions>
          
          
          <v-btn small color="" @click="clearSignature">Limpar</v-btn>

          <v-spacer></v-spacer>

          <v-btn small color="error" @click="dialog_assinar=false">Cancelar</v-btn>
          <v-btn small color="primary" @click="saveSignature">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog
        v-model="dialog_pre_assinar"
        max-width="600px"
        persistent
        scrollable
        :fullscreen="(isMobile)"  
      >
      
        <v-card :height=retorna_altura(100)>
          <!-- <v-card-title class="grey lighten-3">
            {{nome_modelo}} 
          </v-card-title>
          <v-divider class=""></v-divider> -->
          
          <v-card-text class="">
            <!-- <p v-html="editor.getHTML()" v-if="(editor)" ></p> -->
            <div v-html="trata_quebra_p(editor.getHTML())" v-if="(editor)" ></div>
          </v-card-text>

          <v-divider class=""></v-divider>
          <v-card-actions class="grey lighten-3 py-5">
            <v-checkbox
              v-model="checkbox_aceito"
              label="Aceito o Termo"
              dense
              
              class="ml-0 mb-2 "
              hide-details="true" 
              style="margin-left: 8px !important"  
          >
              <!-- <template v-slot:label>
                  <span class="red--text">Aceito o Termo</span>
              </template> -->
          </v-checkbox>
          <v-spacer></v-spacer>


          <v-btn
              color="primary"
              small
              dark
              @click="desenhar_assinatura()"
              v-if="(checkbox_aceito)"
              class="mr-4"
            >
              Assinar
            </v-btn>

          <span class="mr-2">
            <v-btn
              color="error"
              small
              dark
              @click="dialog_pre_assinar=false"
            >
              Cancelar
            </v-btn>
            
          </span>
            

          </v-card-actions>
          <v-divider class="mb-1"></v-divider>

        </v-card>
    </v-dialog>


    <v-dialog
        v-model="dialog_pendentes"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-card-title class="orange">
            {{array_ids_whats.length}} Mensagens de WhatsApp não enviadas!
          </v-card-title>
          <v-card-text>
            <v-select
              :items="array_ids_whats"
              label="Número"
              item-value="CODIGO"
              item-text="CELULAR"
              return-object
              hide-details="true"
            >
          
               <!-- <template v-slot:slot-scope="{ item , index}">  -->
               <!-- <template v-slot:item.NOME="{ item , index}">  -->
               <template slot='item' slot-scope="{ item }">
                 <div class="caption">
                 CODIGO ({{ item.CODIGO }} ) às  {{ item.HORA }} &nbsp | &nbsp {{ item.CELULAR }}
                 </div>                      
              </template>
          
          </v-select>

          <!-- <div v-if="selectedFarmacia" class="mt-2 fs-11">
            {{ selectedFarmacia.WHATS_MSG }}
          </div> -->



          <!-- <v-divider class="px-5 my-10"></v-divider> -->

          <br/>
          <h2 class="mb-1">Possíveis Motivos:</h2>
          <p> 1- Verifique se o Módulo WhatsApp esta conectado</p>
          <p> 2- Volte para a Agenda e verifique o ícone do WhatsApp se esta verde ou vermelho</p>
          <p> 3- Caso esteja conectado, informe a DSi sobre o mesmo</p>


          </v-card-text>
          <v-card-actions>

            <v-spacer></v-spacer>

            <v-checkbox
              v-model="checkbox_avisar_pendentes"
              label="Não avisar mais"
              dense
              class="mr-9 mb-2"
              hide-details="true" 
              style="margin-left: 8px !important"  
          ></v-checkbox>

            <v-btn
              color="error"
              text
              @click="fechar_dialog_pendentes"
            >
              Fechar
            </v-btn>
            

          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog_farmacia"
        max-width="500px"
      >
        <v-card>
          <span v-if="(tipo_enviar=='selecionar_farmacia')">
              <v-card-title>
                Selecione a Farmácia
              </v-card-title>

              <v-card-text>
                <v-select
                  :items="items_farmacias"
                  label="Nome"
                  item-value="CODIGO"
                  item-text="NOME"
                  return-object
                  v-model="selectedFarmacia"
                  hide-details="true"
                >
              
                  <template slot='item' slot-scope='{ item }'>
                    <div class="caption">
                      {{ item.NOME }} &nbsp | &nbsp {{ item.NUMERO }}
                    </div>                      
                  </template>
              
              </v-select>

              <div v-if="selectedFarmacia" class="mt-2 fs-11">
                {{ selectedFarmacia.DESCRICAO }}
              </div>



              <!-- <v-divider class="px-5 my-10"></v-divider> -->

              <v-textarea
                v-model="msg_farmacia"
                class="mt-7"
                label="Texto que será enviado"
                v-if="selectedFarmacia"
                hide-details="true"
                dense
                rows="9"
                :rules="notnullRules"
                outlined
              >
              <!-- vai_imprimir -->
              </v-textarea>

              </v-card-text>
          </span>
          
          <span v-if="(tipo_enviar=='selecionar_paciente')">
              <v-card-title>
                <span>
                  <h4>{{ obj_dados_paciente.NOME }}</h4> 
                  <h6 class="orange--text">Confirme o número do paciente antes de enviar!</h6>
                </span>
              </v-card-title>


              <v-row class="mx-2" >
                <v-col cols="12">
                  <v-text-field
                    v-model="numero_selecionado"
                    label="Número"
                    type="text"
                    dense
                    hide-details=""
                    v-mask = "'(##) #####-####'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>


              <v-card-text>

              <v-textarea
                v-model="msg_farmacia"
                class="mt-7"
                label="Texto que será enviado"
                v-if="numero_selecionado"
                hide-details="true"
                dense
                rows="9"
                :rules="notnullRules"
                outlined
              >
              <!-- vai_imprimir -->
              </v-textarea>

              </v-card-text>
          </span>

          <v-card-actions>

            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="dialog_farmacia = false"
              class="mr-5"
            >
              Cancelar
            </v-btn>
            
            <v-btn
              :color="(zapzap_conectado) ? 'success' : 'grey'"
              dark
              class=""
              @click="dialog_farmacia = false, clicou_em('send_farmacia')"
              :disabled="!msg_farmacia"
            >
              Enviar
                <v-icon
                  right
                  dark
                >
                  mdi-whatsapp
                </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-row>
      <!-- coluna esquerda -->
      <v-col cols="12" md="4" class="" v-if="(!dialog_pre_assinar)">
        <v-card class="no-print grey lighten-4 overflow-y-auto py-3" :height="(!isMobile) ? retorna_altura(15) : ''">

          <v-card class="mx-2 mt-0 dsi_vcard pa-2">
              <!-- v-if="editor_atestado" -->
                <v-tabs
                    v-model="tab_1"
                    background-color="#FAFAFA"
                    show-arrows    
                    height="28"
                    color="" 
                >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab  href="#TABeditor" v-if="2>1">
                        <v-icon size="22" class="mr-2">
                          <!-- mdi-printer  -->
                          mdi-format-page-break 
                          <!-- mdi-format-page-split  -->
                          <!-- mdi-stretch-to-page-outline  -->
                        </v-icon>
                        pagina
                    </v-tab>
                    
                </v-tabs>


                <v-tabs-items 
                  v-model="tab_1"
                  touchless
                >
                  <v-tab-item :key="1" value="TABeditor" class="">


                    <v-card class="dsi_vcard mt-2 pl-2 mx-1" v-if="(PrintPode)">

                      <v-row dense class="my-n5">
                        <v-col cols="11"  class="">
                          <v-switch
                            v-model="editar_modelo"
                            label="Editar Medidas"
                            dense
                          ></v-switch>
                        </v-col>

                        <!-- <v-col cols="6" class="mt-5">

                          <v-chip small color="grey" text-color="white" v-if="(item_edita.MODELO_IMPRESSAO)">
                              {{ item_edita.MODELO_IMPRESSAO }}
                            </v-chip>
                        </v-col> -->

  
                        
                    </v-row>
                    </v-card>

                    <v-card class="dsi_vcard ma-1 mt-3 pa-2 "
                      min-height="100"
                      elevation="8"
                      v-if="(editar_modelo)"
                    >
                      <!-- offset-md="2" deixa um espaço no grid-->
                      <v-row dense class="red lighten-5">

                        <v-col class=""  cols="3">
                          <v-text-field
                            v-model="item_edita.ALTURA_CABECALHO"
                            label="Cabeçalho"
                            type="number"
                            hint="ex: 3.5 cm"
                            
                          ></v-text-field>
                        </v-col>




                        <v-col class="" cols="3">
                          <v-text-field
                            v-model="item_edita.ALTURA_MEIO"
                            label="Meio"
                            type="number"
                            hint="ex: 16 cm"
                          ></v-text-field>
                        </v-col>

                        <v-col class="" cols="3">
                          <v-text-field
                            v-model="item_edita.ALTURA_RODAPE"
                            label="Rodapé"
                            type="number"
                            hint="ex: 5.5 cm"
                          ></v-text-field>
                        </v-col>

                        <v-col class="" cols="3">
                          <v-text-field
                            v-model="item_edita.LARGURA_PAGINA"
                            label="Largura"
                            type="number"
                            hint="ex: 15 cm"
                          ></v-text-field>
                        </v-col>

                      </v-row>

                      <!-- <v-divider class="my-1"></v-divider> -->

                      <v-row  dense class="red lighten-5">

                        <v-col class="py-0 my-n2 px-3" cols="auto">
                          <v-switch
                            v-model="item_edita.MOSTRAR_LOGO"
                            label="Logo"
                            
                          ></v-switch>
                        </v-col>


                        <v-col class="px-3" cols="auto" lg="3" v-if="mostrar_logo">
                          <div class="caption mt-n1">Posição</div>
                          <v-select
                            v-model="item_edita.POSICAO_LOGO"
                            :items="['Esquerda','Centro','Direita']"
                            dense
                          >
                          </v-select>
                        </v-col>

                        <v-col class="px-3"  cols="auto"  v-if="mostrar_logo">
                          <v-text-field
                            v-model="item_edita.ALTURA_LOGO"
                            label="Altura da Logo"
                            type="number"
                            hint="ex: 2.5 cm"
                            class="mt-5"
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col class="py-0 my-n2 px-3" cols="auto">
                          <v-switch
                            v-model="item_edita.MOSTRAR_TITULO"
                            label="Título"
                          ></v-switch>
                        </v-col>


                        <v-col class="py-0 my-n2 px-3" cols="auto">
                          <v-switch
                            v-model="item_edita.MOSTRAR_LOGO_PRO"
                            label="Logo do Profissional"
                            
                          ></v-switch>
                        </v-col>

                      </v-row>

                      <v-row dense class="red lighten-4">

                        <v-col class="px-3" cols="auto" v-if="2>1">
                          <v-switch
                            dense
                            v-model="item_edita.MOSTRAR_RODAPE"
                            label="Rodapé"
                          ></v-switch>
                        </v-col>


                        <v-col class="px-3" cols="auto" v-if="mostrar_rodape">
                          <v-switch
                            dense
                            v-model="item_edita.MOSTRAR_CIDADE"
                            label="Data/Cidade"
                          ></v-switch>
                        </v-col>

                        <v-col class="px-3" cols="auto" v-if="mostrar_rodape">
                          <v-switch
                            dense
                            v-model="item_edita.MOSTRAR_ASSINATURA"
                            label="Assinatura Dr"
                          ></v-switch>
                        </v-col>

                        <v-col class="px-3" cols="auto">
                          <v-switch
                            dense
                            v-model="item_edita.MOSTRAR_ENDERECO"
                            label="Endereço"
                          ></v-switch>
                        </v-col>

                        <v-col class="px-3" cols="auto">
                          <v-switch
                            dense
                            v-model="item_edita.MOSTRAR_DIR_TECNICO"
                            label="Dir.Técnico"
                          ></v-switch>
                        </v-col>

                      </v-row>



                      <v-row dense class="red lighten-5 pt-3">

                        <v-col class="px-3"  cols="12" md="8">
                          <v-text-field
                            v-model="item_edita.MODELO_IMPRESSAO"
                            label="Nome do Modelo"
                            type="text"
                            dense
                            hide-details=""
                            prepend-icon="mdi-file-document-outline "
                          >
                          <template v-slot:label>
                            <span class="red--text">Nome do Modelo</span>
                          </template>
                        </v-text-field>
                        </v-col>

                        <v-col class="px-3" cols="12" md="4">
                          <v-select
                            v-model="item_edita.COD_PROF"
                            dense
                            label="Profissional"
                            :items="obj_profissionais_sub"
                            item-value='CODIGO'
                            item-text='NOME'
                            :return-object="false"
                          >

                          <template v-slot:label>
                            <span class="red--text">Profissional</span>
                          </template>

                          <!-- <template slot='selection' slot-scope='{ item }'>
                            <span class="fs-10"> {{ item.NOME }}</span>
                          </template> -->
                          </v-select>

                        
                        </v-col>

                      </v-row>

                      <v-row dense class="blue lighten-5 px-2">
                        <v-col class="mb-2 mt-2" md="12">                

                        <v-btn
                          color="primary"
                          @click="clicou_em('salvar_como')"
                          x-small
                          class="mr-4"
                          v-if="(item_edita.MODELO_IMPRESSAO !=titulo_modelo )"
                        >
                          Salvar como
                        </v-btn>

                        <v-btn
                          color="success"
                          @click="salvar_tabela('CONFIG_IMPRESSAO', item_edita, '')"
                          x-small
                          class="mr-4"
                          v-if="(item_edita.CODIGO)&&(item_edita.MODELO_IMPRESSAO)"
                        >
                          atualizar - {{ item_edita.CODIGO }}
                           <!-- - {{ item_edita.CODIGO }} -->
                        </v-btn>

                        <v-btn
                          color="orange"
                          dark
                          @click="cancelar_alteracoes()"
                          x-small
                          v-if="(item_edita.CODIGO)&&(item_edita.MODELO_IMPRESSAO)"
                        >
                          cancelar
                           
                        </v-btn>



                        <hr class="my-2" v-if="(isMobile)">

                        <v-btn
                          color="error"
                          @click="remover_tabela('CONFIG_IMPRESSAO', item_edita, item_edita.MODELO_IMPRESSAO)"
                          x-small
                          :class="[(isMobile)?'' : 'ml-10']"
                          v-if="(item_edita.CODIGO)&&(item_edita.MODELO_IMPRESSAO)"
                        >
                          Remover
                           <!-- - {{ item_edita.CODIGO }} -->
                        </v-btn>


                        </v-col>

                      </v-row>

                        
                    </v-card>


                    <v-card class="dsi_vcard ma-1 mt-2"
                      max-height="200"
                      elevation="8"
                    >

                    <h5>&nbsp Formato do Papel:</h5>
                        
                    <v-chip-group class="px-1" column v-if="(2>1)"
                      v-model="pagina_select"
                      active-class="deep-purple accent-4 white--text"
                    >
                      
                          <v-chip
                              v-for="(item, i) in items_modelos_imp"
                              :key="i"
                              small
                              @click="clicou_modelo(item, true)"
                              color="primary"
                              :value="item.MODELO_IMPRESSAO"
                          >
                            {{ item.MODELO_IMPRESSAO }}
                          </v-chip>
                      </v-chip-group>


                      <v-data-table
                        :headers="headers_modelos_imp"
                        :items="items_modelos_imp"
                        :items-per-page="50"
                        :search="search"
                        class="elevation-1"
                        max-height="300px"
                        dense
                        hide-default-footer
                        v-if="(0>1)"
                      >
                      
                      <template v-slot:item.MODELO_IMPRESSAO="{ item }">
                        <div class="blue--text" @click="clicou_modelo(item, true)">
                          <v-chip small color="primary" @click="clicou_modelo(item, true)">
                            <span
                              color="primary"
                              class=""
                            >
                            {{ item.MODELO_IMPRESSAO }}
                            </span>
                          </v-chip>
                      </div>
                      </template>         
                      
                      
                      </v-data-table>
                    </v-card>
                  </v-tab-item>

                </v-tabs-items>

              </v-card>



<!-- {{ this.get[0].printTipo  }} -->


      <v-card class="mx-2 mt-2 pa-1 dsi_vcard pa-2" v-if="0>1">
          <!-- v-if="editor_atestado" -->
            <v-tabs
                v-model="tab_2"
                background-color="#FAFAFA"
                show-arrows    
                height="28"
                color="" 
            >
                <v-tabs-slider></v-tabs-slider>

                <v-tab  href="#TABatestado" v-if="2>1">
                    <!-- <v-icon size="22" class="mr-2">
                      mdi-format-page-break 
                    </v-icon> -->
                    atestado
                </v-tab>
                
            </v-tabs>


            <v-tabs-items 
              v-model="tab_2"
              touchless
            >
                <v-tab-item :key="1" value="TABatestado" class="">

                </v-tab-item>
            </v-tabs-items>

        </v-card>


          <v-card
            
            class="mx-2 mt-2 pa-1 dsi_vcard pa-2"
            elevation="8"
            
          >
          <!-- v-if="this.get[0].printTipo == 'atestado'" -->
            

            <v-row  class="" v-if="(PrintPode)">
              <v-col cols="6" md="6" lg="4" class=" pr-1">
                <!-- <h3>Tipos</h3> -->

                <h5>&nbsp Tipo:</h5>

                <v-select
                  v-model="tipo_atestado"
                  :items="itemsLista"
                  class=""
                  dense
                  chips
                  small-chips

                  color="primary"
                  @change="crud_impressos"
                  hide-details=""
                >

                <template #selection="{ item }">
                  <v-chip small class="mb-1 deep-purple accent-4 white--text"
                    color="primary"
                    >{{ item }}</v-chip
                  >
                </template>

                </v-select>
              </v-col>

              <v-col cols="6" md="auto" class="pa-0 ma-0 px-8 pt-5">
                <v-switch
                  dense
                  v-model="editor_atestado"
                  label="Editor."
                  hide-details=""
                  @click="escolhe_atestado(modelo_selecionado)"
                ></v-switch>
              </v-col>

              <v-col cols="6" md="6" lg="4" class="px-1 pl-3 pt-8" v-if="((items_atestados.length>9) )">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Procurar"
                  single-line
                  hide-details
                  clearable
                  dense
                  @keyup.esc="search=''"
                >
                </v-text-field>
              </v-col> 



            </v-row>

            <v-row v-if="editor_atestado == true" class="mb-3 red lighten-5" dense >

              <v-divider class="mx-8" inset></v-divider>

              <v-col cols="12" md="12" class="">
               

                <div> 
                <h3 class="red--text mb-1">Editor de Modelos:</h3>  
                <v-row dense>
                  
                  <v-col class="px-3 pt-4" cols="12" md="12">
                    <v-text-field
                      dense
                      v-model="nome_modelo"
                      label="Nome do Modelo"
                      type="text"
                      :outlined="false"
                      hide-details=""
                      append-icon="mdi-file-document-outline "
                    >
                    <template v-slot:label>
                      <span class="red--text">Nome do Modelo</span>
                    </template>
                    </v-text-field>
                   
                  </v-col>
                  
                  <v-col class="px-3 pt-4" cols="12" md="6">
                    <v-select
                      v-model="cod_prof_modelo"
                      dense
                      label="Profissional."
                      :items="obj_profissionais_sub"
                      item-value='CODIGO'
                      item-text='NOME'
                      :return-object="false"
                    >

                        <template v-slot:label>
                          <span class="red--text">Profissional.</span>
                        </template>
                    </v-select>
                  </v-col>
                  
                  <v-col class="px-3 pt-4" cols="12" md="6">
                    <v-select
                      v-model="nome_formato_modelo"
                      dense
                      label="Formato do Papel."
                      :items="items_modelos_imp_sub"
                      item-value='MODELO_IMPRESSAO'
                      item-text='MODELO_IMPRESSAO'
                      :return-object="false"
                    >

                        <template v-slot:label>
                          <span class="red--text">Formato do Papel.</span>
                        </template>
                    </v-select>
                  </v-col>
                </v-row>


                  <!-- COLUNA DE SUBIR IMAGEM -->
                  <v-row dense class="px-2" v-if="0>1">
                    <v-col cols="12">
                      <v-file-input
                        v-model="files_upload"
                        label="Selecionar uma imagem"
                        prepend-icon="mdi-camera"
                        :outlined="false"
                        @change="carrega_imagem()"
                        accept="image/*"                             
                        id="file_upload"
                      ></v-file-input>
                      <!-- @change="carrega_imagem()" -->
                    </v-col>
                  </v-row>

                  <v-row dense class="px-2">
                    
                  <!-- COLUNA DOS BOTOES SALVAR CANCELAR -->
                  <v-col class="" cols="12">                

                    <v-btn
                      color="primary"
                      @click="salvar_atestado()"
                      x-small
                      class=""
                      v-if="nome_modelo !== ''"
                    >
                      Salvar como
                    </v-btn>

                    <v-divider class="mx-1" vertical inset></v-divider>

                    <v-btn
                      color="success"
                      @click="atualizar_atestado()"
                      x-small
                      class=""
                      v-if="getCodigoAtestado.length > 0 "
                    >
                      Atualizar - {{ getCodigoAtestado }}
                    </v-btn>

                    <v-divider class="mx-1" vertical inset></v-divider>

                    <v-btn
                      color="error"
                      @click="remover_atestado()"
                      x-small
                      :class="[(isMobile)?'' : 'ml-10']"
                      v-if="getCodigoAtestado.length > 0 "
                    >
                      Remover - {{ getCodigoAtestado }}
                    </v-btn>

                    <hr class="my-2" v-if="(isMobile)">


                  </v-col>
                </v-row>


                </div>

              </v-col>
            </v-row>


          </v-card>

          <v-card
            class="mx-2 mt-2 pa-1 dsi_vcard pa-2"
            elevation="8"
            v-if="this.get[0].printTipo !== 'editar'"
          >

          <h5>Modelos de Impressão:</h5>

            <v-row class="">
              <v-col>
                <v-data-table
                  :headers="headers_atestados"
                  :items="items_atestados"
                  :items-per-page="500"
                  :search="search"
                  class="elevation-1"
                  :height="(tipo_atestado == 'Exames')||(tipo_atestado == 'Receitas') ? '150px' : '400px'"
                  dense
                  hide-default-footer

                  v-if="(items_atestados.length>9)"
                >
                
                  <template v-slot:item.NOME="{ item }">

                    <v-chip  color="primary" class="" small
                    @click="escolhe_atestado(item)"
                    >
                      {{ item.NOME }}
                    </v-chip>

                  </template>         
              
                </v-data-table>

                <v-chip-group class="px-1" column 
                v-model="modelo_select"
                active-class="deep-purple accent-4 white--text"
                v-else   
              >
                    <v-chip
                        v-for="(item, i) in items_atestados"
                        :key="i"
                        small
                        @click="escolhe_atestado(item)"
                        color="primary"
                        :value="item.NOME"
                    >
                      {{ item.NOME }}
                    </v-chip>
                </v-chip-group>                
              </v-col>
            </v-row>

            <v-row class="mt-0 pt-0" v-if="(tipo_atestado=='Receitas') || (tipo_atestado=='Exames')">
              <v-col class="mt-0 pt-0">
                <v-checkbox
                    v-model="inverter_selecao"
                    label="Inverter Seleção"
                    dense
                    class=""  
                    hide-details
                    @click="f_inverter_selecao()"
                ></v-checkbox>
                <!-- style="margin-left: 8px"  -->
              </v-col>
            </v-row>
            
            

          </v-card>

          <!-- CARD RECEITAS -->
          <v-card v-if="(tipo_atestado == 'Receitas')"
            :max-height="retorna_altura( (items_atestados.length>9) ? 420 :  420)"
            class="mx-2 mt-2 pa-1 dsi_vcard pa-2 overflow-y-auto"
            elevation="8"
          >
          <!-- :max-height="retorna_altura(460)" -->

            <v-data-table
              v-model="selected_receitas" 
              :show-select = true
              item-key="CODIGO"

              dense
              :headers="headers_receitas"
              :items="items_receitas"
              class="elevation-1"
              
              :disable-sort="$vuetify.breakpoint.smAndDown"

              fixed-header
              :items-per-page="-1"
              :footer-props="{'items-per-page-options': [-1]}"

              :height="(isMobile) ? '500' :  retorna_altura((items_atestados.length>9) ? 520 : 480)"
            >
            

              <template v-slot:item.MEDICAMENTO="{ item, index }">
                <span class="grey--text">{{ index + 1 }}º &nbsp</span>
                  {{ item.MEDICAMENTO }}
                  <v-chip v-if="(item.MANIPULADO.length>5)" small> 
                    MANIPULADO
                  </v-chip>
              </template>

            </v-data-table> 

          </v-card>

          <!-- CARD EXAMES -->
          <v-card v-if="(tipo_atestado == 'Exames')"
            min-height="100"
            class="mx-2 mt-2 pa-1 dsi_vcard pa-2 overflow-y-auto"
            elevation="8"
          >

            <v-data-table
              v-model="selected_exames" 
              :show-select = true
              item-key="CODIGO"

              dense
              :headers="headers_exames"
              :items="items_exames"
              class="elevation-1"
              
              :disable-sort="$vuetify.breakpoint.smAndDown"
              
              :height="(isMobile) ? '500' : retorna_altura(420)"

              fixed-header
              :items-per-page="-1"
              :footer-props="{'items-per-page-options': [-1]}"
            >

              <template v-slot:item.EXAME="{ item, index }">
                <span class="grey--text">{{ index + 1 }}º &nbsp</span>
                  {{ item.EXAME }}
              </template>

            </v-data-table> 

          </v-card>

        </v-card>
      </v-col>

      <!-- coluna direita -->
      <v-col cols="12" md="8" class="" v-if="(!dialog_pre_assinar)">

        
          
          <v-row no-print>
            <v-col>
              <v-card class="mx-auto pa-1 no-print mt-2 mr-1" v-if="2>1">
                
                <v-toolbar dense>

                  <template v-if="$vuetify.breakpoint.mdAndUp">

                    <!-- <v-spacer></v-spacer> -->

                    <v-btn-toggle
                      v-model="toggle_multiple"
                      color="primary"
                      dense
                      group
                      multiple
                    >
                      <v-btn
                        :value="1"
                        text
                        @click="editor.chain().focus().toggleBold().run()"
                      >
                        <v-icon>mdi-format-bold</v-icon>
                      </v-btn>


                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- @click="editor.chain().focus().setColor('#F98181').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#F98181' })}" -->
                          <v-btn v-bind="attrs" v-on="on" :value="2" v-if="2>1">
                            <v-row align="center" class="flex-column" justify="center">
                              <v-icon class="cols 12"> mdi-format-size  </v-icon>

                              <v-sheet
                                tile
                                style="margin-top: -1px"
                                height="4"
                                width="26"
                                color="blue"
                              ></v-sheet>

                            </v-row>
                          </v-btn>
                        </template>
                        <v-list>
                          <!-- <v-list-item @click="set_font_size('90')">
                            <v-list-item-title> </v-list-item-title>
                            <span>90%</span>
                          </v-list-item> -->
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>24²</span>
                          </v-list-item>
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>18</span>
                          </v-list-item>
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>16</span>
                          </v-list-item>
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>12</span>
                          </v-list-item>
                          
                          <v-list-item @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" >
                            <v-list-item-title> </v-list-item-title>
                            <span>10</span>
                          </v-list-item>

    
                        </v-list>
                      </v-menu>

                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- @click="editor.chain().focus().setColor('#F98181').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#F98181' })}" -->
                          <v-btn v-bind="attrs" v-on="on" :value="3">
                            <v-row align="center" class="flex-column" justify="center">
                              <v-icon class="cols 12"> mdi-format-color-text </v-icon>

                              <v-sheet
                                tile
                                style="margin-top: -4px"
                                height="4"
                                width="26"
                                :color="corTexto"
                              ></v-sheet>
                            </v-row>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="setCorTexto('black')">
                            <v-list-item-title> </v-list-item-title>
                            <v-icon color="black"> mdi-checkbox-blank </v-icon>
                          </v-list-item>

                          <v-list-item @click="setCorTexto('red')">
                            <v-list-item-title></v-list-item-title>
                            <v-icon color="red"> mdi-checkbox-blank </v-icon>
                          </v-list-item>

                          <v-list-item @click="setCorTexto('blue')">
                            <v-list-item-title></v-list-item-title>
                            <v-icon color="blue"> mdi-checkbox-blank </v-icon>
                          </v-list-item>

                          <v-list-item @click="setCorTexto('green')">
                            <v-list-item-title></v-list-item-title>
                            <v-icon color="green"> mdi-checkbox-blank </v-icon>
                          </v-list-item>
                        </v-list>
                      </v-menu>

                    </v-btn-toggle>

                    <!-- <div class="mx-1"></div> -->
                    <v-divider class="mx-1" vertical inset></v-divider>

                    <v-btn-toggle
                      v-model="toggle_exclusive"
                      color="primary"
                      dense
                      group
                    >

                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <!-- @click="editor.chain().focus().setColor('#F98181').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#F98181' })}" -->
                          <v-btn v-bind="attrs" v-on="on" :value="2" v-if="2>1">
                            <v-row align="center" class="flex-column" justify="center">
                              <v-icon>mdi-format-align-justify</v-icon>

                              <v-sheet
                                tile
                                style="margin-top: -1px"
                                height="4"
                                width="26"
                                color="blue"
                              ></v-sheet>
                              
                            </v-row>
                          </v-btn>
                        </template>

                          <v-list>
                            
                            <v-list-item @click="editor.chain().focus().setTextAlign('left').run()" >
                              <v-icon >mdi-format-align-left</v-icon>
                            </v-list-item>
                            
                            <v-list-item @click="editor.chain().focus().setTextAlign('center').run()" >
                              <v-icon >mdi-format-align-center</v-icon>
                            </v-list-item>
                            
                            <v-list-item @click="editor.chain().focus().setTextAlign('right').run()" >
                              <v-icon >mdi-format-align-right</v-icon>
                            </v-list-item>
                            
                            <v-list-item @click="editor.chain().focus().setTextAlign('justify').run()" >
                              <v-icon >mdi-format-align-justify</v-icon>
                            </v-list-item>
                            

      
                          </v-list>
                      </v-menu>


                    </v-btn-toggle>

                    <v-divider class="mx-1" vertical inset></v-divider>

                    <v-btn-toggle
                      v-model="toggle_exclusive"
                      color="primary"
                      dense
                      group
                    >

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            @click="editor.chain().focus().undo().run()"
                            v-bind="attrs"
                            v-on="on"
                            :value="5"
                          >
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                        </template>
                        <span>Desfazer</span>
                      </v-tooltip>


                      <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="editor.chain().focus().redo().run()"
                          v-bind="attrs"
                          v-on="on"
                          v-if="0>1"
                        >
                          <v-icon>mdi-redo</v-icon>
                        </v-btn>
                      </template>
                      <span>Refazer</span>
                    </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="update_windows" v-bind="attrs" v-on="on" :value="6">
                            <v-icon>mdi-refresh </v-icon>
                          </v-btn>
                        </template>
                        <span>Atualizar</span>
                      </v-tooltip>

                    </v-btn-toggle>

                    <v-divider class="mx-1" vertical inset></v-divider>


                    <v-btn  
                    @click="teste_here"
                    color="primary text--white"
                    v-if="0>1"
                    >
                      <v-icon color="white"> mdi-printer </v-icon>
                    </v-btn>

                    </template>

                    <v-spacer></v-spacer>

                    



                          <span v-if="(tipo_atestado=='Termos')">
                          <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <!-- <v-btn  
                                @click="imprimir"
                                color="success" 
                                > -->
                                <v-icon color="primary" x-large v-bind="attrs" v-on="on" class="hand mr-3" @click="ver_documento()"> 
                                    <!-- mdi-text-box  -->
                                    <!-- mdi-text-box-check-outline -->
                                    <!-- mdi-signature-freehand -->
                                    mdi-draw-pen
                                  </v-icon>
                                <!-- </v-btn> -->
                              </template>
                              <span>Assinar e Salvar Termo</span>
                            </v-tooltip>
                          </span>


                        
                          <template v-if="(PrintPode) && (zapzap_conectado)">
                            <div class="text-center">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon x-large v-bind="attrs" v-on="on" class="hand mr-3"
                                  :color="(zapzap_conectado) ? 'success' : 'grey'"
                                  @click="botao_conectar(false, true)"
                                  v-if="(tem_farmacias)"
                                  > 
                                  <!-- v-if="(zapzap_mostrar) && (tem_farmacias)" -->
                                  
                                  <!-- :disabled="!zapzap_conectado" -->
                                  mdi-whatsapp
                                 </v-icon>

                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(item, index) in items_whats"
                                    :key="index"
                                    @click="clicou_em(item.click)"
                                  >
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </div>
                          </template>

                          <v-btn
                            color="green"
                            dark
                            text x-large
                            
                            class="mx-2"
                            v-if="(!zapzap_conectado) && (PrintPode)"
                            @click="botao_conectar(false, true)"
                          >
                            Conectar
                              <v-icon
                                right
                                dark
                                size="38"
                                class="ml-3"
                              >
                                mdi-whatsapp
                              </v-icon>
                          </v-btn>


                          <span v-if="(PrintPode)">
                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <!-- <v-btn  
                            @click="imprimir"
                            color="success" 
                            > -->
                            <v-icon color="success" x-large v-bind="attrs" v-on="on" class="hand mr-3" @click="salvar_em_documentos()"> 
                                <!-- mdi-text-box  -->
                                <!-- mdi-text-box-check-outline -->
                                mdi-text-box-plus
                              </v-icon>
                            <!-- </v-btn> -->
                          </template>
                          <span>Salvar em Atendimento, Registros</span>
                          </v-tooltip>
                          </span>


                          <span v-if="(PrintPode)">
                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <!-- <v-btn  
                            @click="imprimir"
                            color="success" 
                            > -->
                            <v-icon color="red" x-large v-bind="attrs" v-on="on" class="hand" @click="muda_flag()"> 
                                <!-- mdi-text-box  -->
                                <!-- mdi-text-box-check-outline -->
                                mdi-file-pdf-box
                              </v-icon>
                            <!-- </v-btn> -->
                          </template>
                          <span>Salvar em PDF</span>
                          </v-tooltip>
                          </span>
                        


                          <v-btn  
                          @click="imprimir"
                          color="primary text--white" class="ml-5"
                          >
                            <v-icon color="white"> mdi-printer </v-icon>
                          </v-btn>

                     <v-divider class="mx-2" vertical inset></v-divider>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="close" v-bind="attrs" v-on="on">
                            <v-icon color="red"> mdi-window-close </v-icon>
                          </v-btn>
                        </template>
                        <span>Fechar</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="verificar_enviadosHERE()" v-bind="attrs" v-on="on" v-if="0>1">
                            testar
                          </v-btn>
                        </template>
                        <span>Testar</span>
                      </v-tooltip>



                  
                </v-toolbar>
              </v-card>

            </v-col>
          </v-row>


          <v-card 
              :class="[(imprimindo) ? '' : 'overflow-y-auto' , 'mt-2 mx-auto pa-2 px-5']"
              :elevation="elevacao" 
              :height="(imprimindo) ?'' :  retorna_altura(90)"
              id="content-to-print"
          >
          
          <v-row>
            <v-col>

              <div ref="contentToPrint" class="">
              <v-card
                :max-width="(parseFloat(largura_pagina) - parseFloat(largura_dif)) + 'cm'"
                class="mx-auto pa-1 dsi_vcard"
                :elevation="elevacao"
              >
              <!-- :max-width="largura_pagina + 'cm'" -->
              <!-- :max-width="(parseFloat(largura_pagina) + parseFloat(largura_dif)) + 'cm'" -->
              <!-- max-width="700" -->
                <div>
                  <v-card
                    :height="altura_cabecalho + 'cm'"
                    id="id_cabecalho"
                    :elevation="elevacao"
                    :class="['d-flex align-center justify-'+posicao_logo ,'']"
                  >
                  <!-- class="d-flex align-center justify-center" -->

                    <v-row dense class="">
                      <v-col :class="['d-flex align-center justify-'+posicao_logo ,'']" v-if="(mostrar_logo)">
                        <img
                          ref="print_logo"
                          :src="urlLogo"  
                          :style="[{'max-width': 'auto', 'max-height': altura_logo+'cm'}]"
                        />
                        <!-- style="max-width: auto; max-height: 2.5cm;   " -->
                        <!-- :src="urlImages + item_edita['PATH_LOGO']" -->
                      </v-col>
                      

                      <v-col class="mr-1" v-if="(mostrar_titulo)">
                          <div >
                            <div 
                              :class="[(mostrar_logo) && (cor_fernanda) ? 'purple--text' : '' , 'body text-right']"
                            > 
                              <h5 class=""> {{ (pegaTituloArray[0]) }} </h5>
                              <h6 class="font-weight-regular"> {{ (pegaTituloArray[1]) }} </h6>
                              <h6 class="font-weight-regular"> {{ (pegaTituloArray[2]) }} </h6>
                              <h6 class="font-weight-regular"> {{ (pegaTituloArray[3]) }} </h6>
                              <h6 class="font-weight-regular"> {{ (pegaTituloArray[4]) }} </h6>
                              <h6 class="font-weight-regular"> {{ (pegaTituloArray[5]) }} </h6>
                              <h6 class="font-weight-regular"> {{ (pegaTituloArray[6]) }} </h6>
                            </div>

                            <!-- CASO QUEIRA EDITAR O TITULO..USAR ISSO.. CASO CONTRARIO USA O DIV ACIMA MESMO -->
                            <!-- <v-textarea
                              :class="['text-end']"
                              style="color: purple !important"
                              v-model="this.getHTML_Text(pegaTitulo)"
                              hint=""
                              :auto-grow="true"
                              flat solo
                              dense
                              hide-details
                              :height="altura_logo+'cm'"
                              id="textarea_titulo"
                              v-if="(0>1)"
                          > 
                          </v-textarea>-->
                        </div>
                      </v-col>

                      <!-- <v-col :class="['d-flex align-center justify-end' ,'red']" > -->
                      <v-col :class="['d-flex align-center justify-end' ,'']" v-if="(mostrar_logo_pro)">
                        <!-- v-if="(item_edita.MOSTRAR_LOGO_PRO.length)" -->
                        <img
                          :src="urlLogo_pro"  
                          :style="[{'max-width': 'auto', 'max-height': altura_logo+'cm'}]"
                        />
                        <!-- style="max-width: auto; max-height: 2.5cm;   " -->
                        <!-- :src="urlImages + item_edita['PATH_LOGO']" -->
                      </v-col>

                    </v-row>




                  </v-card>





                  <!-- <div>
              <editor v-model="content" />

              <div class="content">
              <h3>Content</h3>
              <pre><code>{{ content }}</code></pre>
              </div>
              </div> -->

                  <!-- CAIXA ONDE FICA O TEXTO PARA DIGITAR EDICAO -->
                  <!-- .ProseMirror la em css -->
                  <div v-if="editor">
                    <editor-content
                      :editor="editor"
                      class=""
                      :style="[{ minHeight: altura_meio + 'cm'}]"
                      id="id_mudar"
                      ref="id_mudar"
                    />
                    <!-- :style="[{'max-width': 'auto', 'max-height': altura_logo+'cm'}]" -->
                    <!-- :style="{ maxHeight: altura_meio + 'cm' }" -->
                  </div>



                 
                  <!-- <p v-html="editor.getHTML()"  id="p-content"></p> -->


                  <!-- RODAPE -->
                  <v-card
                    class=""
                    :height="altura_rodape + 'cm'"
                    id="id_rodape"
                    :elevation="elevacao"
                    
                    v-if="(mostrar_rodape)"  
                  >
                  <!-- v-if="((mostrar_assinatura) || (mostrar_cidade) ||(mostrar_rodape))"   -->
                  <!-- v-if="(mostrar_rodape)" -->
                  
                    <!-- {{ altura_rodape }} -->
                    <!-- <v-card-text v-html="pegaRodape" class="text-center black--text">
                    </v-card-text> -->
                    <center>

                      <p v-if="(inPDF)" v-html="(pegaRodape)" class="mt-1"></p>


                      <v-textarea
                        :class="[(mostrar_logo) && (cor_fernanda) ? 'text-purple' : '' , 'text-center ']"
                        style="color: purple !important"
                        v-model="this.getHTML_Text(pegaRodape)"
                        hint=""
                        :auto-grow="true"
                        flat solo
                        id="textarea_rodape"
                        v-if="(!inPDF)"  
                      >
                      <!-- v-if="(!inPDF)" -->
                      <!-- v-model="this.getHTML_Text(pegaRodape)" -->
                      <!-- :v-model="getHTML_Text(pegaRodape)" -->
                      </v-textarea>

                    </center>

                  </v-card>


                <!-- ENDERECO -->
                <v-card
                  elevation="0"
                  v-if="(mostrar_endereco)"
                  class="my-2 d-flex align-center justify-center"
                  >

                  <v-row dense class="">
                      <v-col :class="[(mostrar_logo)&&(cor_fernanda)? 'purple--text': '','d-flex align-center','justify-center']">
                        <div 
                          :class="[(mostrar_dir_tecnico) ? 'text-left' : 'text-center' , 'ml-1 body']"
                          style="font-size: 12px !important;"
                          v-html="pegaEndereco"
                        /> 
                      </v-col>

                      <v-divider vertical v-if="(mostrar_dir_tecnico)"></v-divider>

                      <v-col :class="['d-flex align-center justify-end','mr-1']" v-if="(mostrar_dir_tecnico)">
                        <div 
                              :class="[(mostrar_logo) && (cor_fernanda) ? 'purple--text' : '' , 'body text-right']"
                            > 
                              <h5 class=""> {{ (pegaDiretorArray[0]) }} </h5>
                              <h6 class="font-weight-regular"> {{ (pegaDiretorArray[1]) }} </h6>
                              <h6 class="font-weight-regular"> {{ (pegaDiretorArray[2]) }} </h6>
                              <h6 class="font-weight-regular"> {{ (pegaDiretorArray[3]) }} </h6>
                            </div>
                            
                      </v-col>
                  </v-row>
                </v-card>


                  <v-row
                  v-if="(TESTE)"
                  >
                    DSI
                    <v-col cols="12" md="12">
                      <v-textarea
                        background-color="#FAFAFA"
                        v-model="pegaTexto"
                        label="EVOLUÇÃO"
                        hint=""
                        rows="16"
                        :auto-grow="false"
                        row-height="8"
                        textarea-line-height="1.2rem"
                        @change="editor.commands.setContent(pegaTexto)"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </div>
            </v-col>
          </v-row>
          </v-card>
      </v-col>
    </v-row>
  </div>
</template>






<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Image from '@tiptap/extension-image'

import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

import axios from "axios";
import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
import { crud_abrir_tabela_canal } from '../assets/js/DSi_sql'
import { setPrintStyle, diaParOuImpar, get_data_hora } from '../assets/js/DSi_basico'
import { send_whats_api, verificar_enviados, replace_emoji, status_whats, conectar_whats } from '../assets/js/DSi_whats'


import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import SignaturePad from 'signature_pad';


var url       = sessionStorage['url']
var url_logo  = localStorage['url_base']


// if (url !== '/api/'){
//   url_logo = 'http://localhost' //no cliente pega a logo na raiz
// }


const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: element => element.getAttribute('data-background-color'),
        renderHTML: attributes => {
          return {
            'data-background-color': attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          }
        },
      },
    }
  },
})

export default {
  components: {
    EditorContent,
  },

  data() {
    return {

      config_whats: {},

      inverter_selecao: false,

      dialog_ok: false,
      dialog_ok_tipo: 'conectar_zap',

      dialog_pronto: false,

      superA: false,
      zapzap_mostrar : false,
      zapzap_conectado: false,

      inPDF:false,

      PrintPode:false,

      hoje:'',

      default_formato_papel:'',
      obj_dados_paciente : null,
      obj_dados_profissional : null,
      obj_dados_empresa : null,
      obj_profissionais_sub  : null,
      items_modelos_imp_sub: null,

      tipo_enviar:'',
      numero_selecionado:'',
      items_whats: [
        { title: 'Farmácia', click:'selecionar_farmacia' },
        { title: 'Paciente', click:'selecionar_paciente' },
      ],

      array_ids_whats:[],

      checkbox_aceito: true,
      dialog_pre_assinar: false,
      dialog_assinar: false,
    

      checkbox_avisar_pendentes: false,
      dialog_pendentes: false,
      dialog_farmacia: false,

      TESTE : false,

      modelo_selecionado: {},

      files_upload: [],
      url_img: '',
      retorno_path_url_img: '',
      session_bd:'',

      toggle_exclusive: 0,
      toggle_multiple: [],

      items_receitas : [],
      items_exames   : [],

      NomeEmpresa :'',

      obj_modelo : {},

      headers_receitas: [
        { text: 'Medicamento', value: 'MEDICAMENTO', width:'200px'},
      ],

      headers_exames: [
        // { text: 'TUSS', value: 'COD96' },
        { text: 'Exame', value: 'EXAME', width:'200px'},
      ],

      selected_receitas:[],
      selected_exames:[],

      tab_1: 'TABeditor',
      tab_2: 'TABatestado',

      isMobile: false,

      pagina_select :'',
      modelo_select :'',

      editar_modelo: false,
      modelo_impressao: '',
      items_modelos_imp: [],    

      tem_farmacias: true,
      items_farmacias: null,
      selectedFarmacia: null,
      msg_farmacia:'',


      headers_modelos_imp: [
            { text: 'MODELO DE IMPRESSÃO', value: 'MODELO_IMPRESSAO' },
            // { text: 'RODAPÉ', value: 'RODAPE' },
            // { text: 'CODIGO', value: 'CODIGO' },
          ],


      titulo_modelo: '',    
      item_edita: {},
      item_before: {},
      tabela_selecionada:'',


      cor_fernanda: false,

      imprimindo : false,

      editor_atestado: false,
      tipo_atestado:'',
      getImpressos:[],

      nome_logo:'logo.png',

      itemsLista: [
        'Atestado',
        'Receitas',
        'Exames',
        'Outros',
        'Termos',
      ],

      headers_atestados: [
            { text: 'MODELOS', value: 'NOME' },
            // { text: 'RODAPÉ', value: 'RODAPE' },
            { text: 'CODIGO', value: 'CODIGO' },
          ],
      items_atestados: [],    
      getCodigoAtestado: 0,
      nome_modelo:'',
      cod_prof_modelo:0,
      nome_formato_modelo:'',

      elevacao: 4,
      editor: null,
      corTexto: 'blue',
      formatting: null,
      alignment: null,

      search:'',

      urlLogo:'',
      urlLogo_pro:'',
      urlImages:'',

      get:[{
        printConteudo:'esse',
        printRodape:'esse',
        printTipo:'',
        // printNomePaciente:'xxx',    
        // printHora        :'',    
        // printHoje        :'',    
        // printNomeDoc     :'',    
        // printCRMdoc      :''
      }],

      pegaTexto:null,
      pegaRodape:null,
      pegaEndereco:null,
      pegaDiretorTecnico:null,

      pegaTitulo: null,
      pegaTituloArray :[],
      pegaDiretorArray :[],

      tamanho_pagina : '10cm', 
      altura_cabecalho:'3', //3cm
      altura_meio: '16', //16cm
      altura_rodape:'5.5',//5.5cm
      largura_pagina:'15',//5.5cm
      largura_dif: '2.5',
      margem:'',

      POSICAO_LOGO: 'Centro', //start center end
      posicao_logo: 'center', //start center end
      mostrar_logo: true,
      mostrar_logo_pro: true,
      altura_logo : '2.5',//cm

      mostrar_titulo: false,

      mostrar_cidade: false,
      mostrar_assinatura: false,
      mostrar_rodape: true,
      mostrar_endereco: true,
      mostrar_dir_tecnico: false,

      snackbar: false,
      snack_text: 'My timeout is set to 2000.',
      snack_color: "success",

      notnullRules: [
          v => !!v || 'necessário'
      ],

      

    }
  },

  created(){
      this.logado()
      this.showDrawer()
      this.ler_localStorage()
      this.isMobile = (window.innerWidth < 800)
      this.situacao_whats()
      
      // setPrintStyle('portrait')
  },

  mounted() {


    this.pegaTexto        = this.get[0].printConteudo,
    this.pegaRodape       = this.get[0].printRodape,
    this.pegaTitulo       = this.get[0].ATESTADO_TITULO,

    this.pegaTituloArray  = this.pegaTitulo?.split('\n');
    this.pegaDiretorArray = this.pegaDiretorTecnico?.split('\n');
    
    this.onMounted()

    this.editor = new Editor({
      extensions: [
        StarterKit,
        Document,
        Text,
        Image,
        Paragraph,
        TextStyle,
        Color,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),

        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        // Default TableCell
        // TableCell,
        // Custom TableCell with backgroundColor attribute
        CustomTableCell,
      ],

      
      content: `
        <h3 style="text-align:center">
          Devs Just Want to Have Fun by Cyndi Lauper
        </h3>
        <p style="text-align:center">
          I come home in the morning light<br>
          My mother says, <mark>“When you gonna live your life right?”</mark><br>
          Oh mother dear we’re not the fortunate ones<br>
          And devs, they wanna have fun<br>
          Oh devs just want to have fun</p>
        <p style="text-align:center">
          The phone rings in the middle of the night<br>
          My father yells, "What you gonna do with your life?"<br>
          Oh daddy dear, you know you’re still number one<br>
          But <s>girls</s>devs, they wanna have fun<br>
          Oh devs just want to have
        </p>
        <p style="text-align:center">
          That’s all they really want<br>
          Some fun<br>
          When the working day is done<br>
          Oh devs, they wanna have fun<br>
          Oh devs just wanna have fun<br>
          (devs, they wanna, wanna have fun, devs wanna have)
        </p>

<h3>
          Have you seen our tables? They are amazing!
        </h3>
        <ul>
          <li>tables with rows, cells and headers (optional)</li>
          <li>support for <code>colgroup</code> and <code>rowspan</code></li>
          <li>and even resizable columns (optional)</li>
        </ul>
        <p>
          Here is an example:
        </p>
        <table>
          <tbody>
            <tr>
              <th>Name</th>
              <th colspan="3">Description</th>
            </tr>
            <tr>
              <td>Cyndi Lauper</td>
              <td>singer</td>
              <td>songwriter</td>
              <td>actress</td>
            </tr>
            <tr>
              <td>Marie Curie</td>
              <td>scientist</td>
              <td>chemist</td>
              <td>physicist</td>
            </tr>
            <tr>
              <td>Indira Gandhi</td>
              <td>prime minister</td>
              <td colspan="2">politician</td>
            </tr>
          </tbody>
        </table>


      `,


    // content: `
    //  <p> 
    //    <h3 style="text-align:center">SOLICITAÇÃO DE EXAMES
    //    </h3>
    // </p>
    // <p> Sr.(a) <strong> ERMELINDA RUANI JORDÃO PACAGLELLI </strong></p>
    // <p style="text-align:left">
    //   1 -xx Coleta de CO- executar - 41301099 ERMELINDA RUANI JORDÃO PACAGLELLI dfadfad ERMELINDA RUANI JORDÃO PACAGLELLI Coleta de CO- executar - 41301099 Coleta de CO- executar - 41301099
    // </p>
    // </div><div class="a" style="text-align:left">2 -xx Colpocitologia oncótica - 40601323</div></div><div class="a" style="text-align:left">3 -xx Creatinina - 40301630</div></div><div class="a" style="text-align:left">4 -xx Cálcio ionico - 40301419</div></div><div class="a" style="text-align:left">5 -xx Glicemia - 40302040</div></div><div class="a" style="text-align:left">6 -xx Hemogramma - 40304361</div></div><div class="a" style="text-align:left">7 -xx Lipidograma - 40302750</div></div><div class="a" style="text-align:left">8 -xx Mamografia - 40808033</div></div><div class="a" style="text-align:left">9 -xx Parcial de urina - 40311210</div></div><div class="a" style="text-align:left">10 -xx TGO - 40302504</div></div><div class="a" style="text-align:left">11 -xx TGP - 40302512</div></div><div class="a" style="text-align:left">12 -xx TSH - 40316521</div></div><div class="a" style="text-align:left">13 -xx US Mamas - 40901114</div></div><div class="a" style="text-align:left">14 -xx US Tireoide - 40901203</div></div><div class="a" style="text-align:left">15 -xx US abdome total - 40901122</div></div><div class="a" style="text-align:left">16 -xx Vitamina D - 40302830</div></div><div class="a" style="text-align:left">17 -xx Ressonancia de Pelve - 41101189</div></div><div class="a" style="text-align:left">18 -xx Ferritina - 40316270</div></div><div class="a" style="text-align:left">19 -xx Ferro - 40301842</div></div><div class="a" style="text-align:left">20 -xx Vitamina B12 - 40316572</div></div><div class="a" style="text-align:left">21 -xx FSH - 40316289</div></div><br/><br/> <p style="text-align: center">Maringá, 12/11/2021</p><p style="text-align: center"> <br/> ___________________________________ <br/>DR.(A) FERNANDA SCHEFFER, CRM:21840</p>
    // `,


      // content : this.get[0].printConteudo,
      content : this.pegaTexto,
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },


  watch:{


    selectedFarmacia(a){
      console.log('selectedFarmacia watch');
      this.atu_msg_farmacia()
    },
    

    'selected_receitas' (){
      // alert('7 watch ')
        // this.imprimirReceita()
        this.escolhe_atestado(this.obj_modelo)
    },

    'selected_exames' (){
        this.imprimirExame()
    },

    'item_edita.ALTURA_CABECALHO'(a){
      this.altura_cabecalho = a
    },

    'item_edita.ALTURA_MEIO'(a){
      this.altura_meio = a
    },

    'item_edita.ALTURA_RODAPE'(a){
      this.altura_rodape = a
    },

    'item_edita.LARGURA_PAGINA'(a){
      this.largura_pagina = a
    },

    'item_edita.MOSTRAR_LOGO'(a){
      this.mostrar_logo = a
      this.f_mostrar_logo()
    },

    'item_edita.MOSTRAR_LOGO_PRO'(a){

      this.mostrar_logo_pro = a

      if (!this.urlLogo_pro){//se nao tiver definido uma logo..nem mostra coluna
        this.mostrar_logo_pro = false
      }
    },
    

    'item_edita.POSICAO_LOGO'(a){
       
      if (a=='Esquerda'){
        this.posicao_logo = 'start'
      }
      else if (a=='Centro'){
        this.posicao_logo = 'center'
      }
      else if (a=='Direita'){
        this.posicao_logo = 'end'
      }
      
    },
    

    'item_edita.MOSTRAR_TITULO'(a){
      this.mostrar_titulo = a
    },

    'item_edita.ALTURA_LOGO'(a){
      this.altura_logo = a
    },



    'item_edita.MOSTRAR_CIDADE'(a){
      this.mostrar_cidade = a
      this.monta_rodape()
    },

    'item_edita.MOSTRAR_ASSINATURA'(a){
      this.mostrar_assinatura = a
      this.monta_rodape()
    },

    'item_edita.MOSTRAR_RODAPE'(a){
      this.mostrar_rodape = a
    },

    'item_edita.MOSTRAR_ENDERECO'(a){
      this.mostrar_endereco = a
    },

    'item_edita.MOSTRAR_DIR_TECNICO'(a){
      this.mostrar_dir_tecnico = a
    },


  },

  methods:{

    f_inverter_selecao(){

      let acao = this.tipo_atestado.toLowerCase()
    
      let A = `selected_${acao}`
      let B = `items_${acao}`   

      // const selecionados = new Set(this.selected_receitas.map(item => item.CODIGO));
      // this.selected_receitas = this.items_receitas.filter(item => !selecionados.has(item.CODIGO));

      const selecionados = new Set(this[A].map(item => item.CODIGO));
      this[A] = this[B].filter(item => !selecionados.has(item.CODIGO));
    },

    async botao_conectar(alertar, conectar){
        
        // verifica se conectado
        this.zapzap_conectado = await status_whats(alertar, conectar, 'connected')

        // caso nao conectado..abre dialog ok
        if (!this.zapzap_conectado){
          this.dialog_ok      = true
          this.dialog_ok_tipo = 'conectar_zap'
        }

    },

     async dialog_ok_clicou(tipo){

       if (tipo == 'conectar_zap'){
          //  somente verifico se conectado
           this.zapzap_conectado = await status_whats(false,false, 'connected')
       }

     },

     trata_quebra_p(texto){

      if (texto){
         texto = texto.replaceAll('<p></p>', `<p>⠀</p>`)
      }

      return texto
     },

     setCanvasWidth() {
      const cardWidth = this.$refs.cardWithCanvas.$el.clientWidth;
      this.$refs.canvas.width = cardWidth - 30;
     },

     clearSignature() {
        this.signaturePad.clear();
      },

      saveSignature() {
        // Verifique se há uma assinatura para salvar
        if (this.signaturePad.isEmpty()) {
          alert('Por favor, assine primeiro!');
          return;
        }
        
        // Obtenha a representação base64 da assinatura
        this.signatureDataURL = this.signaturePad.toDataURL();

         console.log( this.signaturePad.toDataURL());


        this.salvar_em_documentos(this.signatureDataURL)

        // Aqui você pode enviar this.signatureDataURL para o servidor ou fazer o que precisar com ela
        // Por exemplo, você pode criar um elemento de imagem e definir o src para this.signatureDataURL
        // Ou então, você pode enviar a representação base64 para o servidor para salvar em um banco de dados ou em um arquivo
     },

    desenhar_assinatura(){

      this.dialog_assinar = true

      // limpo assinatura
      try{
          this.signaturePad.clear();
      }
      catch (e){}


      // executo o timer pq ele somente executa se o componente estiver visivel na tela = estar no DOM
      setTimeout(function(){
        

        try{
          this.signaturePad = new SignaturePad(this.$refs.canvas, {
            minWidth: 0.5, // Defina a largura mínima do traço para 2 pixels (ou qualquer valor desejado)
            penColor: 'black'
          });
        }
        catch (e) {}


        try{
          this.setCanvasWidth();
        } 
        catch (e){}

      }.bind(this), 500);

    },

    ver_documento(){
      this.dialog_pre_assinar  = true
      this.checkbox_aceito = false
    },

    muda_flag(){

      this.inPDF = true

      setTimeout(function(){
        this.salvar_em_pdf(null, false)
      }.bind(this), 900);
      
    },

    fechar_dialog_pendentes(){

      if (this.checkbox_avisar_pendentes){
        localStorage['enviou_MED_PRINT'] = JSON.stringify(false)
      }

      this.dialog_pendentes=false
    },

    async verificar_enviadosHERE(){
      

      let rr 
      if(rr =  await verificar_enviados('MED_PRINT', 5)){
        this.array_ids_whats = rr

        // alert(rr.length+' mensagens não foram enviadas!')

        this.dialog_pendentes = true
      }

    },

    async atu_msg_farmacia(){

      if (!this.selectedFarmacia){ return '' }

      let msg = this.selectedFarmacia.MENSAGEM_WHATS

      if (!msg){
        `Olá, tudo bem? segue em anexo documento do paciente ${this.obj_dados_paciente.NOME}.`
      }
      else{
         msg = await this.replace_parametros(msg)
      }

      this.msg_farmacia = msg

    },

    async situacao_whats(){
      

      try{
        this.config_whats = JSON.parse(sessionStorage['config_whats'])//busco objeto no sessionStorage
      }catch(e){}


      try{
        this.zapzap_mostrar   = JSON.parse(sessionStorage['zapzap_mostrar']);
        this.zapzap_conectado = JSON.parse(sessionStorage['zapzap_conectado']);
      }
      catch (err) {
        console.log('NAO TEM ZAPZAP');
      }

      this.superA = (sessionStorage['superA']=='S')



      setTimeout(async function(){

        if (!this.zapzap_mostrar){//se nao tiver o modulo ele para
          return false
        }

        try {
          let gg =  JSON.parse(localStorage['enviou_MED_PRINT'])
          if (gg){
            this.verificar_enviadosHERE()
          }
        }
        catch (e) {}

      }.bind(this), 2000);

      // try {
      //   let gg =  JSON.parse(localStorage['enviou_MED_PRINT'])
      //   if (gg){
      //     this.verificar_enviadosHERE()
      //   }
      // }
      // catch (e) { alert(e) }

    },


    async envia_via_whats(acao, numero, msg){

      if (acao == 'enviar'){

        if (!this.zapzap_conectado){
           alert('É necessário Ativar o Módulo WhatsApp!')
           return false
        }

        if (!numero){
          alert('Selecione um número válido!')
          return false
        }
      }


      let retorno =  await this.salvar_em_pdf(null, true)
      // console.log('envia_via_whats. retorno:', retorno);

      if (retorno){
        if (retorno.file_path){
          // dispara o whasapp

            let file
            if (url == '/api/'){//desenvolvimento
              file = 'http://localhost/vue_agenda_firebird/public/'+retorno.file_path
            }else{
              file = url+retorno.file_path//pega url cliente
            }
            
            // let file = 'https://dsi.app.br/teste/i/'+retorno.file_path
            // let file = 'http/teste/i/'+retorno.file_path
            // alert(file)
            // return false
            let retorno_zap
            if (retorno_zap = await send_whats_api('MED_PRINT', numero, msg, file)){
              

              if (retorno_zap.id){
              

                  localStorage['enviou_MED_PRINT'] = JSON.stringify(true)

                  setTimeout(function(){
                    this.alerta('Enviando Mensagem para o WhatsApp', 'green')
                  }.bind(this), 900);

                  // TENHO QUE SALVAR O ID NUM ARRAY E DEPOIS DE 1 MINUTO PERCORRER PRA VER SE ENVIOU OU NAO, ALERTAR CASO NAO TENHA ENVIADO
              }
              else{
                setTimeout(function(){
                    this.alerta('Não foi possível Enviar Mensagem para o WhatsApp!\nTente fazer o login novamente no App', 'red')
                  }.bind(this), 900);
                
              }
              console.log('retorno_zap:', retorno_zap);
              // sql = `update WP_CONTATOS set ID_WHATS = '${retorno.id}', DATA_VISTO = '${this.today}'  where codigo = ${item.CODIGO}`
              // console.log('sql:', sql);
              // await this.crud_sql(sql)
              
            }
            else{
              setTimeout(function(){
                this.alerta('Erro ao enviar comando para o servidor!', 'red')
              }.bind(this), 900);
            }


        }
      }

      

    },


    async captureAndSavePDF(file_name, auto_save) {

      // console.log('file_name:', file_name);
      if (!file_name) {
        file_name = (this.tipo_atestado.toUpperCase()+'_'+ this.primeiro_nome(this.obj_dados_paciente.NOME)  ) || 'conteudo';
      }

      file_name = file_name + '.pdf';

      const contentToPrint = this.$refs.contentToPrint;

      let customPageHeight = (contentToPrint.scrollHeight / 5);

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [210, customPageHeight]
      });

      return html2canvas(contentToPrint).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = pdf.internal.pageSize.getWidth();
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          let totalPages = 1;

          let startY = 0;
          for (let i = 0; i < totalPages; i++) {
            if (i > 0) {
              pdf.addPage([imgWidth, customPageHeight]);
            }

            const pageHeight = Math.min(customPageHeight, imgHeight - startY);
            pdf.addImage(imgData, 'PNG', 0, -startY, imgWidth, pageHeight);

            startY += pageHeight;
          }


          if (!auto_save){
             pdf.save(file_name);
             return true
             // pdf.save('conteudo.pdf');
          }else{

              // //  defino a pasta onde sera salvo o arquivo
              let pasta = sessionStorage['LSfolder']
              let parImpar =  diaParOuImpar()
              pasta = pasta+'/print_lab_'+parImpar  //cria duas pasta assim eu apago a que nao for do dia
              // ESTA PASTA EU APAGO TODA VEZ QUE O USUARIO FAZ LOGIN A ROTINA ESTA EM login.vue limpar_pasta()

              // Convertendo o PDF para um blob
              const pdfBlob = pdf.output('blob');

              return  this.upload_arquivo(pdfBlob, file_name, pasta)

              // // Criando um FormData
              // const formData = new FormData();
              // formData.append('pdfFile', pdfBlob, file_name);
              // formData.append('pasta', pasta);

              
              // // Enviando o arquivo para o servidor via fetch
              // fetch(url+'upload.php', {
              //   method: 'POST',
              //   body: formData
              // })
              // .then(response => {
              //   console.log('retorno:', response);
              //   if (response.ok) {
              //     console.log('Arquivo salvo com sucesso no servidor!');
              //   } else {
              //     console.error('Erro ao salvar o arquivo no servidor.');
              //   }
              // })
              // .catch(error => {
              //   console.error('Erro ao enviar requisição para o servidor:', error);
              // });

          }
      });
    },


    async  upload_arquivo(pdfBlob, file_name, pasta) {

        let rr = ""; // Variável para armazenar a resposta do servidor

        // Criando o objeto FormData
        const formData = new FormData();
        formData.append('pdfFile', pdfBlob, file_name); // Certifique-se de ter essas variáveis definidas
        // Adicione quaisquer outros campos de formulário se necessário
        formData.append('pasta', pasta);


        try {
            // const response = await axios.post('http://dsi.app.br/teste/i/upload.php', formData, {
            const response = await axios.post(url+'/upload.php', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            
            rr = response.data;
            // console.log("Resposta do servidor:", rr);

            if (rr.message.includes('sucesso')){
              this.alerta(rr.message, 'primary')
              return rr
            }else{
              this.alerta(rr.message, 'red')
              return rr
            }

          // Aqui você pode fazer outras operações com a rr
        } catch (error) {
          console.error('Erro ao enviar requisição para o servidor:', error);
          return false
        }
    },



    primeiro_nome(nome){

      if (!nome){ return nome}

      nome = nome.split(" ");//separo as palavras
      nome = nome[0];//pego o primeiro nome   

      return nome
    },

    async replace_parametros(entra){

      if (!entra){
        return ''
      }

      let paciente       = Object.assign({}, this.obj_dados_paciente) 
      let profissional   = Object.assign({}, this.obj_dados_profissional)  

    
      entra = entra.replaceAll('@ide',              '*'+ paciente.CODIGOCLIENTE +'*')
      entra = entra.replaceAll('@paciente',         '*'+ paciente.NOME+'*')
      entra = entra.replaceAll('@celular_paciente', '*'+ paciente.CELULAR+'*')
      entra = entra.replaceAll('@convenio',         '*'+ paciente.CONVENIO+'*')

      entra = entra.replaceAll('@profissional',     '*'+ profissional.NOME+'*')

      entra = entra.replaceAll('@empresa',          '*'+ this.obj_dados_empresa.NOME+'*')


      return entra

    },


    captureAndSavePDFok() {
      const contentToPrint = this.$refs.contentToPrint;

      html2canvas(contentToPrint).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();

        // Define o tamanho da página como 'a4'
        const pdfWidth = 210;
        const pdfHeight = 297;

        // Calcula a proporção da imagem para ajustar ao tamanho da página
        const imageRatio = canvas.width / canvas.height;
        const pdfImageWidth = pdfWidth;
        const pdfImageHeight = pdfWidth / imageRatio;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfImageWidth, pdfImageHeight);
        pdf.save('conteudo.pdf');
      });
    },
  


    TelaSalveAsPDF() {
      const pdf = new jsPDF('p', 'px', 'a4');

      // Capturar o HTML da página inteira usando html2canvas
      html2canvas(document.body, { 
        scrollX: 0, 
        scrollY: 0, 
        scale: 1 
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.save('whole_page.pdf');
      });
    },


    async salvar_em_pdf(file_name, auto_save){
      
      if (auto_save){
        // defino como sera o nome do arquivo
        let agora = new Date().toLocaleTimeString().replace(/:/g, '');
        file_name = this.obj_dados_paciente.CODIGOCLIENTE+'_'+ this.primeiro_nome(this.obj_dados_paciente.NOME)+'_'+ this.tipo_atestado.toUpperCase()+ '_'+agora
      }


      let rr = await this.captureAndSavePDF(file_name, auto_save)
      // console.log('salvar_em_pdf.rr:', rr);

      // setTimeout(function(){
        this.inPDF = false
      // }.bind(this), 6000);
      

      return rr
      // this.TelaSalveAsPDF()
    },

    async salvar_em_documentos(assinatura){

      if (!assinatura){
          if (!confirm("Este Texto será Salvo na guia ATENDIMENTO, REGISTROS. Deseja continuar?")){
            return false
          }
      }

          if (!assinatura){assinatura = ''}

          let nome = this.nome_modelo.toUpperCase()
          if (!nome){ nome = 'XX'}
          let sql = `
          INSERT INTO WEB_REGISTROS
          (
            NOME_TEXTO,
            TEXTO_REGISTRO, 
            TIPO,
            CODIGO_DOC, 
            NOME_DOC,
            CODIGO_CLIENTE , 
            DATA,
            PATH_ASSINATURA
          )
          VALUES 
           (
            '${nome}',
            '${this.editor.getHTML()}', 
            '${this.tipo_atestado.toUpperCase()}',
             ${this.obj_dados_profissional.CODIGO}, 
            '${this.obj_dados_profissional.NOME}', 
             ${this.obj_dados_paciente.CODIGOCLIENTE}, 
            '${this.retorna_today()}',
            '${assinatura}'
             
           )`
          

          // MEMORIZA O NOVO MODELO
          localStorage['printModeloAtual_'+ this.tipo_atestado] = this.nome_modelo

          // console.log('sql:', sql);
          if (await this.crud_sql(sql)){
            // this.dialog_pre_assinar = false
            this.dialog_assinar     = false

            setTimeout(function(){
              // alert('Registro Salvo com sucesso!')
              this.dialog_pronto = true
            }.bind(this), 200);
            
          }
      
    },

    retorna_today(){
      let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
      // console.log('hoje:'+ hoje);

      let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
      return today
    },

    async monta_rodape(){

      this.pegaRodape = ''

      // console.log('cidade', this.mostrar_cidade);
      // console.log('assinatura', this.mostrar_assinatura);

      let cidade     = ``
      let assinatura = ``

      if (this.mostrar_cidade){
          cidade = `${this.get[0].printCidade}, ${this.hoje} <br>`
      }
      
      if (this.mostrar_assinatura){
          assinatura = `<br> ___________________________________ <br> ${this.get[0].printNomeDoc} - CRM: ${this.get[0].printCRMdoc}`
          // assinatura = `<br> ___________________________________ <br> ${this.get[0].printNomeDoc} - CRM: ${this.get[0].printCRMdoc}`
      }

      this.pegaRodape = cidade + assinatura

    },

    async carrega_imagem(){
      

      let r = await this.upload_logo(this.files_upload, this.session_bd+ '/print_outros' , 'nome_arquivo','' )

      this.editor.chain().focus().setImage({ src: this.urlImages +'/'+ r }).run()
    },

    async upload_logo(file,pasta,nome){

      // console.log(file);

      //update refresh image
      this.url_img = URL.createObjectURL(file);

      var bodyFormData = new FormData();
      bodyFormData.append('pasta'       , pasta);
      bodyFormData.append('nome_arquivo', nome);
      bodyFormData.append('userfile'    , file);

      //isso daqui server para ser lido com o $_POST no php 
      const header =   { headers: {'Content-Type': 'multipart/form-data'}}

      // console.log(data);

      const res = await axios.post(url+'upFile.php', bodyFormData, header, )

      // console.log(res.data);

      let r = ''

      // console.log(res.data);

      if (res.data.status == 'OK'){

          this.alerta('Imagem...','blue')
        
          let tipo = file.type
          tipo = tipo.substr(tipo.indexOf('/')+1,5);
        
          r = ( pasta+'/'+nome+'.'+tipo)

      }else{
          this.alerta('Ops: '+res.data,'red')
      }

      // alert(r)
      this.retorno_path_url_img =  r
      return r

    },

    teste_here(){
      // this.editor.chain().focus().setImage({ src: this.url_img }).run()
      this.editor.chain().focus().setImage({ src: 'http://localhost/i/images/128.png' }).run()
    },

    set_font_size(zoom){
      // document.getElementsByClassName('<p>').style.fontSize = '8px';
        // alert(this.$refs.DSI.innerHTML)
        let el = document.getElementById('id_mudar');
        // let el = document.getElementsByTagName('p');

        console.log(el);

        // alterando uma propriedade
        el.style.color = 'red'
        el.style.cssText =
        'color: blue;' +
        'background-color: yellow; font-size: 8px;' +
        'border: 1px solid magenta';
    },

    ordenarPorCampo(array, campo) {
      return array.sort(function(a, b) {
        if (a[campo] < b[campo]) {
          return -1;
        }
        if (a[campo] > b[campo]) {
          return 1;
        }
        return 0;
      });
    },


    agruparPorCampo(arrayOrdenado, campo) {
      var resultado = [];
      var grupoAtual = null;

      for (var i = 0; i < arrayOrdenado.length; i++) {
        var objeto = arrayOrdenado[i];

        if (objeto[campo] !== grupoAtual) {
          grupoAtual = objeto[campo];
          resultado.push({ [campo]: grupoAtual, itens: [] });
        }

        resultado[resultado.length - 1].itens.push(objeto);
      }

      return resultado;
    },


    gerarListaMedicamentos(array) {
      let lista = '';

      // Cria um objeto para agrupar os medicamentos por categoria de uso
      const categorias = {};

      // Itera sobre o array de objetos e agrupa os medicamentos por categoria de uso
      for (const obj of array) {
        const categoria = obj.USO.trim().toUpperCase();

        if (!categorias[categoria]) {
          categorias[categoria] = [];
        }

        categorias[categoria] = categorias[categoria].concat(obj.itens);
      }

      // Gera a lista formatada
      for (const categoria in categorias) {
        lista += `${categoria}:\n<br/>`;

        const medicamentos = categorias[categoria];

        for (let i = 0; i < medicamentos.length; i++) {
          const medicamento = medicamentos[i];
          const nome = medicamento.MEDICAMENTO.trim();
          const quantidade = medicamento.DOSE.trim();
          const posologia =  medicamento.POSOLOGIA.trim();

          lista += `&nbsp${i + 1} - <b>${nome}</b>`;

          if (quantidade !== '') {
            lista += ` ________ QT: <b>${quantidade}</b>`;
          }

          lista += '\n<br/>';

          if (posologia !== '') {
            lista += `&nbsp&nbsp&nbsp${posologia}\n<br/><br/>`;
          }
        }
        // lista += '\n<br/>';
      }

      return lista;
    },

    imprimirExame(){

      console.log('imprimirExame:', this.tipo_atestado);

      if (this.tipo_atestado !='Exames'){
        return 'exit'
      }

      let selecionados = this.selected_exames.map((x) => x);//pega os itens selecionados
       
      let linha = ''
      let titulo = ''
      let paciente = ''
      let cidade = ''
      let profissional = ''


        for (let i = 0; i < selecionados.length; i++) {
          if (selecionados[i].CODIGO > 0){
               
               linha = linha +
                      // '<div class="a">'+
                      '<p style="text-align:left">'+
                      (i+1) +' - '+selecionados[i].EXAME +' - ' + selecionados[i].COD96 + '</p>'
                      
          }
        }
        
        titulo = 
                  '<p> <h3 style="text-align:center">'+
                    'SOLICITAÇÃO DE EXAMES'+
                  '</h3></p> <p/>'

                  //usar o <p/> ao inves do <br/>

        paciente = '<p> Sr.(a) <strong> [PACIENTE] </strong></p> <p/>'   
        
        cidade = '<br/><br/><p/> <p style="text-align: center">'+ sessionStorage['LSCidade2'] +', [HOJE]</p>'

        profissional = '<p style="text-align: center"> <br/> ___________________________________ <br/>'+ this.getNomeDoc+' - CRM:'+ this.getCRMdoc +'</p>'
        
       

        let texto = titulo + paciente + linha;
        // sessionStorage['printConteudo'] =  texto
        sessionStorage['printTipo'] = 'Exames'
        sessionStorage['LSexame']   = linha

        this.editor.commands.setContent(texto)//definio o texto
        this.atu_texto(texto)//faz o stringreplace dos coringas
        

    },


    imprimirReceita2(){

      // aqui quando tem manipulado
      // console.log('imprimirReceita2');

      if (this.tipo_atestado !='Receitas'){
        return 'exit'
      }

      console.log('imprimirReceita:', this.tipo_atestado);

      let selecionados = this.selected_receitas.map((x) => x); //clonando array copiar array

      // let selecionados = []
      // if (this.selected_receitas?.length >0){//caso nao tenha selecionado.. pega todos
      //    selecionados = this.selected_receitas.map((x) => x); //clonando array copiar array
      // }
      // else{
      //    selecionados = this.items_receitas.map((x) => x); //clonando array copiar array   
      // }


      let titulo = ''
      let paciente = ''
      let cidade = ''
      let profissional = ''
      let linha = ''
      let enter = '<br class="ProseMirror-trailingBreak">'


      // orderna por tipo
      selecionados = selecionados.sort(function(a,b) {
          return a.TIPO < b.TIPO ? -1 : a.TIPO > b.TIPO ? 1 : 0;
        });


      // alert(selecionados.length)
      // if (selecionados.length >0){

        if (selecionados.length > 5) {
          enter = ''
        } 

        for (let i = 0; i < selecionados.length; i++) {
          if (selecionados[i].CODIGO > 0){
              
            if (!selecionados[i].MANIPULADO){
              // NORMAL

              let uso   = ''
              let qt    = ''
               
              if (selecionados[i].USO?.length>2){
                // uso = selecionados[i].USO +':' + '<br/>' + enter 
                uso = selecionados[i].USO +':' + enter 
              }

              if (selecionados[i].DOSE?.length>2){
                
                qt =  '---------------- Qt: ' + selecionados[i].DOSE   
              }

              linha = linha +
                      '<p style="text-align:left" class="pagebreak">'+
                      uso +
                      (i+1) +' - <b>'+selecionados[i].MEDICAMENTO +'</b> '+ qt + '<br/>'+
                      '&nbsp &nbsp &nbsp &nbsp'+ selecionados[i].POSOLOGIA + '' +
                      '</p>' + enter 
            }
            else{
              // MANIPULADO
              // linha = linha +  '<br/> '+ (i+1) +' - <b>'+ selecionados[i].MEDICAMENTO + '</b> <br/>'   + selecionados[i].MANIPULADO + '<br/>'
              linha = linha +   '</b> <br/>'   + selecionados[i].MANIPULADO + '<br/>'
              linha = linha.replaceAll('\n','<br>') //trata quebra de linha
              // linha = linha.replaceAll(' ','⠀') 
              linha = linha.replaceAll(' ','&nbsp')//trata espaços 
              
            }

          }
        }



        titulo = 
                  '<p> <h3 style="text-align:center">'+
                    'RECEITUÁRIO'+
                  '</h3></p> <p/>'

        paciente = '<p> Sr.(a) <strong> [PACIENTE] </strong></p> <p/>'  

        profissional = '<p style="text-align: center"> <br/> ___________________________________ <br/>'+ this.getNomeDoc+' - CRM:'+ this.getCRMdoc +'</p>'

        let texto = titulo + paciente + linha;
        
        sessionStorage['printTipo'] = 'Receitas'
        sessionStorage['LSreceita'] = linha

        this.editor.commands.setContent(texto)//definio o texto
        this.atu_texto(texto)//faz o stringreplace dos coringas

        // alert(this.items_receitas.length)
        // alert(linha)
        // this.escolhe_atestado(this.obj_modelo)
        
      // }   

    },

    imprimirReceita(){

      // console.log('imprimirReceita:', this.tipo_atestado);

      if (this.tipo_atestado !='Receitas'){
        return 'exit'
      }

      let selecionados = this.selected_receitas.map((x) => x); //clonando array copiar array

      // let selecionados = []
      // if (this.selected_receitas?.length >0){//caso nao tenha selecionado.. pega todos
      //    selecionados = this.selected_receitas.map((x) => x); //clonando array copiar array
      // }
      // else{
      //    selecionados = this.items_receitas.map((x) => x); //clonando array copiar array   
      // }

      
      let linha = ''

      this.ordenarPorCampo(selecionados, 'USO')
      selecionados = this.agruparPorCampo(selecionados, "USO");
      linha = this.gerarListaMedicamentos(selecionados)


      sessionStorage['printTipo'] = 'Receitas'
      sessionStorage['LSreceita'] =  linha


      // this.editor.commands.setContent(texto)//definio o texto
      // this.atu_texto(texto)//faz o stringreplace dos coringas

    },


    retorna_altura(menos){
        // let r = '100vh'
        // let r = '720'
        // let r = (window.innerHeight - 80 - 60)
        let r = (window.innerHeight - menos)
        let height = window.innerHeight;

        return r
    },


    async onMounted(){
      await this.crud_impressos()
      await this.tabela('CONFIG_IMPRESSAO')
      await this.tabela('FARMACIAS')
      await this.valores_default()
    },

    async valores_default(){

      // PAGINA.. MEDIDAS
       let pagina = ''
       pagina     = localStorage['printPaginaAtual']
        
       let achou = this.items_modelos_imp.filter(x => x?.MODELO_IMPRESSAO === pagina); //clonando array copiar array


       if (achou?.length > 0){
        //  this.item_edita = achou[0]
        this.clicou_modelo(achou[0], true)
        this.default_formato_papel = pagina
        // alert('default 8'+ pagina)
       }
       
       
      //  MODELO DE IMPRESSAO
       let modelo = ''
       modelo     = localStorage['printModeloAtual_'+ this.tipo_atestado]
       
        
       achou = await this.items_atestados.filter(x => x?.NOME === modelo); //clonando array copiar array

      //  console.log('items_atestados:', this.items_atestados);

       await executa(this, achou)
         async function executa(that, achou){
            // console.log('achou:',modelo, achou);
            // if (achou?.length > 0){
              await that.escolhe_atestado(achou[0])
            // }
         }

    },
    
    async clicou_em(acao){
      if (acao=='salvar_como'){
         
         if(!this.item_edita.MODELO_IMPRESSAO){
            alert('Digite o Nome do Modelo')
         }
         else {
           this.salvar_tabela('CONFIG_IMPRESSAO', this.item_edita, ''), this.item_edita.CODIGO = null
         }
      }

      else if (['selecionar_farmacia','selecionar_paciente'].includes(acao)){
      // else if (acao == 'selecionar_farmacia'){

        if (acao == 'selecionar_paciente'){
            try{
              this.numero_selecionado = this.obj_dados_paciente.CELULAR
              this.msg_farmacia = `Olá, ${this.obj_dados_paciente.NOME}`
            }
            catch(e){}            
        }
        else{
          this.atu_msg_farmacia()
        }

        this.tipo_enviar = acao
        if (!this.zapzap_conectado){
            // alert('Módulo WhatsApp não conectado!')
            // return false
        }
        this.dialog_farmacia = true
      }

      else if (acao == 'send_farmacia'){
        // console.log('selectedFarmacia:', this.selectedFarmacia);


        if (!this.zapzap_conectado){
            
            if (confirm("Módulo WhatsApp não conectado! Deseja conectar?")){
              

              this.dialog_ok      = true
              this.dialog_ok_tipo = 'conectar_zap'
              conectar_whats()
              
            }
            return false
        }


        this.inPDF = true

        let numero
        if (this.tipo_enviar == 'selecionar_paciente'){
            numero = this.numero_selecionado
        }
        else if (this.tipo_enviar == 'selecionar_farmacia'){
            numero = this.selectedFarmacia.NUMERO
        }


        setTimeout(function(){
            this.envia_via_whats('enviar', numero, this.msg_farmacia)
        }.bind(this), 900);

        
      }
    },

    async clicou_modelo(item, memorizar){

      // console.log('item.clicou_modelo:', item);
      if (!item){
        return false
      }

      this.pagina_select = item.MODELO_IMPRESSAO
      this.item_edita    = item
      this.item_before   = Object.assign({},item)
      this.titulo_modelo = item.MODELO_IMPRESSAO

      if (memorizar){
        localStorage['printPaginaAtual'] = item.MODELO_IMPRESSAO
      }
    },

    cancelar_alteracoes(){
      this.item_edita = Object.assign({}, this.item_before)
      this.editar_modelo = false
    },

    getHTML_Text(texto){

      let novo_texto = ''

      if (texto){
       novo_texto = texto
       novo_texto = novo_texto.replaceAll('<BR>',"\n")
       novo_texto = novo_texto.replaceAll('<BR/>',"\n")
       novo_texto = novo_texto.replaceAll('<br>',"\n")
       novo_texto = novo_texto.replaceAll('<br/>',"\n")
       
      }
      return novo_texto
       
    },

    salvar_atestado(){ 
      this.pegaTexto = this.editor.getHTML()//pega o texto
  

      let sql = ``
    
      if (this.url_img){
        sql = `
        INSERT INTO IMPRESSOS_WEB
        (NOME, TEXTO, TIPO, PATH_IMG, COD_PROF, NOME_FORMATO )
        VALUES ('${this.nome_modelo}','${this.pegaTexto}', '${this.tipo_atestado}', '${this.retorno_path_url_img}', ${this.cod_prof_modelo}, '${this.nome_formato_modelo}')`
      }
      else{
        sql = `
        INSERT INTO IMPRESSOS_WEB
        (NOME, TEXTO, TIPO, COD_PROF, NOME_FORMATO )
        VALUES ('${this.nome_modelo}','${this.pegaTexto}', '${this.tipo_atestado}', ${this.cod_prof_modelo}, '${this.nome_formato_modelo}')`
      }

      // MEMORIZA O NOVO MODELO
      localStorage['printModeloAtual_'+ this.tipo_atestado] = this.nome_modelo


      // alert(sql)
      // console.log('sql:', sql);
      this.crud_sql(sql)

      setTimeout(function(){//tem um timer apos carregar para mostrar ou nao a logo
        this.crud_impressos()
      }.bind(this), 100);//usar esse .bind(this) para chamar funcoes com o setTimeout
      
    },


    remover_atestado(){
      let sql = 
      `
      delete from  IMPRESSOS_WEB 
      WHERE CODIGO = ${this.getCodigoAtestado}
      `
      // alert(sql)  
      console.log(sql);

      this.crud_sql(sql)

      setTimeout(function(){//tem um timer apos carregar para mostrar ou nao a logo
        this.crud_impressos()
      }.bind(this), 100);//usar esse .bind(this) para chamar funcoes com o setTimeout

    },

    atualizar_atestado(){ 
      this.pegaTexto = this.editor.getHTML()//pega o texto

      let rodape = 'N'
      if (this.mostrar_rodape){
        rodape = 'S'
      }


      let sql_img = ``
      if (this.url_img){
        sql_img = `, PATH_IMG = '${this.retorno_path_url_img}'`
      }


      let sql = 
      `
      UPDATE IMPRESSOS_WEB SET
      TEXTO         = '${this.pegaTexto}',
      NOME          = '${this.nome_modelo}',
      COD_PROF      = '${this.cod_prof_modelo}',
      NOME_FORMATO  = '${this.nome_formato_modelo}',
      RODAPE        = '${rodape}'
      ${sql_img}
      WHERE CODIGO  = ${this.getCodigoAtestado}
      `
      // alert(sql)  
      // console.log(sql);

      
      // exit;
      // let sql = `
      //   UPDATE IMPRESSOS_WEB SET
      //   TEXTO = '`+ this.pegaTexto  +`',
      //   NOME = '` + this.nome_modelo + `'
      //   WHERE CODIGO = `+ this.getCodigoAtestado +`
      // `

      // alert(sql)
      this.crud_sql(sql)

      setTimeout(function(){//tem um timer apos carregar para mostrar ou nao a logo
        this.crud_impressos()
      }.bind(this), 100);//usar esse .bind(this) para chamar funcoes com o setTimeout

    },


    async escolhe_atestado(item){
      
      // console.log('escolhe_atestado:', item);
      this.modelo_selecionado = item


      // if ( (this.NomeEmpresa.includes('Artmed')) || (this.NomeEmpresa.includes('Amicis')) ){  
      let temMANIPULADO = (sessionStorage['TEM_MANIPULADO'] == 'S') ? true : false //quando clica em imprimir la em atendimento..ele verifica se tem manipulado..ai altera aqui o tipo de impressao

      if (temMANIPULADO){  
        // console.log('TEM_MANIPULADO');
        this.imprimirReceita2()
      }else{
        this.imprimirReceita()
      }
      


      let estrutura = ''
      if ((item)&&(item.CODIGO>0)){
          
          this.obj_modelo           = item
          this.getCodigoAtestado    = item.CODIGO
          this.nome_modelo          = item.NOME
          this.cod_prof_modelo      = item.COD_PROF
          this.nome_formato_modelo  = item.NOME_FORMATO
          this.modelo_select        = item.NOME

          estrutura = item.TEXTO

          let nome 
          if (item.NOME_FORMATO !='NENHUM'){
             nome = item.NOME_FORMATO
          }
          else{
             nome = this.default_formato_papel
          }

          let esse = this.items_modelos_imp.filter(x => x.MODELO_IMPRESSAO == nome); //clonando array copiar array
          this.clicou_modelo(esse[0], false)


          // memorizo qual modelo clicou
          localStorage['printModeloAtual_'+ item.TIPO] = item.NOME

      }
      else{

         // vazio..entao define o modelo padrao da estrutura
         if (this.tipo_atestado == 'Receitas'){
          this.nome_modelo = this.tipo_atestado
          estrutura = `<p> <h3 style="text-align:center">
                      RECEITUÁRIO
                      </h3></p> <p/>
                      <p> Sr.(a) <strong> [PACIENTE] </strong></p> <p/>
                      <p>[RECEITA]<p/>`
         }
         else if (this.tipo_atestado == 'Exames'){
          this.nome_modelo = this.tipo_atestado
          estrutura = `<p> <h3 style="text-align:center">
                      SOLICITAÇÃO DE EXAMES
                      </h3></p> <p/>
                      <p> Sr.(a) <strong> [PACIENTE] </strong></p> <p/>
                      <p>[EXAME]<p/>`
         }
         else{
          estrutura = '<p>...</p>'
          this.nome_modelo = this.tipo_atestado
         } 
      }



      if ((item) && (item.PATH_IMG)){
        // alert(item.PATH_IMG)
        this.editor.commands.setContent('')//definio o texto
        this.editor.chain().focus().setImage({ src: this.urlImages +'/'+ item.PATH_IMG }).run()
      }
      else{

        this.editor.commands.setContent(estrutura)//definio o texto
        this.pegaTexto = this.editor.getHTML()


        if (this.editor_atestado == false){
          this.atu_texto(estrutura)//faz o stringreplace dos coringas
        } 
      }

    },

    showDrawer(){
      this.$emit('executar', false)//mostrar ou nao paineis
    },


    logado(){
      DSirotinas.logado(this)
    },


     read_sessionStorage($name,$to){
        if (sessionStorage[$name]) {
           return sessionStorage[$name]
          //  this[$to] =  sessionStorage[$name];
        } 
     },

    
    setCorTexto(cor){
      
      //isso aqui era chamado direto no botao.. adaptei
      // @click="editor.chain().focus().setColor('red').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: 'red' })}"

      this.corTexto = cor
      this.editor.chain().focus().setColor(cor).run() 
      this.editor.class={ 'is-active': this.editor.isActive('textStyle', { color: cor })}
      
    },

    atu_texto(texto){

      // console.log('atu_texto:', texto);
         
      // console.log(event);
      if (texto){
        
      

        let linha = ''
        let titulo = ''
        let paciente = ''
        let cidade = ''
        let profissional = ''

        let novo_texto = texto
        let novo_rodape = ''

        //pegando alguns dados
        cidade        = this.get[0].printCidade +', '+ this.hoje
        profissional  = this.get[0].printNomeDoc+' - CRM:'+ this.get[0].printCRMdoc 



        // console.log('xxxxxxx:');
        // console.log(this.get[0]);


        //substituindo os campos coringas

        // novo_texto = novo_texto.replaceAll('[PACIENTE]',            this.get[0].printNomePaciente)
        novo_texto = novo_texto.replaceAll('[PACIENTE]',            this.obj_dados_paciente.NOME)

        novo_texto = novo_texto.replaceAll('[RGP]','RG: '+          this.obj_dados_paciente.RG)
        novo_texto = novo_texto.replaceAll('[RG]','RG: '+           this.obj_dados_paciente.RG)
        novo_texto = novo_texto.replaceAll('[CPFP]','CPF: '+        this.obj_dados_paciente.CPF)
        novo_texto = novo_texto.replaceAll('[CPF]','CPF: '+         this.obj_dados_paciente.CPF)
        novo_texto = novo_texto.replaceAll('[MAE]',                 this.obj_dados_paciente.NOMEMAE)
        novo_texto = novo_texto.replaceAll('[PAI]',                 this.obj_dados_paciente.NOMEPAI)
        novo_texto = novo_texto.replaceAll('[IDADE]',               this.obj_dados_paciente.IDADE)
        novo_texto = novo_texto.replaceAll('[CIVIL]',               this.obj_dados_paciente.ESTADOCIVIL)
        novo_texto = novo_texto.replaceAll('[SEXO]',                this.obj_dados_paciente.SEXO)
        novo_texto = novo_texto.replaceAll('[CONVENIO]',            this.obj_dados_paciente.CONVENIO) 
        novo_texto = novo_texto.replaceAll('[PROFISSAO]',           this.obj_dados_paciente.PROFISSAO)
        novo_texto = novo_texto.replaceAll('[CRM]',                 this.obj_dados_profissional.CRM)
        novo_texto = novo_texto.replaceAll('[HORACONSULTA]',        this.obj_dados_paciente.HORA)
        novo_texto = novo_texto.replaceAll('[HORACHEGADA]',         this.obj_dados_paciente.HORA)
        novo_texto = novo_texto.replaceAll('[DIASAF]', '1 dia(s)')
        novo_texto = novo_texto.replaceAll('[HOJE]',                  this.hoje)
        novo_texto = novo_texto.replaceAll('[DATAINICIOAFASTAMENTO]', this.hoje)
        novo_texto = novo_texto.replaceAll('[DATAFIMAFASTAMENTO]',    this.hoje)
        novo_texto = novo_texto.replaceAll('[CIDADE/HOJE]',         cidade)
        novo_texto = novo_texto.replaceAll('[DR(A)CRM]',            profissional)
        novo_texto = novo_texto.replaceAll('[DR(A)]',               this.obj_dados_profissional.NOME)



        //ler direto do sessionStorage
        novo_texto = novo_texto.replaceAll('[CRMUF]',          sessionStorage['printCRMUF'])
        novo_texto = novo_texto.replaceAll('[END.PACIENTE]',   sessionStorage['getEndPaciente'])
        novo_texto = novo_texto.replaceAll('[RECEITA]',        sessionStorage['LSreceita'])
        novo_texto = novo_texto.replaceAll('[EXAME]',          sessionStorage['LSexame'])

        novo_texto = novo_texto.replaceAll('[END.CONSULT]',    sessionStorage['LSendereco2'])
        novo_texto = novo_texto.replaceAll('[FONES.CONSULT]',  sessionStorage['LSfones2'])

        
    


        this.editor.commands.setContent(novo_texto)//aqui eu insiro o texto 
        // this.editor.commands.setContent(texto)
        // this.editor.commands.insertContent(novo_texto)
        // https://tiptap.dev/api/commands/insert-content

        sessionStorage['printConteudo'] = novo_texto
        sessionStorage['printRodape']   = novo_rodape
      }
  

    },

    imprimir(){

      this.elevacao = 0 //remove elevacao
      this.imprimindo = true

      // this.largura_dif = 0
      // this.largura_pagina =  parseFloat(this.largura_pagina) - parseFloat(this.margem)

      setTimeout(function(){
        window.print()
      }.bind(this), 100);//usar esse .bind(this) para chamar funcoes com o setTimeout

      setTimeout(function(){
        this.elevacao = 4 //volta elevacao
        this.imprimindo = false
        // this.largura_dif = '2.5'
        // this.largura_pagina =  parseFloat(this.largura_pagina) + parseFloat(this.margem)
      }.bind(this), 1000);//usar esse .bind(this) para chamar funcoes com o setTimeout

      
      // window.print()
    },

    close(){
      window.close()
    },


    f_mostrar_logo(){

      if (this.mostrar_logo == true){
        // this.$refs.print_logo.style.display = "block"; //estava gerando erro
      }
      else{
        this.$refs.print_logo.style.display = "none";
      }
      
      // this.$refs.print_logo.style.display = "none";
    },

    alerta(text,color){
        // alert(text);
         this.snackbar = true;
         this.snack_text= text;
         this.snack_color = color;
    },

    ler_localStorage(){


      
      try {
        this.obj_dados_paciente = JSON.parse(sessionStorage['obj_dados_paciente'])
      }
      catch (e) {
        this.obj_dados_paciente = null
      }
      
      try {
        this.obj_dados_profissional = JSON.parse(sessionStorage['obj_dados_profissional'])
      }
      catch (e) {
        this.obj_dados_profissional = null
      }
      
      try {
        this.obj_dados_empresa = JSON.parse(sessionStorage['obj_dados_empresa'])
      }
      catch (e) {
        this.obj_dados_empresa = null
      }
      

      try {
        this.obj_profissionais_sub = JSON.parse(sessionStorage['obj_profissionais_sub'])
        this.obj_profissionais_sub.unshift({CODIGO:'0', NOME:'TODOS', CATEGORIA:'TODOS', CRM:' ' }) //no começo
      }
      catch (e) {
        this.obj_profissionais_sub = null
      }

      try{
        this.PrintPode = JSON.parse(sessionStorage['PrintPode']) 
      }catch(e){ this.PrintPode = true}

      this.hoje      = get_data_hora('hoje')

      this.urlLogo   =  sessionStorage['emp_url_logo']
      this.urlImages =  sessionStorage['emp_url_images']

      this.session_bd =  'mc_'+ sessionStorage['LSid'];

      this.get[0].printConteudo        = sessionStorage['printConteudo']
      this.get[0].printRodape          = sessionStorage['printRodape']
      this.get[0].printTipo            = sessionStorage['printTipo']


      this.get[0].printNomePaciente    = sessionStorage['printNomePaciente']
      this.get[0].printRG              = sessionStorage['printRG']
      this.get[0].printCPF             = sessionStorage['printCPF']

      this.get[0].printIdade           = sessionStorage['printIdade']
      this.get[0].printEstadoCivil     = sessionStorage['printEstadoCivil']
      this.get[0].printSexo            = sessionStorage['printSexo']
      this.get[0].printConvenio        = sessionStorage['printConvenio']
      this.get[0].printProfissao       = sessionStorage['printProfissao']


      this.get[0].printHora            = sessionStorage['printHora']

      this.get[0].printNomeDoc         = this.obj_dados_profissional.NOME
      this.get[0].printCRMdoc          = this.obj_dados_profissional.CRM
      // this.get[0].printNomeDoc         = sessionStorage['printNomeDoc']
      // this.get[0].printCRMdoc          = sessionStorage['printCRMdoc']
      this.get[0].printCidade          = sessionStorage['LSCidade2']

      // this.get[0].PATH_LOGO            = sessionStorage['getPATH_LOGO']
      this.get[0].PATH_LOGO_PRO        = sessionStorage['getPATH_LOGO_PRO']
      
      this.get[0].ATESTADO_TITULO      = sessionStorage['getATESTADO_TITULO']

      this.get[0].printMae             = sessionStorage['printMae']
      this.get[0].printPai             = sessionStorage['printPai']

      this.NomeEmpresa                = sessionStorage['LSEmpresa']


      // if (this.get[0].PATH_LOGO?.length > 5){
      // if ((this.get[0].PATH_LOGO != '')&&(this.get[0].PATH_LOGO != 'undefined')){ //pega logo dalva no cadastro do profissional
        //  this.urlLogo = this.urlImages + this.get[0].PATH_LOGO
      // }
      
      if ((this.get[0].PATH_LOGO_PRO != '')&&(this.get[0].PATH_LOGO_PRO != 'undefined')){ //pega logo dalva no cadastro do profissional
         this.urlLogo_pro = this.urlImages + this.get[0].PATH_LOGO_PRO
      }


      // this.pegaEndereco  =  sessionStorage['LSendereco2'] + `\n` + '<br>' + sessionStorage['LSfones']
      this.pegaEndereco        =  sessionStorage['LSendereco2'] +  '<br/>' + sessionStorage['LSfones']
      this.pegaDiretorTecnico  =  sessionStorage['LSDiretorTecnico']
    

      this.cor_fernanda = (sessionStorage['printCRMdoc'] == '21840') ? true : false

      this.tipo_atestado = this.get[0].printTipo


      if (this.tipo_atestado == 'Receitas'){
          this.itemsLista = ['Atestado','Receitas','Outros','Termos']
      }
      else if (this.tipo_atestado == 'Exames') {
          this.itemsLista = ['Atestado','Exames','Outros','Termos']
      }else{
          this.itemsLista = ['Atestado','Outros','Termos']
      }


      // geral
      this.itemsLista = ['Atestado','Receitas','Exames','Outros','Termos']

      if (this.get[0].printCRMdoc == 9302){
         this.nome_logo = '9302.png'
      }
      else if (this.get[0].printCRMdoc == 22705){
         this.nome_logo = '22705.png'
      }


      try {
        this.items_receitas = JSON.parse(sessionStorage['print_receitas']);
      }catch(e){}

      try{
        this.items_exames   = JSON.parse(sessionStorage['print_exames']);
      }catch(e){}


      if (sessionStorage['LSselected_receitas']){
        let a = JSON.parse( sessionStorage['LSselected_receitas'])
        if (a?.length>0){
           this.selected_receitas = JSON.parse( sessionStorage['LSselected_receitas'] )
        }
        else{
          this.selected_receitas = this.items_receitas
        }
      }
      
      if (sessionStorage['LSselected_exames']){
        let a = JSON.parse( sessionStorage['LSselected_exames'])
        if (a?.length>0){
          this.selected_exames = JSON.parse( sessionStorage['LSselected_exames'] )
        }
        else{
          this.selected_exames = this.items_exames
        }
      }

    },

     read_localStorage($name,$to){
        if (localStorage[$name]) {
           return localStorage[$name]
          //  this[$to] =  sessionStorage[$name];
        } 
     },

     update_windows(){
       location.reload();
     },


    async crud_impressos () {
               
        // console.log('crud_impressos')

        if (this.tipo_atestado == ''){
            this.tipo_atestado = 'Atestado'
        }
      
          let doc = this.obj_dados_profissional.CODIGO
          let sql = `select * from IMPRESSOS_WEB where (TIPO = '${this.tipo_atestado}') and ((COD_PROF = ${doc}) or (COD_PROF = 0)) order by NOME `
          // console.log('sql:', sql);

          const res = await axios.post(url+'conexao.php', {
            acao:       1,
            meuSQL:   sql,
            // meuSQL:   "select * from IMPRESSOS_WEB where TIPO = '" + this.tipo_atestado + "' order by NOME ",
          })

           this.items_atestados = await res.data; //res       


           await this.valores_default()

    },


           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
             return await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              return await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(tabela)//trato alguns campos necessarios

              await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)
          },
  
          async remover_tabela(tabela, obj_edita, titulo){
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
          },
  
          editar_tabela(item, obj_edita_name, dialog_name) {
              this.seleciona_linha(item)
              DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },

          async tabela(tabela){

            // console.log('tabela');
            if (tabela == 'CONFIG_IMPRESSAO'){

               let doc = this.obj_dados_profissional.CODIGO
               let sql = `select * from CONFIG_IMPRESSAO where ((COD_PROF = ${doc}) or (COD_PROF = 0)) order by MODELO_IMPRESSAO `
              //  console.log(sql);
               if (await this.crud_abrir_tabela(sql, 'items_modelos_imp')){

                   this.items_modelos_imp_sub =  this.items_modelos_imp.map((x) => x);//pega os itens selecionados

                   this.items_modelos_imp_sub.unshift({CODIGO:'0', MODELO_IMPRESSAO:'NENHUM'}) //no começo

               }
            }

            else if (tabela == 'FARMACIAS'){

               let sql = `SELECT * FROM ${tabela}
                          WHERE ativo = 'S'
                           AND numero IS NOT NULL
                           AND CHAR_LENGTH(numero) > 9
                          ORDER BY nome;
                          `
              //  console.log(sql);
               if(await this.crud_abrir_tabela(sql, 'items_farmacias')){ 

                 this.tem_farmacias =  (this.items_farmacias.length>0)
                // console.log('items_farmacias', this.items_farmacias);
               }
               
            }
          },
  
  
           //---------------importando funcoes sql ----------------------- FIM




           define_defaults(tabela){
             if (tabela=='CONFIG_IMPRESSAO'){
              //  this.$set(this.item_edita, 'ATIVO', 'S')
             }
           },

           async before_update(tabela){
            

              // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS

              let VERIFICAR = [
                'CONFIG_IMPRESSAO|COD_DOC',
              ]
            
              let CAMPOS = []
              for (let i = 0; i < VERIFICAR.length; i++) {
                
                CAMPOS = VERIFICAR[i].split("|");//divide
                if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                    if (!this.item_edita[CAMPOS[1]]){//verifica se vazio
                        if (!this.item_edita[CAMPOS[0]]){this.$set(this.item_edita, CAMPOS[1], 0) }
                    }
                }
              }
           },
  


  },
}





</script>

<style lang="scss">
/* Basic editor styles */

.v-application p {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.ProseMirror {
  // min-height: 16cm;
  // background-color: red;

  // color: purple;

  min-height: 300px;
  
  overflow: hidden;
  // overflow: scroll;

  border-style: solid;
  border-style: none;

  margin-top: 1rem;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,h2{
    line-height: 1;
    font-weight: normal;
  }

  h3,h4{
    line-height: 0.9;
    font-weight: normal;
  }

  h5{
    line-height: 0.5;
    font-size: 12px;
    font-weight: normal;
  }

  h6 {
    line-height: 0.6;
    font-weight: normal;
  }

  //  line-height: 0.5cm;
  p {
    line-height: 1.3; //1.3
    font-size: 12px;//mudei as margens do  .v-application p DSI
    // padding-top: 0.3cm;
    // margin: 10px;
    // background-color: blue;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.only-print {
  display: none !important;
}



.ProseMirror:focus-visible {
    border: 1px solid grey !important;
    border-radius: 3px;
    outline: none;
}

@media print {
  .no-print {
    display: none !important;
  }

  .only-print {
    display: block !important;
  }

  .ProseMirror {
    border-style: none !important;

    //quando for imprimir..muda o tamanho..pq na tela tem que ficar maior???
    // p {
    //   line-height: 1.2; //1.3
    //   font-size: 12px;//mudei as margens do  .v-application p DSI
    // }
  }

  .dsi_vcard {
    // max-width: 550px !important;
    // background-color: red !important;

    border: none !important;
    box-shadow: none !important;
  }


    .v-card {
    // max-width: 550px !important;
    // background-color: grey !important;

    border: none !important;
    box-shadow: none !important;
  }

  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */

  .v-toolbar {
    display: none !important;
  }

  .v-navigation-drawer {
    display: none !important;
  }


  @page {
    size: auto;
    margin: 0;

    margin-top: 0.5cm; 
    margin-bottom: 0.5cm; 

    /* change the margins as you want them to be. */
  }

  @page:first {
     margin-top: -1.5cm;           
  }  

  #editor {
    background-color: orange;
  }
}

#id_cabecalho {
  // border-style: solid !important;
  border-width: 2px !important;
  border-color: black;
  // background-color: red;
}

#id_rodape {
  // border-style: solid !important;
  border-width: 2px !important;
  border-color: black;
  // background-color: red;
}

.ProseMirror-focused {
  border-style: none !important;
}

.v-btn-toggle {
  margin-top: 1px;
  margin-bottom: 4px;
}


#textarea_rodape{
    text-align: center !important; //user o important quando nao usar o scope
    // color: red !important;
    //centralizar o texto do textarea
}



#textarea_titulo{
  font-size: 10pt !important;
  margin: 0px !important;
  height: 3cm !important;
  text-align: right !important;
  line-height: 1.7 !important; 
}

// .v-textarea textarea {
//     text-align: center !important; //user o important quando nao usar o scope
//     //centralizar o texto do textarea
// }





/* Table-specific styling */
.ProseMirror {
  table {
    border-collapse: collapse;
    // table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }




    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}


 .text-purple input   {
      color: purple !important;
  }

.text-purple   textarea {
      color: purple !important;
      // #bea3d0
  }


  .footer_class{
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
  }

  .fs-7{
    font-size: 7px !important;
  }
  .fs-8{
    font-size: 8px !important;
  }
  .fs-9{
    font-size: 9px !important;
  }
  .fs-10{
    font-size: 10px !important;
  }
  .fs-11{
    font-size: 11px !important;
  }
  .fs-12{
    font-size: 12px !important;
  }
  .fs-13{
    font-size: 13px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }
  .fs-15{
    font-size: 15px !important;
  }
  .fs-16{
    font-size: 16px !important;
  }
  .fs-17{
    font-size: 17px !important;
  }
  .bold{
    font-weight: bold !important;
  }

  .hand:hover {
   cursor: pointer; /* Muda o cursor para a mão ao passar o mouse sobre o container */
  }

  .elemento-oculto {
    display: none !important;
    color: red($color: #000000);

    // :class="[{ 'elemento-oculto': iniciando }, 'grey lighten-4']"
  }


</style>