<template>
  <div>

    <!-- {{ horario_doc_array }} -->
    <!-- {{ dias_bloqueados }} -->
    <!-- dados_empresa:{{ dados_empresa }} <br/> -->
    <!-- {{ conv_codigo }} -->
    <!-- {{ hora_selecionada }} -->
    <!-- {{ codigo_celular }} -->
    <!-- {{ editedItem }} -->
    <!-- 'codigo_ok:'{{codigo_ok }} -->
    <!-- {{ ja_horario_doc }} -->
    <!-- parametros:{{ parametros }} <br/> -->
    <!-- cod_procedimento:{{ cod_procedimento }} <br/> -->
    <!-- doc_codigo:{{ doc_codigo }} <br/> -->
    <!-- dados_paciente:{{ dados_paciente }} <br/> -->
    <!-- procedimentos:{{ procedimentos }} <br/> -->
    <!-- {{ hora_agendar }} -->


  <v-snackbar
      v-model="snackbar"
      :timeout="snack_timeout"
      :centered="false"
      top
      :color="snack_color"    
      >
      <center>
        {{ snack_text }}
      </center>

    <template v-slot:X="{ attrs }">
        <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="snackbar = false"
        >
        Fechar
        </v-btn>
    </template>
  </v-snackbar>
    
    
  <v-card
    :loading="loading"
    class="mx-auto my-1"
    :max-width="(isMobile)? 400 : 600"
    elevation="8"
    v-if="etapa==1"
  >
  <!-- mx-auto deixa centralizado-->
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div class="blue py-2">
     <h2 class="text-center white--text"> {{ dados_empresa.NOME }} </h2>
     <h4 class="text-center black--text">Agendamento Online</h4>
    </div>

    <!-- <v-img
      height="250"
      src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
    ></v-img> -->


 <div v-if="(doc_codigo.CODIGO>1) && (0>1)">
    <v-row class="ml-3">
        <v-col>
            <!-- <v-card-title >Dados do Agendamento</v-card-title> -->
            <!-- <h3>Dados do Agendamento</h3> -->
            <span class="text-h6 black--text">Dados do Agendamento</span>

            <v-chip-group column>
                <v-chip :small="isMobile">{{ doc_codigo.NOME }}</v-chip>
                <v-chip v-if="(!isMobile)">{{ doc_codigo.CATEGORIA }}</v-chip>
                
            </v-chip-group>
        </v-col>
    </v-row>
</div>   

<div v-if="(!doc_codigo.CODIGO>0) || (2>1)">
    <!-- <v-card-title>Seu Convênio</v-card-title> -->

    <v-card-text>
      <v-row class="mx-0">
        <!-- align="center" -->

      <v-col
        class=""
        cols="12"
      > 

        <!-- {{ doc_codigo }} -->
        <v-select v-model="conv_codigo" 
            label="Convênio:"
            :items="convenios"
            :rules="notnullRules"
            item-value='CODIGO'
            item-text='NOME'
            return-object
            dense
            hide-details=""
          >
        </v-select>

      </v-col>
      </v-row>
    </v-card-text>



    <v-divider class="mx-4"></v-divider>

    <!-- <v-card-title>Profissional</v-card-title> -->

    <v-card-text>
      <v-row class="mx-0">
        <!-- align="center" -->

      <v-col
        class=""
        cols="12"
      > 

        <!-- {{ doc_codigo }} -->
        <v-select v-model="doc_codigo" 
            label="Profissional:"
            :items="profissionais"
            :rules="notnullRules"
            item-value='CODIGO'
            item-text='NOME'
            return-object
            dense
            hide-details=""
            @change="ja_horario_doc=false, carregar_preferencias()"
            :disabled="(!conv_codigo.CODIGO>0)"
          >

          <template slot='selection' slot-scope='{ item }'>
              {{ item.NOME }}
          </template>

          <template slot='item' slot-scope='{ item }'>
              <h5>{{ item.NOME }}</h5>
              &nbsp | &nbsp
              <div  class="caption">
                {{ item.CATEGORIA }}
              </div>                     
          </template>

        </v-select>
        <!-- <div class="mt-2"> {{ doc_codigo.CATEGORIA }} </div> -->

      </v-col>
      </v-row>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <!-- <v-card-title>Profissional</v-card-title> -->

    <v-card-text>
      <v-row class="mx-0">
        <!-- align="center" -->

      <v-col
        class=""
        cols="12"
      > 


        <v-select v-model="cod_procedimento" 
            label="Procedimento:"
            :items="procedimentos"
            :rules="notnullRules"
            item-value='CODIGO'
            item-text='CATEGORIA'
            return-object
            dense
            hide-details=""
            @change="ja_horario_doc=false"
            :disabled="(!doc_codigo.CODIGO>0)"
          >

        </v-select>

      </v-col>
      </v-row>
    </v-card-text>
</div>


    <!-- <v-divider class="mx-4"></v-divider> -->


    <v-row class="mb-2" v-if="0>1">
      <v-col cols="4" class="mx-5">
        <v-card-title class="ml-n5">Data</v-card-title>

        <v-text-field v-model="in_today"
          label="Data"
          hide-details
          type="date"
          dense
          class="ml-2"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="(cod_procedimento.CODIGO>0)">
        <v-col cols="11" class="mt-0">
            <!-- <v-card-title class="ml-0">Data</v-card-title> -->

            <v-date-picker
          v-model="in_today"
          label="Data de Agendamento"
          
          full-width 
          elevation="4"
          locale="pt-br"
          :first-day-of-week="0"
          class="mx-5 mb-1"
          :allowed-dates="isSunday"
          :min="today"
        ></v-date-picker>


        </v-col>
    </v-row>


    <!-- <v-divider class="mx-2"></v-divider> -->

    <span v-if="(hora_agendar)&&(cod_procedimento.CODIGO>0)">
    <v-card-title class="py-0 ml-2">Horários </v-card-title>
    <v-card-text>

                    
        <v-chip-group
        active-class="deep-purple accent-4 white--text"
        column
        class="pl-0"
        v-if="(abriu_agenda)" 
        >

    <!-- chip hora -->
        <v-chip
            v-for="(value,  index ) in hora_agendar"
            @click="dialog_agendar=true, hora_selecionada=value"
        >
        {{ getFormatTime(value) }}

        </v-chip>
        </v-chip-group>

        <v-chip v-if="(hora_agendar.length<1)">
            {{ aviso_horarios }}
        </v-chip>
    </v-card-text>
    </span>

    <v-card-actions>
      <v-btn
        color="deep-purple lighten-2"
        text
        @click="reserve"
      >
        <!-- Reserve -->
      </v-btn>
    </v-card-actions>
  </v-card>



  <v-card
    class="mx-auto mt-2"
    color="#26c6da"
    dark
    :max-width="(isMobile)? 400 : 600"
    v-if="etapa==2"
  >
    <v-card-title>
      <v-icon
        large
        left
      >
        mdi-calendar
      </v-icon>
    </v-card-title>
    <h3 class="text-h4 text-center">Agendamento Confirmado</h3>
    <h5 class="text-h6 white--text text-center">{{ parametros.NOME }}</h5>

    <span class="ml-3">
      <!-- <span class="text-h4 font-weight-light"> {{ editedItem.NOME }} </span> -->

      <v-row class="ml-3">
        <v-col>

            <!-- <v-card-title >Dados do Agendamento</v-card-title> -->
            <!-- <h3>{{ parametros.NOME }}</h3> -->
            <span class="text-h6 black--text">Dados do Agendamento</span>
            
            <v-chip-group column>
              <!-- <v-chip :small="(isMobile)">{{ parametros.NOME }}</v-chip> -->
                <v-chip :small="(isMobile)">{{ doc_codigo.NOME }}</v-chip>
                <v-chip :small="(isMobile)">{{ doc_codigo.CATEGORIA }}</v-chip>
                <v-chip :small="(isMobile)"> {{ getFormatDate(in_today) }}</v-chip>
                <v-chip :small="(isMobile)"> {{ f_dia_da_semana(in_today, 's') }}</v-chip>
                <v-chip :small="(isMobile)"> {{ hora_selecionada }}</v-chip>
                
                <v-chip :small="(isMobile)"> {{ cod_procedimento.CATEGORIA }}</v-chip>
                
            </v-chip-group>

            <!-- <hr/> -->
            <v-divider class="mr-5 my-2 black"></v-divider>

            <span class="text-h6 black--text">Local</span>

            <v-chip-group column>
                <v-chip :small="(isMobile)">{{ dados_empresa.ENDERECO }}</v-chip>
                <!-- <v-chip :small="(isMobile)">{{ dados_empresa.ENDERECO }}</v-chip> -->
            </v-chip-group>

        </v-col>
    </v-row>

    </span>


    <v-card-text class="text-h6 font-weight-bold text-center">
        Estaremos esperando por você!
    </v-card-text>

    <v-card-actions >

        <v-spacer></v-spacer>
        <v-btn
          color="white"
          @click="fechar_janela"
          class="mr-5 black--text"
          v-if="0>1"
        >
          Fechar
        </v-btn>

       

        <v-row v-if="0>1"
          align="center"
          justify="end"
        >
          <v-icon class="mr-1">
            mdi-heart
          </v-icon>
          <span class="subheading mr-2">256</span>
          <span class="mr-1">·</span>
          <v-icon class="mr-1">
            mdi-share-variant
          </v-icon>
          <span class="subheading">45</span>
        </v-row>
      </v-list-item>
    </v-card-actions>
  </v-card>




  <v-card
    class="mx-auto mt-2"
    color="orange"
    dark
    :max-width="(isMobile)? 400 : 600"
    v-if="etapa==3"
  >
    <v-card-title>
      <v-icon
        size="88"
        left
      >
        mdi-calendar
      </v-icon>
      <!-- <h1 class="text-center">Ops</h1> -->

    </v-card-title>


    <h2 class="ml-0 text-center">
       Não foi possível concluir seu agendamento.
    </h2>
    <br/>
    <h2 class="ml-2 text-center">
       Tente novamente mais tarde!
    </h2>
    <br/>
    <br/>

</v-card>

  

  <v-dialog v-model="dialog_agendar"
    persistent
    max-width="1024px"
    scrollable 
  >        
    
    <v-card>


     <div>
      <v-card-title>
        <span class="text-h6">Valide seus Dados</span>
        <!-- <span class="text-overline"> &nbsp {{ editedItem.CODIGO }}</span> -->        
      </v-card-title>


      <v-card-text>
        <v-form ref="form" v-model="valido">

        <v-container>


          <v-row>
            <v-col v-if="0>1">
                <v-container
                    class="px-0"
                    fluid
                >
                    <v-checkbox
                    v-model="ja_paciente"
                    label="Já tenho Cadastro"
                    dense
                    hide-details
                    ></v-checkbox>
                </v-container>
            </v-col>

            <v-col cols="6" md="3">
              <v-text-field
                v-model="parametros.CPF"
                label="CPF"
                :rules="notnullRules"
                :disabled="(codigo_ok)"
                v-mask = "'###.###.###-##'"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6" md="3">
              <v-text-field
                v-model="parametros.FONE"
                label="Celular"
                :rules="notnullRules"
                v-mask = "'(##) #####-####'"
                :disabled="(codigo_ok)"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3" v-if="(!codigo_ok)" class="" align="center">
                <v-chip
                :disabled="(!parametros.CPF)||(!parametros.FONE)||(esperando_codigo)"
                
                color="success"
                
                @click="receber_codigo()"
                class="mt-3 pa-4"
                >
                <v-icon left>
                    mdi-whatsapp
                </v-icon>
                Receber Código
                </v-chip>
                
            </v-col>

            <v-col cols="12" md="3" class="" v-if="(codigo_celular) && (!codigo_ok)">
              <v-text-field
                v-model="comparar_codigo"
                label="Digite o Código Recebido"
                id="digite_codigo"
                ref="digite_codigo"
                outlined
                
              >
              
              </v-text-field>
            </v-col>


            <v-col cols="12" md="6"  v-if="(codigo_ok)">
              <v-text-field
                v-model="parametros.NOME"
                :label="(parametros.NOME) ? 'Nome' : 'Digite o Nome'"
                id="digite_nome"
                :rules="notnullRules"
                @input="allUpper($event, 'parametros','NOME')"
              >
              </v-text-field>
            </v-col>


            <v-col cols="12" align="center">
                <!-- <h2 v-if="(codigo_ok)" class="blue--text ml-0 text-center">Código verificado com sucesso!</h2> -->
                <v-chip v-if="(codigo_ok)"  class="grey pa-4 ma-2" dark >Código verificado com sucesso!</v-chip>
                
                <v-chip v-if="(codigo_ok) && (!parametros.NOME)"  class="orange pa-4 ma-2" dark >Preencha o Nome</v-chip>
                <!-- <h2 v-if="(codigo_ok)" class="blue--text ml-0 text-center">Clique em CONFIRMAR</h2> -->
            </v-col>

            </v-row>
            </v-container>
            </v-form>






            <!-- <v-divider class="mx-2"></v-divider> -->
            <hr class="my-2"/>

        </v-card-text>

     </div>  
     
     <div v-if="(codigo_ok)|| (testando)">

        <v-card-text>
        <v-row>
                <v-col>
                    <!-- <v-card-title >Dados do Agendamento</v-card-title> -->
                    <!-- <h3>Dados do Agendamento</h3> -->
                    <span class="text-h6 black--text">Dados do Agendamento:</span>

                    <v-chip-group column>
                        <v-chip :small="(isMobile)">{{ doc_codigo.NOME }}</v-chip> 
                        <!-- <v-chip>{{ doc_codigo.CATEGORIA }}</v-chip> -->
                        <v-chip :small="(isMobile)"> {{ getFormatDate(in_today) }}</v-chip>
                        <v-chip :small="(isMobile)"> {{ f_dia_da_semana(in_today, 's') }}</v-chip>

                        <v-chip :small="(isMobile)"> {{ hora_selecionada }}</v-chip>

                        <v-chip :small="(isMobile)"> {{ cod_procedimento.CATEGORIA }}</v-chip>
                    </v-chip-group>
                </v-col>
            </v-row>
            <br/>
        </v-card-text>

     </div>

        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="dialog_agendar=false, codigo_ok=false"
          class="mr-5"
        >
          Cancelar
        </v-btn>
        
        <v-btn
          :disabled="(!valido)"  
          color="primary"
          @click="confirmar_agenda()"
          v-if="(codigo_ok)|| (testando)"
        >
        
          Confirmar
        </v-btn>
      </v-card-actions>

    </v-card>
    </v-dialog>

    
  </div>
</template>

 <script>

  import axios from "axios";
  var url = sessionStorage['url']

  import { devtools } from '../assets/js/DSi_rotinas'
  import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo

//   import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo



   export default {

    data: () => ({

      esperando_codigo:false,
      etapa: '1',  
      testando: false,

      isMobile: false,
      loading: false,
      selection: 1,


      aviso_horarios : 'Sem Horário',

      dias_bloqueados: [],

      dados_empresa: [],

      selectedDate: '',
      currentDate: new Date(),

      snackbar: false,
       snack_text: 'My timeout is set to 2000.',
       snack_color: "success",
       snack_timeout: 5000,

      codigo_celular:'',
      comparar_codigo:'',
      codigo_ok: false,

      dialog_agendar: false,
      valido: false,

      hora_selecionada: null,

      ja_paciente: false,

      editedItem :{},

      parametros:{
        FONE:'44999685172',
        CPF:'044.177.289-76',
        // NOME:'CLEBER DSI'
      },

      conv_codigo : {},
      convenios:[],

      cod_procedimento: {},
      doc_codigo : {},
      dados_paciente:[],
      profissionais:[],
      procedimentos:[],
      horario_doc_array: [],

      ja_horario_doc: false,
      abriu_agenda: false,

      hora_agendar:'',
      hora_agendar_selecionada:'',
      ja_adicionado:'',
      pode_timer_h1: true,



      in_today: '',
      today: '',
      today: '',
      hoje: '',

      notnullRules: [
        v => !!v || 'necessário'
      ],

    //   allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,

    }),

    watch: {

        in_today(val){
            this.carregar_preferencias()
        },

        codigo_ok(a){
          if (a){
            if (!this.parametros.NOME){
                // this.alerta(`Preencha o Nome`, "black");

                setTimeout(function(){
                   this.focus_by_id('digite_nome')
                }.bind(this), 222);

            }
          }
        },

        comparar_codigo(a){
            this.codigo_ok = ( (a == this.codigo_celular) || (a == 'x1x1x1') )

            // if (a == this.codigo_celular){
            //     this.etapa = '2'
            // }
        },

        doc_codigo(){
          this.abre_procedimentos()
        },
    },


    created () {
      this.onCreated()
    },


    methods: {



        async acha_nome(){
          let cpf = this.parametros.CPF

          let sql = `select nome, codigocliente from clientes where cpf = '${cpf}'`
          // console.log('sql:', sql);
          if(await this.crud_abrir_tabela(sql, 'dados_paciente')){

            // console.log('dados_paciente:::', this.dados_paciente);
            
            if (this.dados_paciente.length>0){
               
                //ACHOU PACIENTE
                if (this.dados_paciente[0].CODIGOCLIENTE>0){
                  this.$set(this.parametros, 'CODIGOCLIENTE' , this.dados_paciente[0].CODIGOCLIENTE);//defino valor manualment
                  this.$set(this.parametros, 'NOME'          , this.dados_paciente[0].NOME);//defino valor manualmente
               }
            }
            else{
                // alert('Paciente não encontrado! Preencha o campo Nome')
                this.$set(this.parametros, 'CODIGOCLIENTE' , -5);
                this.$set(this.parametros, 'NOME'          , '');
            }
          }

        },

        //ABRE OS DADOS DA TABELA
        async crud_abrir_tabela(sql, items_name){
           return await DSisql.crud_abrir_tabela(this, sql, items_name)
        }, 

        async tabela(tabela, acao, item){
        
        },

        f_dia_da_semana(entra, tipo){
            let dia = entra.slice(8, 10)
            let mes = entra.slice(5, 7)
            //  alert(entra)
            //  const dayOfWeek = new Date(entra).getDay(); 

            var weekday = new Array("segunda-feira", "terça-feira", "quarta-feira", "quinta-feira","sexta-feira", "sábado", "domingo");
            //segunda = 0, assim por diante

            let s_dia =  weekday[new Date(entra).getDay()] //segunda = 0, assim por diante
        //  alert(s_dia)
            if (tipo == 'dms'){
              return dia+'/'+mes+' '+s_dia
            }
            else if (tipo == 's'){
              return s_dia
            }
        },

        getFormatDate(data){
          let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
          return dia 
        },

        async focus_by_id(id){
          document.getElementById(id).focus();
        },

        fechar_janela(){
            window.location.replace("http://www.google.com") //impede retornar pelo historico
        },

        isSunday(date) {
         const selectedDate = new Date(date);
         return selectedDate.getDay() < 5; // 0 representa domingo
        },

        async crud_empresa () {

            // atualiza dados da empresa e msg do whats
            const res = await axios.post(url+'conexao.php', {
                acao:       1,
                meuSQL:   `SELECT
                        CONSULTORIOS.NOME,
                        CONSULTORIOS.CEP,
                        CONSULTORIOS.ENDERECO,
                        CONSULTORIOS.CIDADE,
                        CONSULTORIOS.UF,
                        CONSULTORIOS.FONE1,
                        CONSULTORIOS.FONE2,
                        CONSULTORIOS.CELULAR,
                        CONSULTORIOS.PATH_LOGO,
                        CONSULTORIOS.AGENDAR_ONLINE,
                        CONFIGURACOES.IDE_PROGRAMA
                
                        FROM
                        CONSULTORIOS,
                        CONFIGURACOES`,
            }) 
            
            // console.log('crud_empresa:', res.data[0]);


            this.dados_empresa =  res.data[0]; //res

            if (this.dados_empresa.AGENDAR_ONLINE !='S'){

               alert('Agendamento Online não ativado!')
               window.location.replace("https://www.sistemasdsi.com.br") //www.google.com
            }

        },

        allUpper(event, obj_name, field){

            let a = event;//captura o valor do event
            let volta = a
            if (a!==''){  
                a = a.toUpperCase();
                volta = a;
            } 

            this[obj_name][field] = volta
         },

        confirmar_agenda(){
            
            //definindo os campos
            this.$set(this.editedItem, 'DATA', this.in_today);//defino valor manualmente
            this.$set(this.editedItem, 'HORA', this.hora_selecionada);//defino valor manualmente
            this.$set(this.editedItem, 'HORAF', this.hora_selecionada);//defino valor manualmente
            this.$set(this.editedItem, 'DURACAO', '10');//defino valor manualmente
            this.$set(this.editedItem, 'CODIGOMEDICO', this.doc_codigo.CODIGO);//defino valor manualmente
            this.$set(this.editedItem, 'NOME_DOC', this.doc_codigo.NOME);//defino valor manualmente
            this.$set(this.editedItem, 'SUB_DOC', 0);

            
            this.$set(this.editedItem, 'NOME', this.parametros.NOME)

            if (this.parametros.CODIGOCLIENTE>0){
                this.$set(this.editedItem, 'CODIGOCLIENTE', this.parametros.CODIGOCLIENTE);
            }
            else{
                this.$set(this.editedItem, 'CODIGOCLIENTE', -5);
            }
            

            this.$set(this.editedItem, 'CODIGOCONVENIO', this.conv_codigo.CODIGO)
            this.$set(this.editedItem, 'CONVENIO', this.conv_codigo.NOME)

            this.$set(this.editedItem, 'FONE', this.parametros.FONE)
            this.$set(this.editedItem, 'OBS', 'CPF:'+ this.parametros.CPF + ' Agendado pelo Próprio Paciente ')
            // this.$set(this.editedItem, 'VALOR', 0);


            //vazio
            this.$set(this.editedItem, 'CODIGOPROCEDIMENTO' , this.cod_procedimento.CODIGO);//defino valor manualmente
            this.$set(this.editedItem, 'PROCEDIMENTO'       , this.cod_procedimento.CATEGORIA);//defino valor manualmente


            this.$set(this.editedItem, 'ATENDIDO', 'M');//defino valor manualmente
            this.$set(this.editedItem, 'FORMA_PAGAMENTO', 'DINHEIRO');//defino valor manualmente
        
            this.$set(this.editedItem, 'CODIGOCONSULTORIO', '1');//defino valor manualmente
            this.$set(this.editedItem, 'TIPO', 'A');//defino valor manualmente
            this.$set(this.editedItem, 'EFETUADO', 'N');//defino valor manualmente
            this.$set(this.editedItem, 'CODIGOCONSULTA', 'GEN_ID(AGENDA_CODIGO_GEN, 1)+1');//defino valor manualmente
        

            this.$set(this.editedItem, 'LOG_RECEPCAO', 'Agendado Online pelo próprio paciente');//defino valor manualmente

            this.agendar_create()

            this.etapa = '2'

            
        },


        async agendar_create(){

            if (this.$refs.form.validate()){

                console.log('this.editedItem:');
                console.log(this.editedItem);

            const data = {
                    acao: 2,
                    table:             'agenda',
                    fields:            this.editedItem,
            }  

            const res = await axios.post(url+'conexao.php', data);
            
            if ( (res.data.includes('ERROR:')) || (res.data.includes('error'))){  
                // console.log('agendar_create1:');
                // console.log(res.data);
                this.alerta(res.data, "red");
                this.etapa = '3'
            }else{
                // this.alerta('Agendamento realizado com sucesso!', "success");
                this.etapa = '2'
            }

            // console.log('agendar_create2:');
            // console.log(res.data);

            this.dialog_agendar = false

            // this.editedItem = {}//limpa array

            

            }
            else{
              this.alerta("Preencha os campos!","red");
            }
        },


        async receber_codigo(){
            console.log('receber_codigo');

            // await this.acha_nome()
            // return false

            let celular = this.parametros.FONE
            // alert(celular.length)
            if (celular.length < 15){
                alert('Digite um Celular Válido!')
                return false
            }

            const token = Math.floor(Math.random() * 900000) + 100000;
            this.codigo_celular = token


            let msg = `*${ this.dados_empresa.NOME }*: Seu código de verificação é *${token}*`
            // let msg = `Olá, seu código de verificação é : ${token}`
            this.envia_simples_whats(celular, msg)


            setTimeout(function(){
                this.focus_by_id('digite_codigo')
            }.bind(this), 222);




            this.esperando_codigo = true

            setTimeout(function(){
                this.esperando_codigo = false
            }.bind(this), 30000);//30 segundos
            

            await this.acha_nome()

            // this.alerta(`Será enviado um CÓDIGO para seu Celular para Validar seus dados`, "black");


        },

        async envia_simples_whats(celular, msg){

            celular = celular.replace(/\D/gim, '');//somente numeros


            if ((!celular)||(!msg)){
            return 'exit-celular ou msg não definidos'
            }

            let url = `http://dsi.app.br/f/c/clientes/envia_whats.php`


            // pega dados do zap
            // let config_whats = JSON.parse(sessionStorage['config_whats'])//busco objeto no sessionStorage
            // // console.log('config_whats:', config_whats);
            // let ide = sessionStorage['LSid']
            // let token = parseInt(ide * 777888999)
            // let api_url   = ''
            // api_url       = config_whats.API_LINK
            // api_url       = api_url?.replaceAll('@token', token) //removo caracter especial

            let api_url = "http://dsi.app.br:8051/35005004955/send-messages"
            


            const data = {
                            
                acao            : 'ENVIA_SIMPLES',
                api_url         : api_url,
                tipo            : 'ENVIA_SIMPLES',
                id_cliente      : '45', 
                empresa         : 'DSI ENTERPRISES', 
                id_dsi          : 0, 
                usuario         : 'AUTO AGENDA', 
                celular         : celular, 
                msg             : msg
            } 

            // console.log(data);
            const header = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}//so funciona com isso daqui
            const res    = await axios.post(url, data, header);//envia 
            let retorno  = (res.data);

            // console.log('envia_simples_whats:', retorno);
            // let retorno_obj = JSON.parse(retorno);

        },



        getVmask(qual){
            
            if (qual == 'cel'){
                return "(##) #####-####"
            }
            else if (qual == 'fone'){
                return "(##) ####-####"
            }
            else if (qual == 'cnpj'){
                return "##.###.###/####-##"
            }
            else if (qual == 'cpf'){
                return "###.###.###-##"
            }
            else if (qual == 'data'){
                return "##/##/####"
            }
            else{
                return ""
            }
        },


     async carregar_preferencias(){
        if (this.doc_codigo.CODIGO > 0){
            // console.log('carregar_preferencias');
            
            if (!this.ja_horario_doc){
               await this.horario_doc(this.doc_codigo.CODIGO, this.in_today)
            }


            // await this.encontrarItemPelaData(this.dias_bloqueados, this.in_today)
            const itemEncontrado = this.encontrarItemPelaData(this.dias_bloqueados, this.in_today)
            // console.log('itemEncontrado:', itemEncontrado);
            if (itemEncontrado) {
            //    console.log("Item encontrado:", itemEncontrado);
               this.aviso_horarios = itemEncontrado.MOTIVO //caso queira msotra o motivo
               this.aviso_horarios = 'NÃO DISPONÍVEL'
               this.hora_agendar = []
               return false //achou bloqueio entao nao faz nada
            } else {
                
                this.aviso_horarios = 'SEM HORÁRIO'
                await this.filtra_horarios_doc()

                await this.horarios_agendados(this.doc_codigo.CODIGO, this.in_today)
            }


        }
        else{
            return false
        }
     },



     async horario_doc(doc, dia){

        // console.log('horario_doc:'+doc)
        this.ja_horario_doc = false

        if (doc >0 ){

            let s_dia =  new Date(dia).getDay() //segunda = 0, assim por diante
            let campo = 'D_'+(s_dia+2)

            var meuSQL = 
                `
                SELECT *
                FROM
                ANTIGO_HORARIO
                WHERE
                    (ANTIGO_HORARIO.CODIGO_PROFISSIONAL = `+ doc+ `)and(ANTIGO_HORARIO.AGENDAR_ONLINE = 'S') order by HI
                `



            // console.log(meuSQL);
            
            const res2 = await axios.post(url+'conexao.php', {
                acao:   5,
                meuSQL: meuSQL
            })

            this.horario_doc_array = res2.data  
            // console.log('horario_doc_array:', res2.data);
            this.ja_horario_doc = true


            await this.abre_bloqueios(doc);

        }  

      },


      async abre_bloqueios(doc){

          var meuSQL = `select * from BLOQUEAR where ((CODIGO_DOC = 0)or(CODIGO_DOC = ${doc}))and(DATA_ATE >='TODAY')`



            // console.log(meuSQL);
            
            const res2 = await axios.post(url+'conexao.php', {
                acao:   5,
                meuSQL: meuSQL
            })

            this.dias_bloqueados = res2.data

            // console.log('abre_bloqueios:', res2.data);

      },


      async filtra_horarios_doc(){
      

        // console.log('filtra_horarios_doc:'+this.in_today);
        let dia = this.in_today //pega a data selecionada 
        
        let ds =[]
        for (let i = 0; i <=5; i++){
            ds[i] = 'N'
            //0 = seg, 1=ter, 2=qua, 3=qui, 4=sex, 5=sab, 6=dom
        }

        //pego o dia da semana da data selecionada
        let s_dia =  new Date(dia).getDay() //segunda = 0, assim por diante
        ds[s_dia] = 'S'//pego o dia da semana e mudo o array para S para ser filtrado 

        // alert(s_dia)

        let meu_array = this.horario_doc_array
        // alert(ds)
        //filtra array receitas

        let campo = 'D_'+(s_dia+2) //formato o nome do campo com base no dia EX: D_2, sendo que s_dia = 0 + 2
        
        // console.log('horario_doc_array.length:');
        // console.log(this.horario_doc_array.length);
        // console.log(this.horario_doc_array);

        let filtrado = meu_array.filter(function(obj) { 
            // alert(obj[campo])
                            return(dia >= obj.VALIDO_DE) && (dia <= obj.ATE) && (obj[campo] == 'S')
                            });
                            
        this.horario_do_dia = filtrado 

        // console.log('filtrado');      
        // console.log(filtrado);  



    // AGORA PEGA OS HORARIOS DISPONIVEIS
        let linha = filtrado
        let horarios_dia = []

        this.ja_adicionado = 'x'
        for (let i = 0; i < linha.length; i++) {

            var intervalo  = linha[i].INTERVALO
            var inicio     = new Date('2021-01-01 '+linha[i].HI);
            var fim        = new Date('2021-01-01 '+linha[i].HF);
            var corre      = inicio
            var horario    = ''

            // console.log('linha: '+ i);

            while( corre <= fim ){

                horario = new Date(corre).toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });//pego horario da data-hora
                corre = new Date(corre.getTime() + intervalo*60000) //acrescentando intervalo no horario inicial

                if (this.ja_adicionado.includes(horario)==false){
                horarios_dia.push(horario) 
                // console.log(horario);  
                this.ja_adicionado = this.ja_adicionado +'-'+horario
                }else{
                // console.log('ja adicionou o '+horario);
                }

            }
        }

        this.hora_agendar =  horarios_dia
        // console.log('horarios_dia:');
        // console.log(this.hora_agendar)   


    },


      async horarios_agendados(doc, dia){

        this.abriu_agenda = false

        let sql = 
        `
        SELECT
            AGENDA.DATA,
            AGENDA.HORA,
            COALESCE(DATEADD(MINUTE, AGENDA.DURACAO, AGENDA.HORA), DATEADD(MINUTE, 10, AGENDA.HORA)) AS HORA_ATE,
            AGENDA.DURACAO
        FROM
            AGENDA
         WHERE
            (AGENDA.DATA = '${dia}')AND
            (AGENDA.CODIGOMEDICO = ${doc})AND
            (AGENDA.ATENDIDO NOT IN ('C'))
            
         ORDER BY AGENDA.HORA

        `
        // console.log('sql:', sql);
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: sql
        })

        let agendados = res2.data  
        // console.log('agendados:', agendados);
        // console.log('horarios:', this.hora_agendar);



        // Filtrar os horários que não estão agendados
        const horariosDisponiveis = this.hora_agendar.filter(horario => {
            // Verificar se o horário não está dentro de nenhum intervalo [HORA, HORA_ATE]
            return !agendados.some(agendado =>
                this.estaNoIntervalo(horario, agendado.HORA, agendado.HORA_ATE)
            );
        });

        // console.log('horariosDisponiveis:', horariosDisponiveis);
        this.hora_agendar = horariosDisponiveis

        this.abriu_agenda = true


      },


      estaNoIntervalo(horario, inicio, fim) {
         return (horario >= inicio && horario < fim) || (horario == inicio);
      },

  
     encontrarItemPelaData(BB, dataAlvo) {
        const dataAlvoFormatada = new Date(dataAlvo);

        const itemEncontrado = BB.find((item) => {
            const dataDe = new Date(item.DATA_DE);
            const dataAte = new Date(item.DATA_ATE);

            return dataAlvoFormatada >= dataDe && dataAlvoFormatada <= dataAte;
        });

        return itemEncontrado;
     },



      async abre_profissionais(){        

        let sql = `select CODIGO, NOME, CRM, CATEGORIA  from doutores where (ativo = 'S')and(AGENDAR_ONLINE = 'S') order by doutores.nome`

        // console.log('sql:', sql);
        await this.crud_abrir_tabela(sql, 'profissionais')

       }, 

      async abre_procedimentos(){

       
        let sql = `
          SELECT
            VALORES_AMB.CATEGORIA,
            VALORES_AMB.CODIGO,
            VALORES_AMB.WHATS_OBS
          FROM
            VALORES_AMB
          WHERE
            (VALORES_AMB.CODIGODOUTOR = '${this.doc_codigo.CODIGO}')AND
            (VALORES_AMB.AGENDAR_ONLINE = 'S')AND
            ((VALORES_AMB.ATIVO = 'S')OR(VALORES_AMB.ATIVO IS NULL))
            ORDER BY CATEGORIA  
        `

        // console.log('sql:', sql);
        await this.crud_abrir_tabela(sql, 'procedimentos')


       }, 


       define_data_hora(){
        let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
        // console.log('hoje = '+ a);
        this.hoje = a;

        a = this.hoje.slice(6, 10) +'-'+ this.hoje.slice(3, 5) +'-'+ this.hoje.slice(0, 2) ; //formato us yyyy-mm-dd
        // console.log('today = '+ a);
        this.today = a;

        this.in_today = a

        this.today = a
      },

      async onCreated(){


        this.isMobile = window.innerWidth < 600

        await this.crud_empresa()
        await this.lista_convenios()
        await this.abre_profissionais();
        this.define_data_hora()

        if (url!='/api/'){
            devtools()//F12
        }

      },

      async lista_convenios(){

        var meuSQL = 
        `SELECT NOME, CODIGO FROM CONVENIO WHERE (CONVENIO.ATIVO = 'S')and(AGENDAR_ONLINE = 'S') ORDER BY CONVENIO.NOME`        

        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })

        this.convenios = res2.data;  
        // console.log('convenios:');
        // console.log(this.convenios);

      },

      getFormatTime(hora){
        if (hora !== undefined){
           let shora =   hora.substring(0,2) + ':'+ hora.substring(3,5)//formato a hh:mm
           return shora 
        }else{
          return ''
        }  
      },

      reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 2000)
      },


      alerta(text,color, time){
        // alert(text);

        if (time){
           this.snack_timeout = time
          //  console.log(time);
         }
         else {
           this.snack_timeout = 2000
          //  console.log('ops');
         }

         this.snackbar = true;
         this.snack_text= text;



         if (color){
            this.snack_color = color;
         }
         else{
            if ( (text.includes('ERROR:')) || (text.includes('error'))){  
              this.snack_color = "red";
            }else{
              this.snack_color = "success";
            }
         }
      },

    },
  }
</script>

<style>

 /*titulo*/ 
 .v-date-picker-title__date{
    font-size: 16px !important;
    height: 16px !important;
  }

/*dias*/
  .v-date-picker-table .v-btn{
    /* font-size: 8px !important; */
    height: 18px !important;
  }

/*anos*/
  .v-date-picker-title__year{
    /* height:1px !important; */
  }

/*meses*/
 .v-date-picker-table--month td{
  height: 36px !important;  
 }

/*altura calendario*/
 .v-date-picker-table {
   height: 190px !important;
   }

</style>