<template>
 <div>

        <v-dialog
          v-model="dialog_ad_exame"
          max-width="600px"
          scrollable 
        >        
        <!-- persistent -->
          
          <v-card>
            <v-card-title>
              <span class="text-h5"> Adicionar Resultado de Exame  </span>
              <span class="text-caption"> &nbsp IDE: {{ pro_ide }}</span>
            </v-card-title>

            <v-card-text>
              <v-form
              ref="form"              
              >


              <v-container>
                <v-row>

                  <v-col
                    cols="12"
                    md="8"
                    
                  >

                  <v-select
                  v-model="editedItem.CODIGOMEDICO"
                  label="Profissional"
                  :items="profissionais"
                  :rules="notnullRules"
                  item-value='CODIGO'
                  item-text='NOME'
                  return-object
                  >
                  
                    <template slot='selection' slot-scope='{ item }'>
                     {{ item.NOME }}
                    </template>
  
                    <template slot='item' slot-scope='{ item }'>
                      <!-- <v-chip>
                      {{ item.NOME }}   
                      </v-chip> -->
                      <h5>{{ item.NOME }}</h5>
                    
                    &nbsp | &nbsp

                      <div 
                       class="caption"
                      >
                       {{ item.CATEGORIA }}
                      </div>                      

                    </template>

                  </v-select>


                  </v-col>


                  <v-col
                    cols="7"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.DATA"
                      label="Data*"
                      :rules="notnullRules"
                      type="date"
                    ></v-text-field>
                  </v-col>


                  <v-col
                    cols="12"
                    md="12"
                  >

                  <v-textarea text-narrow id="input-7-2"
                  dense
                      background-color="#FAFAFA"
                      v-model="editedItem.DESCRICAO"
                      label="DESCRIÇÃO"
                      hint=""
                      rows="9"
                      :auto-grow=false
                      row-height="8"
                      :rules="notnullRules"
                  >
                  
                    <template v-slot:label>
                    <h3>DESCRIÇÃO</h3>
                    </template> 

                  </v-textarea>
                  </v-col>


                </v-row>
              </v-container>

   

              </v-form>
            </v-card-text>

            <small>&nbsp * Campos necessários</small>
            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="manda_emit('cancelou')"
              >
              <!-- @click="dialog_ad_exame=false" -->
              
                Cancelar
              </v-btn>
              
              <v-btn
              
                color="primary"
                @click="adicionar_exame(editedItem)"
                v-if = "0==0"
              >
                Salvar
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>



 </div> 
</template>

<script>
  
  import axios from "axios";
  var url = sessionStorage['url']

export default {

    props:{
        pro_ide: String,
        pro_nome: String,
    },

    data: () => ({

       dialog_ad_exame: true,

       editedItem: {},
       profissionais:[],

       hoje:'',   
       today:'',   

       notnullRules: [
        v => !!v || 'necessário'
       ],
    }),

    created () {
    //   this.logado()
      this.valores_default()
      this.abre_profissionais()
    },

    mounted(){
        this.editedItem.DATA = this.today
    },

    methods:{

    alerta(text,color){
    // alert(text);
        this.snackbar = true;
        this.snack_text= text;
        this.snack_color = color;
    },

    manda_emit(value,v,v2){
        this.$emit('emit_nome', value,v,v2)//mando fechar o dialog la no pai
        this.dialog_ad_exame = false
    }, 

    adicionar_exame(item){

        if (this.$refs.form.validate()){
        // console.log('adicionar exame:');
        // console.log(item);

        console.log(item.CODIGOMEDICO.CODIGO);//acesso o array do CODIGOMEDICO 

        //definindo valores default
        item.CODIGOCONSULTORIO  = 1
        item.CODIGOCONSULTA     = 0
        item.TIPO               =  'P'
        item.CODIGOPROCEDIMENTO = -9
        item.CODIGOCLIENTE      = this.pro_ide
        item.CODIGOMEDICO       = item.CODIGOMEDICO.CODIGO

        this.crud_create('PROCEDIMENTO',item)

        this.dialog_ad_exame = false
                   

        // this.manda_emit('salvou')           
        }

    },

    async crud_create(table,fields){
        const data = {
            acao    :  2,
            table   :  table,
            fields  :  fields,
        }  

        const res = await axios.post(url+'conexao.php', data);

        //   console.log('crud_create');
        //   console.log(res.data);
        

        if (res.data.includes('ERROR:')){
            this.manda_emit('salvou',res.data,"red") 
        }else{
        //   this.alerta(res.data, "success");
            this.manda_emit('salvou',res.data,"success") 
        }        
    },   

    valores_default(){
        
        let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });//formato dd/mm/yyyy  
        // console.log('hoje = '+ a);
        this.hoje = a;

        a = this.hoje.slice(6, 10) +'-'+ this.hoje.slice(3, 5) +'-'+ this.hoje.slice(0, 2) ; //formato us yyyy-mm-dd
        // console.log('today = '+ a);
        this.today = a;

    },

      async abre_profissionais(){ 
        
       let meuSQL = "select * from doutores where ativo = 'S' order by doutores.nome"

       const res = await axios.post(url+'conexao.php', {
          acao:   5,
          table:  this.crud_table,
          meuSQL: meuSQL
        })
        console.log(res.data);          
        this.profissionais = res.data //pego o nome dos profissionais
      }, 

    }

}
</script>

<style>

</style>