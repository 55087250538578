<template>
    <v-container>
      <v-card>
        <v-card-actions>
          <v-btn color="primary" @click="printReport">Imprimir Relatório</v-btn>
        </v-card-actions>
  
        <v-card-title>Relatório de Contas</v-card-title>
        <v-card-text>
          <div v-for="(groupedData, cliente) in groupedByCliente" :key="cliente">
            <!-- <h3>{{ cliente }}</h3> -->
            <div v-for="(orcamentoGroup, orcamento) in groupedData" :key="orcamento">
              <!-- <h4>Orçamento: {{ orcamento }}</h4> -->
              <v-simple-table>
                <!-- <thead>
                  <tr>
                    <th>Nome do Associado</th>
                    <th>Total das Parcelas</th>
                  </tr>
                </thead> -->
                <tbody>
                  <tr v-for="conta in orcamentoGroup" :key="conta.NUMCARTEIRA">
                    <td>
                        <span>{{ cliente }}</span> | 
                        <span>{{ conta.NOMEDOASSOCIADO }}</span>
                    
                    </td>

                    <td>
                      Diag: {{ orcamento }} | 
                      Parcelas: {{ totalParcelas(orcamentoGroup) }} | 
                      Total: R$ {{ formatCurrency(totalValue(orcamentoGroup)) }}
                    </td>
                  </tr>
                  <!-- Linha de resumo -->
                  <!-- <tr>
                    <td><strong>   </strong></td>
                    <td>
                      Parcelas: {{ totalParcelas(orcamentoGroup) }} | 
                      Total: R$ {{ formatCurrency(totalValue(orcamentoGroup)) }}
                    </td>
                  </tr> -->
                </tbody>
              </v-simple-table>
            </div>
            <!-- Adicionar uma quebra de linha quando o número do orçamento mudar -->
            <div v-if="shouldAddBreak(cliente, groupedData)">
              <br />
            </div>
          </div>
          <div class="total-geral">
            <h3>Total Geral das Parcelas Pendentes: R$ {{ formatCurrency(totalGeral) }}</h3>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
  
  <script>
  import DSisql from '../assets/js/DSi_sql';
  
  export default {
    data: () => ({
      contas: [], // Dados carregados do banco de dados
    }),
  
    computed: {
      groupedByCliente() {
        return this.contas.reduce((acc, conta) => {
          if (!acc[conta.NOMECLIENTE]) {
            acc[conta.NOMECLIENTE] = {};
          }
          if (!acc[conta.NOMECLIENTE][conta.CODIGOORCAMENTO]) {
            acc[conta.NOMECLIENTE][conta.CODIGOORCAMENTO] = [];
          }
          acc[conta.NOMECLIENTE][conta.CODIGOORCAMENTO].push(conta);
          return acc;
        }, {});
      },
  
      totalGeral() {
        return this.contas.reduce((total, conta) => {
          return total + parseFloat(conta.TOTAL_PENDENTE);
        }, 0);
      },
    },
  
    created() {
      this.onCreated();
    },
  
    methods: {
      async onCreated() {
        let sql = `
          SELECT DISTINCT
            CONTAS.CODIGOORCAMENTO,
            CONTAS.CODIGOCLIENTE,
            COUNT(CONTAS.CODIGO) AS QT,
            SUM(CONTAS.VALOR) AS TOTAL_PENDENTE,
            CONTAS.NOMECLIENTE,
            CONTAS.NOMEDOASSOCIADO,
            CONTAS.NUMCARTEIRA
          FROM
            CONTAS
          INNER JOIN ORCAMENTO ON (CONTAS.CODIGOORCAMENTO = ORCAMENTO.CODIGO)
          WHERE
            (CONTAS.DATAAPAGAR BETWEEN 'today' AND '01/01/2025') AND
            (CONTAS.ATIVA = 'S') AND
            (CONTAS.EFETUADO = 'N') AND
            (CONTAS.ODONTO = 'D') AND
            (ORCAMENTO.SITUACAO = 'CONCLUIDO')
          GROUP BY
            CONTAS.CODIGOORCAMENTO,
            CONTAS.CODIGOCLIENTE,
            CONTAS.NOMECLIENTE,
            CONTAS.NOMEDOASSOCIADO,
            CONTAS.NUMCARTEIRA
          ORDER BY
            CONTAS.NOMECLIENTE,
            CONTAS.CODIGOORCAMENTO
        `;
  
        await DSisql.crud_abrir_tabela(this, sql, 'contas');
      },
  
      totalParcelas(orcamentoGroup) {
        // Soma a quantidade total de parcelas para o grupo de orçamento
        return orcamentoGroup.reduce((total, conta) => {
          return total + conta.QT;
        }, 0);
      },
  
      totalValue(orcamentoGroup) {
        // Soma o valor total das parcelas restantes para o grupo de orçamento
        return orcamentoGroup.reduce((total, conta) => {
          return total + parseFloat(conta.TOTAL_PENDENTE);
        }, 0);
      },
  
      formatCurrency(value) {
        // Corrigindo o erro de toFixed e formatando corretamente
        return parseFloat(value).toFixed(2).replace('.', ',');
      },
  
      printReport() {
        window.print();
      },
  
      shouldAddBreak(cliente, groupedData) {
        // Verifica se deve adicionar uma quebra de linha
        // Retorna true se for o último orçamento do cliente ou se o cliente mudar
        const lastOrcamento = Object.keys(groupedData).pop();
        return cliente !== lastOrcamento;
      },
    },
  };
  </script>
  
  <style>
  .total-geral {
    text-align: right;
    margin-top: 20px;
    font-weight: bold;
  }
  
  /* Estilos de impressão */
  @media print {
    .v-btn {
      display: none;
    }
    .v-card {
      page-break-inside: avoid;
    }
  }
  </style>
  