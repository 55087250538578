<template>
    <v-app>
      <v-container>
        <v-card class="mx-auto grey lighten-5">
          <div class="d-flex justify-center align-center position-relative">
            <v-img
              class="black--text align-end mt-2"
              src="http://localhost/vue_cfc/public/images/odontograma.png"
              style="max-width: 80%; height: auto;"
            >
              <!-- Contêiner dos botões -->
              <div
                class="position-absolute "
                style="position: absolute; top: 10%; left: 19%; right: 0; width: 69%; display: flex; justify-content: space-around;"
              >
                <!-- 16 botões -->
                <v-btn
                  v-for="n in 16"
                  :key="n"
                  class="transparent-button blue"
                  color="red"                
                  style="opacity: 0.5; width: 5px; height: 130px;"
                >
                
                <!-- style="opacity: 0.5; width: 10px; height: 6.4vw;" -->
                  {{ n }}
                </v-btn>
              </div>
            </v-img>
          </div>
  
          <v-card-subtitle class="pb-0">
            Number 10
          </v-card-subtitle>
  
          <v-card-text class="text--primary">
            <div>Whitehaven Beach</div>
            <div>Whitsunday Island, Whitsunday Islands</div>
          </v-card-text>
  
          <v-card-actions>
            <v-btn color="orange" text>
              Share
            </v-btn>
            <v-btn color="orange" text>
              Explore
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-app>
  </template>
  
  <script>
  export default {
    name: 'TransparentButtonImage',
  };
  </script>
  
  <style scoped>
  .transparent-button {
    color: white; /* Cor do texto do botão */
    font-weight: bold;
  }
  </style>
  