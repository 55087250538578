
import axios from "axios";
import { gerar_token, somenteNumeros } from './DSi_basico'
import { crud_abrir_tabela_canal } from './DSi_sql'

var URL_DSI = sessionStorage['url']



 export async function conectar_whats(){

    let config_whats = JSON.parse(sessionStorage['config_whats'])
    console.log('config_whats:', config_whats);

    if (!config_whats){
      console.log('WhatsApp não configurado!')
      return false
    }


    let ide      = sessionStorage['LSid']
    let token    = parseInt(ide * 777888999)

    let url
    url = config_whats.API_LINK
    url = url?.replaceAll('/send-messages','') //removo caracter especial
    url = url?.replaceAll('@token', token) //removo caracter especial

    // console.log('url:', url);
    
    window.open(url, '_blank');

 };


 export async function status_whats(alertar, conectar, retornar_campo){


    //busca configuracoes do whats como ide e link token 
    let config_whats = JSON.parse(sessionStorage['config_whats'])
    // console.log('config_whats:', config_whats);

    if (!config_whats){
      console.log('WhatsApp não configurado!')
      return false
    }



    let ide      = sessionStorage['LSid']
    let token    = parseInt(ide * 777888999)

    let id_user = config_whats.ID_USER
    if (id_user>0){
      token = token +'i'+id_user
      console.log('id_user .js2:', id_user);
      console.log('novo token .js2:', token);
    }

    let api_url  = config_whats.API_LINK
    api_url      = api_url?.replaceAll('@token', token) //removo caracter especial
    api_url      = api_url?.replaceAll('send-messages','status') //removo caracter especial
    
    let api_qr   = api_url?.replaceAll('/send-messages','') //removo caracter especial

    // console.log('api_url:', api_url)



    let retorno = ''
    try {

        const data = {
          url:  api_url,
        }  
        // console.log('data:' , data);
        // const header =   {}
        const header =   { headers: {'Content-Type': 'application/json'}}//TEM QUE USAR O json_decode NO PHP que vai receber


        // const res = await axios.post(URL_DSI+'scapeCors.php', data, header);
        // console.log('api_url:', api_url);
        const res = await axios.post(URL_DSI+'getQRCode.php', data, header);


        retorno = JSON.parse(res.data);
        // console.log('retorno:', retorno);

        

        if (retorno.connected){
           if (alertar){
              alert('Módulo WhatsApp conectado com sucesso!')
           }
        }else{

            //nao esta conectado
            if (conectar){

              // pergunta se quer conectar
              if (confirm("Módulo WhatsApp não conectado! Deseja conectar?")){
                  await conectar_whats() 
              }
              else{
                return false
              }
            } 
        }

        // muda o session para ser lido pelas janelas
        sessionStorage['zapzap_conectado'] = retorno.connected


        if (retornar_campo){
          // retorna somente o campo especifica
          return retorno[retornar_campo]
        }
        else{
          // retorna o objeto inteiro
          return  retorno
        }

        
        // return  retorno.connected

      // res = await axios.get(url, header) //ORIGINAL ZAPI
    } catch (err) {
        // Handle error
        // console.log('status_zap: ', err);
        // this.zap_message = err.message
        // o programa pode nao estar rodando la no servidor
        // this.zap_message = 'Atualizando Servidor, tente novamente mais tarde'
        console.log('ERRO:', err.message);
        sessionStorage['zapzap_conectado'] = false
        // return err.message

        if (alertar){
           alert('Atualizando o Servidor, tente novamente mais tarde')
        }


        if (retornar_campo){
          // retorna somente o campo especifica
          return false
        }
        else{
          // retorna o objeto inteiro
          return {connected: false, error: `Atualizando Servidor, tente novamente mais tarde: ${err.message}`}
        }

        
    }

 };


 export async function mostrar_whats(){
  
  let zapzap =  sessionStorage['config_whats']
  
  // return false
  if ((zapzap)&&(zapzap.SITUACAO !='CANCELADO')){
    return  true
  }else{
    return false
  }

};


 export async function send_whats_api(tipo, celular, texto, file){


    if (!celular){
        return false
    }
    celular =  await somenteNumeros(celular)


    let url = meu_url()+`clientes/envia_whats.php`

    let config_whats = JSON.parse(sessionStorage['config_whats'])
    // console.log('config_whats:', config_whats);

    let ide = sessionStorage['LSid']
    let token = parseInt(ide * 777888999)

    let id_user = config_whats.ID_USER
    if (id_user>0){
      token = token +'i'+id_user
      console.log('id_user .js:', id_user);
      console.log('novo token .js:', token);
    }

    let api_url = config_whats.API_LINK
    if (!api_url){
       console.log('DSi_whats.js api_url nao definida');
       return false 
    }
    api_url       = api_url?.replaceAll('@token', token) //removo caracter especial
    

    texto = texto.replaceAll('@empresa'         , '*'+sessionStorage['LSEmpresa']+'*')
    let textoTratado = await EmojiparaTexto(texto);//pega emoji e transforma em texto para ser salvo no 45
    texto = textoTratado
    
    let ID_DSI = await gerar_token(ide)

    const data = {
                            
        acao            : 'ENVIA_SIMPLES',
        api_url         : api_url,
        tipo            : tipo,
        id_cliente      : ide, 
        empresa         : sessionStorage['LSEmpresa'], 
        id_dsi          : ID_DSI, 
        usuario         : sessionStorage['lg:USUARIO'], 
        celular         : celular, 
        msg             : texto, 
        file            : file 
        // optionList      : optionList, 
    }  

    // console.log(data);
    const header = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}//so funciona com isso daqui
    const res    = await axios.post(url, data, header);//envia 
    let retorno  = (res.data);
    // console.log('retorno:', retorno);
    return retorno

 }; 



 export async function send_whats_api_45(tipo, origem, empresa, celular, msg){

   celular = celular.replace(/\D/gim, '');//somente numeros


    if ((!celular)||(!msg)){
    return 'exit-celular ou msg não definidos'
    }

    // let url     = `http://localhost/vue_cfc/public/clientes/envia_whats.php`
    let url     = `http://dsi.app.br/f/c/clientes/envia_whats.php`
    let api_url = "http://dsi.app.br:8051/35005004955/send-messages"
    


    const data = {
                    
        acao            : 'ENVIA_SIMPLES',
        tipo            : tipo,
        api_url         : api_url,
        id_cliente      : '45', 
        empresa         : empresa, 
        id_dsi          : 0, 
        usuario         : origem, 
        celular         : celular, 
        msg             : msg
    } 

    // console.log(data);
    const header = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}//so funciona com isso daqui
    const res    = await axios.post(url, data, header);//envia 
    let retorno  = (res.data);

    // console.log('envia_simples_whats:', retorno);
    // let retorno_obj = JSON.parse(retorno);

 };


 export async function enviar_email_45(para, assunto, texto){
    // console.log('enviar_email:');

    let link = "https://www.dsi.app.br/f/c/email.php"

    const data = {
              
              para:      para,
              assunto:   assunto, 
              texto:     texto,
    }  

    // alert(link)
    // console.log(data);
    const header =   { headers: {'Content-Type': 'multipart/form-data'}}

    const res = await axios.post(link, data, header);
    // console.log('enviar_email_45:', res.data);
    return res.data

 };

 export  function replace_emoji(msg){
    msg = msg.replaceAll( '%F0%9F%8E%89'    , '🎉');
    msg = msg.replaceAll( '%F0%9F%8E%8A'    , '🎊');
    msg = msg.replaceAll( '%F0%9F%A5%B3'    , '🥳');
    msg = msg.replaceAll( '@festa1'         , '🎉');
    msg = msg.replaceAll( '@festa2'         , '🎊');
    msg = msg.replaceAll( '@festa3'         , '🥳');
    msg = msg.replaceAll( '%F0%9F%9A%A8'    , '📍');
    msg = msg.replaceAll( '%F0%9F%93%8D'    , '🚨');
    msg = msg.replaceAll( '@sirene'         , '🚨');
    msg = msg.replaceAll( '@local'          , '📍');
    msg = msg.replaceAll( '@;u'             , '😉');
    msg = msg.replaceAll( '@:u'             , '🙂');
    msg = msg.replaceAll( '@h_dir'          , '👉🏼');
    msg = msg.replaceAll( '@av'             , '👩🏼‍💻');
    msg = msg.replaceAll( '@e_fone'         , '☎️');

    msg = msg.replace( /@rb/g     , '🔴');
    msg = msg.replace( /@r_set/g     , '➡️');

    return  msg
 };



  function meu_url(){
          
    let url = ''

    if (sessionStorage['url'] == '/api/'){
      url =  `http://localhost/vue_cfc/public/`
    }
    else{
      url =  `https://dsi.app.br/f/c/`
    }

    return url
 };



 export async function verificar_enviados(tipo, minutos){
      
    let sql = `
    SELECT *
      FROM tabela_api_whats
      WHERE VISTO = 'N'
      AND CODIGO_CLIENTE = 45 
      AND TIPO = '${tipo}'
      AND TIMESTAMPDIFF(MINUTE, STR_TO_DATE(HORA, '%H:%i:%s'), NOW()) > ${minutos}
      ORDER BY CODIGO DESC
    `

    // console.log('sql:', sql);

    let rr 
    if(rr =  await crud_abrir_tabela_canal(sql)){
      // console.log('rr:', rr);

      if (rr.length>0){
        return rr
      }
      else{
        return false
      }
    }
    else{
      return false
    }

 };


 export async function retorna_token(ide){
    let id_user = config_whats.ID_USER
    if (id_user>0){
      token = token +'i'+id_user
      console.log('id_user .js2:', id_user);
      console.log('novo token .js2:', token);
    }
 };


 export async function EmojiparaTexto(texto) {

  // console.log('texto:', texto);
  // Divida o texto em palavras usando espaços em branco como delimitadores
  const palavras = texto.split(/\s+/);

  let achou = '';
  for (let i = 0; i < palavras.length; i++) {
    // Verificar se a palavra contém sequências percent-encoded
      try {
        // Decodificar a sequência percent-encoded dentro da palavra
        achou = encodeURIComponent(palavras[i]);
        // console.log('achou:', achou);

        // se achou ele substitui no texto
        texto = texto.replaceAll(palavras[i] , achou );


      } catch (error) {
        // console.error(`Erro ao decodificar sequência percent-encoded: ${palavras[i]}`);
        achou = palavras[i];
      }
  }

  // console.log('palavras:', palavras);

  return texto
};

export async function encontrarCaractereNoTexto(texto) {

  // console.log('texto:', texto);
  // Divida o texto em palavras usando espaços em branco como delimitadores
  const palavras = texto.split(/\s+/);

  let achou = '';
  for (let i = 0; i < palavras.length; i++) {
    // Verificar se a palavra contém sequências percent-encoded
    if (/%[0-9A-Fa-f]{2}/.test(palavras[i])) {
      try {
        // Decodificar a sequência percent-encoded dentro da palavra
        achou = decodeURIComponent(palavras[i]);
        // console.log('achou:', achou);

        // se achou ele substitui no texto
        texto = texto.replaceAll(palavras[i] , achou );


      } catch (error) {
        // console.error(`Erro ao decodificar sequência percent-encoded: ${palavras[i]}`);
        achou = palavras[i];
      }
    } else {
      // Se a palavra não contiver sequências percent-encoded, mantenha a palavra original
      achou = palavras[i];
    }
  }

  // console.log('palavras:', palavras);

  return texto
};
