<template>
<div>


  <!-- pesquisar_tuss: {{pesquisar_tuss}} <br/> -->
  <!-- {{items_somatorias}} -->
  <!-- item_menu:{{item_menu}} <br/><br/> -->
  <!-- obj_doc_selecionado:{{obj_doc_selecionado}} <br/><br/> -->
  <!-- obj_user: {{obj_user}} <br/> -->
  <!-- {{tabela_selecionada}} -->
  <!-- is_super: {{is_super}} <br/> -->
  <!-- is_doc: {{is_doc}} <br/> -->

  <!-- {{def_parcelas}} -->

  <!-- obj_dados_paciente {{obj_dados_paciente}} <br/> -->
  <!-- {{obj_dados_empresa}} -->

  <!-- {{exames_ultimos}} -->
  <!-- selected: {{selected}} <br/><br/> -->
  <!-- obj_clicado: {{obj_clicado}} <br/> -->
  
<!-- {{item_clicou_agenda}} -->
  <!-- {{is_doc}} -->
  <!-- {{obj_sadt}} -->
  <!-- {{card_titulo}} -->
  <!-- {{vai_imprimir}} -->
  <!-- <br/>{{item_edita}} -->
  <!-- {{getNomePaciente  }} -->

  <!-- {{EditaReceita}} -->
  <!-- {{items_receitas}} -->

  
  <!-- {{EditaManipulado}} -->
  <!-- {{selected_receitas}} -->
  <!-- {{selected_exames}} -->
  
  <!-- {{selecionados}} -->
  <!-- {{editedItem}}
  <br/>
  <br/>
  {{EditaReceita}}
  <br/>
  <br/>
  {{ EditaManipulado }} -->
  
  <!-- {{manipulados_ultimos}} -->

  <!-- {{EditaManipulado.MANIPULADO}} -->
  
  <!-- {{modal_salvou}} -->
  <!-- {{modal_qual}} -->


  <v-overlay :value="overlay">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>


  


  <template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_ok"
      width="222"
      persistent
    >

      <v-card>
        <v-card-title class="text-h6 grey lighten-2 justify-center">
          Concluído
        </v-card-title>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            
            @click="tabela(tabela_selecionada),dialog_ok = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

  <template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_img"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h6 white lighten-2 justify-center ">
          {{item_clicou_agenda.NOME_AGENDA}} 
        </v-card-title>

        <v-card-text>
          <v-img
            alt="user"
            :src="item_clicou_agenda.FOTO_URL"
            height="30%"
            v-if="(item_clicou_agenda.FOTO_URL)"
          >
         </v-img>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog_img = false"
          >
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>




  <!-- MENU -->
  <v-dialog
      v-model="dialog_menu_right"
      max-width="300px"
      scrollable
    >
      <v-card
        class="mx-auto"
      >
        <v-list dense>

        <!-- <v-subheader class="grey lighten-3">
          <h4 class="ml-1"> Menu </h4>
        </v-subheader> -->

        <v-list-group
            v-for="item in menu_um"
            :key="item.title"
            v-model="item.active"
            
            no-action
            @click.stop="" 
            v-if="(item.type=='group')"
        >


            <template v-slot:activator>

              <v-list-item-icon>
                <v-icon :color="item.icon_color"> {{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>



            <v-list-item
            v-for="sub in item.items"
            :key="sub.title"
            link
            @click="clicou_menu_um(sub.click)"
            >

              <v-list-item-content>
                  <v-list-item-title v-text="sub.title"></v-list-item-title>
              </v-list-item-content>
              
              
              <v-list-item-icon>
                <v-icon :color="sub.icon_color"> {{ sub.icon }}</v-icon>
              </v-list-item-icon>


            </v-list-item>
        </v-list-group>

        <v-list-item
            v-for="item in menu_um"
            :key="item.title"
            link
            @click="clicou_menu_um(item.click, item.qual)"
            v-if="item.type=='menu'"
            >


            <v-list-item-icon>
                <v-icon :color="item.icon_color"> {{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>


            </v-list-item>

        </v-list>

      </v-card>
  </v-dialog>
        

  <v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
          :fullscreen="vai_imprimir"
      >
      

      <v-card
      class=""
      width="15cm"    
      :elevation="(vai_imprimir) ? 0 : 2"
      >
      <!-- height="80%" -->


      <span v-if="(vai_imprimir)">
        <v-card-title class="mb-1 text-h5 d-flex align-center justify-center" >{{obj_dados_empresa.NOME}}</v-card-title>
        <v-divider class="mx-7"></v-divider>
      </span>

      
      <v-card-title v-if="(vai_imprimir)" class="mb-1 text-h6 d-flex align-center justify-center ">{{getNomePaciente}}</v-card-title>
      <v-card-title v-else class="mb-1 text-h6 grey lighten-4">{{getNomePaciente}}</v-card-title>

      <v-card-subtitle class="grey lighten-4 pb-2 mb-1">

          <!-- <span class="no-print mr-1 blue--text" v-text="(item_edita.CODIGO>0) ? 'Alterar ' : 'Novo '"/>   -->

          <span class="text-h5 no-print blue--text mr-2">
             {{ ((item_edita.CODIGO>0) ? 'Alterar ': 'Novo ') }}  
          </span>

          <span class="text-h6 ml-n2">
              {{ (card_titulo !='Registros') ? card_titulo : '' }}  
          </span>

          <span class="no-print" v-if="(item_edita.CODIGO>0)">
            {{item_edita.CODIGO}}
          </span>
          

      </v-card-subtitle>

      <!-- <v-divider class="mx-5"></v-divider> -->

      



          <!-- passo 4 monto os campos editaveis -->
          <!-- :readonly = "readonly" -->
              <v-card-subtitle :class="['pa-1', (def_parcelas.length>0) ? 'grey lighten-5':'white']">

                <!-- <v-container> -->
                <v-form
                  ref="form"
                  v-model="valido"
                  >
                  <v-container>


                      <v-row>
                          <v-col
                              :class="[item.class_col]"
                              v-for="(item, i) in items_editaveis"
                              :key="i"
                              :cols="(isMobile) ? 12 : item.cols"
                              v-if="parseInt(item.cols)>0"   
                          >
                          <!-- :cols="item.cols" -->


                          <v-span v-if="(item.tag == 'v-span')">
                            {{  item_edita[item.value] }}
                          </v-span>  
                        

                          <v-card class="grey lighten-5"  elevation="4"
                             v-if="item.tag == 'v-editor'"
                            >
                              <v-card-text>
                                  <editor-content
                                    :editor="editor"
                                    class="white lighten-5"
                                    :style="[{ minHeight: altura_meio + 'cm'}]"
                                    v-if="item.tag == 'v-editor'"
                                  />
                              </v-card-text>
                            </v-card>


                            <p v-if="item.tag == 'p'" :class="[item.class]"
                             v-html="item_edita[item.value]">
                            </p>

                            <span v-if="(item.tag == 'v-img')&&(item_edita[item.value]?.length >10)" style="display: block; text-align: center;">
                              <!-- caso esteja preenchido mostra..caso contrario ele mostra o botao para upload -->
                              <v-divider class="mx-10 my-4"/>
                              <img
                                v-if="(item.tag == 'v-img')&&(item_edita[item.value].length >10)"
                                alt="user"
                                :src="item_edita[item.value]"
                                :width="[item.width]"
                              >
                              </img>
                            </span>


                              <!-- <v-chip
                               v-if="(item.tag == 'v-chip-value')" :color="item.color" @click="clicou_em(item.click)" 
                               :class=[item.class]
                              >
                                {{ item_edita[item.value] }}
                              </v-chip> -->


                              <span v-if="(item.tag == 'v-chip-value')">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                    v-if="(item.tag == 'v-chip-value')" :color="item.color" @click="clicou_em(item.click)"
                                    :class=[item.class]
                                    v-bind="attrs" v-on="on"
                                    >
                                      {{ item_edita[item.value] }}
                                    </v-chip>
                                  </template>
                                  <span> {{item.hint}} </span>
                                </v-tooltip>
                              </span>


                              <span v-if="(item.tag == 'v-chip')">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                    v-if="(item.tag == 'v-chip')" :color="item.color" @click="clicou_em(item.click)"
                                    :class=[item.class]
                                    v-bind="attrs" v-on="on"
                                    >
                                      {{ item.text }}
                                    </v-chip>
                                  </template>
                                  <span> {{item.hint}} </span>
                                </v-tooltip>
                              </span>


                              <span  v-if="(item.tag == 'v-checkbox')">
                                <v-checkbox 
                                      v-model="item_edita[item.value]"
                                      :label="(item_edita[item.value] ? item.text : item.text_2)"
                                      dense
                                      :class="[ (item_edita[item.class_if]) ? item.class_tag :  item.class_tag2]"
                                      :style= "[item.style_tag]"
                                      @click="clicou_em(item.click)"
                                      hide-details="true"
                                      v-if="(!item.if) || (item_edita[item.if])"
                                  >
                                  
                                  <!-- style="margin-left: -10px;display: none " -->
                                  <!-- :style= "[item.style_tag]" -->
                                  
                                  <!-- v-if="( (item_edita.hasOwnProperty(item.if) && item_edita[item.if]) || (item.else && !item_edita[item.else]) )" -->
                                      <!-- <template v-slot:label>
                                        <span class="fs-8">{{ item.text }}??</span>
                                      </template> -->
                                  </v-checkbox>
                                </span>


                              <span v-if="item.tag == 'v-text'">
                                <v-text-field
                                    dense
                                    v-model="item_edita[item.value]"
                                    :label="item.rule == 'S' ? item.text+' *': item.text"
                                    :type="item.type"
                                    v-if="(!item.if) || (item_edita[item.if])"
                                    @keyup="meu_keyup($event, item.value, item.upper), clicou_em(item.click)"
                                    :rules="item.rule == 'S' ? notnullRules: noRules"
                                    v-mask = "getVmask(item.vmask)"
                                    :class="[item.class + item.class_tag]"
                                >
                              </v-text-field>
                              </span>  

                              <!-- v-if="item.tag == 'v-text'" -->
                              <!-- :autofocus="i==1" -->


                              <v-combobox
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-autocomplete'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"
                                  :class="[item.class_tag]"
                              ></v-combobox>
                              
                              <v-select
                                  :class="[item.class]"
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-select'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"

                                  item-value='CODIGO'
                                  item-text='NOME'
                                  :clearable = "item.tag_clear == 'S'"
                                  :persistent-hint="item.cols>2"
                                  :hint="item.hint"
                                  @change="clicou_em(item.click)"
                              >
                              
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                <template v-slot:label>
                                  <v-tooltip bottom v-if="item.hint">
                                      <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                        > 
                                          <span :class="item.class">
                                            {{ item.text }} 
                                            <!-- {{ item_edita[item.value] }} -->
                                          </span>
                                        </span>

                                      </template>
                                      <span> {{ item.hint }} </span>
                                    </v-tooltip>
                                </template>
    
                            </v-select>


                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                              <v-textarea
                                v-model="item_edita[item.value]"
                                :label="item.text"
                                v-if="item.tag == 'v-textarea'"
                                :hint="item.hint"
                                :persistent-hint="item.cols>2"
                                dense
                                :rows="(vai_imprimir) ? '35' : item.rows"
                                :autofocus="i==2"
                                :rules="item.rule == 'S' ? notnullRules: noRules"
                                :outlined="(vai_imprimir) ? false : true"
                              >

                                <!-- <template v-slot:label>
                                  <span class=""> {{(vai_imprimir) ? '' : item.text }} </span>
                                </template>   -->
                              </v-textarea>




                              <!-- INICIO DIV IMAGEM -->
                              <div class=""
                                v-if="item.tag == 'img'"
                              >
                              
                              <v-row dense class="grey lighten-5">
                                <v-col cols="8" class="">
                                  <span class="pb-2">Logo</span>
                                    <v-img
                                    v-if="urlLogo"
                                    max-height="90"
                                    max-width="170"
                                    :src="urlLogo"
                                    contain
                                  >
                                  </v-img>
                                </v-col>
                                
                                <v-col class="mt-5">
                                  <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p>
                                  <!-- <p class="primary--text"><label for="file_upload" style="cursor: pointer;">Alterar Logo</label></p> -->
                                  <!-- <p>Remover</p> -->
                                  <v-btn text x-small color="red" for="file_upload"
                                    @click="item_edita[item.value] = '', urlLogo=''"
                                  >
                                    Remover
                                  </v-btn>
                                </v-col>


                                </v-row>
                                <!-- oculto -->
                                <v-row dense class="grey lighten-3">
                                  <v-col>
                                    <!-- necessario para fazer upload logo -->
                                    <v-file-input
                                            v-model="files_upload"
                                            accept="image/*"
                                            label="Logomarca"
                                            @change="upload_logo(files_upload,'DSi','logo',item.value)"
                                            style="display: none"
                                            id="file_upload"
                                          ></v-file-input>
                                    <!-- necessario para fazer upload logo -->
                                  </v-col>
                                </v-row>
                              </div>
                              <!-- FINAL DIV IMAGEM -->


                              
                              
                          </v-col>
                      </v-row>
                  </v-container>
                </v-form>


                <!-- <span v-if="(vai_imprimir)">
                  <span class="d-flex align-center justify-center black--text fs-10" > {{ LSendereco }} - {{obj_dados_empresa.FONE1}}</span>
                </span> -->
              <!-- </v-container> -->
              </v-card-subtitle>


              <v-card-text class="pa-0 pl-0">
                      <v-card-text>
                          <!-- <v-container> -->
                              <v-row class="" dense
                              v-for="(item, i) in def_parcelas"
                              :key="i"
                              >
                              <!-- {{ def_parcelas }} -->
                              <!-- {{ item }}                      -->
                              <!-- {{edita_parcelas}} -->

                                     
                                  <span  class="mt-3">
                                      {{ parseInt(i+1)}}ª 
                                      <!-- {{ 1-inicio + parseInt(i)}}ª  -->
                                  </span>   

                                  <v-col
                                  cols="6"
                                  md="2"
                                  >
                                      <v-text-field
                                          v-model="item.VALOR_UM"
                                          type=""
                                          label="Valor"
                                          prefix="R$"
                                          dense
                                          @keyup="item.VALOR_UM = moeda($event), digitou_entrada(item)"
                                      >
                                      <!-- :disabled="def_parcelas.length==i+ parseInt(1)"     -->
                                      

                                      </v-text-field>
                                  </v-col>

                                  &nbsp
                                  &nbsp


                                  <v-col
                                  cols="6"
                                  md="2"
                                  >

                                      <v-select
                                      v-model="item.FORMA_PAGAMENTO"
                                      label="Forma..."
                                      :items="items_tabela_formas"
                                      item-value='DESCRICAO'
                                      item-text='DESCRICAO'
                                      :return-object="false"
                                      dense
                                      :clearable = "false"
                                      >
                                      
                                      <template slot='selection' slot-scope='{ item }'>
                                          <span class="fs-12"> {{ (item.DESCRICAO)? item.DESCRICAO : item }}</span>
                                      </template>
                                      </v-select>

                                  </v-col>

                                  &nbsp
                                  &nbsp

                                  <v-col
                                  cols="6"
                                  md="2"
                                  >
                                  <v-text-field
                                      v-model="item.DATA"
                                      type="date"
                                      label="Vencimento"
                                      dense
                                  >
                                  </v-text-field>
                                  </v-col>

                                  &nbsp
                                  &nbsp

                                  <v-col
                                  cols="6"
                                  md="2"
                                  >
                                  <v-text-field
                                      v-model="item.DATA_EFETUADO"
                                      type="date"
                                      label="Efetuado"
                                      dense
                                  >
                                  </v-text-field>
                                  </v-col>

                                  &nbsp
                                  &nbsp


                                  <v-col
                                  cols="3"
                                  >
                                  <v-text-field
                                      v-model="item.CATEGORIA"
                                      type=""
                                      label="Categoria"
                                      dense
                                      class="fs-12"
                                      :rules="notnullRules"
                                      @keyup="item.CATEGORIA=item.CATEGORIA.toUpperCase()"
                                  >
                                  </v-text-field>
                                  </v-col>

                              </v-row>
                          <!-- </v-container> -->
                      </v-card-text>
              </v-card-text>


              <v-divider class="mx-10" v-if="(!vai_imprimir)"></v-divider>

              <span v-if="(vai_imprimir)">
                 <span class="d-flex align-center justify-center black--text fs-10" > {{ LSendereco }} - {{obj_dados_empresa.FONE1}}</span>
              </span>
              

      <v-card-actions class="no-print">
          <v-btn
              x-small
              color="red"
              text
              @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false"
              v-if="(permissoesUsuarios('excluir'))"
          >
            Excluir
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              color="blue"
              text
              @click="imprimir_pagina()"
              class="mr-5"
              small
          >
            Imprimir.
          </v-btn>

          <v-btn
              color="red"
              text
              @click="dialog_tabela = false"
          >
            Cancelar
          </v-btn>
          
          &nbsp

          <v-btn
              :disabled="!valido"
              color="primary"
              @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela')"
          >
            Salvar
          </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>



  <!-- div principal -->
  <div
    @click="meu_dsi_timer"
    v-if="na_tela"
    class="mt-3"
    id="div_principal"
  >

    <template>

  <v-row class="pl-1 ">


    <v-col
      class=""
      cols="12"
      md="12"
    >

      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        top
        :color="snack_color"  
      >
        <center>
          {{ snack_text }}
        </center>

      </v-snackbar>

  <!-- PAINEL DADOS PACIENTE -->
      <v-card
          tile
          elevation="3"
          class=""
      >
        
        <v-container class="pt-0 ">
          <v-row class="">

            <v-col
            class=""
            cols="12"
            md="12"
            id="painel_principal"
            >
                      

                <!-- <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  column
                > -->
               <!-- is_doc: {{ this.is_doc }}
               - is_super: {{ this.is_super }}
               - superA: {{ this.superA }} -->
              

                   

                    <v-avatar  class="py-1 mr-2">
                      <img
                        alt=""
                        :src="item_clicou_agenda.FOTO_URL"
                        v-if="(tem_foto)"
                        @click="dialog_img=true"  
                        class="hand"
                      >
                      

                      <v-icon x-large v-else class="mt-n1">
                        mdi-account-circle
                      </v-icon>
                    </v-avatar>



                    <v-btn
                        color="red lighten-3"
                        @click="volta_agenda()"
                        small
                        class="mb-1"
                    >
                        Voltar
                    </v-btn>

                    &nbsp 

                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                          color="primary" small
                          @click="abrir_modal_cadastro(item_clicou_agenda.CODIGOCLIENTE)"
                          v-bind="attrs" v-on="on"
                      >
                        {{ getNomePaciente }}
                        <v-icon small class="pl-1" > mdi-account </v-icon>
                      </v-chip>

                  </template>
                    <span> Abrir Cadastro em uma nova Janela </span>
                  </v-tooltip>

                    
                    &nbsp

                    
                    <v-chip
                        color="grey"
                        dark
                        small
                    >
                    {{ getHora }}
                    </v-chip>

                    &nbsp

                    <v-chip
                        color="grey" small
                        dark
                    >
                    {{ getConvenio }}
                    </v-chip>

                    &nbsp

                    <v-chip
                        color="grey" small
                        dark
                    >
                    {{ getProcedimento }}
                    </v-chip>

                    &nbsp

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    color="grey" small
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="getNascimento"
                                >
                                {{ getNascimento }}
                                </v-chip>
                    </template>
                    <span> Nascimento </span>
                  </v-tooltip>

                    &nbsp

                    <v-chip
                        color="grey" small
                        dark
                        v-if="getNascimento"
                    >
                    <!-- {{ getIdade }} -->
                      {{ calcula_idade(getNascimento, 0) }}
                    </v-chip>
                    &nbsp

                    <v-chip
                        color="grey" small
                        dark
                        v-if="getProfissao"
                    >
                    {{ getProfissao }}
                    </v-chip>

                  <!-- &nbsp
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    color="grey"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="getCPF"
                                >
                                {{ getCPF }}
                                </v-chip>
                    </template>
                    <span> CPF</span>
                  </v-tooltip> -->


                  <!-- &nbsp
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    color="grey"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="getRG"
                                >
                                {{ getRG }}
                                </v-chip>
                    </template>
                    <span> RG</span>
                  </v-tooltip> -->

                    &nbsp

                    <v-chip
                        color="grey" small
                        dark
                        v-if="getEstadoCivil"
                    >
                    {{ getEstadoCivil }}
                    </v-chip>

                    &nbsp
                    <v-chip
                        color="pink lighten-2" small
                        dark
                        v-if="(nome_mae && is_menor)"
                    >
                    Mãe: 
                    {{ nome_mae }}
                    </v-chip>
                    
                    &nbsp
                    <v-chip
                        color="light-blue darken-1" small
                        dark
                        v-if="(nome_pai && is_menor)"
                    >
                    Pai: 
                    {{ nome_pai }}
                    </v-chip>

                    &nbsp


                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                    color="success" small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="maps(getEndereco)"
                                >
                                  <v-icon             
                                      class="mr-2"
                                      size="16"
                                      
                                  >
                                      mdi-map-marker-radius 
                                      <!-- mdi-google-maps  -->
                                  </v-icon> 
                                  {{ getEndereco }}
                                </v-chip>
                    </template>
                    <span> clique para abrir mapa</span>
                  </v-tooltip>

                    &nbsp


                    <v-chip
                      v-if="TESTE==true"
                    >
                    DSi
                      <span class="menor"> &nbsp Timer: {{ timer | minutesAndSeconds }} </span>
                      <span
                        v-if="boo_alterou==true"
                        class="menor"> &nbsp boo_alterou 
                      </span>
                      
                      <span
                        v-if="boo_atendeu==true"
                        class="menor"> &nbsp boo_atendeu 
                      </span>
                      <span class="menor"> &nbsp idConduta: {{ idConduta }} </span>
                      <span class="menor"> &nbsp getCodigoConsulta: {{ getCodigoConsulta }} </span>
                      <span class="menor"> &nbsp convenio: {{ getCodigoConvenio }} </span>
                      <span class="menor"> &nbsp getCodigoDoc: {{ getCodigoDoc }} </span>
                      <span class="menor"> &nbsp getCodigoPaciente: {{ getCodigoPaciente }} </span>                      
                      <span class="menor"> &nbsp Hoje: {{ hoje }} </span>
                      <span class="menor"> &nbsp Today: {{ today }} </span>
                      <span class="menor"> &nbsp condutaItem.DATA: {{ condutaItem.DATA }} </span>
                      <span class="menor"> &nbsp getAtendido: {{ getAtendido }} </span>
                      <span class="menor"> &nbsp getDataHora: {{ data_hora }} </span>
                      <span class="menor"> &nbsp crud_table: {{ crud_table }} </span>

                    </v-chip>

                    <v-chip
                      v-if="TESTE==true"
                    >
                    DSi
                      <span class="menor"> &nbsp getNomeDoc: {{ getNomeDoc }} </span>
                      <span class="menor"> &nbsp getCRMdoc: {{ getCRMdoc }} </span>
                      <span class="menor"> &nbsp getConselho: {{ getConselho }} </span>

                    </v-chip>

                <!-- </v-chip-group> -->

            </v-col>
          </v-row>
        </v-container>
      </v-card>


  <!-- PAINEL INFERIOR -->
      <div>
        <v-row class="">
          <!-- PAINEL ESQUERDO -->
          <v-col
            class="mt-1"
            cols="12"
            md="10"
            >
            <v-card :height="(isMobile) ? '' : retorna_altura(140)">
                <v-tabs
                    v-model="tab_1"
                    background-color="#FAFAFA"
                    show-arrows    
                    height="27"
                    color=""
                >

                    <v-tabs-slider></v-tabs-slider>

                    <v-tab href="#TABevolucao"
                    @click="crud_table='procedimento'"
                    v-if="(getConselho!=='CRO') && ((is_doc)||(is_super))"
                    >
                        <v-icon 
                        dense
                        small
                        >
                        mdi-text-box-multiple 
                        </v-icon>
                          
                        <div
                          v-if="mobile==false"
                        >
                          
                            <span v-if="DEBUG==true">
                              &nbsp evoluçãox
                            </span>
                            <span v-else>
                              &nbsp evolução
                            </span>

                        </div>

                    </v-tab>

                    
                    <v-tab href="#TABexames"
                    @click="crud_table='exames'"
                    v-if="getConselho!=='CRO'"
                    >
                            <v-icon dense small>
                            mdi-microscope 
                            </v-icon>
                            
                            <div
                            v-if="mobile==false"
                            >
                              
                            <span v-if="DEBUG==true">
                              &nbsp examesx
                            </span>
                            <span v-else>
                              &nbsp exames
                            </span>

                              <span
                                class="count_item"
                              >
                              {{ count_e }}
                              </span>

                            </div>
                    </v-tab>

                    <v-tab href="#TABreceitas"
                    @click="crud_table='receitas'"
                    v-if="getConselho!=='CRO'"
                    >
                            <v-icon dense small>
                            mdi-pill 
                            </v-icon>

                            <div
                            v-if="mobile==false"
                            >

                            <span v-if="DEBUG==true">
                              &nbsp Receitasx
                            </span>
                            <span v-else>
                              &nbsp Receitas
                            </span>

                              <span
                                class="count_item"
                              >
                              {{ count_r }}
                              </span>
                            </div>
                    </v-tab>
                    
                    <v-tab href="#TABtabelas" >
                      <!-- v-if="getConselho=='CRO'" -->
                    
                            <v-icon dense small>
                              <!-- mdi-list-box   -->
                              mdi-format-list-bulleted 
                            </v-icon>

                            <div
                            v-if="mobile==false"
                            >
                            <span>
                              &nbsp Atendimento
                            </span>

                            <span
                                class="count_item"
                              >
                              {{ count_tabelas }}
                              </span>
                            

                            </div>
                    </v-tab>

                    <v-tab href="#TABimprimir">
                            <v-icon dense small>
                            mdi-printer 
                            </v-icon>

                            <div
                            v-if="mobile==false"
                            >

                            <span v-if="DEBUG==true">
                              &nbsp imprimirxy
                            </span>
                            <span v-else>
                              &nbsp imprimir
                            </span>

                            </div>
                    </v-tab>

                </v-tabs>

                <v-tabs-items v-model="tab_1">
                    <v-tab-item :key="1" value="TABevolucao" class="">

                        <v-row class="">
                            <v-col
                            class=""
                            cols="12"
                            md="6"
                            id="painel_esquerdo"
                            
                            >
                                <v-card 
                                flat
                                elevation="5"
                                class="mb-2"
                                v-if="(getConselho!=='CRO') && ((is_doc)||(is_super))"
                                >
                                

                                    <!-- <v-card-title>
                                    <span> {{ codigoConsulta }} </span> 
                                    </v-card-title> -->

                                    <v-card-text class="pb-0 mb-1">
                                      <v-form ref="form">
                                        <v-container class="pa-2">
                                            <v-row>

                                                <v-col
                                                    cols="12"
                                                    md="12"
                                                >

                                                <v-textarea text-narrow id="input-7-2"
                                                    dense
                                                    background-color="#FAFAFA"
                                                    v-model="condutaItem.DESCRICAO"
                                                    label="EVOLUÇÃO"
                                                    hint=""
                                                    :height="(isMobile) ? '500' : retorna_altura(275)"
                                                    filled
                                                    
                                                    
                                                    @keyup="alterou_conduta"
                                                    :readonly = "readonlyBor"
                                                    :rules="notnullRules"
                                                >
                                                <!-- v-html="condutaItem.DESCRICAO" -->
                                                
                                                
                                                  <template v-slot:label>
                                                    <h3>EVOLUÇÃO</h3>
                                                  </template>  

                                                </v-textarea>

                                                <!-- <p v-html="condutaItem.DESCRICAO"></p> -->

                                                </v-col>

                                            </v-row>
                                        </v-container>
                                      </v-form>
                                    </v-card-text>

                                    <v-card-actions class="">
                                    <v-spacer></v-spacer>
                                  

                                    <v-btn
                                      color="blue"
                                      text
                                      @click="abrir_impressao('CONDUTA', condutaItem)"
                                      class="mr-5"
                                      small
                                  >
                                    Imprimir
                                  </v-btn>


                                    <v-btn
                                        color="red lighten-3"
                                        @click="volta_agenda()"
                                        small
                                    >
                                        Voltar
                                    </v-btn>

                                    <v-divider
                                    class="mx-4"
                                    vertical
                                    inset
                                    ></v-divider>
        
                                    <!-- <v-btn
                                        color="primary"
                                        @click="save(idConduta)"
                                        v-if="readonly==false"
                                    >
                                        Salvar
                                    </v-btn> -->

                                    <v-btn
                                        small
                                        color="success"
                                        @click="save_conduta(idConduta); pergunta=true"
                                        v-if="readonlyBor==false"
                                    >
                                        Finalizar
                                    </v-btn>
                                    
                                    </v-card-actions>


                                </v-card>
                            </v-col>
                      
                              <v-divider
                                inset
                                vertical
                              ></v-divider>

                            <v-col
                            class="mt-1 mb-1 "
                            id="painel_direito"
                            cols="11"
                            md="6"
                            >
                              <v-card elevation="5" class="" >
                                  <v-tabs
                                      v-model="tab_2"
                                      background-color="#FAFAFA"
                                      show-arrows    
                                      height="27"
                                      color=""
                                      mobile-breakpoint
                                  >
                                      <v-tabs-slider></v-tabs-slider>

                                      <v-tab href="#vazio">
                                              <v-icon small>
                                              mdi-arrow-left  
                                              </v-icon>

                                              <div>
                                                <!-- &nbsp Receitas -->
                                              </div>
                                      </v-tab>
                                      
                                      <v-tab href="#historico" @click="monta_historico" v-if="((is_doc)||(is_super))">
                                          <v-icon small>
                                          mdi-text-box-multiple 
                                          </v-icon>
                                            
                                          <div>
                                              <span v-if="DEBUG==true">
                                                &nbsp historicox
                                              </span>
                                              <span v-else>
                                                &nbsp historico
                                              </span>

                                            <span
                                              class="count_item"
                                            >
                                            {{ count_h }}
                                            </span>
                                          </div>
                                      </v-tab>
                                      
                                      <v-tab  href="#obs">
                                          <v-icon small>
                                          mdi-note 
                                          </v-icon>
                                            
                                          <div>
                                              <span v-if="DEBUG==true">
                                                &nbsp Notasx
                                              </span>
                                              <span v-else>
                                                &nbsp Notas
                                              </span>

                                            <span
                                              class="count_item"
                                            >
                                            {{ count_notas }}
                                            </span>
                                          </div>
                                      </v-tab>

                                      <v-tab href="#tab_calculos">
                                          <v-icon small>
                                          mdi-chart-box  
                                          </v-icon>
                                            
                                          <div>
                                              <span v-if="DEBUG==true">
                                                &nbsp calculosx
                                              </span>
                                              <span v-else>
                                                &nbsp calculos
                                              </span>

                                            <span
                                              class="count_item"
                                            >
                                            {{ count_imc + count_gestante }}
                                            </span>
                                          </div>
                                      </v-tab>
                                  </v-tabs>

                                  <v-tabs-items v-model="tab_2" class="">
                                      <v-tab-item :key="1" value="historico" class="">
                                        <v-card
                                            class="overflow-y-auto grey lighten-2 " 
                                            :height="(isMobile) ? '460' : retorna_altura(200)"
                                        >
                                        
                                        <!-- GUIA HISTORICO MEDICO HISTORICO DE EXAMES HISTORICO DE MEDICAMENTOS -->
                                          <v-card
                                            class="mx-auto mt-2"
                                            v-for="(value,  index ) in obj_historico2"
                                          >
                                            <!-- {{ value }} -->
                                            <!-- {{ value['receitas'] }} -->
                                            <v-card-text>
                                              <div style="display: inline" class="text-subtitle-2 blue--text">
                                                <v-chip
                                                  color="grey"
                                                  class="white--text"
                                                  small
                                                >
                                                  <v-icon small color="white">
                                                  <!-- mdi-text-box-multiple  -->
                                                  mdi-calendar-month 
                                                  </v-icon>
                                                  &nbsp
                                                  {{ value['X_DATA']}}
                                                </v-chip>

                                                <h5 style="display: inline" class="black--text"> - {{ value['PROCEDIMENTO']}}</h5>
                                              </div>
                                              <br/>

                                                <!-- <v-chip
                                                  active-class="deep-purple accent-4 white--text"        
                                                >{{ value['DOUTOR']}}</v-chip> -->

                                                <h4 style="display: inline" class="black--text "> &nbsp {{ value['DOUTOR']}}</h4>
                                                - {{ value['ESPECIALIDADE']}}         
                                            <br/>  
                                            <br/>
                                            <v-divider class="mx-0"></v-divider>

                                                <v-textarea
                                                  label=""
                                                  v-model="value['DESCRICAO']"
                                                  :auto-grow=true
                                                  :disabled=false
                                                  readonly          
                                                  class=""
                                                >
                                                </v-textarea>

                                            </v-card-text>


                                            <v-card-text
                                            v-if="value['receitas'].length >0"
                                            class=""
                                            
                                            >
                                              <div class="text-subtitle-2 black--text blue--text">
                                                <v-chip
                                                color="grey"
                                                class="white--text"
                                                small
                                                >
                                                <v-icon small color="white">
                                                mdi-pill 
                                                </v-icon>
                                                &nbsp

                                                  RECEITAS.
                                                </v-chip>
                                            



                                              <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon  
                                                    color="primary"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    size="22"
                                                    class="ml-1 pb-1"
                                                    >
                                                    mdi-dots-vertical
                                                    <!-- mdi-dots-horizontal -->
                                                    <!-- mdi-dots-grid  -->
                                                    <!-- mdi-menu -->
                                                    </v-icon>
                                                </template>
                                                <v-list>
                                                  <v-list-item
                                                    dense
                                                    @click="copiaAllreceita(value['receitas'], 'RECEITAS')"
                                                  >
                                                    <v-list-item-title
                                                    > Copiar Todos </v-list-item-title>
                                                  </v-list-item>
                                                </v-list>
                                              </v-menu>

                                              </div>

                                                <div
                                                  v-for="(value,  index ) in value['receitas']"
                                                >


                                                  <!-- <h4 style="display: inline" class="black--text ">{{ index+1 }} - &nbsp {{ value['MEDICAMENTO']}}</h4> -->
                                                  <p style="display: inline" class="black--text ">{{ index+1 }} - &nbsp </p>

                                                  <v-menu offset-y>
                                                    <template v-slot:activator="{ on, attrs }">

                                                    <h4 style="display: inline" class="primary--text "
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                      {{ value['MEDICAMENTO']}}
                                                    </h4>
                                                    </template>
                                                    <v-list>
                                                      <v-list-item
                                                        dense
                                                        @click="copiaUMreceita(value, 'RECEITAS')"
                                                      >
                                                        <v-list-item-title
                                                        > Copiar  </v-list-item-title>
                                                      </v-list-item>
                                                    </v-list>
                                                  </v-menu>



                                                  <v-tooltip bottom v-if="(value['MANIPULADO'].length>5)">
                                                    <template v-slot:activator="{ on, attrs }">
                                                      <v-chip
                                                          v-bind="attrs"
                                                          v-on="on"
                                                          small
                                                          class="mb-1"
                                                      > 
                                                      MANIPULADO.
                                                      </v-chip>

                                                    </template>
                                                    <span v-html="quebra_linha(value['MANIPULADO'])"> 
                                                    </span>
                                                  </v-tooltip>

                                                  <p v-else style="display: inline" class="text-caption black--text "> &nbsp - &nbsp
                                                     <span class="font-weight-bold"> {{ value['DOSE']}} - </span>
                                                     {{ value['POSOLOGIA']}}
                                                  </p>

                                                </div>

                                            </v-card-text>


                                            <v-divider class="mx-8"></v-divider>

                                            <v-card-text
                                            v-if="value['exames'].length >0"
                                            >
                                              <div class="text-subtitle-2 blue--text">
                                                <v-chip
                                                color="grey"
                                                class="white--text"
                                                small
                                                >
                                                <v-icon small color="white">
                                                mdi-microscope
                                                </v-icon>
                                                &nbsp
                                                EXAMES.
                                                </v-chip>

                                              <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon  
                                                    color="primary"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    size="22"
                                                    class="ml-1 pb-1"
                                                    >
                                                    mdi-dots-vertical
                                                    </v-icon>
                                                </template>
                                                <v-list>
                                                  <v-list-item
                                                    dense
                                                    @click="copiaAllreceita(value['exames'], 'EXAMES')"
                                                  >
                                                    <v-list-item-title
                                                    > Copiar Todos </v-list-item-title>
                                                  </v-list-item>
                                                </v-list>
                                              </v-menu>

                                              </div>

                                              <div
                                              v-for="(value,  index ) in value['exames']"
                                              >
                                          
                                            <p style="display: inline" class="black--text "> {{ index+1 }} - &nbsp </p>

                                                  <v-menu offset-y>
                                                    <template v-slot:activator="{ on, attrs }">

                                                    <h4 style="display: inline" class="primary--text "
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                      {{ value['EXAME']}}
                                                    </h4>
                                                    </template>
                                                    <v-list>
                                                      <v-list-item
                                                        dense
                                                        @click="copiaUMreceita(value, 'EXAMES')"
                                                      >
                                                        <v-list-item-title
                                                        > Copiar  </v-list-item-title>
                                                      </v-list-item>
                                                    </v-list>
                                                  </v-menu>

                                            <p style="display: inline" class="text-caption black--text "> &nbsp - &nbsp {{ value['COD96']}}</p>
                                                  <!-- <v-chip>
                                                  {{ value['COD96'] }}
                                                  </v-chip>

                                                  &nbsp
                                                  <v-chip>
                                                    {{ value['EXAME'] }}
                                                  </v-chip> -->
                                              </div>
                                            </v-card-text>

                                          </v-card>

                                        </v-card>
                                      </v-tab-item>

                                      <v-tab-item :key="2" value="vazio">
    
                                      </v-tab-item>
                                      
                                      <v-tab-item :key="3" value="obs">
                                          <v-card 
                                          flat
                                          elevation="5"
                                          class="mt-1"
                                          >

                                              <v-card-text>
                                              <v-form>
                                              <v-container>
                                                  <v-row>

                                                      <v-col
                                                          cols="12"
                                                          md="12"
                                                      >


                                                      <!-- espera:
                                                      {{espera}}
                                                      ----------
                                                      {{ res_notas }} -->
                                                      <v-textarea
                                                          background-color="#FAFAFA"
                                                          v-model="res_notas.DESCRICAO"
                                                          label="NOTAS"
                                                          hint=""

                                                          :height="(isMobile) ? '400' : retorna_altura(260)"

                                                          hide-details=""
                                                          filled
                                                          :auto-grow=false
                                                          @keyup="timerNotas"
                                                          textarea-line-height="1.2rem"
                                                          :readonly="espera_notas"
                                                      >
                                                      
                                                        <template v-slot:label>
                                                        <h3>NOTAS</h3>
                                                        </template> 

                                                      </v-textarea>

                                                      </v-col>

                                                  </v-row>
                                              </v-container>
                                              </v-form>
                                              </v-card-text>

                                          </v-card>
                                      </v-tab-item>


                                    <v-tab-item :key="4" value="tab_calculos">
                                          <v-card 
                                          flat
                                          elevation="5"
                                          class="my-1"
                                          >
                                            


                              <v-expansion-panels inset>

                                <v-expansion-panel
                                  key="1"
                                  class=""
                                >
                                  <!-- <v-expansion-panel-header>Cálculo de IMC  <h3> &nbsp {{ imc.imc }}  - {{ imc.display }}</h3> </v-expansion-panel-header> -->
                                  <v-expansion-panel-header class="">
                                    <!-- Cálculo de IMC  <h3> &nbsp {{ imc.imc }}  -  <span  :style="{ color: 'red' }"> {{ imc.display }}</span> </h3>   -->
                                    {{ count_imc  }} : Cálculo de IMC
                                    <h3> &nbsp &nbsp {{ edita_imc.IMC }}  -  <span  :style="{ color: edita_imc.X_COR }"> {{ edita_imc.RESULTPESO }}</span> </h3>  

                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>


                                      <v-row class="my-1"
                                      
                                      >
                                      <!-- v-if="novo_imc==true" -->

                                          <v-btn
                                            color="primary"
                                            x-small
                                            @click="adicionar_imc()"
                                          >
                                          <!-- @click="salvar('edita_imc','CODIGO','DADOS_ADICIONAIS')" -->
                                          <!-- edita_imc.DATA -->
                                          
                                          <!-- v-if="edita_imc.imc > 0" -->
                                            novo
                                          </v-btn>

                                      </v-row>



                          <!-- <v-card class="blue"> -->
                                      <v-row class="my-1 grey lighten-5"
                                      v-if="novo_imc==false"
                                      >
                                        <v-col class="" cols="2">
                                          <v-text-field
                                            v-model="edita_imc.PESO"
                                            label="Peso Kg"
                                            type="number"
                                            hint="ex: 79 kg"
                                            
                                          ></v-text-field>
                                        </v-col>

                                        <v-col class="" cols="2">
                                          <v-text-field
                                            v-model="edita_imc.ALTURA"
                                            label="Altura mts"
                                            type="number"
                                            hint="ex: 1,72"
                                            :outlined = "false"
                                          ></v-text-field>
                                        </v-col>
                                        
                                        <v-col class="" cols="3" v-if="0>1">
                                        <v-btn
                                            color="primary"
                                            @click="calcular_imc(edita_imc.PESO, edita_imc.ALTURA)"
                                            x-small
                                            class="mb-1"
                                            v-if=" (edita_imc.PESO >0) && (edita_imc.ALTURA > 0) "
                                          >
                                            calcular
                                          </v-btn>
                                        </v-col>

                                        <v-col class="" cols="4">
                                              <v-text-field
                                                v-model="edita_imc.DATA"
                                                label="Data"
                                                type="date"
                                                :rules="notnullRules"
                                              >
                                              </v-text-field>
                                        </v-col>

                                        <v-col class="" cols="2">
                                          <v-text-field
                                            v-model="edita_imc.PC"
                                            label="PC"
                                            type="number"
                                            :outlined = "false"
                                          ></v-text-field>
                                        </v-col>

                                        <v-col class="" cols="2">
                                          <v-btn
                                            color="primary"
                                            x-small
                                            @click="salvar_imc"
                                            v-if="edita_imc.IMC > 0"
                                          >
                                        
                                            Salvar
                                          </v-btn>
                                        </v-col>

                                      </v-row>  
                          <!-- </v-card> -->

                                      <v-row 
                                      class="mt-0"
                                      v-if="edita_imc.IMC > 0"
                                      >
                                        <v-col class="" cols="12" >



                                              <v-chip
                                                  color="blue lighten-4"
                                              >
                                                  Peso Mínimo:
                                                <b>
                                                  {{ edita_imc.PESOMINIMO }}
                                                </b>
                                              </v-chip>
                                              &nbsp 
                                              <v-chip
                                                  color="blue lighten-3"
                                              >
                                                  Peso Máximo:
                                                <b>
                                                  {{ edita_imc.PESOMAX }}
                                                </b>
                                              </v-chip>
                          <v-chip-group>
                          </v-chip-group>


                                        </v-col>


                                      </v-row> 


                                      <v-row>
                                        <v-col class="pa-0" cols="12">

                                        <v-card 
                                          width=""
                                        >
                                          <v-data-table
                                            dense
                                            :headers="headers_imc"
                                            :items="items_imc"
                                            class="elevation-1"
                                            disable-sort
                                            :mobile-breakpoint="55"
                                            hide-default-footer
                                          >

                                        <template v-slot:item...="{ item }">

                                          <v-icon
                                          small
                                            color="primary"
                                            @click="editar_imc(item)"
                                            v-if = "readonly==false"
                                          >
                                            mdi-pencil
                                          </v-icon>

                                          &nbsp

                                          <v-icon
                                          small
                                            color="red"
                                            @click="deletar_imc(item)"
                                            v-if = "readonly==false"
                                          >
                                            mdi-delete-forever
                                          </v-icon>

                                        </template>

                                      <template v-slot:item.DATA="{ item }">
                                          <span>
                                          {{ getFormatDate(item.DATA,'br') }}
                                          </span>
                                      </template>
                                      
                                      
                                      <template v-slot:item.RESULTPESO="{ item }">
                                          <span
                                          :class="[getColorPeso(item.RESULTPESO)+'--text']"
                                          >
                                          {{ item.RESULTPESO }}
                                          </span>
                                      </template>


                                          </v-data-table>
                                        </v-card>

                                        </v-col>
                                      </v-row>


                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                                

                                <v-expansion-panel
                                  key="2"
                                  class=""
                                >
                                  <v-expansion-panel-header>

                                    <!-- Gestante  <h3> &nbsp {{ edita_imc.IMC }}  -  <span  :style="{ color: edita_imc.X_COR }"> {{ edita_imc.RESULTPESO }}</span> </h3>   -->
                                    {{ count_gestante  }} : Gestante
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>


                                  <v-row class="pb-2">
                                      <v-btn
                                        color="primary"
                                        x-small
                                        @click="adicionar_gestante()"
                                      >
                                      
                                        novo
                                      </v-btn>
                                  </v-row>

                                  <v-row class="grey lighten-4" 
                                  v-if="(novo_gestante==false)"
                                  >
                                        <v-col 
                                        class=""
                                        cols="3"
                                        v-if="novo_gestante==false"
                                        
                                        >
                                              <v-select
                                              v-model="tipo_gestante"
                                              :items="['DUM','ULTRA']"
                                              persistent-hint
                                              label="Tipo"
                                              dense
                                              ></v-select>
                                        </v-col>


                                        <v-col class=""
                                        v-if="((novo_gestante==false) && (tipo_gestante=='DUM'))"
                                        >
                                          <v-row class="grey lighten-4" 
                                          >

                                            <v-col class="" cols="6">
                                                  <v-text-field
                                                    v-model="edita_gestante.DATA"
                                                    label="Data"
                                                    type="date"
                                                    :rules="notnullRules"
                                                    dense
                                                  >
                                                  </v-text-field>
                                            </v-col>

                                            <v-col class="" cols="6"
                                            v-if="0>1"
                                            >
                                              <v-btn
                                                  color="primary"
                                                  @click="calcular_dum(edita_gestante.DATA)"
                                                  x-small
                                                  class="mb-1"
                                                >
                                                  calcular
                                                </v-btn>
                                            </v-col>

                                          </v-row> 
                                        </v-col>

                                        <v-col class=""
                                        v-if="((novo_gestante==false) && (tipo_gestante=='ULTRA'))"
                                        >
                                          <v-row class="grey lighten-4" 
                                          >

                                            <v-col class="" cols="6">
                                                  <v-text-field
                                                    v-model="edita_gestante.X_DATA_ULTRA"
                                                    label="Data"
                                                    type="date"
                                                    :rules="notnullRules"
                                                    dense
                                                  >
                                                  </v-text-field>
                                            </v-col>

                                            <v-col class="" cols="3"
                                            >
                                              <v-text-field
                                                v-model="edita_gestante.X_SEMANAS"
                                                label="Semanas"
                                                type="number"
                                                dense
                                              ></v-text-field>
                                            </v-col>

                                            <v-col class="" cols="3"
                                            >
                                              <v-text-field
                                                v-model="edita_gestante.X_DIAS"
                                                label="Dias"
                                                type="number"
                                                dense
                                              ></v-text-field>
                                            </v-col>

                                          </v-row> 
                                        </v-col>

                                        
                                  </v-row>


                                  <v-row class="mt-0"
                                  v-if="(novo_gestante==false)"
                                  >

                                    <v-col class="pink lighten-5" cols="7"
                                    v-if="edita_gestante.TEXTO !=''"
                                    >
                                    
                                        <v-textarea
                                          dense
                                            background-color="pink lighten-5"
                                            v-model="edita_gestante.X_TEXTO"
                                            label=""
                                            hint=""
                                            :auto-grow='false'
                                            rows="16"
                                            row-height="8"              
                                        >
                                        </v-textarea>
                                    </v-col>

                                    <v-col class="grey lighten-3" cols="5"
                                    v-if="edita_gestante.TEXTO !=''"
                                    >
                                      <span class="text-subtitle-2">
                                        Tempo:  <h4 class="pink--text"> {{ edita_gestante.TEXTO }}</h4>  
                                      </span>

                                      <br>

                                      <span class="text-subtitle-2">
                                        Data provável do parto:  <h4 class="pink--text">  {{ getFormatDate(edita_gestante.DATA2,'br') }}</h4>  
                                      </span>

                                      <br>

                                      <v-btn
                                          color="primary"
                                          @click="salvar_gestante"
                                          x-small
                                          class="mb-1"
                                        >
                                          salvar
                                      </v-btn>

                                      &nbsp
                                      
                                      <v-btn
                                          color="primary"
                                          @click="imprimirGestante(edita_gestante)"
                                          x-small
                                          class="mb-1"
                                        >
                                          imprimir
                                      </v-btn>


                                    </v-col>
                                  
                                  </v-row> 



                                      <v-row>
                                        <v-col class="pa-0" cols="12">

                                        <v-card 
                                          width=""
                                        >
                                          <v-data-table
                                            dense
                                            :headers="headers_gestante"
                                            :items="items_gestante"
                                            class="elevation-1"
                                            disable-sort
                                            :mobile-breakpoint="55"
                                            hide-default-footer
                                          >

                                        <template v-slot:item...="{ item }">

                                          <v-icon
                                            small
                                            color="primary"
                                            @click="editar_gestante(item)"
                                            v-if = "readonly==false"
                                          >
                                            mdi-pencil
                                          </v-icon>

                                          &nbsp

                                          <v-icon
                                          small
                                            color="red"
                                            @click="deletar_gestante(item)"
                                            v-if = "readonly==false"
                                          >
                                            mdi-delete-forever
                                          </v-icon>

                                        </template>

                                      <template v-slot:item.DATA="{ item }">
                                          <a
                                          @click="editar_gestante(item)"
                                          >
                                          {{ getFormatDate(item.DATA,'br') }}
                                          </a>
                                      </template>
                                      
                                      <template v-slot:item.DATA2="{ item }">
                                          <a
                                          @click="editar_gestante(item)"
                                          >
                                          {{ getFormatDate(item.DATA2,'br') }}
                                          </a>
                                      </template>
                                      
                                      <template v-slot:item.TEXTO="{ item }">
                                          <a
                                          @click="editar_gestante(item)"
                                          >
                                          {{ item.TEXTO}}
                                          </a>
                                      </template>
                                      
                                      


                                          </v-data-table>
                                        </v-card>

                                        </v-col>
                                      </v-row>


                                  </v-expansion-panel-content>
                                </v-expansion-panel>


                                <v-expansion-panel
                                  key="3"
                                  v-if="0>1"
                                >
                                  <v-expansion-panel-header
                                  >Gestante</v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                  </v-expansion-panel-content>
                                </v-expansion-panel>

                              </v-expansion-panels>
                            


                                          </v-card>
                                      </v-tab-item>
                                      

                                  </v-tabs-items>
                              </v-card>
                            </v-col>

                        </v-row>



                    </v-tab-item>

                    <!-- Receitasx -->
                    <v-tab-item :key="2" value="TABreceitas">

                      <v-row class="">
                        <v-col
                        class=""
                        cols="11"
                        :md="md_rA"
                        id="painel_esquerdo_2"
                        >
                            <v-card 
                            flat
                            elevation="5"
                            class="grey lighten-3 mb-1"
                            >

                              <!-- novas receitas -->
                              <v-data-table
                                v-model="selected_receitas" 
                                :show-select = true
                                item-key="CODIGO"

                                dense
                                :headers="headers_receitas"
                                :items="items_receitas"
                                class="elevation-1"
                                
                                :disable-sort="$vuetify.breakpoint.smAndDown"
                                
                                :height="(isMobile) ? '500' : retorna_altura(280)"

                                fixed-header
                                :items-per-page="-1"
                                :footer-props="{'items-per-page-options': [-1]}"
                              >



                                <template v-slot:top>
                                    <v-card
                                        elevation="4"
                                        class="mt-1 mb-1 pa-1"
                                        height="40px"
                                    >

                                    <v-row dense>
                                      <v-col cols="6">

                                            <v-btn
                                              color="primary"
                                              class="red ml-2 mt-1"
                                              x-small
                                              @click="novo_receita_manual('editedItem')"
                                              v-if = "readonly==false"  
                                            >
                                            

                                                <span v-if="DEBUG==true">
                                                  &nbsp novoxreceitas
                                                </span>
                                                <span v-else>
                                                  &nbsp novo 
                                                </span>
                                            </v-btn>

                                            <v-btn
                                              color="primary"
                                              class="red ml-2 mt-1"
                                              x-small
                                              @click="imprimirReceita"
                                            >

                                              <v-icon
                                                small
                                                class="mr-2"
                                              >
                                                mdi-printer 
                                              </v-icon>

                                                <span v-if="DEBUG==true">
                                                  imprimirxreceitas
                                                </span>
                                                <span v-else>
                                                  imprimir
                                                </span>
                                                <!-- IMPRIMIR RECEITAS -->
                                              
                                            </v-btn>
                                          </v-col>

                                          <v-col cols="6"  class="d-flex align-center justify-end pr-2">

                                            <!-- <v-spacer></v-spacer> -->

                                            <v-btn
                                              color=""
                                              class="red ml-2 mt-1 white--text"
                                              x-small
                                              @click="remove_all_receitas"
                                              v-if="readonly==false"
                                            >
                                            <!-- pendente -->

                                              <v-icon
                                                small
                                                class="mr-2"
                                              >
                                                mdi-delete-forever  
                                              </v-icon>

                                                <span v-if="DEBUG==true">
                                                  excluirxreceitas
                                                </span>
                                                <span v-else>
                                                  Excluir todos
                                                </span>
                                              
                                            </v-btn>

                                          </v-col>
                                    </v-row>

                                      </v-card>      
                                </template>

                                
                                <!-- <template v-slot:item.MEDICAMENTO="{ item, index }">

                                  <span class="grey--text">{{ index + 1 }}º &nbsp</span>

                                  <v-chip color="blue lighten-3" small class="font-weight-medium ma-1">
                                    {{ item.MEDICAMENTO }}
                                  </v-chip>

                                   <v-chip color="grey lighten-2" small class="ma-1">
                                    {{ item.POSOLOGIA }}
                                  </v-chip>

                                  <v-chip color="grey lighten-2" small class="ma-1 font-weight-bold">
                                    {{ item.DOSE }}
                                  </v-chip>

                                  <v-chip color="grey lighten-2" small class="ma-1 font-weight-normal">
                                    {{ item.USO }}
                                  </v-chip> 
                                </template> -->

                                     <template v-slot:item.MEDICAMENTO="{ item, index }" >

                                      <span class="grey--text">{{ index + 1 }}º &nbsp</span>
                                      

                                      <a
                                        @click="editItem(item, false,'editedItem','items_receitas','dialog')"
                                        v-if = "(readonly==false)"
                                      >
                                      {{ item.MEDICAMENTO }}
                                      </a>

                                      <span
                                      v-else
                                      >
                                        {{ item.MEDICAMENTO }}
                                      </span>
                                    </template> 

                                    <template v-slot:item.POSOLOGIA="{ item }">

                                      <v-tooltip bottom v-if="(item.MANIPULADO.length>5)">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-chip
                                              v-bind="attrs"
                                              v-on="on"
                                              class="my-1"
                                          > 
                                          MANIPULADO
                                          </v-chip>

                                        </template>
                                        <span v-html="quebra_linha(item.MANIPULADO)"> 
                                        </span>
                                      </v-tooltip>

                                      <div v-else>
                                        {{ item.POSOLOGIA }}
                                      </div>
                                    </template>

                                    <!-- v-if = "readonly==false" -->


                                <template v-slot:item.actions="{ item }">
                                  <!-- {{ item }} -->
                                  <!-- {{ item.id }} -->
                                  <v-icon
                                    color="red"
                                    @click="deleteItem(item,'editedItem', 'items_receitas')"
                                    v-if = "readonly==false"
                                    small
                                  >
                                    mdi-delete-forever 
                                  </v-icon>
                                </template>



                              </v-data-table> 


                            </v-card>
                        </v-col>

                        <v-divider
                          inset
                          vertical
                        ></v-divider>
                        
                        <v-col
                        class="mt-1 mb-1"
                        id="painel_direito_2"
                        cols="11"
                        :md="md_rB"
                        >

                          <!-- card do historico de receitas do profissional  -->
                          <v-card elevation="5" >
                                <v-tabs
                                    v-model="tab_receita"
                                    background-color="#FAFAFA"
                                    show-arrows    
                                    height="33"
                                    color=""
                                    mobile-breakpoint
                                >

                                    <v-tabs-slider></v-tabs-slider>

                                    <v-tab href="#vazio" @click="md_rA=9; md_rB=3;">
                                            <v-icon small
                                              v-if="md_rA==9"
                                            >
                                            mdi-arrow-left
                                            </v-icon>

                                            <v-icon small
                                              v-if="md_rA==6"
                                            >
                                            mdi-arrow-right  
                                            </v-icon>

                                            <div>
                                              <!-- &nbsp Receitas -->
                                            </div>
                                    </v-tab>
                                    
                                    <v-tab href="#historico"@click="crud_receitas_mais(); md_rA=6; md_rB=6;">
                                        <v-icon small>
                                        mdi-text-box-multiple 
                                        </v-icon>
                                          
                                            <span v-if="DEBUG==true">
                                              &nbsp Medicamentosx
                                            </span>
                                            <span v-else>
                                              &nbsp (HISTÓRICO R)
                                            </span>
                                            <span class="count_item ml-1"> {{ receitas_ultimos.length }}</span>
                                    </v-tab>

                                    <v-tab href="#manipulados" @click="crud_manipulados_mais(); md_rA=6; md_rB=6;" v-if="(2>1)">

                                        <v-icon small>
                                        mdi-spa 
                                        </v-icon>
                                          
                                            <span v-if="DEBUG==true">
                                              &nbsp manipuladosXmais
                                            </span>
                                            <span v-else>
                                              &nbsp (MANIPULADOS)
                                            </span>

                                            <span class="count_item ml-1"> {{ manipulados_ultimos.length }}</span>
                                            
                                    </v-tab>
                                    
                                </v-tabs>

                                <v-tabs-items v-model="tab_receita">

                                  <v-tab-item :key="1" value="historico" class="mb-n10">
                                    <!-- (HISTÓRICO R) -->


                                        <v-card class="overflow-y-auto ">

                                        <v-card-title>
                                          <v-row dense class="grey lighten-3">
                                             <v-col cols="6">
                                              <v-text-field
                                                v-model="search"
                                                append-icon="mdi-magnify"
                                                label="Procurar Medicamento"
                                                single-line
                                                hide-details
                                                clearable
                                                dense
                                                @keyup.esc="search=''"
                                              >

                                              </v-text-field>
                                             </v-col>
                                          </v-row>
                                        </v-card-title>

                                        <v-card-text>
                                          <v-row dense class="">
                                            <v-col class="mt-0" cols="12">
                                              <v-data-table
                                                :headers="headers"
                                                :items="receitas_ultimos"
                                                :search="search"

                                                class="elevation-1 mb-10"
                                                
                                                dense
                                                @contextmenu:row="rightClick_receitas"
                                                @click:row="seleciona_linha"

                                                fixed-header
                                                :height="(isMobile) ? '500' : retorna_altura(370)"
                                                :items-per-page="500"
                                                :footer-props="{'items-per-page-options': [500,-1]}"
                                                
                                              >
                                              
                                              <template v-slot:item.actions="{ item }">
                                                <v-icon
                                                size="20"
                                                color="primary"
                                                @click="novo_receita('receitas',EditaReceita, item );"
                                                v-if = "readonly==false"
                                                >
                                                mdi-plus-circle 
                                                <!-- mdi-plus -->
                                                </v-icon>
                                                    <span v-if="DEBUG==true">
                                                    maisXr
                                                    </span>
                                                <!-- {{ item.MEDICAMENTO }} -->
                                              </template>         
                                              
                                              
                                              </v-data-table>
                                            </v-col>
                                          </v-row>
                                        </v-card-text>
                                        </v-card>
                                      <!-- </v-col>
                                    </v-row> -->
 

                                  </v-tab-item>

                                  <v-tab-item :key="2" value="vazio">
                                      <!-- <v-card flat>
                                          <v-card-text>Vazio</v-card-text>
                                      </v-card> -->
                                  </v-tab-item>

                                  <v-tab-item :key="3" value="manipulados" class="">
                                  <!-- (MANIPULADOS) -->

                                  <v-card class="overflow-y-auto mt-1">

                                    <v-card-title class="">
                                      <v-row dense class="grey lighten-3">
                                        <v-col cols="5">

                                          <v-text-field
                                            v-model="search_manipulado"
                                            append-icon="mdi-magnify"
                                            label="Procurar Manipulado"
                                            single-line
                                            hide-details
                                            clearable
                                            dense
                                            @keyup.esc="search_manipulado=''"
                                          >
                                          </v-text-field>

                                        </v-col>  

                                        <v-col cols="6">
                        
                                          <v-btn
                                              color="primary"
                                              x-small
                                              @click="abrir_janela_modal(false,'novo')"
                                              class="ml-5"
                                          >
                                              Novo
                                          </v-btn>
                                        </v-col>

                                      </v-row> 
                                    </v-card-title>


                                    <v-card-text>
                                  <v-row dense class="">
                                    <v-col class="mt-0" cols="5">
                                      <v-card class="mt-0">
                                        <v-data-table
                                          :headers="headers_hist_manA"
                                          :items="manipulados_ultimos"
                                          :search="search_manipulado"
                                          dense

                                          class="elevation-1"
                                          @keyup.esc="search=''"

                                          fixed-header
                                          :height="(isMobile) ? '500' : retorna_altura(315)"
                                          :items-per-page="-1"
                                          hide-default-footer
                                        >
                                        
                                            <template v-slot:item.NOME="{ item }">
                                              <div @click="mostra_manipulado(item)">

                                                <v-icon
                                                  size="20"
                                                  color="primary"
                                                  @click="novo_manipulado('receitas',EditaManipulado, item );"
                                                  v-if = "readonly==false"
                                                  class="mr-2"
                                                >
                                                
                                                mdi-plus-circle 
                                                </v-icon>

                                                <a @click="mostra_manipulado(item)">
                                                  {{ item.NOME}}.
                                                </a>
                                              </div>
                                            </template>



                                            <!-- <template v-slot:item.NOME="{ item }">

                                              <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-chip
                                                      v-bind="attrs"
                                                      v-on="on"
                                                      color="primary lighten-1"
                                                      @click="mostra_manipulado(item)"
                                                  > 
                                                  {{ item.NOME }}
                                                  </v-chip>

                                                </template>
                                                <span v-html="quebra_linha(item.MODELO)"> 
                                                </span>
                                              </v-tooltip>
                                            </template> -->





                                            <template v-slot:item.actions="{ item, index }">
                                              <v-icon
                                              size="20"
                                              color="primary"
                                              @click="novo_manipulado('receitas',EditaManipulado, item );"
                                              v-if = "readonly==false"
                                              >
                                              
                                              mdi-plus-circle 
                                              </v-icon>
                                            </template>
                                            
                                            
                                            <template v-slot:item.actions_2="{ item, index }">
                                              <v-icon
                                              small
                                              color="primary"
                                              @click="abrir_janela_modal(item,'editar')"
                                              v-if = "readonly==false"
                                              >
                                              <!-- obj_janela_modal=item, janela_modal=true -->
                                              mdi-pencil 
                                              </v-icon>

                                            </template>  
                                        
                                        </v-data-table>
                                      </v-card>
                                    </v-col>

                                    <v-col class="mt-0" cols="7">
                                      <v-textarea
                                          dense
                                          background-color="grey lighten-4"
                                          v-model="manipulado_clicou.MODELO"
                                          readonly         
                                          hide-details=""
                                          filled
                                          :height="(isMobile) ? '500' : retorna_altura(320)"
                                      >
                                      </v-textarea>
                                    </v-col>
                                  </v-row>
                                </v-card-text>

                                  </v-card>
                                  </v-tab-item>

                                </v-tabs-items>
                            </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <!-- examesx -->
                    <v-tab-item :key="3" value="TABexames">
                      
                      
                      <v-row class="">
                            <v-col
                            class=""
                            cols="11"
                            :md="md_rA"
                            >
                                <v-card 
                                flat
                                elevation="5"
                                class="grey mb-1"
                                >

                              <!-- novos exames -->
                              <v-data-table
                                v-model="selected_exames" 
                                :show-select = true
                                item-key="CODIGO"

                                dense
                                :headers="headers_exames"
                                :items="items_exames"
                                class="elevation-1"
                                :disable-sort="$vuetify.breakpoint.smAndDown"

                                :height="(isMobile) ? '500' : retorna_altura(220)"
                                
                                fixed-header
                                :items-per-page="-1"
                                hide-default-footer

                              >



                                <template v-slot:top>
                                    <v-card
                                        elevation="4"
                                        class="mt-1 mb-1 pa-1"
                                        height="40px"
                                    >


                                      <v-row>
                                        <v-col cols="9">

                                            <v-btn
                                              color="primary"
                                              class="red ml-2 mt-1"
                                              x-small
                                              @click="novo_exame_manual('editedItem')"
                                              v-if = "readonly==false"
                                            >

                                                <span v-if="DEBUG==true">
                                                  &nbsp novoXexames
                                                </span>
                                                <span v-else>
                                                  &nbsp novo
                                                </span>
                                            </v-btn>

                                            <v-btn
                                              color="primary"
                                              class="red ml-2 mt-1"
                                              x-small
                                              @click="imprimirExame"
                                            >
                                            <!-- pendente -->

                                              <v-icon
                                                small
                                                class="mr-2"
                                              >
                                                mdi-printer 
                                              </v-icon>

                                                <span v-if="DEBUG==true">
                                                  imprimirXexames
                                                </span>
                                                <span v-else>
                                                  imprimir
                                                </span>
                                              
                                            </v-btn>


                                            <v-btn
                                              color="primary"
                                              class="red ml-2 mt-1"
                                              x-small
                                              @click="imprimir_sadt"
                                            >
                                            <!-- pendente -->

                                              <v-icon
                                                small
                                                class="mr-2"
                                              >
                                                mdi-printer 
                                              </v-icon>
                                              SADT
                                            </v-btn>

                                        </v-col>

                                        <v-col cols="3"  class="d-flex align-center justify-end pr-4" v-if="(!isMobile)">

                                            <v-btn
                                              color=""
                                              class="red ml-2 mt-1 white--text"
                                              x-small
                                              @click="remove_all_exames"
                                              v-if="readonly==false"
                                            >
                                            <!-- pendente -->

                                              <v-icon
                                                small
                                                class="mr-2"
                                              >
                                                mdi-delete-forever  
                                              </v-icon>

                                                <span v-if="DEBUG==true">
                                                  excluirxexames
                                                </span>
                                                <span v-else>
                                                  Excluir todos
                                                </span>
                                              
                                            </v-btn>

                                          </v-col>
                                      </v-row>

                                      </v-card>      
                                </template>


                                
                                
                                    <template v-slot:item.EXAME="{ item , index}">

                                      <span class="grey--text">{{ index + 1 }}º &nbsp</span> 
                                      
                                      <a
                                        @click="editItem(item, false,'editedItem','items_exames','dialog_exames')"
                                        v-if = "readonly==false"
                                      >
                                        {{ item.EXAME }}
                                      </a>

                                      <span
                                      v-else
                                      >
                                        {{ item.EXAME }}
                                      </span>

                                    </template>
                                    <!-- v-if = "readonly==false" -->


                                <template v-slot:item.actions="{ item }">
                                  <!-- {{ item }} -->
                                  <!-- {{ item.id }} -->
                                  <v-icon
                                    color="red"
                                    @click="deleteItem(item,'editedItem', 'items_exames')"
                                    v-if = "readonly==false"
                                    small
                                  >
                                    mdi-delete-forever 
                                  </v-icon>
                                </template>



                              </v-data-table> 


                                </v-card>
                            </v-col>
                      
                            <v-divider
                              inset
                              vertical
                            ></v-divider>



                            <v-col
                            class="mt-1 mb-1"
                            id="painel_direito_2"
                            cols="11"
                            :md="md_rB"
                            >
                            <!-- card do historico de exames do profissional -->
                              <v-card elevation="5">
                                  <v-tabs
                                      v-model="tab_exame"
                                      background-color="#FAFAFA"
                                      show-arrows    
                                      height="33"
                                      color=""
                                      mobile-breakpoint
                                  >

                                      <v-tabs-slider></v-tabs-slider>

                                      <v-tab href="#vazio" @click="md_rA=9; md_rB=3;">
                                              <v-icon small
                                                v-if="md_rA==9"
                                              >
                                              mdi-arrow-left
                                              </v-icon>

                                              <v-icon small
                                                v-if="md_rA==6"
                                              >
                                              mdi-arrow-right  
                                              </v-icon>

                                              <div>
                                                <!-- &nbsp Receitas -->
                                              </div>
                                      </v-tab>
                                      
                                      <v-tab  href="#historico" @click="crud_exames_mais(); md_rA=6; md_rB=6;">

                                          <v-icon small>
                                          mdi-text-box-multiple 
                                          </v-icon>
                                            
                                              <span v-if="DEBUG==true">
                                                &nbsp examesXmais
                                              </span>
                                              <span v-else>
                                                <!-- &nbsp (Exames) -->
                                                &nbsp (HISTÓRICO e)
                                              </span>
                                              <span class="count_item ml-1"> {{ exames_ultimos.length }}</span>
                                      </v-tab>
                                      
                                      
                                      
                                  </v-tabs>

                                  <v-tabs-items  v-model="tab_exame">

                                    <v-tab-item :key="1" value="historico" class="">
                                      <!-- (HISTÓRICO E) -->

                                      <v-card class="overflow-y-auto ">

                                        <v-card-title >
                                          <v-row dense class="grey lighten-3 pt-1">


                                            <v-col cols="4" class="">

                                              <v-text-field
                                                dense
                                                v-model="search_exames"
                                                append-icon="mdi-magnify"
                                                label="Procurar Exame"
                                                single-line
                                                hide-details
                                                clearable
                                                @keypress.enter="abrir_exames_tuss()"
                                                @keyup.esc="search_exames=''"
                                              >

                                              </v-text-field>

                                            </v-col>   


                                            <v-col cols="3" class="pl-1">

                                              <v-select
                                                dense
                                                v-model="pesquisar_tuss"
                                                :items="['HISTÓRICO','TUSS']"
                                              >
                                              </v-select>

                                            </v-col>

                                            <v-col cols="4" class="" v-if="(pesquisar_tuss=='HISTÓRICO')">
                                              <v-switch
                                                v-model="switch1"
                                                :label="`POR GRUPOS`"
                                                @click="closeAll(switch1)"
                                                dense
                                                class="ma-0 pa-0 ml-5 mt-2 mb-n2"
                                              ></v-switch>

                                            </v-col>


                                          </v-row> 

                                        </v-card-title>
                                  
                                        <v-card-text>
                                          <v-row dense class="">
                                              <v-col class="mt-0 " cols="12" v-if="(pesquisar_tuss=='HISTÓRICO')">

                                                <v-data-table
                                                  :headers="headers_hist_ex"
                                                  :items="exames_ultimos"
                                                  :search="search_exames"
                                                  dense

                                                  @contextmenu:row="rightClick_exames"
                                                  @click:row="seleciona_linha"

                                                  group-by="CATEGORIA"
                                                  item-key="CODIGO"   
                                                  :expanded.sync="expanded"          

                                                  class="elevation-1"
                                                  @keyup.esc="search_exames=''"

                                                  fixed-header
                                                  :height="(isMobile) ? '500' : retorna_altura(355)"
                                                  :items-per-page="500"
                                                  :footer-props="{'items-per-page-options': [500,-1]}"
                                                >
                                                
                                              <template late v-slot:group.header="{ group, headers, toggle, isOpen }">
                                                  <td :colspan="headers.length">
                                                      <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen" cat-titulo="true">
                                                          <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                                                          <v-icon v-else>mdi-chevron-down</v-icon>
                                                      </v-btn>


                                                    <span v-if="DEBUG==true">
                                                      catX
                                                      </span>
                                                      
                                                      &nbsp

                                                      {{ group }}


                                                      &nbsp

                                                      <v-tooltip bottom
                                                      v-if = "readonly==false && group.length > 0 && switch1==true && isOpen==true"
                                                      >
                                                        <template v-slot:activator="{ on, attrs}">
                                                            
                                                            <v-icon
                                                            color="success"
                                                            @click="novo_exame_cat(group);"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            dense
                                                            size="20"
                                                            >
                                                            mdi-plus-circle 
                                                            </v-icon>

                                                        </template>
                                                        <span> Adicionar todos do Grupo {{ group }}</span>
                                                      </v-tooltip>


                                                  </td>


                                              </template>



                                                <template v-slot:item.EXAME="{ item }">
                                                    {{ item.EXAME }} 
                                                    <!-- || {{ item.CATEGORIA }} -->
                                                </template> 


                                                <template v-slot:item.actions="{ item, index }">
                                                  <v-icon
                                                    color="primary"
                                                    @click="novo_exame('exames',EditaExame, item );"
                                                    v-if = "readonly==false"
                                                    :ref= "'cat_'+item.NOME+'_'+index"
                                                    :cat-item = "true"
                                                    :cat-item-categoria = "item.NOME"
                                                    size="25"
                                                    class="pt-1"
                                                  >
                                                      mdi-plus-circle 
                                                  </v-icon>

                                                  <span v-if="(switch1)">
                                                    {{ index +1}}
                                                  </span>

                                                  <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                      <v-icon
                                                        color="error"
                                                        @click="remover_exame(item, switch1 );"
                                                        v-if = "readonly==false"
                                                        size="25"
                                                        v-bind="attrs" v-on="on"
                                                        class="ml-3 pt-1"
                                                      >
                                                          mdi-close
                                                      </v-icon>

                                                    </template>
                                                    <span> {{(switch1)? 'Remover do Grupo' : 'Remover do Histórico'}} </span>
                                                  </v-tooltip>


          
                                                  

                                                  <span v-if="DEBUG==true">
                                                     maissssXex
                                                  </span>
                                                  <!-- {{ item.MEDICAMENTO }} -->
                                                </template>         
                                                
                                                
                                                </v-data-table>

                                              </v-col>

                                              <v-col class="mt-0 " cols="12" v-if="(pesquisar_tuss=='TUSS')">
                                                <!-- :search="search" -->
                                                <v-data-table
                                                  :headers="headers_tuss"
                                                  :items="exames_tuss"
                                                
                                                  dense
                                                  class="elevation-1"

                                                  fixed-header
                                                  :height="(isMobile) ? '500' : retorna_altura(355)"
                                                  :items-per-page="500"
                                                  :footer-props="{'items-per-page-options': [500,-1]}"
                                                >

                                                <template v-slot:item.actions="{ item, index }">
                                                  <v-icon
                                                    color="purple"
                                                    @click="novo_exame_tuss(item)"
                                                    size="25"
                                                    class="pt-1"
                                                  >
                                                      mdi-plus-circle 
                                                  </v-icon>
                                                </template>
   

                                                </v-data-table>
                                              </v-col>
                                          </v-row>
                                        </v-card-text>

                                      </v-card>
                                    </v-tab-item>

                                  <v-tab-item :key="2" value="vazio">
                                      <!-- <v-card flat>
                                          <v-card-text>Vazio</v-card-text>
                                      </v-card> -->
                                  </v-tab-item>

                                  </v-tabs-items>
                              </v-card>
                            </v-col>
                        </v-row>
                        
                      
                    </v-tab-item>

                    <v-tab-item :key="4" value="TABimprimir">

                      <v-card
                          elevation="4"
                          class="mt-1 mb-1 pa-1"
                          height="40px"
                      >

                        <v-btn
                          color="primary"
                          class="red ml-2 mt-1"
                          x-small
                          @click="imprimirAtestado('Atestado')"
                        >
                        <!-- pendente -->

                          <v-icon
                            small
                            class="mr-2"
                          >
                            mdi-printer 
                          </v-icon>

                            <span v-if="DEBUG==true">
                              imprimirxatestado
                            </span>
                            <span v-else>
                              Atestado.
                            </span>
                          
                        </v-btn>

                        </v-card>
                    </v-tab-item>
                    
                    <v-tab-item :key="5" value="TABtabelas">

                      <v-card
                          elevation="4"
                          class="mt-1 mb-1 pa-1"
                      >

                        <v-container>
                          <v-row>
                              <v-col
                                cols="12"
                                md="2"
                                class=""
                              >
                                <v-card
                                    class="mx-auto"
                                >
                                    <v-list         
                                      dense
                                      nav
                                    >
                                        <v-list-item-group
                                        v-model="selectedItem"
                                        color="primary"
                                        active-class="blue--text"
                                        >
                    
                                            <v-divider
                                                class="mx-0"
                                            ></v-divider>
                    
                                            <!-- passo 2  mostro os items da lista -->
                                            <v-list-item
                                                v-for="(item, i) in lista_tabelas"
                                                :key="i"
                                                @click="card_titulo=item.text, tabela(item.click,'')"
                                                class="pa-0 mr-1 "
                                            >
                                                <v-list-item-icon class="pa-0 mr-2">
                                                   <v-icon v-text="item.icon" :color="(item.color) ? item.color: ''"></v-icon>
                                                </v-list-item-icon>
                    
                                                <v-list-item-content>
                                                  <!-- <v-list-item-title v-text="item.text"> -->
                                                  <v-list-item-title>
                                                    {{ item.text }}

                                                    <span v-if="item.click == 'PT'" class="count_item"> {{ count_PT }} </span>
                                                    <span v-else-if="item.click == 'PC'" class="count_item"> {{ count_PC }} </span>
                                                    <span v-else-if="item.click == 'WEB_REGISTROS'" class="count_item"> {{ count_Reg }} </span>
                                                    <span v-else-if="item.click == 'LANCAMENTOS'" class="count_item"> {{ count_Lanc }} </span>
                                                    <!-- <span class="count_item"> {{ item.count }} </span> -->
                                                    <span v-else class="count_item"> {{ item.count }} </span>

                                                  </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                    
                                            <v-divider
                                                class="mx-4"
                                            ></v-divider>
                    
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>    


                              </v-col> 
                    
                    
                              <v-col
                              class="grey lighten-5"
                              :cols="(isMobile) ? 12 :  (11-col_menu)"
                              >
                                <v-card
                                  class="mx-auto"
                                >
                                <!-- v-if="items_lista.length > 0" -->

                                    <v-card-title
                                    class="pa-1 pl-2"
                                    v-if="sub_codigo>0"
                                    >
                                      <!-- <h4 class="primary--text" @click="ultimo_click()" >{{ sub_descricao }} </h4> -->
                                      <v-chip
                                      color="primary"
                                      @click="ultimo_click()"
                                      >
                                        {{ sub_descricao }}
                                      </v-chip>
                                      &nbsp
                                      <h5 class="mb-2 grey--text">{{ sub_codigo }} </h5>

                                      &nbsp

                                      <v-chip
                                      color="primary"
                                      @click="ultimo_click()"
                                      >
                                        VOLTAR
                                      </v-chip>

                                    </v-card-title>

                                    <v-card-title
                                      v-if="card_titulo"
                                      class="py-0"
                                    >
                                        <span class="text-h5"> {{ card_titulo }} </span>
                                        <span class="red--text mb-2 ml-1"> {{ items_lista.length }} </span>
                    
                    
                                        &nbsp 
                                        <v-col
                                        cols="5"
                                        md="2"
                                        >
                    
                                            <v-text-field
                                            dense
                                            v-model="search"
                                            clearable
                                            append-icon="mdi-magnify"
                                            label="Procurar.."
                                            single-line                
                                            hide-details
                                            @keyup.esc="search=''"
                                        
                                            >
                                            </v-text-field>
                    
                                        </v-col>
                    
                                        <v-spacer></v-spacer>
                    
                                        <v-btn
                                            color="primary"
                                            small
                                            @click="imprimir_conteudo(items_header,items_lista, card_titulo, false, true,)"
                                            class="mr-5"
                                            v-if="(!isMobile)"
                                        >
                                            Imprimir
                                        </v-btn>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-btn
                                                color="primary"
                                                small
                                                @click="clicou_em_novo('item_edita','dialog_tabela')"
                                                v-bind="attrs" v-on="on"
                                               >
                                                Novo
                                            </v-btn>
                                        </template>
                                          <span> Novo.. </span>
                                      </v-tooltip>
                                    
                                    </v-card-title>
                    
                                    <v-card-subtitle>
                    
                                    </v-card-subtitle>
                    
                                    <v-card-text class="pa-1"
                                        v-if="card_titulo"
                                    >
                                    <div>
                                        <v-row class="">
                                            <v-col :cols="col_five" class="" >
                                                <v-card>
                                                <!-- passo 5 monto a lista -->
                                                    <v-data-table
                                                    v-if="(!vai_imprimir)"
                                                    v-model="selected"
                                                    single-select
                                                    item-key="CODIGO"
                                                    @click:row="seleciona_linha"

                                                    :headers="items_header"
                                                    :items="items_lista"
                                                    class="elevation-1"
                                                    :items-per-page="500"
                                                    :disable-sort="false"
                                                    :mobile-breakpoint="55"
                                                    hide-default-footer
                                                    :search="search"
                                                    dense
                                                    :height="(isMobile) ? '270' : retorna_altura((mostrar_rodape) ? 350 : 270)"
                                                    >
                                                    

                                                        <template slot="no-data">
                                                        <div></div>
                                                        </template>


                                                        <template v-slot:item.NOME_TEXTO="{ item }">
                                                            <!-- <v-chip
                                                            color="primary"
                                                            small
                                                            class="mt-1"
                                                            >
                                                            
                                                            {{ item.NOME_TEXTO }}
                                                            </v-chip> -->
                                                            <a class="bold"> {{ item.NOME_TEXTO }} </a>

                                                            <span class="fs-10">{{ item.NOME_DOC }}</span>
                                                            
                                                        </template>


                                                        <template v-slot:item.DATA="{ item }">
                                                          <span> {{ getFormatDate(item.DATA,'br') }} </span>
                                                          <!-- {{ item.DATA }} -->
                                                        </template>
                                                        

                    
                                                        <template v-slot:item.menu="{ item }">
                                                            <v-icon
                                                                class="mr-2"
                                                                @click="abre_menu_3(item)"
                                                                color="blue"
                                                                :size = "size_mobile"
                                                            >
                                                            mdi-menu
                                                            </v-icon>
                                                        </template>
                    
                                                        <template v-slot:item.actions="{ item }">
                                                            <v-icon
                                                                class="mr-2"
                                                                @click="editar_tabela(item, 'item_edita', 'dialog_tabela')"
                                                                color="blue"
                                                                :size = "size_mobile"
                                                            >
                                                            mdi-pencil
                                                            </v-icon>
                                                        </template>
                    


                                                        <!-- //passo 6 personalizo as acoes dos botoes na lista  INICIO-->
                                                        <template v-slot:item.actions_clock="{ item }">
                                                          <v-tooltip 
                                                                bottom
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    class="mr-2"
                                                                    :size = "size_mobile"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    color="blue"
                                                                    @click="tabela('ANTIGO_HORARIO','SUB',item), card_titulo='Horários'"
                                                                > 
                                                                <!-- :color="item.CONTRATO ? 'blue': 'grey'" -->
                                                                mdi-clock-time-four-outline 
                                                                </v-icon>
                    
                                                                </template>
                                                                <span> Horários</span>
                                                            </v-tooltip>
                                                        </template>
                    

                                                        
                                                        <template v-slot:item.actions_proced="{ item }">
                                                          <v-tooltip 
                                                                bottom
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    class="mr-2"
                                                                    :size = "size_mobile"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    color="blue"
                                                                    @click="tabela('VALORES_AMB','SUB',item), card_titulo='Procedimentos'"
                                                                > 
                                                                mdi-format-list-checkbox 
                                                                </v-icon>
                    
                                                                </template>
                                                                <span> Procedimentos</span>
                                                            </v-tooltip>
                                                        </template>


                                                        <!-- //passo 6 personalizo as acoes dos botoes na lista  FIM -->
                    
                                                    </v-data-table>
                                                </v-card>
                    
                                            </v-col>

                                            <v-col  :cols="(12-col_five)" class="" v-if="(col_five<12)">


                                              <v-row class="" dense>
                                                <v-col class="">

                                                    <v-row justify="center">
                                                      <v-col cols="12">
                                                        <v-card class="overflow-y-auto" :height=retorna_altura(270) elevation="8">
                                                          <v-card-text>
                                                            

                                                           

                                                            <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                                                            <v-textarea
                                                              v-model="obj_clicado.TEXTO_REGISTRO"
                                                              label="TEXTO"
                                                              v-if="obj_clicado.TIPO == 'TEXTO'"
                                                              :persistent-hint="false"
                                                              dense
                                                              
                                                              readonly
                                                              auto-grow
                                                              rows="18"
                                                              class="pt-2"
                                                              solo
                                                              
                                                            >
                                                            <!-- rows="12" -->

                                                              <!-- <template v-slot:label>
                                                                <span class=""> {{(vai_imprimir) ? '' : item.text }} </span>
                                                              </template>   -->
                                                            </v-textarea>

                                                            <p v-else v-html="obj_clicado.TEXTO_REGISTRO"></p>
                                                            

                                                            <v-divider class="mx-10 my-4"/>

                                                            <span v-if="(obj_clicado.PATH_PDF)" style="display: block; text-align: center;">
                                                              <img
                                                                alt="user"
                                                                :src="obj_clicado.PATH_PDF"
                                                                height="30%"  
                                                                v-if="(obj_clicado.PATH_PDF)"
                                                              >
                                                              </img>
                                                            </span>
                                                            
                                                            
                                                            <span v-if="(obj_clicado.PATH_ASSINATURA?.length >10)" style="display: block; text-align: center;">
                                                              <img
                                                                alt="userr"
                                                                :src="obj_clicado.PATH_ASSINATURA"
                                                                width="50%"
                                                                v-if="(obj_clicado.PATH_ASSINATURA.length >10)"
                                                              >
                                                              </img>
                                                            </span>


                                                          </v-card-text>
                                                        </v-card>
                                                      </v-col>
                                                    </v-row>

                                                </v-col>
                                              </v-row>  
                                            </v-col>
                                        </v-row>

                                         <!-- row do rodape -->
                                        <v-row dense v-if="mostrar_rodape" class="" ref="rodape_soma" id="rodape_soma">


                                        <!-- mostro os cards de somatorias -->
                                        <v-col 
                                          class="py-0 ml-3"
                                          cols="12"
                                          md="11"
                                        >

                                        <v-container
                                        class="px-0"
                                        >
                                        <v-row>
                                          <v-col
                                            class="pa-0"
                                          >
                                          
                                          <v-chip-group 
                                            active-class="deep-purple accent-4 white--text"
                                            column
                                            class="pl-0"
                                            >

                                            <v-chip 
                                              v-for="(item,  index ) in items_somatorias"
                                              dark label
                                              large
                                              :class="retorna_cor(item.CAMPO)"
                                              @click="search=item.CAMPO.toLowerCase()"
                                            >
                                            <!-- :class="[(item.CAMPO == 'TOTAL') ? 'blue' : 'grey']" -->
                                            
                                            <span class="mt-n3 fs-12">{{ item.CAMPO }} </span>
                                            <span class="mt-n6 ml-1  white--text text-size-4 count_item_2 fs-14"> {{ item.QT }} </span>

                                            <h5 class="mt-5 ml-n8" v-if="(item.VALOR!='NaN')">
                                              {{ getFormatCurrency(item.VALOR,'N') }}
                                            </h5>
                                            <!-- <div style="position: absolute; top: 90%; left: 50%; transform: translate(-50%, -50%);">Texto Sobreposto</div> -->
                                            
                                            </v-chip>



                                          </v-chip-group>
                                          </v-col>
                                        </v-row>
                                        </v-container>

                                        </v-col>

                                        </v-row>
                    
                                    </div>
                                    </v-card-text>
                                </v-card>
                              </v-col>     
                          </v-row> 
                        </v-container>  

                      </v-card>
                    </v-tab-item>

                </v-tabs-items>
            </v-card>
          </v-col>

          <!-- PAINEL DIREITA -->
          <v-col
            class="px-0   lighten-4"
            cols="12"
            md="2"
          >

            <v-card 
            flat
            elevation="5"
            class="mt-1 grey lighten-2"
            >
              <v-expansion-panels 
                :multiple="false"
                :focusable='false'
                v-model="value_expansion"
                >

                <v-expansion-panel
                >
                  <v-expansion-panel-header 
                  >
                    <div>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon>
                                mdi-account-group 
                            </v-icon> 
                            &nbsp
                            Sala de Espera

                          <span
                            class="count_item"
                          >
                          {{ this.sala_lista.length }}
                          </span>

                        </v-col>
                            <!-- {{ dsi_timers[0].acao }}
                            {{ dsi_timers[0].ultimo }}
                            {{ dsi_timers[0].proximo }}
                            {{ ponteiro }} -->

                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content
                  class="py-0"
                  >



                  <v-row
                    dense
                    class="mt-1 px-0"
                    >
                      <v-col
                      class="px-0 pb-0"
                      cols="12"
                      >

                          <v-select
                            dense
                            v-model="filtra_sala"
                            :items="definir_A"
                            label="Filtrar"
                            item-value='sigla'
                            item-text='text'
                            @change="abrir_salaespera(filtra_sala)"
                          >

                          <!-- <template slot='selection' slot-scope='{ item }'> -->
                          <template slot='item' slot-scope='{ item }'>

                              <v-icon
                               size="18"
                               :color="getColorSituacao(item.sigla)"
                              >mdi-checkbox-blank-circle
                              </v-icon>
                              
                              &nbsp
                              
                              <span class="subtitle">
                               {{ item.text }}
                              </span>
                          </template>

                          </v-select>
                      </v-col>
                  </v-row>


                      <v-container
                        class="px-0 pr-1"
                      >
                        <v-row>
                          <v-col
                            class="px-0 py-0 red"
                          >
                            
                            <v-list
                            dense
                            style="max-height: 465px"
                            class="overflow-y-auto px-0 py-0"
                            id="id_sala"
                            >
                              <v-list-item-group
                                active-class="border"
                                v-model="ponteiro"
                              >


                                <v-list-item
                                  v-for="(item, i) in sala_lista"
                                  :key="i"
                                  class="px-0 py-0 grey lighten-5"
                                >
                                <!-- @click="salva_timer(0, 2000)" -->

                                  <v-list-item-content
                                  class="py-0"
                                  >
                                  
                                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                        <!-- <v-icon
                                        small
                                        :color="getColorSituacao(item.ATENDIDO)"
                                        >
                                          mdi-clock 
                                        </v-icon> -->
                                        <!-- :style="{ color: edita_imc.X_COR }" -->
                                        <!-- style="display: inline" class=" text-caption black--text" -->
                                        <!-- <p :style="{ color: getColorSituacao(item.ATENDIDO) }">  {{ getFormatTime(item.HORA)}} </p> -->
                                        <p style="display: inline" class=" text-caption black--text">  {{ getFormatTime(item.HORA)}} </p>
                                        
                                        <v-icon
                                        
                                        :color="getColorSituacao(item.ATENDIDO)"
                                        >
                                          mdi-clock 
                                        </v-icon>


                                        <p
                                        v-if="item.HORA_CHEGADA" 
                                        style="display: inline" class=" text-caption black--text"> {{ getFormatTime(item.HORA_CHEGADA)}} </p>

                                        <h5 style="display: inline; font-size: 12px;" class=""> {{ item.NOME }} </h5>
                                      </div>

                                      <v-divider
                                          class="mx-0"
                                      />
                                  </v-list-item-content>
                                </v-list-item>





                              </v-list-item-group>
                            </v-list>

                          </v-col>
                        </v-row>
                      </v-container>

                  </v-expansion-panel-content>
                </v-expansion-panel>


                <!-- <v-expansion-panel
                >
                  <v-expansion-panel-header 
                  >
                    <div>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                            <v-icon>
                              mdi-chart-line-variant 
                            </v-icon> 
                            &nbsp
                            Estatísticas
                        </v-col>

                      </v-row>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    dfadsfads
                  </v-expansion-panel-content>
                </v-expansion-panel> -->

                  


              </v-expansion-panels>
            </v-card>
  
          </v-col>

        </v-row>
      </div>

    </v-col>


        <!-- <v-col
        class="grey"
        cols="12"
        md="2"
        id="painel_azul"
        >
  ...
        </v-col> -->

  </v-row>


          <v-dialog v-model="pergunta" max-width="500px" persistent>
            <v-card>
              <v-card-title class="text-h5">Deseja finalizar este atendimento?</v-card-title>
              <p/>
              <v-card-subtitle>  </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" @click="pergunta=false">Não</v-btn>
                &nbsp
                <v-btn color="primary"  @click="finalizar(); pergunta=false;">Sim</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>


  </template>




        <v-dialog
            v-model="dialog"
            :persistent="true"
            max-width="800px"
          >        
            
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <span class="text-overline"> &nbsp ({{ editedItem.CODIGO }})</span>
                <!-- <v-subheader>Subheader</v-subheader> -->
              </v-card-title>

              <v-card-text>
                <v-form
                ref="form"
                :readonly = "readonly"
                v-model="valido_receitas"
                >
                <v-container class="pa-0">
                  <v-row v-if="(!editedItem.MANIPULADO)">

                    <v-col
                      cols="12"
                      md="6"
                      
                    >
                      <v-text-field
                        v-model="editedItem.MEDICAMENTO"
                        label="Medicamento"
                        :rules="notnullRules"
                        autofocus
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      
                    >

                      <v-combobox
                      v-model="editedItem.DOSE"
                      :items="[
                        ' ',
                        '1 Vd',
                        '2 Vd',
                        '1 Ampola',
                        '2 Ampolas',
                        '1 Caixa',
                        '2 Caixas',
                        '1 Frasco',
                        '2 Frascos',
                        '1 Tubo',
                        '2 Tubos',
                        'uso contínuo'
                      ]"
                    
                      persistent-hint
                      label="Qtde"
                      :rules="notnullRules"
                      ></v-combobox>

                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                      
                    >
                      <v-combobox
                      v-model="editedItem.USO"
                      :items="[
                        'uso interno',
                        'uso externo',
                        'uso nasal',
                        'uso retal',
                        'uso tópico',
                        'uso ocular',
                        'contínuo',
                        'inalação',
                        'uso oral',
                        'uso nasal',
                        'subcutâneo'
                      ]"
                    
                      persistent-hint
                      label="Uso"
                      ></v-combobox>
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                      
                    >
                      <v-text-field
                        v-model="editedItem.POSOLOGIA"
                        label="Posologia"
                        :rules="notnullRules"
                        type="text"
                      ></v-text-field>
                    </v-col>

                  </v-row>

                  <v-row dense v-if="(editedItem.MANIPULADO)" class="pa-0">
                    <v-col>
                      <h2 class="mb-2">
                        {{ editedItem.MEDICAMENTO }} 
                        <!-- {{ editedItem.TIPO }} -->
                      </h2>

                        <v-textarea
                            dense
                            solo
                            background-color=""
                            v-model="editedItem.MANIPULADO"
                            :auto-grow='false'
                            rows="22"
                            row-height="8" 
                            hide-details             
                        >
                        </v-textarea>
                    </v-col>
                  </v-row>
                  
                </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                
                <v-btn
                
                  color="primary"
                  @click="save('editedItem','CODIGO','items_receitas','receitas')"
                  v-if = "readonly==false"
                  :disabled="!valido_receitas"
                >

                      <span v-if="DEBUG==true">
                        &nbsp salvarxreceita
                      </span>
                      <span v-else>
                        &nbsp salvar
                      </span>

                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <!-- dialog exames -->
        <v-dialog
            v-model="dialog_exames"
            persistent
            max-width="800px"
          >        
            
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
                <span class="text-overline"> &nbsp {{ editedItem.CODIGO }}</span>
                <!-- <v-subheader>Subheader</v-subheader> -->
              </v-card-title>

              <v-card-text>
                <v-form :readonly = "readonly" v-model="valido_exames">
                <v-container>
                  <v-row>

                    <v-col
                      cols="3"
                      md="2"
                      
                    >
                      <v-text-field
                        v-model="editedItem.COD96"
                        label="TUSS"
                        autofocus
                        type="text"
                      ></v-text-field>
                    </v-col>


                    <v-col
                      cols="6"
                      md="7"
                      
                    >
                      <v-text-field
                        v-model="editedItem.EXAME"
                        label="Exame"
                        :rules="notnullRules"
                        type="text"
                      ></v-text-field>
                    </v-col>


                    <v-col
                      cols="4"
                      md="3"
                      
                    >
                      <v-text-field
                        v-model="editedItem.CATEGORIA"
                        label="GRUPO"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    
                    <!-- <v-col
                      arruma cid
                      cols="4"
                      md="3"
                      
                    >
                      <v-text-field
                        v-model="editedItem.CID=' '"
                        label="CID"
                        type="text"
                      ></v-text-field>
                    </v-col> -->

                  </v-row>
                </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="close"
                >
                  Cancelar
                </v-btn>
                
                <v-btn
                
                  color="primary"
                  @click="save('editedItem','CODIGO','items_exames','exames')"
                  v-if = "readonly==false"
                  :disabled="!valido_exames"
                >

                      <span v-if="DEBUG==true">
                        &nbsp salvarXexame
                      </span>
                      <span v-else>
                        &nbsp salvar
                      </span>

                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>





          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Deseja realmente excluir este ítem? </span>
                <span class="text-overline"> &nbsp {{ editedItem.CODIGO }}</span>
              </v-card-title>
              <p/>
              <v-card-subtitle> {{ editedItem.nome }} </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="closeDelete">Cancelar</v-btn>
                <v-btn color="primary"  @click="deleteItemConfirm('editedItem','CODIGO', crud_table)">Confirmar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>


          


  </div>


  <Guias
    v-if="na_tela==false"
    :pro_dados = obj_sadt
  />


       <!-- MOSTRA JANELA MODAL -->
       <div class="mt-n10">
      <v-row>
        <v-col>
        <LembreteVue 
          :aberto = 'janela_modal'
          pro_tabela = 'MANIPULADOS'
          pro_titulo = 'Manipulados'
          :pro_obj_1 = obj_janela_modal
          :pro_obj_menus = null
          :show_menu = 'false'
          :pro_modo_edicao = 'true'
          @muda_aberto_lembretes="muda_aberto_lembretes"
          @modal_salvar="modal_salvar"
        />
        </v-col>
      </v-row>
    </div>




        <!-- MOSTRA JANELA MODAL -->
        <div class="mt-n10">
        <v-row>
          <v-col>
          <Modal_Editar 
            :aberto            = 'janela_modal_abre'
            :pro_tabela        = 'janela_modal_tabela'
            :pro_titulo        = 'janela_modal_titulo'
            :pro_obj_menus     = null
            :show_menu         = 'false'
            :pro_modo_edicao   = 'janela_modo_edicao'
            @muda_aberto_lembretes="janela_modal_change"
          />
          
          </v-col>
        </v-row>
      </div>


    </div>




</template>




<script>

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Image from '@tiptap/extension-image'

import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

import axios from "axios";
import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
import { removerCampos, somatoria_lista } from '../assets/js/DSi_basico'
import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
import Guias from './guias.vue';




var url = sessionStorage['url']



export default {
    
   components:{
      EditorContent,  
      Guias,
      LembreteVue:  () => import("./Lembretes.vue"),
      Modal_Editar: () => import("./Editar.vue")
   },

  data: () => ({


    mostrar_rodape: false,
    items_somatorias:[],

    itemsLista: [
        'Atestado',
        'Receitas',
        'Exames',
        'Outros',
        'Termos',
    ],

    pesquisar_tuss: 'HISTÓRICO', //'HISTÓRICO',

    def_parcelas : [],
    lista_parcelas:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
    items_tabela_formas: ['DINHEIRO', 'CREDITO','DEBITO','PIX','BOLETO','CONVENIO','GRATIS','CHEQUE','CORTESIA','OUTROS','PERMUTA','DEPOSITO'],

    validate: false,

    janela_modal_abre: false,
    janela_modal_tabela: '',
    janela_modal_titulo: '',
    janela_modo_edicao : false,

    obj_user: {},
    obj_dados_empresa: null,
    obj_dados_paciente:{},
    obj_doc_selecionado:{},

    dialog_ok : false,

    LSendereco :'',

    item_clicou_agenda :{},
    dialog_img: false,
    tem_foto: false,

    item_menu:{},

    janela_modal: false,
    modal_salvou: false,
    modal_qual:'',
    obj_janela_modal: {},

    vai_imprimir: false,
    
    menu_um: [],
    dialog_menu_right: false,
    obj_clicado : {},
    data_table_clicou : '',
    overlay: false,
    


    // EDITOR DE TEXTO TIP TAP -- INICIO
    editor: null,
    altura_meio: '16', //16cm
    // EDITOR DE TEXTO TIP TAP -- FIM

    // ------------ TABELAS INICIO ---------------
    col_menu : 1,
    col_five : 12,
    selectedItem: -1,
    card_titulo:'',
    dialog_tabela: false,
    tabela_selecionada:'',
    search: '',
    search_exames: '',
    search_manipulado: '',

    lista_tabelas:[],
    selected: [],
    items_lista:[],
    items_header: [],
    items_editaveis: [],
    items_count:[],
    item_edita: {},

    sub_codigo:0,
    sub_descricao:'',

    count_PT:0,
    count_PC:0,
    count_Reg:0,
    count_Lanc:0,
    count_tabelas:0,

    valido: false,
    valido_exames: false,
    valido_receitas: false,
    isMobile: false, 
    
    size_mobile:22,

    w_data: '99px',
    w_fone: '125px',
    w_cpf: '120px',
    w_nome: '200px',
    // ------------ TABELAS FIM ---------------




    is_doc: false,
    is_super: false,
    superA: false,

    TESTE: false,
    DEBUG: false,

    filtra_sala:'G',
    definir_A:[
        {text:'Todos',      sigla:''},
        {text:'Confirmado', sigla:'O'},
        {text:'Retorno',    sigla:'N'},
        {text:'Remarcado',  sigla:'Q'},
        {text:'Aguardando', sigla:'G'},
        {text:'Atendido',   sigla:'A'},
        {text:'Faltou',     sigla:'F'},
        {text:'Agendado',   sigla:'M'},
        ],

    espera_notas: false,

    ponteiro:0,


    // isso é chamado em meu_dsi_timer()
    dsi_timers:[
      { index:0, acao:'espera', ativo:'N', ultimo:'', proximo:''}
    ],

    obj_sadt:{},
    na_tela:true,


    pode_notas: true, //timer notas

    tab_1: "conduta",//conduta receitas exames imprimir manipulados
    tab_2: "vazio",//tab_calculos
    tab_receita: "vazio",
    tab_exame: "vazio",

items: [
        {
          action: '15 min',
          headline: 'Brunch this weekend?',
          subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          title: 'Ali Connors',
        },
        {
          action: '2 hr',
          headline: 'Summer BBQ',
          subtitle: `Wish I could come, but I'm out of town this weekend.`,
          title: 'me, Scrott, Jennifer',
        },
],

    value_expansion: 0, //0 ou null
    
    sala_lista  : [] ,
    sala_header : [] ,

    expanded: [],
    switch1:false,
    expandir_manipulado: false,

    editedIndex: -1,

    crud_table:'procedimento',

    isDoc:false, //permissoes
    
    snackbar: false,
    snack_text: 'My timeout is set to 2000.',
    snack_color: "success",

    noRules:[v => true],

    notnullRules: [
        v => !!v || 'necessário'
       ],

    hora:'',
    today:'',   
    data_hora :'',
    hoje:'',   
    hoje_mm_dd_yyyy:'',   

    timer: 60 * 20,//começa com 10 minutos
    timerAS: '',
   
    boo_atendeu: false,//caso ele salvou ele pode finalizar o atendimento
    boo_alterou: false,  //identifico quando digitou algo 
    mobile: false,
    boo_monta_historico: false,
    
    historico: [],
    receitas: [],
    
    count_h:0,
    count_r:0,
    count_e:0,

    count_notas: 0,
    count_imc: 0,
    count_gestante:0,
    count_manipulado:0,

    exames: [],
    obj_historico2:[],
    readonly: false,
    readonlyBor: true,
    pergunta: false,

    items_direita: [
        { text: 'Serviços', icon: 'mdi-format-list-bulleted-square', click:'tabela_servicos' },
        { text: 'Veículos', icon: 'mdi-car ', click:'veiculos' },
        { text: 'Instrutores', icon: 'mdi-account-voice ', click:'instrutores' },
    ],

    
 
    idConduta: 0,//recebe quando ja tem procedimento salvo.. assim ele edita 
       getCodigoDoc:0,
       getNomeDoc: 0,
       getCRMdoc:0,
       getCodigoConsulta:0,
       getCodigoPaciente:0,
       getCodigoConvenio:0,
       getNomePaciente:'',
       getConvenio:'',
       getProcedimento:'',
       getIdade:'',
       getProfissao:'',
       getData:'',
       getHora:'',
       getCidade:'',
       getCPF:'',
       getRG:'',
       getEstadoCivil:'',
       getEndereco:'',
       getSexo:'',
       getNascimento:'',
       nome_pai:'',
       nome_mae:'',
       is_menor:false,

    condutaItem: {
        CODIGO: 0,
        CODIGOCONSULTORIO: 10,
        CODIGOCONSULTA: 0,
        CODIGOCLIENTE:0,
        CODIGOMEDICO:0,
        DESCRICAO: '',
        DATA: '',
        TIPO: '',
    },

    res_notas:{
      CODIGO : 0,
      DESCRICAO : ''
    },

    novo_imc : true,
    novo_gestante : true,

    manipulado_clicou:[],
    manipulados_ultimos: [],
    headers_hist_manA:[
      // {text: '', value: 'actions', sortable: false },
      {text: 'MODELO', value:'NOME'},
      {text: '', value: 'actions_2', sortable: false },
    ],
    headers_hist_manB:[
      { text: '',width:'22px', value: 'actions', sortable: false },
      {text: 'Nome', value:'NOME'},
      {text: 'Modelo', value:'MODELO'},
    ],

    items_manipulado: [],
    headers_manipulado:[
      { text: 'Data', width:'10px', value: 'DATA'},
      { text: 'Nome', value: 'MEDICAMENTO'},
      {text: '', value: 'actions', sortable: false },
    ],

    tipo_gestante: '',
    items_gestante:[],
    headers_gestante:[
      { text: 'DUM', value: 'DATA' },
      { text: 'Parto', value: 'DATA2' },
      { text: 'Tempo', value: 'TEXTO' },
      { text: '..', value: '..' },
    ],

    edita_gestante: {
      CODIGO: 0,
      TEXTO: '',
      DATA:'',
      DATA2:'',
      X_TEXTO:'',
      X_DATA_ULTRA:'',
      X_SEMANAS:0,
      X_DIAS:0
    },   

    items_imc:[],
    headers_imc:[
      { text: 'Data', value: 'DATA' },
      { text: 'Peso', value: 'PESO' },
      { text: 'Altura', value: 'ALTURA' },
      { text: 'IMC', value: 'IMC' },
      { text: 'Resultado', value: 'RESULTPESO' },
      { text: 'PC', value: 'PC' },
      { text: '..', value: '..' },
    ],
    edita_imc: {
      CODIGO: 0,
      PESO: '',
      ALTURA: '',
      IMC: '',
      PESOMINIMO:0,
      PESOMAX:0,
      RESULTPESO:'',
      DATA:'',
      X_COR: 'red'
    },
    campos_imc:[
          'CODIGO',
          'TIPO',
          'DATA',
          'PESO',
          'ALTURA',
          'CODIGO_CLIENTE',
          'CODIGO_MEDICO',
          'IMC',
          'PESOMAX',
          'PESOMINIMO',
          'RESULTPESO'      
    ],


    receitas_ultimos: [],
    search:'',
    headers: [
      { text: '+', value: 'actions', sortable: false },
      { text: 'Medicamento', value: 'MEDICAMENTO' },
      { text: 'Qt', value: 'DOSE' },
      { text: 'Posologia', value: 'POSOLOGIA' }
    ],
    
    items_receitas: [],
    headers_receitas: [
      { text: 'Medicamento', value: 'MEDICAMENTO' },
      { text: 'Posologia', value: 'POSOLOGIA' },
      { text: 'Qtde', value: 'DOSE' },
      { text: 'Uso', value: 'USO' },
      // { text: 'Tipo', value: 'TIPO' },
      // { text: 'Codigo', value: 'CODIGO' },
      { text: '.', value: 'actions', sortable: false },
    ],

    exames_tuss: [],
    exames_ultimos: [],
    headers_hist_ex: [
      { text: 'Ad',width:'130px', value: 'actions', sortable: false },
      { text: 'Categoria', value: 'CATEGORIA' },
      { text: 'TUSS', value: 'COD96' },
      { text: 'Exame', value: 'EXAME' },  
    ],
    
    headers_tuss: [
      { text: 'Ad', width:'99px', value: 'actions', sortable: false },
      { text: 'TUSS', width:'90px', value: 'STRING_CODIGO_TUSS' },
      // { text: 'GRUPO', value: 'GRUPO_TUSS' },
      { text: 'Exame', value: 'PROCEDIMENTO' },  
    ],

    items_exames: [],
    headers_exames: [
      { text: 'Exame', value: 'EXAME',/* width:'200px' */},
      { text: 'TUSS', value: 'COD96' },
      // { text: 'Codigo', value: 'CODIGO' },
      { text: '.', value: 'actions', sortable: false },
    ],

    selected_receitas:[],
    selected_exames:[],
    dialog: false,
    dialog_exames: false,
    dialogDelete: false,
    EditaReceita:{},
    EditaExame:{},
    EditaManipulado:{},

    editedItem: {},
    md_rA:9,
    md_rB:3,

  }),

  computed: {
    formTitle () {
      // alert(this.tab)//pego conforme o tab selecionado...
      if (this.tab_1=='TABreceitas'){
        return this.editedIndex === -1 ? ('Nova Receita') : ('Editar') //this.editedItem.id 
      }
      else if(this.tab_1 == 'TABexames'){
        return this.editedIndex === -1 ? ('Novo Exame') : ('Editar') //this.editedItem.id 
      }
      
    },

    showHeaders () {
      if(this.expandir_manipulado){
        return this.headers_hist_manA
      }else{
        return this.headers_hist_manB
      }
      // return this.headers.filter(s => this.selectedHeaders.includes(s))  
    },
  },

  watch: {


    pesquisar_tuss(a){
      if (a!='TUSS'){
         this.search_exames = '' 
      }
    },

    dialog_tabela(val){
      this.$set(this.item_edita, 'x_VEZES', 1)
      this.$set(this.item_edita, 'x_TIPO', true)

      this.$set(this.item_edita, 'x_EFETUADO'    , ( this.item_edita.EFETUADO == 'S') ? true : false)

      this.def_parcelas = []

    },

    'item_edita.TEXTO_REGISTRO'(val){
      //  console.log('a:', val);
       if (val){
        this.editor.commands.setContent(val)//definio o texto
       }
       else{
        // this.editor.commands.setContent('<p> <br/> <span style="color: black"> clique aqui</span> </p>')//definio o texto
        // this.editor.commands.setContent('<p style="text-align: center"> <br/> <br/> <br/> <br/> ... </p>')//definio o texto
       }
    },

    'item_edita.TIPO'(val){
        this.campos_adicionais(this.item_edita)
    },



    async modal_salvou(a){
       if (a == true){
        this.alerta('Atualizando', 'blue')
        this.modal_salvou = false
        if (this.modal_qual=='MANIPULADOS'){
          this.manipulados_ultimos = []
          this.crud_manipulados_mais()
        }
       }
    },

    'edita_imc.ALTURA'(){
      this.calcular_imc(this.edita_imc.PESO, this.edita_imc.ALTURA)

    },

    'edita_imc.PESO'(){
      this.calcular_imc(this.edita_imc.PESO, this.edita_imc.ALTURA)
    },
    
    'edita_gestante.DATA'(){
      this.calcular_dum(this.edita_gestante.DATA)
    },

    'edita_gestante.X_DATA_ULTRA'(){
      this.calcular_ultra()
    },

    'edita_gestante.X_SEMANAS'(){
      this.calcular_ultra()
    },

    'edita_gestante.X_DIAS'(){
      this.calcular_ultra()
    },

    dialog (val) {
      val || this.close()
    },
    dialog_exames (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },


  filters: {
    minutesAndSeconds (value) {
      var minutes = Math.floor(parseInt(value, 10) / 60)
      var seconds = parseInt(value, 10) - minutes * 60
      return `${minutes}:${seconds}`
    }
  },

  created(){
      this.showDrawer()
      this.logado()
      this.update_values()
      this.ler_sessions()
      this.ler_historico()
      this.mobile = this.$vuetify.breakpoint.mdAndDown

      this.permite_items()
      this.isMobile = (window.innerWidth < 800)

      this.carregar_dados()
  },


  mounted () {
    // alert(this.$route.name)
    this.timerAutoSave()
    
    this.editor = new Editor({
      extensions: [

        StarterKit,
        Document,
        Text,
        Image,
        Paragraph,
        TextStyle,
        Color,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),

        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell
      ],

      onUpdate: ({ editor }) => {
        // const html = editor.getHTML();
        // this.$emit('input', html); // Emite o evento 'input' quando o conteúdo é atualizado
        this.editor_updated()
      },
    
      // content: '<p> <br/> clique aqui </p>'
      content: null

    })

  },

  beforeUnmount() {
    this.editor.destroy()
  },

  // resiz (){
  //   alert('oiiiii')
  //   if (this.$vuetify.breakpoint.mdAndUp){
  //     alert('a')
  //   }else{
  //     alert('b')
  //   }
  // },


  // watch:{
  //   'condutaItem.DESCRICAO'(a,b){
  //     console.log('b='+b);
  //     if (b==''){
  //       // alert('vazio')
  //     }
  //   }
  // },

  methods:{


    async abrir_exames_tuss(){
       

       let quero = this.search_exames

       if ((!quero)||(this.pesquisar_tuss !='TUSS')) {
        return false
       }


       let ZERO = quero.trim()

      let UM, DOIS, TRES;

      if (ZERO.includes(' ')) {
          let parts = ZERO.split(' ');

          UM = parts[0];
          DOIS = parts[1] || ''; // Garantindo que não haverá erro se houver menos de 3 partes
          TRES = parts[2] || '';
      } else {
          UM = ZERO;
          DOIS = ZERO;
          TRES = ZERO;
      }

       
       

       let sql = `
       SELECT *
        FROM
        TUSS
        WHERE
          ((TUSS.CODIGO_TUSS CONTAINING '${UM}') OR
          (TUSS.GRUPO_TUSS CONTAINING '${UM}') OR
          (TUSS.PROCEDIMENTO CONTAINING '${UM}'))AND
          
          ((TUSS.CODIGO_TUSS CONTAINING '${DOIS}') OR
          (TUSS.GRUPO_TUSS CONTAINING '${DOIS}') OR
          (TUSS.PROCEDIMENTO CONTAINING '${DOIS}'))AND
          
          ((TUSS.CODIGO_TUSS CONTAINING '${TRES}') OR
          (TUSS.GRUPO_TUSS CONTAINING '${TRES}') OR
          (TUSS.PROCEDIMENTO CONTAINING '${TRES}'))
          

        ORDER BY
        TUSS.PROCEDIMENTO
       `

      //  console.log('sql:', sql );

       
       await this.crud_abrir_tabela(sql,'exames_tuss')

      //  console.log('exames_tuss:', this.exames_tuss);
       

    },

    retorna_cor(campo){

      if (campo == 'TOTAL'){
        return 'blue'
      }
      if (campo == 'EFETUADO'){
        return 'green'
      }
      if (campo == 'PENDENTE'){
        return 'red'
      }
      else{
        return 'grey'
      }

    },

    getFormatCurrency(v,string){
      if (v){
        return DSibasico.getFormatCurrency(v, string)
      }
      else{
        return v
      }
      
    },



    async replace_array_soma(){

      // aqui substituo os campos especificos do array somatoria

      let tabela = this.tabela_selecionada

      const jsonString = JSON.stringify(this.items_somatorias);

      if (tabela == 'LANCAMENTOS'){
        const novaString = jsonString.replace(/"CAMPO":\s*"N"/g, '"CAMPO": "PENDENTE"')
                                     .replace(/"CAMPO":\s*"S"/g, '"CAMPO": "EFETUADO"');

        const novoArray = JSON.parse(novaString);


        this.items_somatorias = await novoArray
      }
    },

    permissoesUsuarios(botao){
            
       let tabela = this.tabela_selecionada

        if ( (tabela == 'LANCAMENTOS') || (tabela == 'VALORES_AMB') || (tabela == 'ANTIGO_HORARIO') ) {
           if (botao == 'excluir'){
             return this.obj_user.SUPERUSER == 'S'
           }
            
        }
        else if ((tabela == 'CONSULTORIOS')||((tabela == 'CONSULTORIOS'))){
              if (botao == 'novo'){
                return false
              }
              else if (botao == 'alterar'){
                return this.user_permissoes.superUser
              // return true
              }
            
            
        }
        else {
            return false
        }

        
    },


    verifica_vezes(){

      // limpo variaveis
      this.def_parcelas = []


      let pago  = this.item_edita.x_EFETUADO
      let vezes = this.item_edita.x_VEZES
      let tipo  = this.item_edita.x_TIPO

      let valor = parseFloat(DSibasico.strtofloat(this.item_edita.VALOR_UM));//passa para float
      let valor_string 


      //copio o objeto
      let copia =  Object.assign({}, this.item_edita)


      if (vezes>1){
          // alert('mais')

          if (tipo){
            // alert('x')

            valor = valor * vezes
            valor_string = DSibasico.floatTostring(valor)//para para string
            
            this.$set(this.item_edita, 'x_TOTAL'    , valor_string)


            // defino o valor no copia
            this.$set(copia, 'VALOR_UM'   , this.item_edita.VALOR_UM)
          }
          else{
            // alert('/')

            valor = valor / vezes
            valor_string = DSibasico.floatTostring(valor)//para para string
            
            this.$set(this.item_edita, 'x_TOTAL'    , valor_string)


            // defino o valor no copia
            this.$set(copia, 'VALOR_UM'   , valor_string)
          }


          //percorre a quantidade adicionando os valores no array
          for (let i = 0; i < vezes; i++){

            let copia_aqui =  Object.assign({}, copia)

            // defino alguns valores
            this.$set(copia_aqui, 'SEQUENCIA'  , `${parseInt(i+1)}/${vezes}` )
            this.$set(copia_aqui, 'DATA'  , DSibasico.incMonth(this.item_edita.DATA,i,'us') )

            if (!pago){
               this.$set(copia_aqui, 'EFETUADO'       , 'N')
               this.$set(copia_aqui, 'DATA_EFETUADO'  , '')
            }
            


            // //adiciona no array o objeto
            this.def_parcelas.push(copia_aqui)
          }

          // console.log('def_parcelas:', this.def_parcelas);


      }
      else{
        // alert('um')
        
        this.$set(this.item_edita, 'x_TOTAL'    , this.item_edita.VALOR_UM)

        if (!pago){
            this.$set(this.item_edita, 'EFETUADO'       , 'N')
            this.$set(this.item_edita, 'DATA_EFETUADO'  , '')
        }
        else{
            this.$set(this.item_edita, 'EFETUADO'       , 'S')
            this.$set(this.item_edita, 'DATA_EFETUADO'  , this.today)
        }
      }
    },

    abrir_cadastro(nova_aba, vem, acao, cs){

        // this.set_sessionStorage('qual_acao', 'novo')
        this.set_sessionStorage('qual_acao', acao)
        this.set_sessionStorage('nome_digitou', vem)
        this.set_sessionStorage('nome_digitou_abrir', 'S')


        if (nova_aba){
          let route = this.$router.resolve({path: '/paciente'});
          window.open(route.href, '_blank');
        }
        else{
          this.$router.push('/paciente')//redireciono para a pagina do router
        }
          
    },


    abrir_modal_cadastro(ide){

      this.abrir_cadastro(true, ide,'')

      // aqui abre janela modal so com os dados
      // this.abre_janela_modal(true, true, 'DADOS_CADASTRO', 'msg')
    },

    abre_janela_modal(status, edicao, tabela, titulo){
        
        this.janela_modal_tabela   = tabela
        this.janela_modal_titulo   = titulo
        this.janela_modo_edicao    = edicao
        this.janela_modal_abre     = status
     },


    janela_modal_change(valor){

      this.janela_modal_abre = valor

      let tabela = this.janela_modal_tabela
      // if (!valor){

      //   if (tabela == 'CANCELAR_AGENDA' ){
      //     if (sessionStorage['obj_janela_editar'] ){
      //         // alert('deu ok')

      //         let retorno = JSON.parse(sessionStorage['obj_janela_editar'])
      //         // console.log('retorno:', retorno);
      //         this.definir(this.item_principal, this.item_clicou_menu_definir, retorno.MOTIVO )
      //     }
      //     else{
      //       alert(false)
      //     }
      //   }
      // }
    },

    async remover_exame(item, grupo){
      
      // console.log('item:', item);


      if (!confirm(`Deseja Remover do Histórico ${item.EXAME}?`)){
        return false
      }


      let sql

      if (grupo){
        sql = `update exames set ver = 'N' where EXAME = '${item.EXAME}' and COD96 = '${item.COD96}' and  CATEGORIA = '${item.CATEGORIA}' and CODIGOMEDICO = ${this.getCodigoDoc}`
      }
      else{
        sql = `update exames set ver = 'N' where EXAME = '${item.EXAME}' and COD96 = '${item.COD96}' and CODIGOMEDICO = ${this.getCodigoDoc}`
      }

      // console.log('sql:', sql);

      if (await this.crud_sql(sql)){
          
          // atualizo
          const index = this.exames_ultimos.findIndex(itemm => JSON.stringify(itemm) === JSON.stringify(item));
          if (index !== -1){
            this.exames_ultimos.splice(index, 1)
          }
      }   

    },

    async clicou_em(acao){

      if (acao == 'get_impressao'){

         let ss = this.item_edita.TIPO

         if (ss == 'OUTROS'){ss = 'Outros'}//Atestado
         else if (ss == 'TERMOS'){ss = 'Termos'}
         
         this.imprimirAtestado(ss) 
         this.dialog_tabela = false
         
         this.dialog_ok = true //ao clicar em ok ele atualiza a tabela

      }

      else if (acao == 'verifica_vezes'){
        this.verifica_vezes()
      }

    },

    editor_updated(){

      return false 
      console.log('editor_updated');
      let tabela = this.tabela_selecionada
      if (tabela == 'WEB_REGISTROS'){
        this.$set(this.item_edita, 'TEXTO_REGISTRO', this.editor.getHTML())
      }

    },

    async campos_adicionais(item){

      // console.log('campos_adicionais');
      // return 'exit'

      if (this.tabela_selecionada=='WEB_REGISTROS'){
          
          removerCampos(this, 'items_editaveis', ['PATH_PDF','TEXTO_REGISTRO','get_impressao','NOME_TEXTO','PATH_ASSINATURA'])
          
          if (['IMAGEM','PDF'].includes(item.TIPO)){

              this.items_editaveis.push(
                { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S',class:'no-print' },
                // { text: 'PATH_PDF', value: 'PATH_PDF', tag:'v-text', type:'text', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                { text: 'PATH_PDF', value: 'PATH_PDF', tag:'v-img', width:'70%', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
              )
          }
          
          else if (['TEXTO'].includes(item.TIPO)){

              this.items_editaveis.push(
                { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'8',rule:'S', upper:'S',class:'no-print' },
                { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'v-textarea', type:'text', cols:'12', rows:'19',rule:'S' }
              )
          }
          
          else if (item.TIPO){  
          // if (['TERMOS'].includes(item.TIPO)){

            if (this.item_edita.TEXTO_REGISTRO){
              this.items_editaveis.push(
                { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'8',rule:'S', upper:'S',class:'no-print' },
                { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'p', type:'text', class:'mx-15', cols:'12', rows:'19',rule:'N' },
                // { text: 'CONTEÚDO', value: 'TEXTO_REGISTRO',tag:'v-editor', type:'text', cols:'12', rows:'19',rule:'N' },
                // { text: 'PATH_ASSINATURA', value: 'PATH_ASSINATURA', tag:'v-text', type:'text', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
                { text: 'PATH_ASSINATURA', value: 'PATH_ASSINATURA', tag:'v-img', width:'50%', cols:'12',rule:'N', if_campo:'ACEITOU', if_value:'S', upper:'N' },
              )
            }
            else{

              // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "NOME_TEXTO") , 1);//removo a coluna senha

              this.items_editaveis.push(
                { text: 'ABRIR IMPRESSÃO', value: 'get_impressao', tag:'v-chip', cols:'2', color:'primary', click:'get_impressao'}
              )
            }
          }

          
      }
    },

    clicou_em_novo(a,b){
      //  nova_tabela('item_edita','dialog_tabela')
       let tabela = this.tabela_selecionada

       if (tabela == 'WEB_REGISTROS!'){
         //alert('eu')
       }
       else{//padrao
         this.nova_tabela(a,b)
       }
    },

    async carregar_dados(){
        //  pega dados do paciente selecionado la na agenda
        try {
            this.item_clicou_agenda = JSON.parse(sessionStorage['item_clicou_agenda'])    || null;//pega da agenda.vue

        } catch (error) {
            this.item_clicou_agenda = null
        }


        this.tem_foto = await DSibasico.testar_url_img(this.item_clicou_agenda.FOTO_URL)// verifica foto valida
    },


    abrir_impressao(modelo, item){

      sessionStorage['param_modelo']     = modelo
      // sessionStorage['param_texto']      = item.DESCRICAO
      // sessionStorage['param_titulo']     = 'ATENDIMENTO'
      // sessionStorage['param_sub_titulo'] = 'SUB TITULO'

      sessionStorage['param_item']       =  JSON.stringify(item)

      let route = this.$router.resolve({path: '/imprimir'});
      window.open(route.href, '_blank');
    },

    async tem_manipulado(){
      // MANIPULADO": ""
    },

    imprimir_pagina(){
      this.vai_imprimir = true

      setTimeout(function(){
        window.print()
        this.vai_imprimir = false            
      }.bind(this), 111);
    },


    async imprimir_conteudo(headers, items, titulo, ocultar_header, auto_print){

      let componente = ''

      if (items.constructor === Array){
        componente = 'v-data-table'
        headers = JSON.stringify(headers)
        items = JSON.stringify(items)
      }
      else{
        componente = 'v-textarea'
      }

      // if (this.tabela_selecionada=='PT'){
      //   componente = 'v-textarea'
      //   items = this.item_edita.DESCRICAO
      // }


      sessionStorage['rel.qual']         = 'AUTO'
      sessionStorage['rel.componente']   = componente
      sessionStorage['rel.headers']      = headers
      sessionStorage['rel.items']        = items
      sessionStorage['rel.titulo']       = titulo

      sessionStorage['rel.auto_print']      = (auto_print) ? 'S' : 'N'
      sessionStorage['rel_ocultar_header']  = (ocultar_header) ? 'S' : 'N'

      //  this.$router.push('/relatorios')//redireciono para a pagina do router
      let route = this.$router.resolve({path: '/relatorios'});
      window.open(route.href, '_blank');
    },


    ordenarPorCampo(array, campo) {
      return array.sort(function(a, b) {
        if (a[campo] < b[campo]) {
          return -1;
        }
        if (a[campo] > b[campo]) {
          return 1;
        }
        return 0;
      });
    },


   agruparPorCampo(arrayOrdenado, campo) {
      var resultado = [];
      var grupoAtual = null;

      for (var i = 0; i < arrayOrdenado.length; i++) {
        var objeto = arrayOrdenado[i];

        if (objeto[campo] !== grupoAtual) {
          grupoAtual = objeto[campo];
          resultado.push({ [campo]: grupoAtual, itens: [] });
        }

        resultado[resultado.length - 1].itens.push(objeto);
      }

      return resultado;
    },


    meu_keyup(event,campo,upper){
  
      //identifica se o campo é double e muda o display
      if ((campo.includes('double_') || campo.includes('VALOR')) ){
        this.item_edita[campo] = DSibasico.moeda(event)
        // @keyup="editedItem.double_credito = moeda($event)"
      }
      else if (campo.includes('CEP')) {

        this.consulta_cep(this.item_edita[campo])    
      }
      else{
        if ((campo.includes('EMAIL')==false) &&
          (campo.includes('SENHA')==false) &&
          (campo.includes('SENHA_WEB')==false)){

            if (upper !=='N'){
              this.allUpper(event, campo)
            }
            
        }
      }
    },

    allUpper(event, field){
        let a = event.target.value;//captura o valor do event
        if (a!==''){  
            a = a.toUpperCase();
            this.item_edita[field] = a;
        } 
    },


    gerarListaMedicamentos(array) {
      let lista = '';

      // Cria um objeto para agrupar os medicamentos por categoria de uso
      const categorias = {};

      // Itera sobre o array de objetos e agrupa os medicamentos por categoria de uso
      for (const obj of array) {
        const categoria = obj.USO.trim().toUpperCase();

        if (!categorias[categoria]) {
          categorias[categoria] = [];
        }

        categorias[categoria] = categorias[categoria].concat(obj.itens);
      }

      // Gera a lista formatada
      for (const categoria in categorias) {
        lista += `${categoria}:\n`;

        const medicamentos = categorias[categoria];

        for (let i = 0; i < medicamentos.length; i++) {
          const medicamento = medicamentos[i];
          const nome = medicamento.MEDICAMENTO.trim();
          const quantidade = medicamento.DOSE.trim();
          const posologia = medicamento.POSOLOGIA.trim();

          lista += `${i + 1} - ${nome}`;

          if (quantidade !== '') {
            lista += ` ---------------- Qt: ${quantidade}`;
          }

          lista += '\n';

          if (posologia !== '') {
            lista += `     ${posologia}\n`;
          }
        }

        lista += '\n';
      }

      return lista;
    },




    retorna_altura(menos){
        // let r = '100vh'
        // let r = '720'
        // let r = (window.innerHeight - 80 - 60)
        let r = (window.innerHeight - menos)
        let height = window.innerHeight;

        return r
      },

    quebra_linha(entra){
      let r = ''
      if (entra){
        r = entra.replaceAll('\n', '<br/>')
      }

      return r
    },

    async abrir_janela_modal(item, acao){

      // console.log('abrir_janela_modal:', item);
      
  
      this.janela_modal      = true

      if (acao == 'editar'){
        this.obj_janela_modal  = item
      }
      else if (acao == 'novo'){
        this.obj_janela_modal  = null
      }

    },


    muda_aberto_lembretes(valor){
       this.janela_modal = valor
    },

    modal_salvar(valor, tabela){
       this.modal_salvou = valor
       this.modal_qual   = tabela
    },

    async rightClick_exames(event, item) {
      // console.log(item.item);
      let qual = 'exames'

      this.abre_menu_right(qual, item)
      event.preventDefault()
    },

    async rightClick_receitas(event, item) {
      // console.log(item.item);
      let qual = 'receitas'

      this.abre_menu_right(qual, item)
      event.preventDefault()
    },

    async abre_menu_3(item){

      let tabela = this.tabela_selecionada

      this.menu_um =  [] //limpa array
      this.item_menu = item
    


      if (tabela == 'LANCAMENTOS'){
        this.menu_um.push( 
            {type:'menu', title: `Recibo CNPJ (${this.item_menu.CODIGO})`,   icon:'mdi-checkbox-blank-circle',      icon_color: 'blue',    click: 'recibo_cnpj' },
            // {type:'menu', title: 'Recibo CPF',   icon:'mdi-checkbox-blank-circle',      icon_color: 'green',    click: 'recibo_cpf' },
          ) 
        }

      this.dialog_menu_right = true
    },


    async abre_menu_right(qual, item){
      this.menu_um =  [] //limpa array
      this.item_menu = item.item
      

      this.menu_um.push( 

          {type:'menu', title: 'Copiar Tudo',   icon:'mdi-content-copy',      icon_color: 'success',    click: 'copiar_'+qual },
          {type:'menu', title: 'Colar',         icon:'mdi-content-duplicate', icon_color: 'primary',    click: 'colar_'+qual },
      )

      if (qual == 'receitas'){
        this.menu_um.push( 

          {type:'menu', title: 'Remover desta lista',   icon:'mdi-pencil-remove ',      icon_color: 'red',    click: 'remover_'+qual },
          ) 
        }

      this.dialog_menu_right = true
    },

    async clicou_menu_um(qual){  
        this.dialog_menu_right = false


        // recibo
        if (qual == 'recibo_cnpj'){
          
 
           let item =  Object.assign({}, this.item_menu)//clono

           if (this.item_menu.EFETUADO == 'N'){
            alert('É necessário efetuar antes!')
            return false
           }

            //insiro alguns campos necessarios no objeto

            this.$set(item, 'EMPRESA_TIPO'   , 'CNPJ')//CPF ou CNPJ

            this.$set(item, 'EMPRESA'        , sessionStorage['LSEmpresa'])
            this.$set(item, 'EMPRESA_CNPJ'   , sessionStorage['LScnpj'])
            this.$set(item, 'EMPRESA_FONES'  , sessionStorage['LSfones'])
            this.$set(item, 'EMPRESA_END'    , sessionStorage['LSendereco'])
            this.$set(item, 'EMPRESA_EMAIL'  , sessionStorage['emp_email'])
            this.$set(item, 'EMPRESA_CIDADE' , sessionStorage['LSCidade'])
            this.$set(item, 'CRM_DOC'        , this.item_menu.CRM)
            this.$set(item, 'VALOR'          , this.item_menu.VALOR_UM)
            this.$set(item, 'PROCEDIMENTO'   , this.item_menu.CATEGORIA)
            this.$set(item, 'DATA'           , this.item_menu.DATA)

            this.$set(item, 'NOME'           , this.obj_dados_paciente.NOME)
            this.$set(item, 'CPF'            , this.obj_dados_paciente.CPF)

            sessionStorage['recibo:dados']  =  JSON.stringify(item)

            let v = parseFloat(this.item_menu.VALOR_UM)
            if (v){
              let route = this.$router.resolve({path: '/recibo'});
              window.open(route.href, '_blank');          
            }
            else{
              alert('Defina um Valor Válido!')
            }

        }

        // RECEITAS
        else if (qual == 'copiar_receitas'){

            sessionStorage['clipboard'] = JSON.stringify(this.receitas_ultimos)
            // console.log(this.receitas_ultimos);
        }
        else if (qual == 'colar_receitas'){

          let obj = JSON.parse(sessionStorage['clipboard'])
          if (obj){

            this.overlay = true

            console.log('colar esse daqui');
            // console.log(obj);

            await this.copiaAllreceita(obj, 'RECEITAS')

            this.overlay = false
            this.alerta('Concluído','primary')

            this.receitas_ultimos = [] //limpa para atualizar
            this.crud_receitas_mais()//refresh
          }
        }
        else if (qual == 'remover_receitas'){

         
          let sql = `UPDATE RECEITAS SET RECEITAS.VER='N' WHERE MEDICAMENTO ='${this.item_menu.MEDICAMENTO}' AND DOSE ='${this.item_menu.DOSE}' AND POSOLOGIA ='${this.item_menu.POSOLOGIA}' AND USO='${this.item_menu.USO}'`
          // console.log(sql);
          this.crud_sql(sql)

          // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
          let index = this.receitas_ultimos.indexOf(this.item_menu)
          // alert(index)

          this.receitas_ultimos.splice(index, 1);
        }


        // EXAMES
        if (qual == 'copiar_exames'){

            sessionStorage['clipboard'] = JSON.stringify(this.exames_ultimos)
            // console.log(this.exames_ultimos);
        }
        else if (qual == 'colar_exames'){

          let obj = JSON.parse(sessionStorage['clipboard'])
          if (obj){

            this.overlay = true

            console.log('colar esse daqui');
            // console.log(obj);

            await this.copiaAllreceita(obj, 'EXAMES')

            this.overlay = false
            this.alerta('Concluído','primary')


            this.exames_ultimos = [] //limpa para atualizar
            this.crud_exames_mais()//refresh
          }
        }


    },

    showDrawer(){
       this.$emit('executar', true)//mostrar ou nao paineis
    },

    permite_items(){
        //passo 1 monto os items e suas tabelas e permissoes

        let s = []


        // s.push( { text: 'Convênios', icon: 'mdi-home-city ', click:'CONVENIO' } )

        this.lista_tabelas = [
        { text: 'Plano Tratam.', count: 0, icon: 'mdi-clipboard-list-outline ', click:'PT' },
        { text: 'Proc. Clínico', count: 0 , icon: 'mdi-format-list-checks', click:'PC' },
        { text: 'Registros', count: 0 , icon: 'mdi-text-box ', click:'WEB_REGISTROS', color:'success' },
        { text: 'Lançamentos', count: 0 , icon: 'mdi-credit-card-multiple ', click:'LANCAMENTOS', color:'primary' },
        ]

        this.return_count('PT', 'count_PT')
        this.return_count('PC', 'count_PC')
        this.return_count('WEB_REGISTROS', 'count_Reg')
        this.return_count('LANCAMENTOS', 'count_Lanc')
        

        // this.lista_tabelas = s
    },

    async return_count(tabela, onde){

      let sql = ''
      if (tabela == 'PT'){
        sql = `select  '${tabela}' as tipo, count(codigo) as count_codigo from ${tabela} where codigo_paciente = ${this.getCodigoPaciente}`
      }
      else if (tabela == 'PC'){
        sql = `select  '${tabela}' as tipo, count(codigo) as count_codigo from ${tabela} where codigo_paciente = ${this.getCodigoPaciente}`
      }
      else if (tabela == 'WEB_REGISTROS'){
        sql = `select  '${tabela}' as tipo, count(codigo) as count_codigo from ${tabela} where codigo_cliente = ${this.getCodigoPaciente}`
      }
      else if (tabela == 'LANCAMENTOS'){
        sql = `select  '${tabela}' as tipo, count(codigo) as count_codigo from ${tabela} where codigo_cliente = ${this.getCodigoPaciente}`
      }

      // console.log( tabela+' : '+sql);
      await this.crud_abrir_tabela(sql,'items_count')

      await definir(this, onde)

      async function definir(that,onde){
         that[onde] = await that.items_count[0].COUNT_CODIGO

         that.count_tabelas += parseInt(that[onde])
      }
    },


    getVmask(qual){
            
      if (qual == 'cel'){
          return "(##) #####-####"
      }
      else if (qual == 'fone'){
          return "(##) ####-####"
      }
      else if (qual == 'cnpj'){
          return "##.###.###/####-##"
      }
      else if (qual == 'cpf'){
          return "###.###.###-##"
      }
      else if (qual == 'cep'){
          return "#####-###"
      }
      else{
          return ""
      }
    },

    async tabela(tabela, acao, item){
      //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table


        if (!item){
          sessionStorage['tabela_clicou']   = tabela
          sessionStorage['tabela_titulo']   = this.card_titulo
        }

        this.search = ''//limpa filtro

        this.tabela_selecionada = tabela
        this.mostrar_rodape     = false

      //   console.log('item:');
      //   console.log(item);

        //limpa array
        this.items_lista       = [] //recebe os dados do sql
        this.items_header      = [] //campos que tem que mostrar no header
        this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header


        this.col_five = 12

        
        if (tabela=='LANCAMENTOS'){


          this.col_menu = 1

          //limpa campos sub
          this.sub_codigo    = 0
          this.sub_descricao = ''

          this.items_header = [

              { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
              { text: '..', value: 'menu', sortable: false, type:'hide', cols:'0',width:'5px', click:'agora'},
              { text: 'Codigo', value: 'CODIGO', type:'number', cols:'0' },
              { text: 'Seq', value: 'SEQUENCIA', tag:'v-text', type:'text', cols:'1'},
              // { text: 'EFETUADO', value: 'EFETUADO', tag:'v-text', type:'text', cols:'1'},

              { text: 'Vencimento', value: 'x_DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },

              { text: 'Efetuado', value: 'x_DATA_EFETUADO', tag:'v-text', type:'date', cols:'2', width: this.w_data,},
              
              
              // { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data },

              { text: 'Valor', value: 'VALOR_UM', tag:'v-text', type:'text', cols:'2',rule:'S', upper:'N', click:'verifica_vezes' },


              { text: 'X', text_2:'/', value: 'x_TIPO', tag:'v-checkbox', cols:'1',rule:'S', upper:'N', class_col:'',click:'verifica_vezes', style_tag: {'margin-left': '-4px'} },

              { text: 'Parcelas', value: 'x_VEZES',
                  type:'text',
                  tag:'v-select',
                  tag_items: this.lista_parcelas,
                  cols:'1',rule:'S' ,
                  click:'verifica_vezes',
                  class:'ml-n4'
              },

              

              // { text: 'Total', value: 'x_TOTAL', tag:'v-chip-value', color:'', cols:'1', click:'', class_col:'px-0', class:'ml-n3', hint:''},



              { text: 'Forma', value: 'FORMA_PAGAMENTO',
                  type:'text',
                  tag:'v-select',
                  tag_items: this.items_tabela_formas,
                  cols:'3',rule:'S' ,
                  click:'verifica_vezes'
              },

              // { text: 'CATEGORIA', value: 'CATEGORIA', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
              // { text: 'OPERACAO', value: 'OPERACAO', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
              // { text: 'PROFISSIONAL', value: 'PROFISSIONAL', tag:'v-text', type:'text', cols:'3',rule:'S', upper:'S', width: this.w_nome },
              
              { text: 'Categoria', value: 'CATEGORIA', tag:'v-text', type:'text', cols:'5',rule:'S', upper:'S', width: this.w_nome, click:'verifica_vezes' },
              { text: 'Descrição', value: 'DESC_PROCEDIMENTO', tag:'v-text', type:'text', cols:'7',rule:'N', upper:'S', width: this.w_nome, click:'verifica_vezes' },
              
              // { text: 'LANCOU', value: 'LANCOU', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S', width: '220px' },
              { text: 'Alterou', value: 'ALTEROU', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S', width: '220px' },
              
              

          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

          // removo da edição
          // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "PROFISSIONAL") , 1);//removo a coluna senha
          this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DATA") , 1);//removo a coluna senha
          this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "x_DATA_EFETUADO") , 1);//removo a coluna senha
          this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "LANCOU") , 1);//removo a coluna senha
          // this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ALTEROU") , 1);//removo a coluna senha
          this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "SEQUENCIA") , 1);//removo a coluna senha


          // ADICIONO SOMENTE NA EDIÇÃO
          this.items_editaveis.unshift(
              { text: 'Vencimento', value: 'DATA', tag:'v-text', type:'date', cols:'2',click:'verifica_vezes' },

              // { text: 'EFETUADO', text_2:'EFETUADO', value: 'x_EFETUADO', tag:'v-checkbox', cols:'1',rule:'N', upper:'N', class_col:'red', class_tag:'mt-9 ml-10',click:'verifica_vezes', if:'x_EFETUADO' },
              // { text: 'EFETUADO', text_2:'EFETUADO', value: 'x_EFETUADO', tag:'v-checkbox', cols:'1',rule:'N', upper:'N', class_col:'blue', class_tag:'ml-10',click:'verifica_vezes', else:'x_EFETUADO' },
              { text: 'EFETUADO', text_2:'PENDENTE', value: 'x_EFETUADO', tag:'v-checkbox', cols:'1',rule:'N', upper:'N', class_col:'', 
                class_if:'x_EFETUADO', class_tag: 'mt-9 ml-10', class_tag2: 'ml-10', click:'verifica_vezes'},

              { text: 'Efetuado', value: 'DATA_EFETUADO', tag:'v-text', type:'date', cols:'2', click:'verifica_vezes', if:'x_EFETUADO', class:'', class_tag:'ml-n10' },
          )
          


          this.items_header.splice(this.items_header.findIndex(x => x.value === "x_VEZES") , 1);//removo a coluna senha
          this.items_header.splice(this.items_header.findIndex(x => x.value === "x_TIPO") , 1);//removo a coluna senha

          let sql = `
                SELECT
                  CODIGO,
                  CODIGO_CLIENTE,
                  CODIGO_PROCED,
                  CODIGO_CONSULTA,
                  CODIGO_PROFISSIONAL,
                  CODIGO_CONVENIO

                  COD_USER_RECEBEU,
                  COD_USER_LANCOU,

                  TIPO,
                  EFETUADO,
                  VISTO,
                  DATA,
                  DATA_LANCAMENTO,
                  DATA_EFETUADO,
                  
                  R_RECEBEU,
                  ALTEROU,
                  LANCOU,
                  CATEGORIA,
                  PROFISSIONAL,
                  VALOR_UM,
                  VALOR_DOIS,
                  FORMA_PAGAMENTO,

                  OPERACAO,
                  DESCRICAO,
                  DESC_PROCEDIMENTO,

                  SEQUENCIA
                FROM
                  LANCAMENTOS
                WHERE  (LANCAMENTOS.CODIGO_CLIENTE = ${this.getCodigoPaciente}) order by DATA desc, CODIGO desc`


          // console.log( tabela+' : '+sql);
          await this.crud_abrir_tabela(sql,'items_lista')

          await somatoria_lista(this, 'items_lista', 'items_somatorias', 'VALOR_UM', 'EFETUADO', false)
          
          await this.replace_array_soma()

          this.mostrar_rodape = true 

        }

        else if (tabela=='PT'){

          this.col_menu = 1

          //limpa campos sub
          this.sub_codigo    = 0
          this.sub_descricao = ''

          this.items_header = [

              { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
              // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
              { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'S' },
              { text: 'DESCRIÇÃO', value: 'DESCRICAO',tag:'v-textarea', type:'text', cols:'12', rows:'19',rule:'S' },
              
              
          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

          // removo os campos que nao quero visivel somente para edicao
          // this.items_header.splice(this.items_header.findIndex(x => x.value === "NOTAS") , 1);//removo a coluna senha

          let sql = `select * from ${tabela} where codigo_paciente = ${this.getCodigoPaciente} order by data`

          // console.log( tabela+' : '+sql);
          this.crud_abrir_tabela(sql,'items_lista')
        }

        else if (tabela=='WEB_REGISTROS'){


          this.col_menu = 1

          if (!this.isMobile){this.col_five =  5}//somente se nao for mobile
          
          this.items_header = [

              { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'30px'},
              // { text: 'CODIGO', value: 'CODIGO', tag:'v-text', type:'number', cols:'0' },
              { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: '80px',rule:'S' },
              { text: 'Tipo', value: 'TIPO', type:'text', tag:'v-select', tag_items:['TEXTO','TERMOS','OUTROS'],cols:'2',rule:'S', class : 'no-print'  },      
              // { text: 'Tipo', value: 'TIPO', type:'text', tag:'v-select', tag_items:['PDF','IMAGEM','TEXTO','TERMOS','OUTROS'],cols:'2',rule:'S', class : 'no-print'  },      
              { text: 'Descrição', value: 'NOME_TEXTO', tag:'v-text', type:'text', cols:'7',rule:'S', upper:'S' },
              // { text: 'Profissional', value: 'NOME_DOC', tag:'v-text', type:'text', cols:'0' },
              
          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

          let sql = `SELECT * FROM ${tabela} where CODIGO_CLIENTE = ${this.getCodigoPaciente}  order by CODIGO desc`
          
          //  console.log('usuarios: '+sql);
          await this.crud_abrir_tabela(sql, 'items_lista')

          await this.simula_click()

        }

        else if (tabela=='PC'){

          this.col_menu = 1

          //limpa campos sub
          this.sub_codigo    = 0
          this.sub_descricao = ''

          this.items_header = [

              { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
              // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
              { text: 'DATA', value: 'DATA', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'S' },
              { text: 'DESCRIÇÃO', value: 'PROCEDIMENTO',tag:'v-textarea', type:'text', cols:'12', rows:'6',rule:'S' },
              
          ]

          // clono os items
          this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

          // removo os campos que nao quero visivel somente para edicao
          // this.items_header.splice(this.items_header.findIndex(x => x.value === "NOTAS") , 1);//removo a coluna senha

          let sql = `select * from ${tabela} where codigo_paciente = ${this.getCodigoPaciente} order by data`

          // console.log( tabela+' : '+sql);
          this.crud_abrir_tabela(sql,'items_lista')
        }

    },

    async define_defaults(tabela){

      // --------------------DEFINE DATA E HORA-----------------------
      let hoje = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });  
      // console.log('hoje:'+ hoje);

      let today = hoje.slice(6, 10) +'-'+ hoje.slice(3, 5) +'-'+ hoje.slice(0, 2) ; //formato us yyyy-mm-dd
      // console.log('today:'+ today);

      let hora = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
      let hora_curta = hora.slice(0, 5)
      // console.log('hora = '+ hora);
      // console.log('hora = '+ hora_curta);
      // --------------------DEFINE DATA E HORA-----------------------


      let quem_alterou = await DSibasico.returna_quem_alterou()


      // passo 7 defino valores default para alguns campos de determinada tabela
      if (tabela=='PT'){
        this.$set(this.item_edita, 'DATA', today)
        this.$set(this.item_edita, 'CODIGO_PACIENTE', this.getCodigoPaciente)
        this.$set(this.item_edita, 'CODIGO_MEDICO',   this.getCodigoDoc)
        this.$set(this.item_edita, 'CODIGO_DIAG',     0)
        this.$set(this.item_edita, 'TIPO',           '1')
      }
      else if (tabela=='WEB_REGISTROS'){
        this.$set(this.item_edita, 'DATA', today)
        this.$set(this.item_edita, 'CODIGO_CLIENTE', this.getCodigoPaciente)
        this.$set(this.item_edita, 'CODIGO_DOC'    ,  this.getCodigoDoc)
        this.$set(this.item_edita, 'TEXTO_REGISTRO',  null)
      }
      else if (tabela=='PC'){
        this.$set(this.item_edita, 'DATA', today)
        this.$set(this.item_edita, 'CODIGO_PACIENTE', this.getCodigoPaciente)
        this.$set(this.item_edita, 'CODIGO_MEDICO',   this.getCodigoDoc)
        this.$set(this.item_edita, 'CODIGO_DIAG',     0)
        this.$set(this.item_edita, 'TIPO',           '1')
      }
      else if (tabela=='LANCAMENTOS'){
        this.$set(this.item_edita, 'CODIGO_CLIENTE'        , this.obj_dados_paciente.CODIGOCLIENTE)
        this.$set(this.item_edita, 'DESCRICAO'             , this.obj_dados_paciente.NOME)
        this.$set(this.item_edita, 'CODIGO_CONVENIO'       , this.obj_dados_paciente.CODIGOCONVENIO)
        this.$set(this.item_edita, 'CONVENIO'              , this.obj_dados_paciente.CONVENIO)
        this.$set(this.item_edita, 'CODIGO_PROFISSIONAL'   , this.obj_dados_paciente.CODIGOMEDICO)
        this.$set(this.item_edita, 'PROFISSIONAL'          , this.obj_dados_paciente.PROFISSIONAL)
        this.$set(this.item_edita, 'DATA'                  , this.today)
        this.$set(this.item_edita, 'DATA_LANCAMENTO'       , this.today)
        this.$set(this.item_edita, 'DATA_EFETUADO'         , this.today)

        this.$set(this.item_edita, 'FORMA_PAGAMENTO'       , 'DINHEIRO')
        this.$set(this.item_edita, 'OPERACAO'              , 'AGENDA')

        this.$set(this.item_edita, 'CATEGORIA'             , '')
        this.$set(this.item_edita, 'DESC_PROCEDIMENTO'     , '')
        this.$set(this.item_edita, 'VALOR_UM'              , '')

        this.$set(this.item_edita, 'TIPO'                  , 'E')
        this.$set(this.item_edita, 'EFETUADO'              , 'S')
        this.$set(this.item_edita, 'VISTO'                 , 'N')
        this.$set(this.item_edita, 'SEQUENCIA'             , '1')
        this.$set(this.item_edita, 'LANCOU'                , quem_alterou)
        this.$set(this.item_edita, 'ALTEROU'               , quem_alterou)

        this.$set(this.item_edita, 'x_TIPO'                , 'X')
        this.$set(this.item_edita, 'x_VEZES'               , 1)
        this.$set(this.item_edita, 'x_EFETUADO'            , true)

        DSibasico.returna_quem_alterou()
      }

    },

    async before_update(tabela){
      // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela


      // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS

      if (this.tabela_selecionada == 'WEB_REGISTROS'){
        
        if (!['IMAGEM','PDF','TEXTO'].includes(this.item_edita.TIPO)){
           this.$set(this.item_edita, 'TEXTO_REGISTRO', this.editor.getHTML())
        }
      }

      let VERIFICAR = [
         'CADASTRO_ORC|CODIGO_CLIENTE|0',
         'LANCAMENTOS|DATA|null',
         'LANCAMENTOS|DATA_EFETUADO|null',
         'LANCAMENTOS|DATA_LANCAMENTO|null',
         'LANCAMENTOS|CODIGO_CONSULTA|0',
         'LANCAMENTOS|COD_USER_LANCOU|0',
         'LANCAMENTOS|COD_USER_RECEBEU|0',
         'LANCAMENTOS|CODIGO_PROCED|1',
      ]
    
      let CAMPOS      = []
      let CAMPO_VER   = ''
      let CAMPO_VALOR = ''
      for (let i = 0; i < VERIFICAR.length; i++) {
          
          CAMPOS      = VERIFICAR[i].split("|");//divide
          CAMPO_VER   = CAMPOS[1]
          CAMPO_VALOR = this.item_edita[CAMPO_VER]

          if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
            if (!CAMPO_VALOR){//verifica se vazio
              this.$set(this.item_edita, CAMPOS[1], CAMPOS[2] ) 
            }
          }
      }


      if (tabela == 'LANCAMENTOS'){
        let quem_alterou = await DSibasico.returna_quem_alterou()
        this.$set(this.item_edita, 'ALTEROU', quem_alterou)

      }
    },

    seleciona_linha(row) {
        this.selected= [{"CODIGO" : row.CODIGO}] //via v-modal no data-table

        //clonando objeto
        this.obj_clicado = Object.assign({}, row)
    },

    async simula_click(){

      // quando altera um texto ou imagem.ele atualiza simulando o click no item
       let CODIGO 
       try{
         CODIGO = this.selected[0].CODIGO
       }
       catch (error) { return false}

       if (CODIGO){       
         let achar = this.items_lista.filter(x => (x.CODIGO === CODIGO));//clono somente os codigos da lista atualizada
         achar = achar[0]
         if (achar){
          this.obj_clicado = Object.assign({}, achar)
         }
        //  console.log('achar:', achar);
       }
    },

    getColorSituacao (v,sub) {
        if ((sub =='')||(sub == null)){
          if (v == 'G') return '#0080ff'
          if (v == 'N') return '#804040'
          if (v == 'F') return '#ff8080'
          if (v == 'L') return '#ff8080'
          if (v == 'T') return '#dede03'
          if (v == 'O') return '#800080'
          if (v == 'H') return '#800080'
          if (v == 'Q') return '#E70EA8'
          if (v == 'C') return 'black'
          else if (['D','B'].includes(v)) return 'red'
          else if (v == 'A') return 'green'
          else return 'grey'

        }
        else {
          return '#03e3e3'
        }
      },

      copiaUMreceita(value, qual){
        // console.log(value);

        
        if (qual == 'RECEITAS'){
          this.EditaReceita['DOSE'] = ' ' 

        
          if (value.MANIPULADO?.length>5){
            // alert('manipulado')
            // this.$set(value, 'TIPO',  'M');
          }
          this.novo_receita('receitas',this.EditaReceita, value );
        }
        else if (qual == 'EXAMES'){
          this.novo_exame('exames',this.EditaExame, value );
        }
        

        if (qual == 'RECEITAS'){
          //atuaizar o manipulado
          this.crud_manipulado(this.getCodigoPaciente, this.getCodigoConsulta)
        }
      },

      async copiaAllreceita(value, qual){
        // console.log(value);

        for (let i = 0; i < value.length; i++) {

          if (qual == 'RECEITAS'){
            this.EditaReceita['DOSE'] = ' ' 
            await this.novo_receita('receitas',this.EditaReceita, value[i] );
          }
          else if (qual == 'EXAMES'){
            // this.EditaReceita['DOSE'] = ' ' 
            await this.novo_exame('exames',this.EditaExame, value[i] );
          }
        }



        if (qual == 'RECEITAS'){
          //atuaizar o manipulado
          this.crud_manipulado(this.getCodigoPaciente, this.getCodigoConsulta)
        }

      },

      modelos_rodape(tipo){

        let rodape = ''

        if (this.getCRMdoc == '22705'){ tipo = 2} //dr carlos

        if (tipo == 1){
          rodape  = sessionStorage['LSCidade2']+', '+ this.hoje + '<BR>' + '___________________________________'+ '<BR>'+ this.getNomeDoc+' - CRM:'+ this.getCRMdoc
        }
        else if (tipo == 2){
          rodape  =   sessionStorage['LSCidade2']+', '+ this.hoje + '<BR><BR>' +'___________________________________'
        }

        return rodape
      },

      imprimir_sadt(){
        //monto um objeto para passar para o componente guias.vue

        // //insiro no objeto os valores
        this.$set(this.obj_sadt, 'PACIENTE',    this.getNomePaciente);
        this.$set(this.obj_sadt, 'PROFISSIONAL',this.getNomeDoc);
        this.$set(this.obj_sadt, 'CONVENIO',    this.getConvenio);
        this.$set(this.obj_sadt, 'CRM',         this.getCRMdoc);
        this.$set(this.obj_sadt, 'CRM_UF',      sessionStorage['getCRMuf']);
        this.$set(this.obj_sadt, 'CONSELHO',    this.getConselho);
        this.$set(this.obj_sadt, 'CLINICA',     sessionStorage['LSEmpresa']);


        console.log('selected_exames', this.selected_exames.length);
        if (this.selected_exames.length >0){
          this.$set(this.obj_sadt, 'EXAMES',this.selected_exames);
        }
        else{
          this.$set(this.obj_sadt, 'EXAMES',this.items_exames);
        }

        // Transformar o objeto em string e salvar em sessionStorage
        sessionStorage.setItem('obj_sadt', JSON.stringify(this.obj_sadt));

        let route = this.$router.resolve({path: '/guias'});
        window.open(route.href, '_blank');
      },

      calcula_idade(data,campos){
        let s = DSibasico.calcula_idade(data, campos)

        if (s.years <18){
          this.is_menor = true
        }else{
          this.is_menor = false
        }

        return s.texto
      },

     passa_local_storage(){
        
        //limpa alguns dados
        sessionStorage['LSreceita'] = ''

        sessionStorage['printNomePaciente'] = this.getNomePaciente
        sessionStorage['printRG']           = this.getRG
        sessionStorage['printCPF']          = this.getCPF

        sessionStorage['printIdade']        = this.getIdade
        sessionStorage['printEstadoCivil']  = this.getEstadoCivil
        sessionStorage['printSexo']         = this.getSexo
        sessionStorage['printConvenio']     = this.getConvenio
        sessionStorage['printProfissao']    = this.getProfissao

        sessionStorage['printHora']         = this.getHora
        sessionStorage['printHoje']         = this.hoje
        sessionStorage['printNomeDoc']      = this.getNomeDoc
        sessionStorage['printCRMdoc']       = this.getCRMdoc
        sessionStorage['printConselho']     = this.getConselho

        sessionStorage['printMae']          = this.nome_mae
        sessionStorage['printPai']          = this.nome_pai
     },

     scroll_to(id, posicao){
       //tem que definir um id para o componente 
       let container = document.getElementById([id])
       if (container !== null){
        container.scrollTop = posicao // not working
       }
       // let container = document.getElementById('id_sala')
     },

     meu_dsi_timer(){
       
       for(let i = 0; i <= this.dsi_timers.length-1; i++){

         // timers=[{ index:0, acao:'espera', ativo:'N', ultimo:'', proximo:''}]
          // console.log('meu_timer:'+i);

          if (this.dsi_timers[i].ativo == 'S'){

             let acao     = this.dsi_timers[i].acao//identifica a acao
             let proximo  = this.dsi_timers[i].proximo//identifica horario da proxima execucao
             let agora    = this.getHoraAgora()//pega hora agora

             if (agora >= proximo){
                //  console.log('pode executar')

                this.dsi_timers[i].ativo = 'N'//zera a pendencia

                if (acao == 'espera'){
                   if (this.value_expansion == 0){//somente se estiver com o expansion menu aberto
                     this.abrir_salaespera(this.filtra_sala) //atualiza lista de espera
                   }else{
                     //ativa o meu_dsi_timer 1 minuto
                     this.salva_timer(0, 60000)
                   }
                }
             }
          }
       }
     },

      salva_timer(index_timer, intervalo){

        let agora = this.getHoraAgora()
        
        let aux     = new Date('2021-01-01 '+agora);
        let proximo = new Date(aux.getTime() + intervalo) //acrescentando intervalo no horario inicial
        proximo = new Date(proximo).toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });//pego horario da data-hora
        

        this.dsi_timers[index_timer].ultimo  = agora
        this.dsi_timers[index_timer].proximo = proximo
        this.dsi_timers[index_timer].ativo = 'S'

      },

      getHoraAgora(){
        let a = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' })
        return a
      },

      getColorPeso(texto){
    
          if (texto.includes('Abaixo')) return 'red'
          else if (texto.includes('Normal')) return 'primary'
          else if (texto.includes('Acima')) return 'orange'
          else if (texto.includes('Obeso')) return 'red'
          else return 'white'
      },

      getColorSituacao (v,sub) {
        if ((sub =='')||(sub == null)){
          if (v == 'G') return '#0080ff'
          if (v == 'N') return '#804040'
          if (v == 'F') return '#ff8080'
          if (v == 'L') return '#ff8080'
          if (v == 'T') return '#dede03'
          if (v == 'O') return '#800080'
          if (v == 'H') return '#800080'
          if (v == 'Q') return '#E70EA8'
          if (v == 'C') return 'black'
          else if (['D','B'].includes(v)) return 'red'
          else if (v == 'A') return 'green'
          else return 'grey'

        }
        else {
          return '#03e3e3'
        }
      },

      getFormatTime(hora){
        if (hora !== undefined){
           let shora =   hora.substring(0,2) + ':'+ hora.substring(3,5)//formato a hh:mm
           return shora 
        }else{
          return ''
        }  
      },

      abrir_salaespera(tipo){

          sessionStorage.setItem('filtra_sala', tipo) 

          //limpa array
          this.sala_lista = [] 
          this.sala_header = [] 


          this.sala_header = [
              // { text: 'CODIGO', value: 'CODIGO'},
              { text: 'HORA', value: 'HORA'},
              { text: 'NOME', value: 'NOME'},
          ]
          let sql = 
          `
            SELECT
            AGENDA.CODIGO,
            AGENDA.CODIGOCONSULTA,
            AGENDA.HORA,
            AGENDA.HORA_CHEGADA,
            AGENDA.NOME,
            AGENDA.ATENDIDO,
            AGENDA.CONVENIO
            FROM
            AGENDA
            WHERE
            (AGENDA.DATA = 'TODAY')AND
            ((AGENDA.ATENDIDO containing '${tipo}')AND
            (AGENDA.ATENDIDO <> 'C'))AND
            (AGENDA.CODIGOMEDICO =` + this.getCodigoDoc + `)
            ORDER BY HORA
          `

          // (AGENDA.ATENDIDO = 'G')AND
          // console.log('abrir_salaespera:')
          // console.log(sql);
          this.crud_abrir_tabela(sql,'sala_lista')


          //seta o ponteiro do scroll
          setTimeout(function(){
          this.scroll_to('id_sala', this.ponteiro * 40)
          // this.count_sala_espera = this.sala_lista.length
          }.bind(this), 555);//usar esse .bind(this) para chamar funcoes com o setTimeout

          //ativa o meu_dsi_timer 1 minuto
          this.salva_timer(0, 60000)
          
      },


    // //ABRE OS DADOS DA TABELA
    // async crud_abrir_tabela(sql, items_name){
    //     //GLOBAL    
    //     var meuSQL = sql
        
    //     // console.log(meuSQL);
                
    //     const res2 = await axios.post(url+'conexao.php', {
    //         acao:   5,
    //         meuSQL: meuSQL
    //     })

    //     this[items_name] = res2.data 
    //     console.log('crud_abrir_tabela:')
    //     console.log(res2.data);
    // }, 

     associa_registro(keyA, keyB, item_editar, item_buscar){
     
       //capturo o codigo de uma lista para poder atualizar apos um insert
      //  alert(this[item_editar].CODIGO) 
       if((this[item_editar].CODIGO =='') || (this[item_editar].CODIGO == undefined)){

          var valueA = this[item_editar][keyA]
          var valueB = this[item_editar][keyB]

          //filtro pela chaveA e chaveB
          var filtrado = this[item_buscar].filter(function(obj) { return obj[keyA] == [valueA]   &&   obj[keyB] == [valueB]; });

          this[item_editar] = Object.assign({}, filtrado[0])
       }    
     },

      mostra_manipulado(item){
          this.manipulado_clicou = item
      },

      subQuebra(texto,para){
        
        let a = texto.replaceAll('@quebra',para)
        return a
      },

      imprimir(){
        this.dialog = false
        window.print()
      },

      getFormatDate(data,tipo){
        if (data !== undefined){

           let dia = '' 
           if(tipo=='br'){
              dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
           }else if(tipo == 'us'){
              // dia =   data.substring(6,10) + '/'+ data.substring(3,5)+ '/'+ data.substring(0,2);//formato a dd/mm/yyyy
              dia =   data.substring(6,10) + '-'+ data.substring(3,5)+ '-'+ data.substring(0,2);//formato a dd/mm/yyyy
           }
           
           return dia 
        }else{
          return ''
        }  
      },



    calcular_imc(peso,altura){
        
        let imc = 0
        imc = peso / (altura * altura)
        
        if (imc > 0){

           this.edita_imc.IMC = imc.toString().substring(0,5)//copy to string  = inttostring floattostring
 
           if (imc < 18.5){
             this.edita_imc.RESULTPESO = 'Abaixo do Peso'
             this.edita_imc.X_COR = 'red'
           }
           else if ((imc >= 18.5)&&(imc <= 25)){
             this.edita_imc.RESULTPESO = 'Normal'
             this.edita_imc.X_COR = 'blue'
           }
           else if ((imc > 25) && (imc <= 30)){
             this.edita_imc.RESULTPESO = 'Acima do Peso'
             this.edita_imc.X_COR = 'orange'
           }
           else if (imc > 30){
             this.edita_imc.RESULTPESO = 'Obeso'
             this.edita_imc.X_COR = 'red'
           }

           let min = 18.5 * (altura * altura)
           let max = 25 * (altura * altura)

           this.edita_imc.PESOMINIMO = min.toString().substring(0,5)//copy to string
           this.edita_imc.PESOMAX = max.toString().substring(0,5)//copy to string
        }
        
    },

    timerNotas() {

        if (this.pode_notas){
              // console.log('timerNotas');
              this.pode_notas = false //defino que nao.. para nao ficar criando varios timers

              setTimeout(function(){
                // console.log('timerNotas OK')
                this.pode_notas = true
                this.salva_notas() 
              }.bind(this), 5000);//usar esse .bind(this) para chamar funcoes com o setTimeout   
        }       
    },




    closeAll(vem) {
        // console.log(vem);//vem false ou true

      
        //percorre todos os $refs procurando o atribulo cat-titulo
        Object.keys(this.$refs).forEach(k => {
            // console.log(this.$refs[k])
          if (vem){//closeAll
              if (this.$refs[k]  && this.$refs[k].$attrs['cat-titulo'] && this.$refs[k].$attrs['data-open']) {
                  this.$refs[k].$el.click()

                  // console.log('this.$refs[k]:', this.$refs[k]);
              }
          }
          else {//openAll
            if (this.$refs[k] && this.$refs[k].$attrs['cat-titulo'] && !this.$refs[k].$attrs['data-open']) {
                this.$refs[k].$el.click()
            }
          }  


        })
    },



      logado(){

          let key_session  = this.read_sessionStorage('refresh_size') 
          let logado       =  this.read_sessionStorage('buffer')
          let key          =  this.return_key().toString() //passo pra string para comparar

          if (key_session == undefined){
              key_session = ''
          }
          key_session  =  key_session.toString()//passo pra string para comparar

          if ( (logado !=='S') || (key !== key_session)) {
            this.$router.push('/')//redireciona para a pagina login
          }

          //chamo o ler_session do app.vue para atualizar o nome do usuario
          this.$emit('executar2', 'ler_session')      
      },

      return_key(){

         //pego o mes e o dia multiplico e chego num valor.. sera analisado   
          let dia = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' }); 
          dia =  dia.slice(3, 5) + dia.slice(0, 2) ; //formato us yyyy-mm-dd
          let a = dia.substring(0,2)
          let b = dia.substring(4,2)
          let c = (a * 3 * b)
          return c
      },
      
    timerAutoSave() {
      this.timerAS = setInterval(() => {
        this.timer -= 1
        if(this.TESTE==true){
          // console.log(this.timer);
        }
        
         //caso sai da janela..ele para os timers 
        if ((this.$route.name !== 'Atender')||(this.readonlyBor==true)) {
          console.log('route <> Atender entao para');
          this.timersStop();
        }

        //se zerou entao salva update, no keyup ele joga o timer pra 5 segundos
        if(this.timer <=0){
          console.log('update');
          this.save_conduta(this.idConduta)
          // this.timer = 60 * 10 //joga o timer pra 10 minuto novamente
        }
      }, 1000);        
    },

    timersStop(){
      clearTimeout(this.timerAS);
      console.log('parando os timers...');
    },

      set_sessionStorage($name, $value){
        sessionStorage[$name] = $value;
      },

     read_sessionStorage($name,$to){
        if (sessionStorage[$name]) {
           return sessionStorage[$name]
          //  this[$to] =  sessionStorage[$name];
        } 
     },

    maps(item){
      let link = 'https://www.google.com/maps/search/'+item
      window.open(link, '_blank');      
    },

    imprimirReceita(){        
    

      // passa dados da consulta
      this.passa_local_storage()//antes para limpar alguns campos

      if (this.items_receitas.length >0){

        let rodape = this.modelos_rodape(1)
        
        // sessionStorage['printConteudo'] = titulo + paciente + linha;
        // sessionStorage['printConteudo'] = 'printConteudo'
        sessionStorage['printRodape']   = rodape;
        sessionStorage['printTipo'] = 'Receitas'
        // sessionStorage['LSreceita'] = 'linha'
        sessionStorage['LSselected_receitas'] = JSON.stringify(this.selected_receitas)

        sessionStorage['print_receitas']      =  JSON.stringify(this.items_receitas)

        console.log('this.selected_receitas:', this.selected_receitas);
        // return 'exit'

        
        let seTEMmanipulado = JSON.stringify(this.items_receitas) //copio os medicamentos
        seTEMmanipulado = seTEMmanipulado.replaceAll('"MANIPULADO":""','##')//apago os manipulados vazios..depois verifico se sobrou algo 

        if (seTEMmanipulado.includes('MANIPULADO')){
            sessionStorage['TEM_MANIPULADO'] = 'S'
        }
        else{
          sessionStorage['TEM_MANIPULADO'] = 'N'
        }

        
      }   
      else{
          alert('Nenhum Receita para impressão!')
          return 'exit'
        }

      let route = this.$router.resolve({path: '/print'});
      window.open(route.href, '_blank');
    },

    imprimirExame(){

        if(this.TESTE == true){
          console.log(this.selected_exames);
        }          
        

        
        let selecionados = this.items_exames
        if (this.selected_exames.length >0){
            selecionados = this.selected_exames
        }

        let linha = ''
        let titulo = ''
        let paciente = ''
        let cidade = ''
        let profissional = ''

        // console.log(selecionados);
        // alert(selecionados.length)

        this.passa_local_storage()//antes para limpar alguns campos

        if (selecionados.length >0){

          for (let i = 0; i < selecionados.length; i++) {
            if (selecionados[i].CODIGO > 0){
                
                linha = linha +
                        // '<div class="a">'+
                        '<p style="text-align:left">'+
                        (i+1) +' - '+selecionados[i].EXAME +' - ' + selecionados[i].COD96 + '</p>'
                        
            }
          }
          
          titulo = 
                    '<p> <h3 style="text-align:center">'+
                      'SOLICITAÇÃO DE EXAMES'+
                    '</h3></p> <p/>'

                    //usar o <p/> ao inves do <br/>

          paciente = '<p> Sr.(a) <strong> '+ this.getNomePaciente  +' </strong></p> <p/>'   
          
          cidade = '<br/><br/><p/> <p style="text-align: center">'+ sessionStorage['LSCidade2'] +', '+ this.hoje +'</p>'

          profissional = '<p style="text-align: center"> <br/> ___________________________________ <br/>'+ this.getNomeDoc+' - CRM:'+ this.getCRMdoc +'</p>'
          
          let rodape = this.modelos_rodape(1)

          // this.passa_local_storage()//antes para limpar alguns campos
          sessionStorage['printConteudo'] =  titulo + paciente + linha;
          sessionStorage['printRodape']   = rodape;
          sessionStorage['printTipo'] = 'Exames'
          sessionStorage['LSexame']   = linha
          


          sessionStorage['LSselected_exames'] = JSON.stringify(this.selected_exames)
          sessionStorage['print_exames']      = JSON.stringify(this.items_exames)
          
        }
        else{
          alert('Nenhum Exame para impressão!')
          return 'exit'
        }

        let route = this.$router.resolve({path: '/print'});
        window.open(route.href, '_blank');

    },

    imprimirAtestado(tipo){

      console.log('tipo:', tipo);
      if (!tipo){ tipo = 'Atestado'}

      if(this.TESTE == true){
        console.log('imprimir atestado');
      }          
      
      let linha = ''
      let titulo = ''
      let paciente = ''
      let cidade = ''
      let profissional = ''


        
        titulo = 
                  '<p> <h3 style="text-align:center">'+
                    'ATESTADO MÉDICO'+
                  '</h3></p> <br/><br/><br/><br/>'

        paciente = '<p> Sr.(a) <strong> '+ this.getNomePaciente  +' </strong></p>'   

        linha = '<br> Esteve em consulta hoje às <strong>' + this.getHora+ '</strong>,</br></br>'+

                 'Necessita afastar-se de suas ocupações por 0,5 (meio) dia no período de '+ this.hoje +' à ' + this.hoje + ' por motivo de saúde.'+
        
                '</p>'
        
        
        cidade = '<br/><br/><br/><br/><br/><br/><br/> <p style="text-align: center">'+ sessionStorage['LSCidade2'] +', '+ this.hoje +'</p>'

        profissional = '<p style="text-align: center"> <br/> ___________________________________ <br/>'+ this.getNomeDoc+' - CRM:'+ this.getCRMdoc +'</p>'

       let rodape = this.modelos_rodape(1) 

        this.passa_local_storage()//antes para limpar alguns campos
        sessionStorage['printConteudo'] =  titulo + paciente + linha ;
        sessionStorage['printRodape']   = rodape;
        sessionStorage['printTipo']     = tipo
        
 

      let route = this.$router.resolve({path: '/print'});
      window.open(route.href, '_blank');
    },

    imprimirGestante(item){
      // this.dialogImprimir = true

        let linha = '<p>'+ this.edita_gestante.X_TEXTO +'<p>'

        linha = linha.replaceAll('\n','<br>') 


        let titulo = 
                  '<p> <h3 style="text-align:center">'+
                    'Data Provável do Parto : '+ this.getFormatDate(this.edita_gestante.DATA2,'br')+
                  '</h3>'+

                  '<h4 style="text-align:center">'+
                    'Tempo: '+ this.edita_gestante.TEXTO +
                  '</h4><p/><p/>'

                  //usar o <p/> ao inves do <br/>

        let paciente = '<p><strong> '+ this.getNomePaciente  +' </strong></p> <p/>'   
        
        let cidade = '<br/><br/><p/> <p style="text-align: center">'+ sessionStorage['LSCidade2'] +', '+ this.hoje +'</p>'

        let profissional = '<p style="text-align: center"> <br/> ___________________________________ <br/>'+ this.getNomeDoc+' - CRM:'+ this.getCRMdoc +'</p>'
        
        let rodape = this.modelos_rodape(1)

        this.passa_local_storage()//antes para limpar alguns campos
        sessionStorage['printConteudo'] = titulo + paciente + linha;
        sessionStorage['printRodape']   = rodape;
        sessionStorage['printTipo'] = 'Atestado'
        

        
        let route = this.$router.resolve({path: '/print'});
        window.open(route.href, '_blank');
    },



    alterou_conduta(){//chamo no keyup do input
      if (this.readonlyBor==false){
         this.timer = 5 * 1
         this.boo_alterou = true
      }
    },
   
    finalizar(){
      
      // if (this.boo_atendeu == true){
      // if (['T','G'].includes(this.getAtendido)){

        this.update_values()//atu datahora

        //  this.crud_update('CODIGOCONSULTA',this.getCodigoConsulta,'agenda', { ATENDIDO:'A', LOG_RECEPCAO: item.LOG_RECEPCAO+'\r\n'+ this.getDataHora +'_WEB: ATENDENDO'}); 
        let user = DSirotinas.cripto(this.read_sessionStorage('84hfjdyruxy1_is'))
        let sql = "update agenda set atendido = 'A', LOG_RECEPCAO = LOG_RECEPCAO ||'" + '\r\n' + this.data_hora +"_w_"+ user +": ATENDIDO'" + "where codigoconsulta = "+this.getCodigoConsulta
        // console.log(sql);   
        this.crud_sql(sql);    
      // } 
      window.history.back();
    },


    remove_all_receitas(){
        if (confirm("Deseja realmente excluir todos as receitas deste atendimento?")){

          let sql = `delete from receitas where codigoconsulta = ${this.getCodigoConsulta} `
          // let sql = "delete from receitas where codigoconsulta = "+this.getCodigoConsulta + " and RECEITAS.MANIPULADO IS  NULL"
          console.log(sql);   
          this.crud_sql(sql);    
          
          //atualiza
          setTimeout(function(){
            this.crud_conduta_receitas(this.getCodigoConsulta)
          }.bind(this), 1000);//usar esse .bind(this) para chamar funcoes com o setTimeout
        }
    },

    remove_all_exames(){

        if (confirm("Deseja realmente excluir todos os exames deste atendimento?")){

          let sql = "delete from exames where codigoconsulta = "+this.getCodigoConsulta
          // console.log(sql);   
          this.crud_sql(sql);    
          
          //atualiza
          setTimeout(function(){
            this.crud_conduta_exames(this.getCodigoConsulta)
          }.bind(this), 1000);//usar esse .bind(this) para chamar funcoes com o setTimeout
        }

      
    },

    permissoes(){

      if (this.condutaItem.CODIGO > 0){
          this.readonlyBor = (this.condutaItem.DATA !== this.today) //se for mesmo dia pode alterar
      }else{
          this.readonlyBor = false
      }

    },

    update_values(){
        
        let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });//formato dd/mm/yyyy  
        // console.log('hoje = '+ a);
        this.hoje = a;

        a =   this.hoje.slice(3, 5) + '/'+this.hoje.slice(0, 2)+'/'+this.hoje.slice(6, 10) ;//formato mm/dd/yyyy  
        // console.log('hoje_mm_dd_yyyy = '+ a);
        this.hoje_mm_dd_yyyy = a;


        // a = new Date().toLocaleTimeString('pt-Br',{ timeStyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });
        a = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
        // console.log('hora = '+ a);
        this.hora = a;

        a = this.hoje + '_'+ this.hora;
        // console.log('data_hora = '+ a);
        this.data_hora = a;


        a = this.hoje.slice(6, 10) +'-'+ this.hoje.slice(3, 5) +'-'+ this.hoje.slice(0, 2) ; //formato us yyyy-mm-dd
        // console.log('today = '+ a);
        this.today = a;
    },

    ler_sessions(){

       this.obj_dados_empresa    = JSON.parse(sessionStorage['obj_dados_empresa'])
       this.obj_doc_selecionado  = JSON.parse(sessionStorage['obj_doc_selecionado'])
       this.obj_user             = JSON.parse(sessionStorage['obj_user'])

       this.LSendereco = sessionStorage['LSendereco']

       this.is_super      = (this.read_sessionStorage('r0s9i3khepus_is') =='S' ? true : false)
       this.is_doc        = (this.read_sessionStorage('is_doc')          =='S' ? true : false)
       this.superA        = (this.read_sessionStorage('superA')          =='S' ? true : false)

       let pode_historico = (this.read_sessionStorage('set.doutor')      =='S' ? true : false) 

      //  if ((this.superA)||(pode_historico)){
       if ((this.superA)){
         this.is_doc = true
       }


       
       this.getCodigoDoc         = this.read_sessionStorage('getCodigoDoc')
       this.getNomeDoc           = this.read_sessionStorage('getNomeDoc')
       this.getCRMdoc            = this.read_sessionStorage('getCRMdoc')
       this.getConselho          = this.read_sessionStorage('getConselho')

       this.getCodigoConsulta    = this.read_sessionStorage('getCodigoConsulta')
       this.getCodigoPaciente    = this.read_sessionStorage('getCodigoPaciente')
       this.getCodigoConvenio    = this.read_sessionStorage('getCodigoConvenio')
       this.getNomePaciente      = this.read_sessionStorage('getNomePaciente')
       this.getConvenio          = this.read_sessionStorage('getConvenio')   
       this.getProcedimento      = this.read_sessionStorage('getProcedimento')    
       this.getIdade             = this.read_sessionStorage('getIdade')
       this.getProfissao         = this.read_sessionStorage('getProfissao')
       this.getData              = this.read_sessionStorage('getData')
       this.getHora              = this.read_sessionStorage('getHora')
       this.getCPF               = this.read_sessionStorage('getCPF')
       this.getRG                = this.read_sessionStorage('getRG')
       this.getEstadoCivil       = this.read_sessionStorage('getEstadoCivil')
       this.getCidade            = this.read_sessionStorage('getCidade')
       this.getEndereco          = this.read_sessionStorage('getEndereco')
       this.getAtendido          = this.read_sessionStorage('getAtendido')
       this.getSexo              = this.read_sessionStorage('getSexo')
       this.getNascimento        = this.read_sessionStorage('getNascimento')


        // Receber a string -- RECEBENDO DADOS DO PACIENTE
        let pessoaString = sessionStorage.getItem('obj_dados_paciente');

        // transformar em objeto novamente
        this.obj_dados_paciente = JSON.parse(pessoaString);
        // console.log('obj_dados_paciente:');
        // console.log(this.obj_dados_paciente); 

        this.nome_mae = this.obj_dados_paciente.NOMEMAE
        this.nome_pai = this.obj_dados_paciente.NOMEPAI



        this.filtra_sala = sessionStorage.getItem('filtra_sala')
        if (this.filtra_sala == null){ this.filtra_sala = 'G' }



        // LIMPA SESSIONS DE IMPRESSAO
          sessionStorage['printConteudo'] = ''
          sessionStorage['printRodape']   = ''
          sessionStorage['printTipo']     = ''

          sessionStorage['LSexame']       = ''

          sessionStorage['LSselected_exames'] = ''
          sessionStorage['print_exames']      = ''
          
          sessionStorage['LSselected_receitas'] = ''
          sessionStorage['print_receitas']      = ''

          

          this.passa_local_storage()//antes para limpar alguns campos

    },

    ler_historico(){

        //atendimento
        this.boo_monta_historico = false

        this.crud_conduta(this.getCodigoConsulta)
        this.crud_historico(this.getCodigoPaciente)
        this.crud_receitas(this.getCodigoPaciente)
        this.crud_exames(this.getCodigoPaciente)

        //receitas
        this.crud_conduta_receitas(this.getCodigoConsulta)
        this.crud_conduta_exames(this.getCodigoConsulta)

        //obs = notas
        this.crud_notas(this.getCodigoPaciente)
        this.crud_imc(this.getCodigoPaciente)
        this.crud_gestante(this.getCodigoPaciente)
        this.crud_manipulado(this.getCodigoPaciente, this.getCodigoConsulta)

        this.abrir_salaespera(this.filtra_sala)

    },

    async crud_notas(ide){

        var meuSQL = 
        `
        SELECT *
        FROM
        OBS
        WHERE
          (OBS.COD_CLIENTE = `+ ide +`) AND
          (OBS.TIPO = 'O')
        `        

        // console.log(meuSQL);
        
          const res = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        if (res.data.length > 0 ){
          this.res_notas = res.data[0]; 
          this.count_notas =  res.data.length          
        }

    },

    salva_notas(){

        let sql = ''

        if (this.res_notas.CODIGO > 0){
          //update
            sql = 
            `update OBS set descricao = '`+ this.res_notas.DESCRICAO +`'
            where codigo = `+this.res_notas.CODIGO
        } 
        else{
          //novo
            sql = 
            `insert into OBS
            (TIPO,COD_CLIENTE,DESCRICAO) 
            VALUES('O',`+ this.getCodigoPaciente +`,'`+ this.res_notas.DESCRICAO +`')`

            this.espera_notas = true //enquanto nao salvar.. ele espera para digitar
        } 


        // console.log('salva_notas');
        // console.log(sql);
 
          
        this.crud_sql(sql); 

        if (this.res_notas.CODIGO  < 1){
            //atualiza para pegar o codigo
            setTimeout(function(){    
            this.crud_notas(this.getCodigoPaciente)
            this.espera_notas = false
            }.bind(this), 3000);//usar esse .bind(this) para chamar funcoes com o setTimeout
        }
    },

    async crud_imc(ide){

        var meuSQL = 
        `
        SELECT
          DADOS_ADICIONAIS.CODIGO,
          DADOS_ADICIONAIS.TIPO,
          DADOS_ADICIONAIS.DATA,
          DADOS_ADICIONAIS.PESO,
          DADOS_ADICIONAIS.ALTURA,
          DADOS_ADICIONAIS.CODIGO_CLIENTE,
          DADOS_ADICIONAIS.CODIGO_MEDICO,
          DADOS_ADICIONAIS.IMC,
          DADOS_ADICIONAIS.PESOMAX,
          DADOS_ADICIONAIS.PESOMINIMO,
          DADOS_ADICIONAIS.RESULTPESO,
          DADOS_ADICIONAIS.PC
        FROM
        DADOS_ADICIONAIS
        WHERE
          (DADOS_ADICIONAIS.CODIGO_CLIENTE = `+ ide +`) AND
          (DADOS_ADICIONAIS.TIPO = 'D')
        ORDER BY
          DADOS_ADICIONAIS.DATA DESC
        `        

        // console.log(meuSQL);
        
          const res = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        this.items_imc   = res.data;
        if (res.data.length > 0 ){
          this.items_imc   = res.data; 
          this.count_imc =  res.data.length 


          //tratando alguns campos
          for (let i = 0; i < this.items_imc.length; i++) {
            this.items_imc[i].IMC    =  this.items_imc[i].IMC.substring(0,5) //copio somente os 5 caracteres iniciais
            this.items_imc[i].PESO   =  this.items_imc[i].PESO.substring(0,5) //copio somente os 5 caracteres iniciais
            this.items_imc[i].ALTURA =  this.items_imc[i].ALTURA.substring(0,4) //copio somente os 5 caracteres iniciais
            this.items_imc[i].PC     =  this.items_imc[i].PC.substring(0,5) //copio somente os 5 caracteres iniciais
          }
          // console.log('this.items_imc:');
          // console.log(this.items_imc);
        }

        this.associa_registro('DATA','TIPO', 'edita_imc', 'items_imc')


    },

    adicionar_imc(){
       
       this.edita_imc = {}//limpando array

       this.novo_imc = false
       this.edita_imc.DATA = this.today

    },

    salvar_imc(){

        //defino alguns campos manualmente
        this.$set(this.edita_imc, 'TIPO', 'D');
        this.$set(this.edita_imc, 'CODIGO_CLIENTE', this.getCodigoPaciente);
        this.$set(this.edita_imc, 'CODIGO_MEDICO', this.getCodigoDoc);

        if(this.edita_imc.PC == ''){
          //  delete this.edita_imc.PC //apagar
          this.$set(this.edita_imc, 'PC', 0);//se for vazio da erro no sql entao defino 0
        }
      

        this.salvar('edita_imc','CODIGO','DADOS_ADICIONAIS')
      
        setTimeout(function(){    
          this.crud_imc(this.getCodigoPaciente)
        }.bind(this), 555);//usar esse .bind(this) para chamar funcoes com o setTimeout
    },

    deletar_imc(item){

       if (confirm("Deseja realmente excluir? "+ this.getFormatDate(item.DATA,'br'))){

          this.crud_delete(item.CODIGO, 'DADOS_ADICIONAIS')

          setTimeout(function(){    
            this.crud_imc(this.getCodigoPaciente)
          }.bind(this), 555);//usar esse .bind(this) para chamar funcoes com o setTimeout
       }
    },

    editar_imc(item){

      this.edita_imc = Object.assign({}, item)
      // this.edita_imc.CODIGO      = item.CODIGO
      // this.edita_imc.DATA        = item.DATA
      // this.edita_imc.PESO        = item.PESO
      // this.edita_imc.ALTURA      = item.ALTURA
      // this.edita_imc.RESULTPESO  = item.RESULTPESO

      this.novo_imc = false

    },

    async crud_gestante(ide){

        var meuSQL = 
        `
        SELECT
          DADOS_ADICIONAIS.CODIGO,
          DADOS_ADICIONAIS.TIPO,
          DADOS_ADICIONAIS.DATA,
          DADOS_ADICIONAIS.DATA2,
          DADOS_ADICIONAIS.TEXTO,
          DADOS_ADICIONAIS.CODIGO_CLIENTE,
          DADOS_ADICIONAIS.CODIGO_MEDICO
        FROM
        DADOS_ADICIONAIS       
        WHERE
          (DADOS_ADICIONAIS.CODIGO_CLIENTE = `+ ide +`) AND
          (DADOS_ADICIONAIS.TIPO = 'G')
        ORDER BY
          DADOS_ADICIONAIS.DATA DESC
        `        

        // console.log(meuSQL);
        
          const res = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        this.items_gestante   = res.data;
        if (res.data.length > 0 ){
          this.items_gestante   = res.data; 
          this.count_gestante =  res.data.length 


          //tratando alguns campos
          // for (let i = 0; i < this.items_gestante.length; i++) {
          //   this.items_gestante[i].IMC    =  this.items_gestante[i].IMC.substring(0,5) //copio somente os 5 caracteres iniciais
          //   this.items_gestante[i].PESO   =  this.items_gestante[i].PESO.substring(0,5) //copio somente os 5 caracteres iniciais
          //   this.items_gestante[i].ALTURA =  this.items_gestante[i].ALTURA.substring(0,4) //copio somente os 5 caracteres iniciais
          // }

          
          // console.log('this.items_gestante:');
          // console.log(this.items_gestante);
        }

        // this.associa_registro('DATA','DATA2', 'edita_gestante', 'items_gestante')

    },


    adicionar_gestante(){
       
      //  this.edita_gestante = {}//excluindo itens do objeto
      for (var member in this.edita_gestante)  this.edita_gestante[member]= ''; //limpando os campos do objeto 

       this.novo_gestante = false
       this.edita_gestante.X_DATA_ULTRA = this.today

    },

    salvar_gestante(){

        //defino alguns campos manualmente
        this.$set(this.edita_gestante, 'TIPO', 'G');
        this.$set(this.edita_gestante, 'CODIGO_CLIENTE', this.getCodigoPaciente);
        this.$set(this.edita_gestante, 'CODIGO_MEDICO', this.getCodigoDoc);

        this.salvar('edita_gestante','CODIGO','DADOS_ADICIONAIS')
      
        setTimeout(function(){    
          this.crud_gestante(this.getCodigoPaciente)
        }.bind(this), 555);//usar esse .bind(this) para chamar funcoes com o setTimeout
    },

    deletar_gestante(item){

       if (confirm("Deseja realmente excluir? "+ this.getFormatDate(item.DATA,'br'))){

          this.crud_delete(item.CODIGO, 'DADOS_ADICIONAIS')


          for (var member in this.edita_gestante)  this.edita_gestante[member]= ''; //limpando os campos do objeto 

          setTimeout(function(){    
            this.crud_gestante(this.getCodigoPaciente)
          }.bind(this), 555);//usar esse .bind(this) para chamar funcoes com o setTimeout
       }
    },

    editar_gestante(item){

    //  console.log(item);   

      this.edita_gestante = Object.assign({}, item)//isso aqui acabava apagando o texto..

      // this.edita_gestante.X_TEXTO      = 'esse daqui'
      // this.edita_gestante.DATA        = item.DATA
      // this.edita_gestante.DATA2       = item.DATA2

      this.novo_gestante = false
      this.tipo_gestante = 'DUM'

      this.calcular_dum(this.edita_gestante.DATA)//calculo apos mudar a data

    },

    incDay(data,dias){
       let a = new Date(data+' 00:00:00')//sempre que criar data definir horario
      // console.log('dias = '+dias);
       a.setDate(a.getDate() + dias)//adiciono dias na data
       let r = new Date(a).toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });//formato dd/mm/yyyy 
      //  alert(r)
       return r
    },


    calcular_ultra(){

      let data    = this.edita_gestante.X_DATA_ULTRA
      let semanas =  this.edita_gestante.X_SEMANAS
      let dias    =  this.edita_gestante.X_DIAS

      if ((data !== undefined)&&(data !=='')&&(semanas > 0)){

          let Tdias =  (semanas * 7) + Number(dias)
          let dataDUM = this.incDay(data, - Tdias)
          this.edita_gestante.DATA = this.getFormatDate(dataDUM,'us')
      }
    },

    calcular_dum(data){

      if ((data !== undefined)&&(data !=='')){

        // console.log('data:');
        // console.log(data);
        let datadoparto = this.incDay(data,280) //criei essa funcao incDay
        //  alert(datadoparto)
        let T = this.diferenca_datas(data, this.today, 'dias')
        let V = (T / 7)
        let G = V.toString()
        let posicao = (G.indexOf(".")) 
        let semanas = G
        let texto = ''

        if (posicao > 0){//caso tenha , ou . = quebrado
          
            G = G.substring(0, posicao) //copia string ate posicao de .
            semanas = G

            V = Number(G) 
            V = V * 7
            let dias = V * 7
            dias = T - V
            texto = semanas+' Semana(s) e '+ dias +' dia(s)'
        }
        else{
            texto = semanas + ' Semana(s)'
        }





        let xNiver = [
          30,61,91,122,152,182,213,243,274
        ]

        let xTexto = [
          '4 semanas e 2 dias',
          '8 semanas e 4 dias',
          '13 semanas',
          '17 semanas e 2 dias',
          '21 semanas e 5 dias',
          '26 semanas',
          '30 semanas e 2 dias',
          '34 semanas e 5 dias',
          '39 semanas'
        ]

        let Mes = 0
        let Niver = 
            'Hoje é dia '+ this.hoje     +'\n'+
            'Olá, seu bebê tem hoje:'    +'\n'+
            'Semanas: '+ texto           +'\n'+ 
            'Aniversário da barriguinha:'+'\n'+
            '----------------------------------------------------------'

        let a = ''
            for (let n = 0; n < 275; n++){
              if(n == xNiver[Mes]){

                Mes = Mes+1

                if (Mes == 1){
                  a = ' mês     : '
                }else{
                  a = ' meses : '
                }

                Niver = Niver+'\n'+
                        Mes+ a + this.incDay(data,n) +
                        ' - '+ xTexto[Mes-1]
              }
            }

        Niver = Niver+ '\n\n' + 'Data Provável do Nascimento: '+ datadoparto

        
        //define o campo
        this.edita_gestante.TEXTO   = texto
        this.edita_gestante.DATA2   = this.getFormatDate(datadoparto,'us')
        this.edita_gestante.X_TEXTO = Niver

      }
    },

    diferenca_datas(di,df,retorna){
        var dataInicio = new Date(di+' 00:00:00');
        var dataFim    = new Date(df+' 00:00:00');
        // alert(dataInicio)
        // alert(dataFim)
        var diffMilissegundos = dataFim - dataInicio;
        var diffSegundos = diffMilissegundos / 1000;
        var diffMinutos = diffSegundos / 60;
        var diffHoras = diffMinutos / 60;
        var diffDias = diffHoras / 24;
        var diffMeses = diffDias / 30;

        if (retorna=='dias'){
           return diffDias
        }
        else if (retorna=='meses'){
           return diffMeses
        }
        
    },

    

    volta_agenda(){

      // if (this.boo_atendeu == true){
      //   if (['T','G'].includes(this.getAtendido)){
      //     // alert('pode finalizar')
      //     this.boo_atendeu = false
      //     this.dialog = true
      //     // window.history.back();
      //   } 
      // }else
       if (this.boo_alterou == true){//caso tenha alterado..salva ao sair
          this.timersStop();
          this.save_conduta(this.idConduta)

          //enrolo 2 segundos para dar tempo de mostrar o alerta
          setTimeout(function(){
            window.history.back();
          }, 2000);

          // this.timerClose = setInterval(() => {
          //   this.volta_agenda()//rodo novamente para verificar se pode finalizar o atendimento
          //   clearTimeout(this.timerClose)//encerro esse timer

          // }, 2000);   
      }else{
        window.history.back();
      }

      // this.$router.push('/')
    },

    alerta(text,color){
        // alert(text);
         this.snackbar = true;
         this.snack_text= text;
         this.snack_color = color;
    },



      editItem (item, readonly,obj_edita,items,qual_dialog) {
        //item = item
        //items = pegar o :items do v-data-table 
        //obj_edita = pegar objeto  do v-model do v-dialog
  

        this.editedIndex = this[items].indexOf(item)
        // this.editedItem = Object.assign({}, item)
        this[obj_edita] = Object.assign({}, item)

        // this.dialog = true ou dialog_exames
        this[qual_dialog] = true
        this.readonly = readonly
      },

      deleteItem (item,obj_edita,items) {
        this.editedIndex = this[items].indexOf(item)
        this[obj_edita] = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm (obj_edita,id_field,items) {
        // alert(this.editedItem.id)
        this[items].splice(this.editedIndex, 1)
        this.closeDelete()

        // this.crud_delete(this.editedItem.id)
        // if (items=='items_receitas'){}
        this.crud_delete(this[obj_edita][id_field], this.crud_table)
      },

      close () {      

        this.dialog = false
        this.dialog_exames = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },

      novo(obj_edita, qual_dialog){
          this[obj_edita] = {}//limpa o objeto
          // this.dialog=true;
          this[qual_dialog] = true
          this.readonly=false
      },

      novo_receita_manual(obj_edita){

        this.novo(obj_edita,'dialog')//abro o dialog

        //definindo valores default
        this[obj_edita]['DATA']            = this.hoje_mm_dd_yyyy
        this[obj_edita]['DATAHORA']        = 'now'
        this[obj_edita]['CODIGOCONSULTA']  = this.getCodigoConsulta 
        this[obj_edita]['TIPO']            = 'R' 
        this[obj_edita]['ATIVO']           = 'S' 
        this[obj_edita]['VER']             = 'S' 
        this[obj_edita]['DOSE']            = '' 
        this[obj_edita]['CODIGOCLIENTE']   = this.getCodigoPaciente
        this[obj_edita]['CODIGOMEDICO']    = this.getCodigoDoc
        this[obj_edita]['CODIGOCONVENIO']  = this.getCodigoConvenio
      },

      novo_exame_manual(obj_edita){

        this.novo(obj_edita,'dialog_exames')//abro o dialog

        //definindo valores default
        this[obj_edita]['DATA']            = this.hoje_mm_dd_yyyy
        this[obj_edita]['DATAHORA']        = 'now'
        this[obj_edita]['CODIGOCONSULTA']  = this.getCodigoConsulta 
        this[obj_edita]['TIPO']            = 'E' 
        this[obj_edita]['ATIVO']           = 'S' 
        this[obj_edita]['VER']             = 'S' 
        this[obj_edita]['CODIGOCLIENTE']   = this.getCodigoPaciente
        this[obj_edita]['CODIGOMEDICO']    = this.getCodigoDoc
        this[obj_edita]['CODIGOCONVENIO']  = this.getCodigoConvenio
        this[obj_edita]['CODIGOCONSULTORIO']  = 0
      },


      save(obj_edita,field,items_data_table,table) {

        // if (this.$refs.form.validate()){

          if (this.editedIndex > -1) {

           Object.assign(this[items_data_table][this.editedIndex], this[obj_edita])
            // Object.assign(this.selected_receitas[this.editedIndex], this.editedItem)

            // this.crud_update('CODIGO',this[obj_edita][field],'receitas',this[obj_edita]);
            this.crud_update(field,this[obj_edita][field],table,this[obj_edita]);
            
          } else {
            this[items_data_table].push(this[obj_edita]);
            // alert('Novo = '+ this.editedIndex);

            if (table == 'exames'){
              this.$set(this[obj_edita], 'CID', ' '); //nao deixar o cid nulo..interfere no civic historico
              console.log('obj_edita:', this[obj_edita]);
            }

            this.crud_create(table,this[obj_edita]);
          }

          this.close()
        // }
        // else{
        //   this.alerta("Preencha os camposS!","red");
        // }
      },


      salvar(obj_edita, field, table) {

          if (this[obj_edita][field] > 0) {
            this.crud_update(field,this[obj_edita][field],table,this[obj_edita]);
            
          } else {
            return this.crud_create(table,this[obj_edita]); 
          }

          this.close()
      },


    save_conduta(id){
        
        this.boo_alterou = false
        this.timer = 60 * 10 //joga o timer pra 10 minuto novamente

        if (this.readonly==true){
            exit
        }

        if (this.$refs.form.validate()){
            
            if (id > 0) {
              console.log('Editando = '+ id)
              this.crud_update('CODIGO',id,'procedimento',this.condutaItem);
              this.boo_atendeu = true
            } else {
             console.log('novo')
            //  this.crud_create('procedimento',this.condutaItem);
             this.novo_evolucao('procedimento',this.condutaItem);
            //  this.crud_update('CODIGOCONSULTA',-99,'procedimento',this.condutaItem);
            }
        }
        else{
            this.alerta("Preencha os campos!","red");
        }  
    },

    monta_historico(){

      if (this.boo_monta_historico == true){
        // exit
      }
      this.boo_monta_historico = true //executar somente 1 vez por atendimento

      console.log('monta_historico');

      let h = this.historico
      let r = this.receitas
      let e = this.exames
      let f_final = []


      //percorre o historico.. filtrando as receitas e exames
      for (let i = 0; i < h.length; i++) {
          // console.log('H: '+h[i].CODIGO +' '+h[i].DATA+' - ide:'+h[i].CODIGOCLIENTE+' - doc:'+h[i].CODIGOMEDICO+' - dd:'+h[i].DESCRICAO);
          // console.log('-------receitas\n');


          //filtra array receitas
          var r_filtrado = r.filter(function(obj) { return (obj.DATA == h[i].DATA) && (obj.CODIGOMEDICO == h[i].CODIGOMEDICO)&& (obj.CODIGOCLIENTE == h[i].CODIGOCLIENTE) });

          for (let i = 0; i < r_filtrado.length; i++) {
              // console.log('R: '+filtrado[i].CODIGO +' '+filtrado[i].DATA+' - ide:'+filtrado[i].CODIGOCLIENTE+' - doc:'+filtrado[i].CODIGOMEDICO+' - mm:'+filtrado[i].MEDICAMENTO);
          }


          // console.log('-------exames\n');
          //filtra array exames
          var e_filtrado = e.filter(function(obj) { return (obj.DATA == h[i].DATA) && (obj.CODIGOMEDICO == h[i].CODIGOMEDICO)&& (obj.CODIGOCLIENTE == h[i].CODIGOCLIENTE) });

          for (let i = 0; i < e_filtrado.length; i++) {
              // console.log('E: '+filtrado[i].CODIGO +' '+filtrado[i].DATA+' - ide:'+filtrado[i].CODIGOCLIENTE+' - doc:'+filtrado[i].CODIGOMEDICO+' - mm:'+filtrado[i].EXAME);
          }


          // criando o objeto array que sera usado no html
          let obj = h[i]
          let sfields = {}

          //descobrindo campo do objetos e preencho
          for (var property in obj){
            this.$set(sfields, property, obj[property]);//insiro no objeto os valores

            //trato alguns campos
            if (property='DATA'){
              let aux = obj[property]
              aux = aux.substring(8,10) + '/'+ aux.substring(5,7)+ '/'+ aux.substring(0,4);//formato a data dd/mm/yyyy // 2021-09-28
              
              this.$set(sfields, 'X_'+property, aux);//insiro no objeto os valores
            }
          }
          //insiro outros valroes
          this.$set(sfields, 'receitas', r_filtrado);//insiro no objeto os valores
          this.$set(sfields, 'exames', e_filtrado);//insiro no objeto os valores

          //insiro no objeto final as linhas
          f_final.push(sfields)
      }

      //defino o final no objeto que esta no html
      this.obj_historico2 = f_final
      // console.log('obj_historico2');
      console.log('obj_historico2:', this.obj_historico2);

    },

    novo_evolucao(table,fields){
// ´
    
      // console.log('novo_evolucao A');
      console.log(table);
      console.log(fields);
      // console.log('novo_evolucao B');
      //definindo valores default
      this.condutaItem['DATA']            = this.hoje_mm_dd_yyyy
      this.condutaItem['CODIGOCONSULTA']  = this.getCodigoConsulta 
      this.condutaItem['TIPO']            = 'P' 
      this.condutaItem['CODIGOCLIENTE']   = this.getCodigoPaciente
      this.condutaItem['CODIGOMEDICO']    = this.getCodigoDoc

      this.crud_create(table,fields);
    },

    async novo_receita(table,fields,item){
      
      if (fields != null){
        
          // console.log('novo_receita fields');
          // console.log(fields);
        
      }

      if (item != null){//adiciona no fields os item vindo do historico

        
          // console.log('novo_receita item');
          // console.log(item);
        


        let obj = item
        for (var property in obj){
            this.$set(fields, property, obj[property]);//insiro no objeto os valores
        }
      }


      if (this.EditaReceita.TIPO == undefined){
        this.$set(this.EditaReceita, 'TIPO',              'R')  
      }

      // //definindo valores default
      this.$set(this.EditaReceita, 'DATA',              this.today)
      this.$set(this.EditaReceita, 'DATAHORA',          'now')
      this.$set(this.EditaReceita, 'CODIGOCONSULTA',    this.getCodigoConsulta )
      this.$set(this.EditaReceita, 'ATIVO',             'S')
      this.$set(this.EditaReceita, 'VER',               'S')

      this.$set(this.EditaReceita, 'CODIGOCLIENTE',     this.getCodigoPaciente )
      this.$set(this.EditaReceita, 'CODIGOMEDICO',      this.getCodigoDoc )
      this.$set(this.EditaReceita, 'CODIGOCONVENIO',    this.getCodigoConvenio )


      // console.log('fields:', fields);

      await this.crud_create(table,fields);

      await limpa(this)
        async function limpa(that){
          that.EditaReceita = {}; //limpo o campo para nao pegar sujeiro 
        }

    },



    novo_exame_cat(grupo){
      console.log(this.exames_ultimos);
      let filtrado = this.exames_ultimos.filter( x => x['CATEGORIA'] === grupo );
      console.log('FILTRADO:');
      console.log(filtrado);

      for (let i = 0; i < filtrado.length; i++) {
        this.novo_exame('exames',this.EditaExame, filtrado[i] )
      }

    },

    // novo_exame_cat(item){
      // metodo antigo

    //     // alert(item)
    //     console.log(this.$refs);
    //     Object.keys(this.$refs).forEach(k => {
    //         // console.log(this.$refs[k])

    //         //filtro para clicar somente nos items que preciso
    //         if (this.$refs[k]   &&
    //             this.$refs[k].$attrs['cat-item'] && 
    //             this.$refs[k].$attrs['cat-item-categoria'] == item) {

    //               this.$refs[k].$el.click()
    //               // console.log(this.$refs[k])
    //         }
    //     })
    // },

    async novo_exame(table,fields,item){

      // console.log('novo_exame:');

      if (fields != null){
        if(this.TESTE == true){
          console.log('novo_exame fields');
          console.log(fields);
        }
      }

      if (item != null){//adiciona no fields os item vindo do historico

        if(this.TESTE == true){
          console.log('novo_exame item');
          console.log(item);
        }


        let obj = item
        for (var property in obj){
            this.$set(fields, property, obj[property]);//insiro no objeto os valores
        }
      }

      
      //definindo valores default
      this.EditaExame['DATA']            = this.hoje_mm_dd_yyyy
      this.EditaExame['DATAHORA']        = 'now'
      this.EditaExame['CODIGOCONSULTA']  = this.getCodigoConsulta 
      this.EditaExame['TIPO']            = 'E' 
      this.EditaExame['ATIVO']           = 'S' 
      this.EditaExame['VER']             = 'S' 
      this.EditaExame['CODIGOCLIENTE']   = this.getCodigoPaciente
      this.EditaExame['CODIGOMEDICO']    = this.getCodigoDoc
      this.EditaExame['CODIGOCONVENIO']  = this.getCodigoConvenio
      this.EditaExame['CODIGOCONSULTORIO']  = 0


      await this.crud_create(table,fields);

      await this.crud_conduta_exames(this.getCodigoConsulta)

      // setTimeout(function(){
      //   this.crud_conduta_exames(this.getCodigoConsulta)
      // }.bind(this), 1000);//usar esse .bind(this) para chamar funcoes com o setTimeout

    },

    async novo_exame_tuss(item){

      // console.log('novo_exame_tuss:', item);

      if (!item){
        return false
      }

  
      //definindo valores default

      this.$set(this.item_edita, 'COD96'                , item.STRING_CODIGO_TUSS)
      this.$set(this.item_edita, 'EXAME'                , item.PROCEDIMENTO)

      this.$set(this.item_edita, 'DATA'                 , this.hoje_mm_dd_yyyy)
      this.$set(this.item_edita, 'DATAHORA'             , 'now')
      this.$set(this.item_edita, 'TIPO'                 , 'E' )
      this.$set(this.item_edita, 'ATIVO'                , 'S' )
      this.$set(this.item_edita, 'VER'                  , 'S' )

      this.$set(this.item_edita, 'CODIGOCONSULTA'       , this.getCodigoConsulta )
      this.$set(this.item_edita, 'CODIGOCLIENTE'        , this.getCodigoPaciente)
      this.$set(this.item_edita, 'CODIGOMEDICO'         , this.getCodigoDoc)
      this.$set(this.item_edita, 'CODIGOCONVENIO'       , this.getCodigoConvenio)
      this.$set(this.item_edita, 'CODIGOCONSULTORIO'    , 0)

      await this.crud_create('EXAMES',this.item_edita);

      await this.crud_conduta_exames(this.getCodigoConsulta)

      // setTimeout(function(){
      //   this.crud_conduta_exames(this.getCodigoConsulta)
      // }.bind(this), 1000);//usar esse .bind(this) para chamar funcoes com o setTimeout

    },



    async crud_historico (ide) {
        const res = await axios.post(url+'meuSQL.php', {
        qual : 'historico', 
        ide  : ide
        })
        var meuSQL =  res.data;
        // console.log(res.data);
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })
      
        if(this.TESTE == true){
          console.log('crud_historico');
          console.log(res2.data);
        }   

        if (res2.data.length > 0 ){
          if (res2.data[0].CODIGO > 0) {
            this.historico =  res2.data;
            this.count_h = res2.data.length//qt historico
            // console.log(res2.data.length);
          }
        }


        //percorrer o array de dados retornados
        // let esse = res2.data;
        // for (let i = 0; i < esse.length; i++) {
        //     console.log('esse: '+esse[i].CODIGO +' '+esse[i].DATA);
        // }
    },

    async crud_receitas_mais () {//historico de receitas do medico

      if (this.receitas_ultimos ==''){

          const res = await axios.post(url+'meuSQL.php', {
          qual : 'receitas_ultimos', 
          doc  : this.getCodigoDoc
          })
          var meuSQL =  res.data;
          // console.log(res.data);
          
          const res2 = await axios.post(url+'conexao.php', {
              acao:   5,
              meuSQL: meuSQL
          })
        
          if(this.TESTE == true){
            console.log('receitas_ultimos');
            console.log(res2.data);  
          }
          // console.log(res2.data); 

          if (res2.data.length > 0 ){
            if (res2.data[0].MEDICAMENTO !=='') {
              this.receitas_ultimos =  res2.data;
            }
          }    

      }
    },

    async crud_exames_mais () {//historico de exames do medico

      if (this.exames_ultimos ==''){

          const res = await axios.post(url+'meuSQL.php', {
          qual : 'exames_ultimos', 
          doc  : this.getCodigoDoc
          })
          var meuSQL =  res.data;
          // console.log(res.data);
          
          const res2 = await axios.post(url+'conexao.php', {
              acao:   5,
              meuSQL: meuSQL
          })
        
          if(this.TESTE == true){
            console.log('exames_ultimos');
            console.log(res2.data);  
          }
          // console.log(res2.data); 

          if (res2.data.length > 0 ){
            if (res2.data[0].EXAME !=='') {
              this.exames_ultimos =  res2.data;
            }
          }    

      }
    },

     async crud_receitas (ide) {//historico de receitas do paciente
        const res = await axios.post(url+'meuSQL.php', {
        qual : 'hist_receitas', 
        ide  : ide
        })
        var meuSQL =  res.data;
        // console.log(res.data);
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })
      
          // console.log('crud_receitas');
          // console.log(res2.data);  
        

        if (res2.data.length > 0 ){
          if (res2.data[0].CODIGO > 0) {
            this.receitas =  res2.data;

        }
    }
    },

    async crud_exames (ide) {//historico de exames do paciente
        const res = await axios.post(url+'meuSQL.php', {
        qual : 'hist_exames', 
        ide  : ide
        })
        var meuSQL =  res.data;
        // console.log(res.data);
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })
      
        if(this.TESTE == true){
          console.log('crud_exames');
          console.log(res2.data);
        }  

        if (res2.data.length > 0 ){
          if (res2.data[0].CODIGO > 0) {
            this.exames =  res2.data;
          }
        }
    },

    async crud_conduta (cs) {//abre o atendimento atual
      //  alert('cs = '+cs)
        //1º o sql fica salvo no arquivo meuSQL.php
        //2º executa o sql no arquivo CRUD.php com a acao 5
        // where = 'WHERE clientes.id in (5,6,15)'
        const res = await axios.post(url+'meuSQL.php', {
        qual : 'conduta', 
        cs  : cs
        })
        var meuSQL =  res.data;
        // console.log(res.data);
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })
      
       if(this.TESTE==true){
          console.log('crud_conduta');
          console.log(res2.data);  
          // alert(res2.data[0].CODIGO ) 
       }


        if (res2.data.length > 0 ){
          if (res2.data[0].CODIGO > 0) {
            this.condutaItem =  res2.data[0];
            this.idConduta = res2.data[0].CODIGO;
            //this.condutaItem['DESCRICAO']  = res2.data[0].DESCRICAO  
          }          
        }

        this.permissoes()
    },
    
    async crud_conduta_receitas (cs) {//abre as receitas do atendimento atual

        // const res = await axios.post(url+'meuSQL.php', {
        // qual : 'conduta_receita', 
        // cs  : cs
        // })
        // var meuSQL =  res.data;

        var meuSQL =  `
        SELECT 
          RECEITAS.CODIGO,
          RECEITAS.CODIGOCLIENTE,
          RECEITAS.CODIGOCONSULTA,
          RECEITAS.CODIGOMEDICO,
          RECEITAS.CODIGOCONVENIO,
          RECEITAS.TIPO,
          RECEITAS.ATIVO,
          RECEITAS.DOSE,
          RECEITAS.PERIODO,
          RECEITAS.POSOLOGIA,
          RECEITAS.MEDICAMENTO,
          RECEITAS.USO,
          RECEITAS.DATAHORA,
          RECEITAS.DATA,
          RECEITAS.VER,
          RECEITAS.CATEGORIA,
          RECEITAS.MANIPULADO
        FROM
        RECEITAS
          WHERE
            (RECEITAS.CODIGOCONSULTA = ${cs}) 
   
          ORDER BY
            RECEITAS.TIPO DESC,
            RECEITAS.CODIGO,
            RECEITAS.MANIPULADO
            
        `
        
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })
      
       if(this.TESTE==true){
          console.log('crud_conduta_receitas');
          console.log(res2.data);  
       }


        if (res2.data.length > 0 ){
          if (res2.data[0].CODIGO > 0) {

            this.items_receitas =  res2.data;
            this.count_r = res2.data.length//qt historico
            //this.condutaItem['DESCRICAO']  = res2.data[0].DESCRICAO  
          }          
        }else{//limpa caso zere
          this.items_receitas = [];//limpo array
          this.count_r = '0';
        }

        sessionStorage['print_receitas']  =  JSON.stringify(res2.data)

        // this.permissoes()
    },
    
    async crud_conduta_exames (cs) {//abre as receitas do atendimento atual

        const res = await axios.post(url+'meuSQL.php', {
        qual : 'conduta_exame', 
        cs  : cs
        })
        var meuSQL =  res.data;
        // console.log(res.data);
        
        const res2 = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
        })
      
       if(this.TESTE==true){
          console.log('crud_conduta_exames');
          console.log(res2.data);  
       }


        if (res2.data.length > 0 ){
          if (res2.data[0].CODIGO > 0) {

            this.items_exames =  res2.data;
            this.count_e = res2.data.length//qt historico
            //this.condutaItem['DESCRICAO']  = res2.data[0].DESCRICAO  
          }          
        }else{//limpa caso zere
          this.items_exames = [];//limpo array
          this.count_e = '0';
        }

        sessionStorage['print_exames']  =  JSON.stringify(res2.data)

        // this.permissoes()
    },


    imprimir_manipulado(MANIPULADO){
      

        let linha = '<p>'+ MANIPULADO +'<p>'
        // let linha = '<p>'+ this.EditaManipulado.MANIPULADO +'<p>'

        linha = linha.replaceAll('\n','<br>') 


        let titulo = 
                  `<p> <h3 style="text-align:center">
                    RECEITUÁRIO
                   </h3><p/>`

                  //usar o <p/> ao inves do <br/>

        let paciente = '<p><strong> '+ this.getNomePaciente  +' </strong></p> <p/>'   
        
        let cidade = '<br/><br/><p/> <p style="text-align: center">'+ sessionStorage['LSCidade2'] +', '+ this.hoje +'</p>'
        let profissional = '<p style="text-align: center"> <br/> ___________________________________ <br/>'+ this.getNomeDoc+' - CRM:'+ this.getCRMdoc +'</p>'

        let rodape = this.modelos_rodape(1)
   
        
        // cidade + profissional
        this.passa_local_storage()//antes para limpar alguns campos
        sessionStorage['printConteudo'] = titulo + paciente + linha ; 
        sessionStorage['printRodape']   = rodape;
        sessionStorage['printTipo'] = 'Receitas'
        sessionStorage['LSreceita'] = linha
        

        
        let route = this.$router.resolve({path: '/print'});
        window.open(route.href, '_blank');
    },

    salvar_manipulado(){

        this.salvar('EditaManipulado','CODIGO','RECEITAS')
      
        setTimeout(function(){    
          this.crud_manipulado(this.getCodigoPaciente, this.getCodigoConsulta)
        }.bind(this), 555);//usar esse .bind(this) para chamar funcoes com o setTimeout
    },

    deletar_manipulado(item){

       if (confirm("Deseja realmente excluir? " + item.MEDICAMENTO +' - ' + this.getFormatDate(item.DATA,'br'))){

          this.crud_delete(item.CODIGO, 'RECEITAS')

          this.EditaManipulado = {}

          setTimeout(function(){    
            this.crud_manipulado(this.getCodigoPaciente, this.getCodigoConsulta)
            this.crud_conduta_receitas(this.getCodigoConsulta)
          }.bind(this), 555);//usar esse .bind(this) para chamar funcoes com o setTimeout
       }
    },

    editar_manipulado(item){

      this.EditaManipulado = Object.assign({}, item)
      // this.edita_imc.CODIGO      = item.CODIGO
      // this.edita_imc.DATA        = item.DATA

    },


    async novo_manipulado(table,fields,item){

      // console.log('novo_manipulado:', item);

      this.mostra_manipulado(item)

    
      this.$set(this.EditaManipulado, 'DATA',              this.today)
      this.$set(this.EditaManipulado, 'DATAHORA',          'now')
      this.$set(this.EditaManipulado, 'CODIGOCONSULTA',    this.getCodigoConsulta )
      this.$set(this.EditaManipulado, 'TIPO',              'M')
      this.$set(this.EditaManipulado, 'ATIVO',             'S')
      this.$set(this.EditaManipulado, 'VER',               'S')
      this.$set(this.EditaManipulado, 'DOSE',              '.')
      this.$set(this.EditaManipulado, 'POSOLOGIA',         '.')
      this.$set(this.EditaManipulado, 'USO',               '.')
      this.$set(this.EditaManipulado, 'CODIGOCLIENTE',     this.getCodigoPaciente )
      this.$set(this.EditaManipulado, 'CODIGOMEDICO',      this.getCodigoDoc )
      this.$set(this.EditaManipulado, 'CODIGOCONVENIO',    this.getCodigoConvenio )

      this.$set(this.EditaManipulado, 'MEDICAMENTO',       item.NOME)
      this.$set(this.EditaManipulado, 'MANIPULADO',        item.MODELO)
      this.$set(this.EditaManipulado, 'CODIGO',        '')


      // console.log('this.EditaManipulado:')
      // console.log(this.EditaManipulado);

     await this.crud_create(table,fields);

     await update(this)

       async function update(that){
         await that.crud_manipulado(that.getCodigoPaciente, that.getCodigoConsulta)
       }


    },
    
    async crud_manipulados_mais () {//historico de receitas do medico

      // this.expandir_manipulado = true
      // closeAll(expandir_manipulado)

      let doc = this.getCodigoDoc


      if (this.manipulados_ultimos ==''){

          var meuSQL = 
          `
            SELECT
            MANIPULADOS.CODIGO,
            MANIPULADOS.NOME,
            MANIPULADOS.MODELO,
            MANIPULADOS.TEXTO
            FROM
            MANIPULADOS
            WHERE
              (MANIPULADOS.CODIGO_PROFISSIONAL = `+ doc +`)
            ORDER BY
              MANIPULADOS.NOME
          `

          // console.log(meuSQL);
          
          const res = await axios.post(url+'conexao.php', {
              acao:   5,
              meuSQL: meuSQL
          })
        
            // console.log('manipulados_ultimos');
            // console.log('manipulados_ultimos:', res.data);  

          this.manipulados_ultimos   = res.data;   


      }
    },
    
    async crud_manipulado(ide,cs){

        var meuSQL = 
        `
        SELECT 
        RECEITAS.CODIGO,
        RECEITAS.CODIGOCLIENTE,
        RECEITAS.CODIGOCONSULTA,
        RECEITAS.CODIGOMEDICO,
        RECEITAS.CODIGOCONVENIO,
        RECEITAS.TIPO,
        RECEITAS.ATIVO,
        RECEITAS.MEDICAMENTO,
        RECEITAS.DATA,
        RECEITAS.VER,
        RECEITAS.MANIPULADO
      FROM
      RECEITAS
        WHERE
          (RECEITAS.CODIGOCONSULTA = `+ cs +`)AND
          (RECEITAS.CODIGOCLIENTE = `+ ide +`) AND
          ((RECEITAS.TIPO = 'M') OR 
          (RECEITAS.MANIPULADO IS NOT NULL))
        ORDER BY
          RECEITAS.MANIPULADO,
          RECEITAS.CODIGO DESC
              `        

        // console.log(meuSQL);
        
          const res = await axios.post(url+'conexao.php', {
            acao:   5,
            meuSQL: meuSQL
          })

        this.items_manipulado   = res.data;
        if (res.data.length > 0 ){
          this.items_manipulado   = res.data; 
          this.count_manipulado   = res.data.length 
          
          // console.log('this.items_manipulado:');
          // console.log(this.items_manipulado);
        }
        
        this.associa_registro('DATA','MEDICAMENTO', 'EditaManipulado', 'items_manipulado')

    },    

    async crud_create(table,fields){

        // alert(fields['DESCRICAO'])

        const data = {
            acao    :  2,
            table   :  table,
            fields  :  fields,
        }  

        const res = await axios.post(url+'conexao.php', data);

        console.log(res.data);
        
        //refresh nas tabelas apos o save
        if (table == 'receitas'){

            //refresh receitas
             await this.crud_conduta_receitas(this.getCodigoConsulta)
        }
        else if (table == 'exames'){

            //refresh exames
            this.crud_conduta_exames(this.getCodigoConsulta)
        }
        else if (table == 'procedimento'){
            //refresh procedimento = conduta
            this.crud_conduta(this.getCodigoConsulta)//update values

        }
      

        if (res.data.includes('ERROR:')){
          this.alerta(res.data, "red");
          return 'ERRO'
        }else{
          this.alerta(res.data, "success");
          return 'OK'
        }
        
    },     
    
    async crud_update(id_field,id,table,fields){

        if (id != null && id !== undefined) {
            

          console.log(fields);
        // console.log(this.editedItem);
            const res = await axios.post(url+'conexao.php', {
            acao: 3,
            id:                id,
            id_field:          id_field, 
            table:             table,
            fields:            fields,
            // ativo:             this.editedItem.ativo,              
            })

            if(this.TESTE == true){
              console.log('crud_update');
              console.log(res.data);
            }
            this.alerta(res.data, "success");
        }else{
            this.alerta("id inválido","red");
        }
    },

    // async crud_sql (sql) {

    //     const res = await axios.post(url+'conexao.php', {
    //         acao:   6,
    //         meuSQL: sql
    //     })
      
    //     console.log(res.data);
    //     this.alerta(res.data, "success");//so retorna ok ou error

    //     // return res.data
    //     return true
    // },



    async crud_delete(id, table){
                
        //  alert('crud delete id:'+id);
        if (id != null && id !== undefined) {

            const res = await axios.post(url+'conexao.php', {
              acao  : 4,
              id    : id,
              // table : this.crud_table
              table : table
            })

            console.log(res.data);
            this.alerta(res.data, "success"); 

           //atualiza apos deletar conforme tabela selecionada 
           if (this.crud_table == 'receitas'){
             console.log('atualizando.. '+this.crud_update);
              this.crud_conduta_receitas(this.getCodigoConsulta)           
           } 
           if (this.crud_table == 'exames'){
             console.log('atualizando.. '+this.crud_update);
              this.crud_conduta_exames(this.getCodigoConsulta)
           } 
            
          
        }else{
          this.alerta("id inválido","red");
        }
    },



           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
             return await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios


              if (this.def_parcelas.length>0){//caso tenha gerado parcels multiplas
                
                // Transformando o loop for em um loop assíncrono
                await Promise.all(this.def_parcelas.map(async (def_parcela, i) => {
                    // console.log('this.def_parcelas[i]:', i, ' - ', def_parcela);
                    await DSisql.salvar_tabela(this, tabela, def_parcela, dialog_name);
                }));
              }
              else{
                await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)//modo normal
              }
              
          },
  
          async remover_tabela(tabela, obj_edita, titulo){
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
          },
  
          async editar_tabela(item, obj_edita_name, dialog_name) {
              this.seleciona_linha(item)
              this.campos_adicionais(this.item_edita)
              await DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },



  }
}
</script>

<style scoped>
/* <style lang="scss"> */
/* <style scoped> */
  .container{
    max-width: 100%;
  }


menor{
  font-size: 12px;
  color: grey
}

.menor{
  font-size: 12px;
  color: grey
}

.div_menor{
  font-size: 12px;
  color: grey
}

.text aa{
  font-size: 20px;
  font-weight: bolder;
  color: black
}

.text bb{
  font-size: 20px;
  color: #424242
}
.text cc{
  font-size: 20px;
  font-weight: bolder;
  color: black
}

.v-chip{
    /* margin-bottom: 3px; */
}


.v-textarea  {
  font-size: 10pt;
/* line-height: 1.7 !important;  */
}

.v-tab{
  font-size: 8pt; /* usando o sass-variables */
  color: red;
}

.mesmalinha{
  display: inline;
  background-color: red;
}

.count_item {
  position: relative;
  top: 0px;
  font-size: 90%;
  vertical-align: super;
  color: red;
}

.v-chip_2{
  font-size: 12px !important;
  height: 24px !important;
  /* padding: 5px !important; */
}


.only-print {
  display: none !important;
}



.v-textarea ::v-deep textarea {  
    line-height: 20px; /* tem que usar o ::v-deep quando usa scope */
}


.v-data-table >>> tr>td{
  font-size:12px !important;
  height: 25px !important;
}

/* .v-list-item {
  padding: 0px;
} */




/* Table-specific styling */
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }

}

.tableWrapper {
  overflow-x: auto;
}

/* quando clica em uma coluna ele fica com bordas */
.theme--light.v-data-table tbody  tr.v-data-table__selected  td {
    /* seleciona_linha cor */
    /* background: red !important; */
    /* background: #eee9e9 !important; */
    border-bottom: 1px solid silver !important; 
    border-top: 1px solid silver !important; 
}

@media print {
    .no-print {
      display: none !important;
      /* margin: 5cm !important; */
    }

    .v-app,
    .v-footer,
    .v-navigation-drawer,
    .v-card-actions,
    .v-toolbar {
      display: none !important;
    }

 }


 .menos_marge{
  margin-bottom: 1px;
 }

 .v-application p ::v-deep p {
    margin-bottom: 8px;
 }
 


</style>