<template>
    <v-container>
      <v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
          :fullscreen="imprimindo"
          class=""
      >


      <v-card class="" :elevation="elevacao">

        <!-- {{ item_clicou_agenda }} -->


      <v-card-title class="mb-1 text-h5">
        <v-col cols="12" class="d-flex align-center justify-center " v-if="urlLogo">
            <img
              v-if="urlLogo"
              :src="urlLogo"
              :style="[{'max-width': 'auto', 'max-height': altura_logo+'cm'}]"
            >
            
        </v-col>
        
        <v-col class="d-flex align-center justify-center " v-if="param_titulo">
          <h6 v-html="quebra_linha(param_titulo)" class="text-center"></h6>
        </v-col>

      </v-card-title>

      <v-card-subtitle class="text-center" v-if="param_sub_titulo">
          <h3 class="blue--text mr-2">
             {{ param_sub_titulo }}
          </h3>
      </v-card-subtitle>
      
      <v-card-text class="pa-1">
          <v-container>
              <v-row>
                  <v-col class="" cols="12"  >

                      <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                      <v-textarea
                        v-model="param_texto"
                        label=""
                        dense
                        rows="25"
                        readonly
                      >
                      </v-textarea>
                      
                  </v-col>
              </v-row>
          </v-container>

          <h5 class="text-center"> {{param_rodape}} </h5>
      </v-card-text>

      <v-card-actions class="no-print" v-if="(!imprimindo)">
          <v-spacer></v-spacer>

          <v-btn
              color="primary"
              @click="imprimir()"
              class="mr-5"
          >
            Imprimir
          </v-btn>

          <v-btn
              color="error"
              @click="close()"
              class="mr-5"
          >
            fechar
          </v-btn>

      </v-card-actions>
      </v-card>
  </v-dialog>


    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        
        altura_logo:'2',
        dialog_tabela: true,
        selectedComponent: null,

        imprimindo : false,
        elevacao: 4,

        modelo:'',
        param_texto: "",
        param_titulo: "",
        param_sub_titulo: "",
        param_rodape: "",
        param_item:{},
        item_clicou_agenda:{},

        urlLogo:'/api/images/mc_45/logo.png',

        componentOptions: ["v-data-table", "v-textarea"],

      };
    },

    created() {
      this.onCreate()
    },

    methods: {

      async onCreate(){
        
        this.urlLogo   =  sessionStorage['emp_url_logo']

        const storedComponent   = sessionStorage.getItem("param_texto");
        this.selectedComponent  = storedComponent || null;

        this.param_modelo       = sessionStorage['param_modelo']              || "";
        // this.param_texto        = sessionStorage['param_texto']               || "";
        // this.param_titulo       = sessionStorage['param_titulo']              || "";
        // this.param_sub_titulo   = sessionStorage['param_sub_titulo']          || "";
  
        this.param_item         = JSON.parse(sessionStorage['param_item'])    || "";
        this.item_clicou_agenda = JSON.parse(sessionStorage['item_clicou_agenda'])    || "";//pega da agenda.vue


        let item   = this.param_item
        let clicou = this.item_clicou_agenda

        if (this.param_modelo == 'CONDUTA'){
            this.param_texto  = item.DESCRICAO
            this.param_titulo = clicou.NOME + ' - '+clicou.CONVENIO
            this.param_sub_titulo = 'ATENDIMENTO - '+clicou.x_DATA

            this.param_rodape =  sessionStorage['LSendereco'] + '⠀⠀⠀⠀⠀'+ sessionStorage['LSfone1']


            setTimeout(function(){
                this.imprimir()
            }.bind(this), 100);//usar esse .bind(this) para chamar funcoes com o setTimeout
        }
        
      },

      close(){
        window.close()
      },

      imprimir(){

        this.elevacao = 0 //remove elevacao
        this.imprimindo = true

        // this.largura_dif = 0
        // this.largura_pagina =  parseFloat(this.largura_pagina) - parseFloat(this.margem)

        setTimeout(function(){
          window.print()
        }.bind(this), 100);//usar esse .bind(this) para chamar funcoes com o setTimeout

        setTimeout(function(){
          this.elevacao = 4 //volta elevacao
          this.imprimindo = false
          // this.largura_dif = '2.5'
          // this.largura_pagina =  parseFloat(this.largura_pagina) + parseFloat(this.margem)
        }.bind(this), 1000);//usar esse .bind(this) para chamar funcoes com o setTimeout


        // window.print()
      },

      quebra_linha(msg){
        msg = msg.replaceAll('\n', '<br/>')
        return msg
      },

      printContent() {
        const printWindow = window.open("", "_blank");
        printWindow.document.write('<html><head><title>Print</title></head><body>');
        printWindow.document.write(this.param_texto);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
      }
    }
  };
  </script>
  

  <style scoped>
  @media print {
    .v-image {
      display: block !important;
    }
    .no-print {
      display: none !important;
    }
  }
  </style>